import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import { orderBy, uniqBy } from "lodash";

import * as Variable from "../../utils/variables";
import { CloseEyeIcon, OpenEyeIcon } from "../svg/Icons";
import { classHelper } from "../../utils/stringUtils";
import closeCircle from "../../../assets/svg/close_circle.svg";
import arrowDown from "../../../assets/svg/arrow_down.svg";
import arrowTop from "../../../assets/svg/arrow_top.svg";
import helpIcon from "../../../assets/svg/help.svg";
import warningIcon from "../../../assets/svg/warning.svg";

import "./InputComponent.scss";
import Image from "../UI/Image";
import countries from "countries-list";

const countryCodes = Object.keys(countries.countries);
let PHONE_LIST = [];
countryCodes.forEach((code) => {
	if (countries.countries[code].phone?.includes(",")) {
		countries.countries[code].phone?.split(",")?.forEach((codeInside) => {
			PHONE_LIST.push({
				id: `+${codeInside}`,
				name: `+${codeInside}`,
				pureNumber: Number(codeInside),
			});
		});
	} else if (countries.countries[code].phone) {
		PHONE_LIST.push({
			id: `+${countries.countries[code].phone}`,
			name: `+${countries.countries[code].phone}`,
			pureNumber: Number(countries.countries[code].phone),
		});
	}
});
PHONE_LIST = uniqBy(orderBy(PHONE_LIST, "pureNumber"), "id");

// DOCUMENTATION
// inputType : [label, email, salary, phone, password, textarea, salary, phone]
// dropdownType : [location, search, college, company]
// if use textarea, we have to put value and onChange props

const Input = ({
	dropdownType,
	inputType,
	spesificType,
	autoComplete,
	label,
	name,
	placeholder,
	value,
	onChange,
	rows,
	cols,
	noHint,
	errorHintText,
	withTooltip,
	required,
	hideLabelRequired,
	isError,
	disabled,
	onClickClose,
	ENV_NAME,
	id,
	choiceLabel,
	checked,
	characterLimit = 5000,
	...props
}) => {
	// Probably temporary value
	const [isOpen, setIsOpen] = useState(false);
	const [phoneCode, setPhoneCode] = useState("+62");
	const [isShowPassword, setIsShowPassword] = useState(false);

	useEffect(() => {
		let code = getPhoneValue(value, "code");
		if (PHONE_LIST && inputType === "phone" && value && code !== phoneCode) {
			if (PHONE_LIST?.find((i) => i?.id === code)) {
				setPhoneCode(code);
			} else {
				setPhoneCode("+62");
			}
		}
	}, [PHONE_LIST, value, inputType, phoneCode]); // eslint-disable-line

	const getPhoneValue = (value, type = "number") => {
		const phoneNumber = parsePhoneNumber(`${value || ""}`);
		if (!phoneNumber) return null;
		let phoneSplit = phoneNumber.formatInternational()?.split(" ");
		if (type === "code") return phoneSplit?.[0];
		return phoneSplit?.slice(1, phoneSplit?.length).join("");
	};

	const handleMaxChange = (event) => {
		onChange(event?.target?.value?.slice(0, characterLimit));
	};

	return (
		<div className={classHelper("J-inputComp", props.className)}>
			{
				// These are for Input dropdown input
				dropdownType ? (
					<div className="positionRelative">
						{label && (
							<label>
								{dropdownType === "location"
									? Variable.LOCATION_LABEL[ENV_NAME]
									: dropdownType === "search"
									? Variable.SEARCH_LABEL[ENV_NAME]
									: dropdownType === "college"
									? Variable.COLLEGE_LABEL[ENV_NAME]
									: dropdownType === "company"
									? Variable.COMPANY[ENV_NAME]
									: label}
							</label>
						)}
						<input
							className={`J-inputComp-input ${
								dropdownType === "location"
									? "J-inputComp-location"
									: dropdownType === "search"
									? "J-inputComp-search"
									: dropdownType === "college"
									? "J-inputComp-college"
									: dropdownType === "company"
									? "J-inputComp-company"
									: "J-inputComp-dropdown"
							}`}
							autoComplete={autoComplete}
							type={spesificType}
							name={name || `inputComponent-${dropdownType}`}
							placeholder={placeholder ?? Variable.SRC_PLACEHOLDER[ENV_NAME]}
							value={value}
							onChange={(e) => {
								onChange(e);
								if (dropdownType === "dropdown" && e?.target?.value?.length !== 0) {
									setIsOpen(true);
								} else {
									setIsOpen(false);
								}
							}}
							disabled={disabled}
						/>
						{(value?.length !== 0 && dropdownType === "location") ||
						(value?.length !== 0 && dropdownType === "search") ||
						(value?.length !== 0 && dropdownType === "college") ||
						(value?.length !== 0 && dropdownType === "company") ? (
							<Image
								className="J-inputComp-rightBtn cursorPointer"
								onClick={onClickClose}
								src={closeCircle}
							/>
						) : dropdownType === "dropdown" ? (
							<Image
								className="J-inputComp-rightBtn cursorPointer"
								onClick={() => setIsOpen(false)}
								src={`${isOpen ? arrowDown : arrowTop}`}
							/>
						) : (
							<></>
						)}
					</div>
				) : // These are for input field
				inputType ? (
					<div>
						{label && (
							<label
								className={`${required && !hideLabelRequired ? "isRequired" : ""}`}
							>
								{inputType === "label"
									? label || Variable.LABEL_NAME[ENV_NAME]
									: inputType === "email"
									? label || Variable.EMAIL[ENV_NAME]
									: inputType === "salary"
									? label || Variable.SALARY[ENV_NAME]
									: inputType === "phone"
									? label || Variable.PHONE[ENV_NAME]
									: inputType === "password"
									? label || Variable.PASSWORD[ENV_NAME]
									: inputType === "textarea"
									? label || Variable.TEXTAREA[ENV_NAME]
									: label}
								{withTooltip && (
									<Image className="J-inputComp-tooltip" src={helpIcon} />
								)}
							</label>
						)}
						{
							// These are for salary and phone type
							inputType === "salary" || inputType === "phone" ? (
								<div
									className={`d-flex positionRelative ${
										isError ? "borError" : ""
									} ${disabled ? "disable" : ""}`}
								>
									{inputType === "salary" ? (
										<select
											className="J-inputComp-curSalDropdown"
											disabled={disabled}
										>
											{Variable.CURRENCY_LIST?.map((cl, clIdx) => (
												<option key={clIdx}>{cl?.name}</option>
											))}
										</select>
									) : (
										<select
											className="J-inputComp-curSalDropdown"
											disabled={disabled}
											value={phoneCode}
											onChange={(e) => {
												onChange({
													type: "change",
													target: {
														name: name || `inputComponent-${inputType}`,
														value: `${e.target.value}${getPhoneValue(
															value,
														)}`,
													},
												});
											}}
										>
											{PHONE_LIST?.map((pl, plIdx) => (
												<option key={plIdx}>{pl?.name}</option>
											))}
										</select>
									)}
									<input
										id="special-field"
										className={`J-inputComp-input`}
										autoComplete={autoComplete}
										type={`${
											inputType === "salary" || inputType === "phone"
												? "number"
												: spesificType
										}`}
										min={`${
											inputType === "salary" || inputType === "phone" ? 0 : ""
										}`}
										name={name || `inputComponent-${inputType}`}
										placeholder={
											inputType === "salary"
												? Variable.SALARY_PLACEHOLDER[ENV_NAME]
												: inputType === "phone"
												? Variable.PHONE_PLACEHOLDER[ENV_NAME]
												: placeholder
										}
										value={`${
											// inputType === "phone" ? getPhoneValue(value) :
											value
										}`}
										onChange={(e) => {
											if (inputType === "phone") {
												onChange({
													type: "change",
													target: {
														name: name || `inputComponent-${inputType}`,
														// need to add code to send code in different value
														value: `${e.target.value}`,
													},
												});
											} else {
												onChange(e);
											}
										}}
										disabled={disabled}
									/>
									{isError && (
										<Image
											className="J-inputComp-rightBtn warningIcon"
											src={warningIcon}
										/>
									)}
								</div>
							) : inputType === "textarea" ? (
								// This is for Textarea input field
								<div>
									<textarea
										id="textarea"
										name="textarea"
										rows={rows ?? "4"}
										cols={cols ?? "50"}
										maxLength={characterLimit}
										onChange={handleMaxChange}
										value={value}
										placeholder={placeholder}
										className={`J-inputComp-input ${isError ? "borError" : ""}`}
									/>
								</div>
							) : inputType === "radio" ? (
								<div className="J-inputComp-choice">
									<input
										id={id}
										type="radio"
										name={name}
										className="J-inputComp-choice-option"
										value={value}
										onChange={onChange}
										checked={checked}
									/>
									<label
										htmlFor={id}
										className={`J-inputComp-choice-label ${
											isError ? "choice-field-error" : ""
										}`}
									>
										{choiceLabel}
									</label>
								</div>
							) : inputType === "checkbox" ? (
								<div className="J-inputComp-choice">
									<input
										id={id}
										type="checkbox"
										name={name}
										className="J-inputComp-choice-option"
										value={value}
										onChange={onChange}
										checked={checked}
									/>
									<label
										htmlFor={id}
										className={`J-inputComp-choice-label ${
											isError ? "choice-field-error" : ""
										}`}
									>
										{choiceLabel}
									</label>
								</div>
							) : (
								// These are for email, label, password, and etc
								<div className="positionRelative">
									<input
										id={`${inputType === "email" ? "" : "normal-field"}`}
										className={`J-inputComp-input ${
											inputType === "email" ? "J-inputComp-email" : ""
										} ${isError ? "borError" : ""} ${
											disabled ? "disable" : ""
										}`}
										autoComplete={autoComplete}
										type={`${
											inputType === "password" && isShowPassword
												? "text"
												: inputType === "password" && !isShowPassword
												? "password"
												: inputType === "label"
												? "label"
												: inputType === "email"
												? "email"
												: spesificType
										}`}
										name={name || `inputComponent-${inputType}`}
										placeholder={
											placeholder ?? Variable.SRC_PLACEHOLDER[ENV_NAME]
										}
										value={value}
										onChange={(e) => {
											onChange(e);
										}}
										disabled={disabled}
									/>
									{isError && (
										<Image
											className="J-inputComp-rightBtn warningIcon"
											src={warningIcon}
										/>
									)}
									{inputType === "password" && (
										<div
											className={`onOffPassword cursorPointer ${
												!isShowPassword ? "active" : ""
											}`}
											onClick={() => setIsShowPassword((p) => !p)}
										>
											{isShowPassword ? (
												<OpenEyeIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#056cf2"
												/>
											) : (
												<CloseEyeIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#BEC4CF"
												/>
											)}
										</div>
									)}
								</div>
							)
						}
						<div
							className={`${
								noHint
									? "d-flex justify-content-between"
									: "d-flex justify-content-end"
							}`}
						>
							{noHint && (
								<p className={`J-inputComp-below ${isError ? "error" : ""}`}>
									{isError && errorHintText
										? errorHintText
										: isError
										? Variable.ERROR_HINT_MESSAGE[ENV_NAME]
										: Variable.HINT_MESSAGE[ENV_NAME]}
								</p>
							)}
							{inputType === "textarea" && (
								<p className={`J-inputComp-below ${isError ? "error" : ""}`}>
									{value?.length ?? 0} / {characterLimit}
								</p>
							)}
						</div>
					</div>
				) : (
					<></>
				)
			}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Input));
