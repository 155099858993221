/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import moment from "moment";
import DatePicker, {
	DateObject,
	DatePickerRef,
	Value as DatePickerValueType,
} from "react-multi-date-picker";
import * as Icons from "react-icons/bs";
import { Overlay } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

import { MyJobsBadge } from "types/API/TypesSwagger";
import Search from "assets/svg/search-myjobs.svg";
import useToast from "shared/hooks/useToast";
import useFetch from "shared/hooks/useFetch";
import Image from "shared/component/UI/Image";
import * as Variables from "shared/utils/variables";
import useTranslate from "shared/hooks/useTranslate";
import { useEnvironment } from "shared/hooks/useEnvironment";
import Button from "shared/component/ButtonComponent/Button";
import { Typography } from "shared/component/Typography/Typography";
import TabNavigation from "shared/component/TabNavigation/TabNavigation";
import indonesian_locale from "shared/utils/multiDatePickerIndonesiaLocales";

import { TabPropsTypes } from "./Types";
import "./MyJobsComponent.scss";

// Import Tabs
import BookmarkTab from "./BookmarkTab";
import AppliedTab from "./AppliedTab";
import InterviewTab from "./InterviewTab";
import WithdrawnTab from "./WithdrawnTab";
import ComingSoon from "./ComingSoon";
import { INCOMPLETE_APPLICATIONS } from "shared/utils/routeLink";
import { Application } from "./IncompleteApplication/IncompleteApps";

const sortApplicationOptions = () => {
	type AvailableOrderingsNameType = Record<
		string,
		Record<"asc" | "desc", { label: keyof typeof Variables; name: string }>
	>;

	const availableOrderingsName: AvailableOrderingsNameType = {
		AZ: {
			asc: {
				label: "ASC_AZ",
				name: "asc",
			},
			desc: {
				label: "DESC_AZ",
				name: "desc",
			},
		},
		// Reverse the naming because for due to the behavior of date in the backend is inverted
		newestOldest: {
			asc: {
				label: "DESC_NEWESTOLDEST",
				name: "desc",
			},
			desc: {
				label: "ASC_NEWESTOLDEST",
				name: "asc",
			},
		},
	};

	const availableOptions: Record<
		string,
		{ label: string; name: string; order: AvailableOrderingsNameType[string] }
	> = {
		company_name: {
			label: "COMPANY_NAME_ORDERBY",
			name: "company_name",
			order: availableOrderingsName.AZ,
		},
		job_title: {
			label: "JOB_TITLE_ORDERBY",
			name: "job_title",
			order: availableOrderingsName.AZ,
		},
		date_applies: {
			label: "DATE_APPLIES_ORDERBY",
			name: "date_applies",
			order: availableOrderingsName.newestOldest,
		},
		date_withdrawn: {
			label: "DATE_WITHDRAWN_ORDERBY",
			name: "date_withdrawn",
			order: availableOrderingsName.newestOldest,
		},
	};

	const tabAvailableFilters = {
		bookmark: {
			options: [availableOptions.job_title, availableOptions.company_name],
		},
		applied: {
			options: [
				availableOptions.date_applies,
				availableOptions.job_title,
				availableOptions.company_name,
			],
		},
		interview: {
			options: [
				availableOptions.date_applies,
				availableOptions.company_name,
				availableOptions.job_title,
			],
		},
		withdrawn: {
			options: [
				availableOptions.date_withdrawn,
				availableOptions.date_applies,
				availableOptions.job_title,
				availableOptions.company_name,
			],
		},
	};

	const getOrderingOptionsByName = (name: string, ordering: string) => {
		return availableOptions[name]?.order[ordering];
	};

	const getOptionsByName = (name: string) => {
		return availableOptions[name];
	};

	const formatSortOrderBy = (filterBy: string, orderBy: string) =>
		`${orderBy === "desc" ? "-" : ""}${filterBy}`;

	return Object.assign(tabAvailableFilters, {
		getOptionsByName,
		getOrderingOptionsByName,
		formatSortOrderBy,
	});
};

type StatusOptions = Record<string, keyof typeof Variables>;

const filterStatusOptions: StatusOptions = {
	// ongoing: "On going", // Disabled because it is redundant with pending
	all: "ALL",
	pending: "PENDING_APPLIED",
	viewed: "VIEWED",
	prospect: "PROSPECT",
	shortlist: "SHORTLIST",
	interview: "INTERVIEW",
	offered: "OFFERED",
	hired: "HIRED",
	rejected: "REJECTED",
};

const interviewStatusOptions: StatusOptions = {
	all: "ALL",
	accepted: "ACCEPTED",
	declined: "DECLINED",
	pending: "PENDING_INTERVIEW",
};

type TabsType = Record<
	string,
	{
		label: keyof typeof Variables;
		Component: React.JSXElementConstructor<TabPropsTypes>;
		feature: Array<"sortOrderBy" | "filterStatus" | "dateRange">;
		badgeCounterName: string;
		availableFilterStatus?: StatusOptions;
	}
>;

const Tabs: TabsType = {
	bookmark: {
		label: "BOOKMARK",
		Component: BookmarkTab,
		feature: ["sortOrderBy"],
		badgeCounterName: "bookmark_counter",
	},
	applied: {
		label: "APPLIED",
		Component: AppliedTab,
		feature: ["sortOrderBy", "filterStatus", "dateRange"],
		badgeCounterName: "applied_counter",
		availableFilterStatus: filterStatusOptions,
	},
	interview: {
		label: "INTERVIEW",
		// Component: InterviewTab,
		Component: ComingSoon,
		// feature: ["sortOrderBy", "filterStatus", "dateRange"],
		feature: [],
		badgeCounterName: "interview_counter",
		availableFilterStatus: interviewStatusOptions,
	},
	withdrawn: {
		label: "WITHDRAWN",
		Component: WithdrawnTab,
		feature: ["sortOrderBy", "dateRange"],
		badgeCounterName: "withdrawn_counter",
	},
};

export type TabsEnum = keyof typeof Tabs;

const MyJobsComponent = () => {
	const t = useTranslate();
	const history = useHistory();

	const jobtypeRef = useRef<HTMLDivElement>(null);
	const alphaOrderRef = useRef<HTMLDivElement>(null);
	const jobstatusRef = useRef<HTMLDivElement>(null);
	const keywordsRef = useRef<HTMLInputElement>(null);
	const calendarRef = useRef<DatePickerRef>(null);

	const [tabName, setTabName] = useState<TabsEnum>("bookmark");
	const [jobtypedropdown, setJobtypedropdown] = useState(false);
	const [alphaorderdropdown, setAlphaorderdropdown] = useState(false);
	const [jobstatusdropdown, setJobstatusdropdown] = useState(false);
	const [jobstatus, setJobstatus] = useState<string>("all");
	const [filterBy, setFilterBy] = useState("");
	const [orderBy, setOrderBy] = useState("");
	const [rangeDateTemp, setRangeDateTemp] = useState<DatePickerValueType>([
		new DateObject().subtract(6, "month"),
		new DateObject(),
	]);
	const [rangeDate, setRangeDate] = useState<DatePickerValueType>([
		new DateObject().subtract(6, "month"),
		new DateObject(),
	]);

	const { data: badgeData, getDatas: badgeRefetch } = useFetch<MyJobsBadge>("getJobBadge");
	const { data: applicationData } = useFetch<Application[]>("getJobApplication", {
		queryParams: { stage: "pending" },
	});
	const ENV_NAME = useEnvironment();
	const toast = useToast();

	const SelectedTab = Tabs[tabName];
	const isDateRange = SelectedTab.feature.indexOf("dateRange") >= 0;
	const isSortOrderBy = SelectedTab.feature.indexOf("sortOrderBy") >= 0;

	useEffect(() => {
		setJobstatus("all");
	}, [tabName]);

	useEffect(() => {
		const orderByDefaultOption = sortApplicationOptions()[tabName].options[0];

		setFilterBy(orderByDefaultOption.name);
		setOrderBy(orderByDefaultOption.order.asc.name);
	}, []);

	const refetchBadge = () => {
		if (keywordsRef.current === null) return;
		badgeRefetch({ queryParams: { keyword: keywordsRef.current.value } });
	};

	const handleSearchChange = () => {
		if (keywordsRef.current === null) return;
		badgeRefetch({ queryParams: { keyword: keywordsRef.current.value } });
	};

	const handleResetSortOrder = (header: string) => {
		const orderByDefaultOption = sortApplicationOptions()[header].options[0];

		setFilterBy(orderByDefaultOption.name);
		setOrderBy(orderByDefaultOption.order.asc.name);
	};

	const handleOrderTypeChange = (name: string) => {
		const orderByName = sortApplicationOptions().getOptionsByName(name);

		setFilterBy(name);
		setOrderBy(orderByName.order.asc.name);
	};

	const handleChangeTabHeader = (name: TabsEnum) => {
		setTabName(name);
		handleResetSortOrder(name);
	};

	const handleApplyDateRange = (applyChange: boolean) => () => {
		if (applyChange) setRangeDate(rangeDateTemp);
		else setRangeDateTemp(rangeDate);

		calendarRef.current?.closeCalendar();
	};

	const TabProps: TabPropsTypes = {
		keyword: keywordsRef?.current?.value ?? "",
		refreshBadge: refetchBadge,
		sortOrderBy: sortApplicationOptions().formatSortOrderBy(filterBy, orderBy),
		jobStatus: jobstatus,
		dateRangeStart: isDateRange ? rangeDate?.[0]?.format("YYYY-MM-DD") : undefined,
		dateRangeEnd: isDateRange ? rangeDate?.[1]?.format("YYYY-MM-DD") : undefined,
	};

	const renderFilterStatusJobs = Object.keys(Tabs)
		.filter((v) => v === tabName && Tabs[v].feature.indexOf("filterStatus") >= 0)
		.map((v) => (
			<div
				key={v}
				onClick={() => setJobstatusdropdown(!jobstatusdropdown)}
				ref={jobstatusRef}
			>
				<div className="job-dropdown width-330">
					<span>
						{t((Tabs[v].availableFilterStatus?.[jobstatus] ?? "").toUpperCase())}
					</span>
					<i className="fa fa-solid fa-angle-down"></i>
				</div>
				<Overlay
					target={jobstatusRef.current}
					show={jobstatusdropdown}
					placement="bottom-start"
					rootClose
					onHide={() => {
						setJobstatusdropdown(false);
					}}
				>
					{({ placement, arrowProps, show: _show, popper, ...OverlayProps }) => (
						<div {...OverlayProps} className="job-status-dropdown-options">
							{Object.keys(Tabs[v].availableFilterStatus ?? []).map((opt: string) => {
								return (
									<button
										key={opt}
										onClick={() => setJobstatus(opt)}
										className={clsx(
											"job-status-dropdown-options-item",
											jobstatus === opt ? "active" : "",
										)}
									>
										{t(
											(
												Tabs[v].availableFilterStatus?.[opt] ?? ""
											).toUpperCase(),
										)}
									</button>
								);
							})}
						</div>
					)}
				</Overlay>
			</div>
		));

	const renderSortBy = (
		<div
			onClick={() => setJobtypedropdown(!jobtypedropdown)}
			ref={jobtypeRef}
			style={{ marginRight: 10 }}
		>
			<div className="job-dropdown">
				<span>{t(sortApplicationOptions().getOptionsByName(filterBy)?.label)}</span>
				<i className="fa fa-solid fa-angle-down"></i>
			</div>
			<Overlay
				target={jobtypeRef.current}
				show={jobtypedropdown}
				placement="bottom-start"
				rootClose
				onHide={() => {
					setJobtypedropdown(false);
				}}
			>
				{({ placement, arrowProps, show: _show, popper, ...OverlayProps }) => (
					<div {...OverlayProps} id="hn-dropdown-lang" className="hn-dropdown hn-lang">
						{sortApplicationOptions()[tabName].options.map((v, k) => (
							<button
								key={k}
								onClick={() => handleOrderTypeChange(v.name)}
								className={[
									"hn-dropdown-item status-dropdown-item",
									filterBy === v.name ? "active" : "",
								].join(" ")}
							>
								<div className="hn-dropdown-item-label">{t(v.label)}</div>
							</button>
						))}
					</div>
				)}
			</Overlay>
		</div>
	);

	const disableOrderBy = (): boolean => {
		const test: Record<TabsEnum, boolean> = {
			bookmark: !badgeData?.bookmark_counter,
			applied: !badgeData?.applied_counter,
			interview: !badgeData?.interview_counter,
			withdrawn: !badgeData?.withdrawn_counter,
		};

		return test[tabName];
	};

	const renderOrderBy = (
		<div
			onClick={() => !disableOrderBy() && setAlphaorderdropdown(!alphaorderdropdown)}
			ref={alphaOrderRef}
		>
			<div className={clsx("job-dropdown", disableOrderBy() && "disabled")}>
				<span>
					{t(sortApplicationOptions().getOrderingOptionsByName(filterBy, orderBy)?.label)}
				</span>
				<i className="fa fa-solid fa-angle-down"></i>
			</div>
			<Overlay
				target={alphaOrderRef.current}
				show={alphaorderdropdown}
				placement="bottom-start"
				rootClose
				onHide={() => {
					setAlphaorderdropdown(false);
				}}
			>
				{({ placement, arrowProps, show: _show, popper, ...OverlayProps }) => (
					<div {...OverlayProps} id="hn-dropdown-lang" className="hn-dropdown hn-lang">
						{Object.keys(
							sortApplicationOptions().getOptionsByName(filterBy)?.order ?? {},
						).map((v) => {
							const orderByOption = sortApplicationOptions().getOrderingOptionsByName(
								filterBy,
								v,
							);
							return (
								<button
									key={orderByOption.name}
									onClick={() => setOrderBy(orderByOption.name)}
									className={[
										"hn-dropdown-item status-dropdown-item",
										orderBy === orderByOption.name ? "active" : "",
									].join(" ")}
								>
									<div className="hn-dropdown-item-label">
										{t(orderByOption.label)}
									</div>
								</button>
							);
						})}
					</div>
				)}
			</Overlay>
		</div>
	);

	const handleDatePickerChange = (date: DateObject | DateObject[]): void => {
		let momentDateStart = moment(date?.[0]?.format("YYYY-MM-DD"));
		let momentDateStart6Month = moment(date?.[0]?.format("YYYY-MM-DD")).add(6, "months");
		let momentDateEnd = moment(date?.[1]?.format("YYYY-MM-DD"));
		let momentDateEnd6Month = moment(date?.[1]?.format("YYYY-MM-DD")).subtract(6, "months");

		if (momentDateStart.isSameOrBefore(momentDateEnd6Month) && date[1] !== undefined) {
			toast.error(
				t(
					"MAX_6_MONTH_ERROR",
					momentDateEnd6Month.format("D MMM YYYY"),
					date?.[1]?.format("D MMM YYYY"),
				),
			);
			setRangeDateTemp([new DateObject(momentDateEnd6Month.format()), date?.[1]]);
			return;
		}

		if (momentDateEnd.isSameOrAfter(momentDateStart6Month) && date[1] !== undefined) {
			toast.error(
				t(
					"MAX_6_MONTH_ERROR",
					date?.[0].format("D MMM YYYY"),
					momentDateStart6Month.format("D MMM YYYY"),
				),
			);
			setRangeDateTemp([date?.[0], new DateObject(momentDateStart6Month.format())]);
			return;
		}

		setRangeDateTemp(date);
	};

	const DatePickerInput = (stringDate: string, openCalendar: () => any) => {
		return (
			<div className="search-bar-div" onClick={openCalendar}>
				<Icons.BsCalendar2Date />
				<input type="text" className="search-input" value={stringDate} readOnly={true} />
			</div>
		);
	};

	const renderDatePickerRange = (
		<DatePicker
			range
			rangeHover
			maxDate={new DateObject()}
			ref={calendarRef}
			value={rangeDateTemp}
			onChange={handleDatePickerChange}
			render={DatePickerInput}
			numberOfMonths={2}
			format="D MMM YYYY"
			dateSeparator=" - "
			renderButton={(direction: "left" | "right", handleClick: () => MouseEvent) =>
				direction === "left" ? (
					<Icons.BsChevronLeft color="#000" onClick={handleClick} />
				) : (
					<Icons.BsChevronRight color="#000" onClick={handleClick} />
				)
			}
			locale={ENV_NAME === "bhs" ? indonesian_locale : undefined}
			editable={false}
			calendarPosition="bottom-end"
		>
			<div className="date-bottom-cont">
				<div>
					<Typography.Text size="sm" fontWeight="medium">
						{((Array.isArray(rangeDateTemp) && rangeDateTemp) || [])
							.map((date: any) => date.format("D MMM YYYY"))
							.join(" - ")}
					</Typography.Text>
					<Typography.Text size="xs">{t("MAX_6_MONTH_LABEL")}</Typography.Text>
				</div>

				<div>
					<Button
						type="outline"
						title="Cancel"
						size="xs"
						onClick={handleApplyDateRange(false)}
					/>
					<Button
						type="primary"
						title="Terapkan"
						size="xs"
						onClick={handleApplyDateRange(true)}
					/>
				</div>
			</div>
		</DatePicker>
	);

	return (
		<div className="d-flex flex-column w-100 h-100 my-jobs-component">
			<div className="container">
				<p className="page-heading">{t("MY_JOBS")}</p>
				{applicationData?.length ? (
					<div className="data-div incomplete-div">
						<p className="mb-0 font-weight-semibold">{t("APP_INCOMPLETE")}</p>
						<Button
							type="error"
							size="sm"
							title={t("CHECK")}
							onClick={() => history.push(INCOMPLETE_APPLICATIONS)}
						/>
					</div>
				) : null}
				<div className="data-div">
					<div className="top-bar">
						<div className="search-bar-div">
							<Image className="" src={Search} />
							<input
								type="text"
								className="search-input"
								placeholder={t("MYJOB_SEARCH_PLACEHOLDER")}
								onChange={handleSearchChange}
								ref={keywordsRef}
							/>
						</div>
						<div className="date-range-cont">
							{isDateRange && renderDatePickerRange}
						</div>
					</div>
					<div>
						<TabNavigation
							tabs={Object.keys(Tabs).map((tab, k) => ({
								id: k,
								label: t(Tabs[tab].label),
								badge: badgeData?.[Tabs[tab].badgeCounterName],
							}))}
							onTabClick={(id) => handleChangeTabHeader(Object.keys(Tabs)[id])}
						/>
					</div>
					<div className="filters-div">
						<div>
							{/* Filter or interview_status */}
							{renderFilterStatusJobs}
						</div>
						<div className="d-flex">
							{isSortOrderBy && renderSortBy}
							{isSortOrderBy && renderOrderBy}
						</div>
					</div>
					<div className="jobs-div">
						<SelectedTab.Component {...TabProps} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyJobsComponent;
