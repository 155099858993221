// Import from external link
import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { classHelper } from "../../utils/stringUtils";
import { ArrowLeftIcon, KebabIcon, PencilIcon, PlusIcon } from "../svg/Icons";
import "./ProfileFrameComponent.scss";

const ProfileFrameComponent = ({ ENV_NAME, show, ...props }) => {
	return (
		show && (
			<div className={classHelper("profile-frame-comp", props.className)}>
				{(props.title || props.onEdit || props.onAdd) && (
					<div className="pfc-title-cont">
						<div className="d-flex align-items-center gap-xs">
							{props.onBack && (
								<button className="btn pfc-btn" onClick={props.onBack}>
									<ArrowLeftIcon
										stroke="#667085"
										width="2.4rem"
										height="2.4rem"
									/>
								</button>
							)}
							<div className="pfc-title">{props.title || ""}</div>
						</div>
						<div className="d-flex gap-xs">
							{props.onAdd && (
								<button className="btn pfc-btn" onClick={props.onAdd}>
									<PlusIcon stroke="#667085" width="2.4rem" height="2.4rem" />
								</button>
							)}
							{props.onEdit && (
								<button className="btn pfc-btn" onClick={props.onEdit}>
									<PencilIcon stroke="#667085" width="2.4rem" height="2.4rem" />
								</button>
							)}
							{props.dropdown && (
								<Dropdown>
									<Dropdown.Toggle
										as={React.forwardRef(({ children, onClick }, ref) => (
											<button
												className="btn pfc-btn"
												ref={ref}
												onClick={(e) => {
													e.preventDefault();
													onClick(e);
												}}
											>
												<KebabIcon
													stroke="#667085"
													width="2.4rem"
													height="2.4rem"
												/>
											</button>
										))}
									/>

									<Dropdown.Menu>
										{props.dropdown?.map((i, idx) => (
											<Dropdown.Item as="div" key={idx}>
												{i}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>
					</div>
				)}
				{props.children}
			</div>
		)
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapStateToDispatch
)(withRouter(ProfileFrameComponent));
