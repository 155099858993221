export const ROOT = "/";
export const COLLEGE = "/kampus";
export const MAJOR = "/major";
export const CAREER = "/karir";
export const SCHOLARSHIP = "/beasiswa";
export const FAVORITE = "/favorit";
export const PROFILE = "/profil";
export const CREATE_ACCOUNT = "/buat-akun";
export const FORGOT_PASSWORD = "/lupa-password";
export const COLLEGE_DETAIL = "/detail-kampus";
export const MAJOR_DETAIL = "/detail-jurusan";
export const CAREER_DETAIL = "/detail-karir";
export const SCHOLARSHIP_DETAIL = "/detail-beasiswa";
export const RESET_PASSWORD = "/verify-reset-password";

export const DASHBOARD = "/dashboard";
export const TNC = "/tnc";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_USE = "/terms-of-use";

// Job Portal
export const ALL_COMPONENT = "/all-component";
export const SIGN_UP = "/daftar";
export const DETAILS_REGISTER = "/pendaftaran-lengkap";
export const SETTINGS = "/settings";
export const CHANGE_PASSWORD = "/change-password";
export const CLOSE_ACCOUNT = "/close-account";
export const DEACTIVATE_ACCOUNT = "/deactivate-account";
export const JOB_SEARCH = "/cari-kerja";
export const JOB_DETAIL = "/info-kerja";
export const COMPARISON = "/perbandingan";
export const MY_JOBS = "/my-jobs";
export const INCOMPLETE_APPLICATIONS = "/incomplete-applications";
