import { useEffect, useState } from "react";
import moment from "moment";

import Cash from "assets/svg/cash.svg";
import DateIcon from "assets/svg/date-icon.svg";
import Applicants from "assets/svg/applicant-icon.svg";
import { ReactComponent as EmptyWithdrawnIllu } from "assets/svg/empty_withdrawn_illu.svg";
import useFetch from "shared/hooks/useFetch";
import Image from "shared/component/UI/Image";
import { homeUrl } from "shared/utils/apiEndPoints";
import useTranslate from "shared/hooks/useTranslate";
import { useEnvironment } from "shared/hooks/useEnvironment";
import LoadingComponent from "shared/component/LoadingComponent/LoadingComponent";
import { SalaryTypeEnum, WithdrawnJobs } from "types/API/TypesSwagger";

import { SalaryRangeFormatUtil } from "./SalaryRangeFormatUtil";
import { TabPropsTypes } from "./Types";
import PaginationWrapper from "./PaginationWrapper";
import EmptyTabState from "./EmptyTabState";

const WithdrawnItem = (props: WithdrawnJobs & { ENV: string; job_ad: { applicants: number } }) => {
	const t = useTranslate();

	return (
		<div className="job-div">
			<div className="d-flex justify-content-between">
				<div className="d-flex align-items-start">
					<Image
						className=""
						src={props.job_ad.company.company_logo}
						style={{ height: "5.4rem", width: "5.4rem", objectFit: "contain" }}
					/>
					<div className="role-details">
						<p
							className="role-name cursorPointer"
							onClick={() =>
								window.open(
									`https://jobmarket.${homeUrl}/info-kerja/${props.job_ad.id}`,
									"_self",
								)
							}
						>
							{props.job_ad.job_title}
						</p>
						<p className="text-lite">{props.job_ad.company.name}</p>
						<p className="text-lite">
							{[
								props.job_ad.posting_location_district,
								props.job_ad.posting_location_city,
							]
								.filter((v) => v)
								.join(", ")}
						</p>
						{props?.job_ad?.display_salary && (
							<div className="d-flex align-items-center">
								<Image className="" src={Cash} />
								<span className="text-medium">
									{SalaryRangeFormatUtil({
										currency: String(props.job_ad.salary_currency),
										salaryStart: props.job_ad.salary_starting_amount ?? 0,
										salaryEnd: props.job_ad.salary_maximum_amount ?? 0,
										salaryRate: String(props.job_ad.salary_rate),
										salaryType: props.job_ad.salary_type as SalaryTypeEnum,
										salaryAmount: props.job_ad.salary_amount ?? 0,
									})}
								</span>
							</div>
						)}
						<div className="applied-details">
							<Image className="" src={DateIcon} />
							<span>
								{t(
									"INTERVIEW_DATE_APPLIED",
									moment(props.submitted_at).format("D MMM Y"),
								)}
							</span>
							<Image className={"mar-left"} src={Applicants} />
							<span>
								{t(
									"INTERVIEW_APPLICANTS_LABEL",
									`${props.job_ad.applicants}`,
									props.job_ad.applicants > 1 ? "s" : "",
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="d-flex align-items-start">
					{/* <div className="msg-div msg-neutral">Withdrawn on 3 Mar 2023</div> */}
					<div className="msg-div msg-neutral">
						{t("WITHDRAWN_ON", moment(props.withdrawn_at).format("D MMM Y"))}
					</div>
				</div>
			</div>
		</div>
	);
};

const WithdrawnTab = (props: TabPropsTypes) => {
	const t = useTranslate();

	const [page, setPage] = useState<number>(1);

	const page_size = 20;
	const queryParams = {
		keyword: props.keyword,
		page,
		page_size,
		sort_and_order_by: props.sortOrderBy,
		date_range_after: props.dateRangeStart,
		date_range_before: props.dateRangeEnd,
	};

	const ENV = useEnvironment();
	const { response, loading, getDatas } = useFetch<WithdrawnJobs[]>("getWithdrawnJobs", {
		queryParams,
	});

	useEffect(() => {
		getDatas({ queryParams });
	}, [props.keyword, props.sortOrderBy, props.dateRangeStart, props.dateRangeEnd]);

	const renderData =
		(response?.data?.length ?? 0) <= 0 ? (
			<EmptyTabState
				title={t("WITHDRAWN_EMPTY_TITLE")}
				desc={t("BOOKMARK_EMPTY_DESC")}
				buttonBrowse={false}
				image={<EmptyWithdrawnIllu />}
			/>
		) : (
			response?.data?.map((v, k) => <WithdrawnItem key={k} {...v} ENV={ENV} />)
		);

	return (
		<>
			{loading ? <LoadingComponent notFullScreen={true} /> : renderData}
			<PaginationWrapper
				page_size={page_size}
				total_items={response?.total_items ?? 1}
				ENV={ENV}
				onChangePage={(pageNumber) => {
					setPage(pageNumber);
					getDatas({
						queryParams: { ...queryParams, page: pageNumber },
					});
				}}
			/>
		</>
	);
};

export default WithdrawnTab;
