import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import Button from "shared/component/ButtonComponent/Button";
import useTranslate from "shared/hooks/useTranslate";
import { formatString } from "shared/utils/string";
import { RootState } from "store/types/RootStateTypes";

interface Props {
	onNextPrev: (value: number) => void;
}

const CloseConfirm = ({ onNextPrev }: Props) => {
	const t = useTranslate();
	const { first_name } = useSelector((state: RootState) => ({
		first_name: state?.auth?.userDetails?.first_name,
	}));

	return (
		<>
			<div className="header">
				<h3>{t("CLOSE_ACCOUNT")}</h3>
				<p>{formatString(t("CLOSE_CONFIRM"), first_name)}</p>
			</div>

			<div>
				<p className="text-400 text-label-gray text-sm">{t("CLOSE_ACCOUNT_CONFIRM")}</p>
			</div>

			<div className="row-layout">
				<Button
					type="outline"
					size="sm"
					title={t("BACK")}
					customIcon={<BiArrowBack size={18} className="me-xxs" />}
					onClick={() => onNextPrev(0)}
				/>
				<Button
					title={t("CONTINUE")}
					type="primary"
					size="sm"
					onClick={() => onNextPrev(2)}
				/>
			</div>
		</>
	);
};

export default CloseConfirm;
