import React, { useEffect, useRef, useState } from "react";
import makeRequest from "../../shared/utils/request";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { generateRequestOptions, homeUrl } from "../../shared/utils/apiEndPoints";
import cookies from "react-cookies";
import * as ActionTypes from "../../store/actions/actionTypes";
import * as Variable from "../../shared/utils/variables";
import useToast from "shared/hooks/useToast";

// This component only consists to check login status
const LogStatusHandler = ({ ENV_NAME, ...props }) => {
	const [currentTimeStamp, setCurrentTimeStamp] = useState(Date.now());
	const [isLoading, setIsLoading] = useState(false);
	let checkLoginInterval = useRef();
	let timeout = useRef();
	const toast = useToast();

	useEffect(() => {
		if (!checkLoginInterval.current) checkIsLogin();
		checkLoginInterval.current = setInterval(checkIsLogin, 1000);

		return () => {
			clearInterval(checkLoginInterval.current);
			checkLoginInterval.current = null;
		};
		// TODO: Why isLoading is being included in the dependency array
	}, [props.auth.isAuthenticated]); // eslint-disable-line

	// Check cookies load
	const checkIsLogin = () => {
		let authToken = cookies.load("ptkjauthtoken");
		let refreshToken = cookies.load("ptkjrefreshtoken");
		if (authToken && refreshToken && !timeout.current && !isLoading) {
			let tokenData;
			try {
				tokenData = JSON.parse(window.atob(authToken.split(".")[1]));
			} catch {
				props.logout();
			}
			let checkCookie = tokenData
				? tokenData.exp * 1000 - 30000 - Date.now() > 0
					? true
					: false
				: false;
			if (checkCookie) {
				getUserDetails();
			} else {
				requestNewAccessToken();
			}
		} else if (props.auth.isAuthenticated && !authToken && !refreshToken) {
			props.logout();
			clearTimeout(timeout.current);
			timeout.current = null;
		}
	};

	useEffect(() => {
		if (!props.auth.isAuthenticated && timeout.current) {
			clearTimeout(timeout.current);
			timeout.current = null;
		}
		return () => {
			clearTimeout(timeout.current);
			timeout.current = null;
		};
	}, [props.auth.isAuthenticated]);

	// Old login detection implementation from Portal Sekolah
	// On first load, we check whether the user is logged in or not
	// useEffect(() => {
	// 	let authToken = cookies.load("ptkjauthtoken");
	// 	let refreshToken = cookies.load("ptkjrefreshtoken");
	// 	if (authToken && refreshToken) {
	// 		getUserDetails();
	// 	} else if (props.auth.isAuthenticated && !authToken && !refreshToken) {
	// 		props.logout();
	// 	}

	// 	return () => {
	// 		clearTimeout(timeout.current);
	// 		timeout.current = null;
	// 	};
	// }, []); // eslint-disable-line

	const getUserDetails = async () => {
		setIsLoading(true);
		let res = await makeRequest(generateRequestOptions("getUserViaToken"));
		if (res.code === 200) {
			// This will set userDetails, and will call the useEffect above
			await props.dispatch_loginSucceed(res.data);
			await getDetails(res.data);
			setCurrentTimeStamp(new Date());
			setIsLoading(false);
		} else {
			props.logout();
			setIsLoading(false);
		}
	};

	const getDetails = async (data) => {
		if (isValidAuthToken(data)) {
			startTimer();
		} else {
			if (isValidRefreshToken(data)) {
				await requestNewAccessToken();
			} else {
				toast.error(Variable.TOAST_USER_NOT_VALID[ENV_NAME]);
				props.logout();
			}
		}
	};

	const requestNewAccessToken = async () => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("refresh", cookies.load("ptkjrefreshtoken"));
		fetch(
			{
				...generateRequestOptions("getNewAccessToken"),
			}.url,
			{
				method: "POST",
				body: formData,
			},
		)
			.then(async (response) => {
				const res = await response.json();

				if (res.access) {
					cookies.save("ptkjauthtoken", res.access, {
						domain: homeUrl,
						path: "/",
						expires: new Date("9999-12-31T12:00:00.000Z"),
					});
					let timerData = Date.now();
					if (!cookies.load("lang")) {
						cookies.save("lang", "bhs", {
							domain: homeUrl,
							path: "/",
							expires: new Date("9999-12-31T12:00:00.000Z"),
						});
					}
					cookies.save(
						"tokenexpiry",
						JSON.parse(atob(res.access.split(".")[1]))?.exp * 1000 ||
							timerData + 870000,
						{
							domain: homeUrl,
							path: "/",
							expires: new Date("9999-12-31T12:00:00.000Z"),
						},
					);
					setCurrentTimeStamp(timerData);
					setIsLoading(false);
				} else {
					toast.error(Variable.TOAST_SESSION_EXPIRED[ENV_NAME]);
					props.logout();
					setIsLoading(false);
				}
			})
			.catch(async (err, response, body) => {
				if (err.status === 500) {
					props.logout();
				}
				setIsLoading(false);
			});
	};

	// Check whether the auth token is valid
	const isValidAuthToken = (userDetails) => {
		if (!cookies.load("ptkjauthtoken")) return false;
		let tokenData;
		try {
			tokenData = JSON.parse(window.atob(cookies.load("ptkjauthtoken").split(".")[1]));
		} catch {
			return false;
		}
		let checkUserId = tokenData.user_id === userDetails?.id;
		let checkCookie = tokenData
			? tokenData.exp * 1000 + 9000000 - 300000 - Date.now(currentTimeStamp) > 0
				? true
				: false
			: false;
		let data = cookies.load("tokenexpiry");
		if (data - Date.now() > 0 && checkCookie && checkUserId) {
			return true;
		} else {
			return false;
		}
	};

	// Check whether the refresh token is valid
	const isValidRefreshToken = (userDetails) => {
		if (!cookies.load("ptkjrefreshtoken")) return false;
		let tokenData;
		try {
			tokenData = JSON.parse(window.atob(cookies.load("ptkjrefreshtoken").split(".")[1]));
		} catch {
			return false;
		}
		let checkUserId = tokenData.user_id === userDetails?.id;
		if (checkUserId) {
			return true;
		} else {
			return false;
		}
	};

	// Start a timer until the tokenexpiry is passed
	const startTimer = () => {
		let data = cookies.load("tokenexpiry");
		let timeDiff = data - Date.now(currentTimeStamp);
		if (timeDiff > 0) {
			clearTimeout(timeout.current);
			timeout.current = setTimeout(() => {
				clearTimeout(timeout.current);
				timeout.current = null;
				requestNewAccessToken();
			}, timeDiff);
		} else {
			props.logout();
		}
	};

	return <></>;
};

const mapStateToProps = (state) => {
	return { ...state, ENV_NAME: state.auth.selectedEnvironment || "bhs" };
};

const mapStateToDispatch = (dispatch) => {
	return {
		dispatch_loginSucceed: (payload) =>
			dispatch({ type: ActionTypes.LOGIN_SUCCEED_SAGA, payload: payload }),
		logout: () => dispatch({ type: ActionTypes.LOGOUT }),
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(LogStatusHandler));
