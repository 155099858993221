import React, { useEffect, useState } from "react";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";

import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";

import "./ApplyJobModal.scss";
import { isEmpty } from "lodash";
import useToast from "shared/hooks/useToast";
import useTranslate from "shared/hooks/useTranslate";

const QUESTION_TYPE = [
	"mcq", // checbox
	"fill_in_the_blanks", // radio
	"short_answer", // textarea
	"true_false",
	"numeric", // numeric
	"progressive", //radio
	"single_select", //radio
];

function AdditionalQuestions({ jobAppId, jobId, ...props }) {
	const t = useTranslate();
	const YES_NO_OPTIONS = [
		{ id: t("YES"), value: true },
		{ id: t("NO"), value: false },
	];

	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState({});
	const [unansweredQuestions, setUnansweredQuestions] = useState([]);
	const [isEdited, setIsEdited] = useState(false);
	const [errors, setErrors] = useState({});
	const [monitor, setMonitor] = useState({});
	const toast = useToast();

	useEffect(() => {
		if (jobId) retrieveQuestions();
	}, [jobId]);

	useEffect(() => {
		if (questions.length) retrieveAnswers();
	}, [questions]);

	const retrieveQuestions = async () => {
		const res = await makeRequest(
			generateRequestOptions("fetchAdditionalQuestions", { urlParams: jobId }),
		);

		if (res.code === 200) {
			if (!res.data.length) {
				props.onUpdateQuestions(false);
				return props.onUpdateActiveForm(4);
			}
			props.onUpdateQuestions(true);
			setQuestions(res.data);
		} else toast.error(res.message);
	};

	const retrieveAnswers = async () => {
		const res = await makeRequest(
			generateRequestOptions("listPreScreeningAnswers", { urlParams: jobId }),
		);

		if (res.code === 200) {
			const answers = {};
			res.data.forEach((answer) => {
				const question = answer?.question;

				switch (question?.question_type) {
					case "progressive":
					case "single_select":
						answers[`answer_${question?.id}`] = answer["mcq_answer"].join(", ");
						break;
					case "mcq":
						answers[`answer_${question?.id}`] = answer["mcq_answer"];
						break;
					case "true_false":
						answers[`answer_${question?.id}`] = answer?.tf_answer;
						break;
					case "short_answer":
						answers[`answer_${question?.id}`] = answer?.text_answer;
						break;
					case "numeric":
						answers[`answer_${question?.id}`] = answer?.numeric_answer;
						break;
					default:
						break;
				}
			});
			setAnswers(answers);
			setUnansweredQuestions(res.unanswered_questions.map((question) => question.id));
		} else toast.error(res.message);
	};

	const validateAndSave = async () => {
		// if the form is not edited just skip this step
		// if (!isEdited) return props.onUpdateActiveForm(4);

		let errors = {};
		const answerKeys = questions.map((question) => `answer_${question?.id}`);

		answerKeys.forEach((anskey) => {
			if (answers[anskey] !== false && !answers[anskey])
				errors = { ...errors, [anskey]: true };
		});

		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const monitoredQuestions = Object.keys(monitor).map((answer) => +answer.split("_")[1]);
		const questionsList = questions.filter((question) =>
			monitoredQuestions.includes(question.id),
		);
		const data = questionsList.map((question) => ({
			job_ad: jobId,
			job_application: jobAppId,
			question: question?.id,
			...(question?.question_type === QUESTION_TYPE[0]
				? { mcq_answer: answers[`answer_${question?.id}`] }
				: {}),
			...([QUESTION_TYPE[1], QUESTION_TYPE[5], QUESTION_TYPE[6]]?.includes(
				question?.question_type,
			)
				? {
						mcq_answer: [answers[`answer_${question?.id}`]],
				  }
				: {}),
			...(question?.question_type === QUESTION_TYPE[2]
				? { text_answer: answers[`answer_${question?.id}`] }
				: {}),
			...(question?.question_type === QUESTION_TYPE[3]
				? { tf_answer: Boolean(answers[`answer_${question?.id}`]) }
				: {}),
			...(question?.question_type === QUESTION_TYPE[4]
				? {
						numeric_answer: +answers[`answer_${question?.id}`],
				  }
				: {}),
		}));

		const newAnswers = data.filter((q) => unansweredQuestions.includes(q?.question));
		const updatedAnswers = data.filter((q) => !unansweredQuestions.includes(q?.question));

		let newAnswerRes, updateAnswerRes;

		// for new answers
		if (newAnswers.length) {
			newAnswerRes = await makeRequest({
				...generateRequestOptions("saveAdditionalQuestions"),
				data: newAnswers,
			});
		}

		// for updating answers
		if (updatedAnswers.length) {
			updateAnswerRes = await makeRequest({
				...generateRequestOptions("bulkUpdatePreScreeningAnswer"),
				data: updatedAnswers,
			});
		}

		if (
			(newAnswerRes && newAnswerRes?.code !== 200) ||
			(updateAnswerRes && updateAnswerRes?.code !== 200)
		)
			toast.error(newAnswerRes?.message || updateAnswerRes?.message);
		else {
			props.onUpdateActiveForm(4);
			props.clearEditingForm();
			setIsEdited(false);
			resetState();
		}
	};

	const onChange = (e, type = null) => {
		const { name, value } = e.target;
		switch (type) {
			case "checkbox":
				const answersClone = { ...answers };
				const answer = answersClone[name];

				if (answer && answer.includes(value)) {
					setAnswers((prev) => ({
						...prev,
						[name]: answer.filter((ans) => ans !== value),
					}));
					return;
				}
				setAnswers((prev) => ({
					...prev,
					[name]: Array.isArray(answer) ? [...answer, value] : [value],
				}));
				break;

			case "numeric":
				setAnswers((prev) => ({
					...prev,
					[name]: /^[0-9\s]*$/.test(value) ? value : prev[name],
				}));
				break;

			case "yes_no":
				setAnswers((prev) => ({
					...prev,
					[name]: JSON.parse(value),
				}));
				break;

			default:
				setAnswers((prev) => ({
					...prev,
					[name]: value,
				}));
		}
		setErrors((prev) => ({ ...prev, [name]: false }));
		setMonitor((prev) => ({ ...prev, [name]: true }));
		setIsEdited(true);
	};

	const resetState = () => setQuestions([]);

	return (
		<div className="form-container">
			<div className="contact-form">
				<div className="body">
					{questions.map((question, idx) => (
						<React.Fragment key={idx + 1}>
							{/* mcq */}
							{question?.question_type === QUESTION_TYPE[0] && (
								<div className="question">
									<p
										className={`text ${
											question?.is_mandatory ? "isRequired" : ""
										}`}
									>
										{question?.question_text}
									</p>

									{question?.mcq_choices?.map((opt, opt_idx) => (
										<Input
											key={opt_idx + 1}
											choiceLabel={opt}
											inputType="checkbox"
											id={`${question?.id}_${opt_idx + 1}`}
											name={`answer_${question?.id}`}
											value={opt}
											onChange={(e) => onChange(e, "checkbox")}
											checked={answers[`answer_${question?.id}`]?.includes(
												opt,
											)}
											isError={errors[`answer_${question?.id}`]}
										/>
									))}
								</div>
							)}

							{/* short answer */}
							{question?.question_type === QUESTION_TYPE[2] && (
								<div className="question">
									<Input
										label={question?.question_text}
										value={answers[`answer_${question?.id}`] || ""}
										name={`answer_${question?.id}`}
										onChange={(value) => {
											setAnswers((prev) => ({
												...prev,
												[`answer_${question?.id}`]: value,
											}));
											setErrors((prev) => ({
												...prev,
												[`answer_${question?.id}`]: false,
											}));
											setMonitor((prev) => ({
												...prev,
												[`answer_${question?.id}`]: true,
											}));
											setIsEdited(true);
										}}
										inputType="textarea"
										placeholder={""}
										isError={errors[`answer_${question?.id}`]}
										required={question?.is_mandatory}
									/>
								</div>
							)}

							{/* Yes/No */}
							{question?.question_type === QUESTION_TYPE[3] && (
								<div className="question">
									<p
										className={`text ${
											question?.is_mandatory ? "isRequired" : ""
										}`}
									>
										{question?.question_text}
									</p>

									{YES_NO_OPTIONS?.map((opt, opt_idx) => (
										<Input
											key={opt_idx + 1}
											choiceLabel={opt?.id}
											inputType="radio"
											id={`${question?.id}_${opt_idx + 1}`}
											name={`answer_${question?.id}`}
											value={opt?.value}
											onChange={(e) => onChange(e, "yes_no")}
											checked={
												answers[`answer_${question?.id}`] === opt?.value
											}
											isError={errors[`answer_${question?.id}`]}
										/>
									))}
								</div>
							)}

							{/* numeric */}
							{question?.question_type === QUESTION_TYPE[4] && (
								<div className="question">
									<Input
										label={question?.question_text}
										placeholder={""}
										value={answers[`answer_${question?.id}`] || ""}
										name={`answer_${question?.id}`}
										onChange={(e) => onChange(e, "numeric")}
										inputType="label"
										className="w-100"
										required={question?.is_mandatory}
										isError={errors[`answer_${question?.id}`]}
									/>
								</div>
							)}

							{/* single_select */}
							{[QUESTION_TYPE[1], QUESTION_TYPE[5], QUESTION_TYPE[6]].includes(
								question?.question_type,
							) && (
								<div className="question">
									<p
										className={`text ${
											question?.is_mandatory ? "isRequired" : ""
										}`}
									>
										{question?.question_text}
									</p>

									{question?.mcq_choices.map((option, opt_idx) => (
										<Input
											key={opt_idx + 1}
											choiceLabel={option}
											inputType="radio"
											id={`${question?.id}_${opt_idx + 1}`}
											name={`answer_${question?.id}`}
											value={option}
											onChange={onChange}
											isError={errors[`answer_${question?.id}`]}
											checked={option === answers[`answer_${question?.id}`]}
										/>
									))}
								</div>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => props.onUpdateActiveForm(2)}
						title={t("BACK")}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={t("NEXT")}
						disabled={
							isEmpty(answers) || Object.keys(answers).length !== questions.length
						}
					/>
				</div>
			</div>
		</div>
	);
}

export default AdditionalQuestions;
