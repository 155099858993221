import cookies from "react-cookies";
import usePost from "./usePost";
import { homeUrl } from "shared/utils/apiEndPoints";
import { useDispatch } from "react-redux";
import { LOGOUT, LOGOUT_SUCCEED } from "store/actions/actionTypes";
import useToast from "./useToast";

const useAuth = () => {
	const { postFunction: logout } = usePost("logout");
	const dispatch = useDispatch();
	const toast = useToast();

	const handleLogout = async () => {
		if (cookies.load("ptkjrefreshtoken")) {
			const res = await logout({
				body: {
					refresh_token: cookies.load("ptkjrefreshtoken"),
				},
			}).then(async (status) => {
				if (status) {
					cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
					cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
					dispatch({ type: LOGOUT });
					dispatch({
						type: LOGOUT_SUCCEED,
					});
				} else {
					toast.error("Something went wrong");
				}
				return status;
			});
			return res;
		}
	};

	return {
		handleLogout,
	};
};

export default useAuth;
