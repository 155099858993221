import React from "react";
import * as Variable from "../../utils/variables";
import { useSelector } from "react-redux";

const LoadingComponent = (props) => {
	const { ENV_NAME } = useSelector((state) => ({
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
	}));
	return (
		<div className={`container loading-component ${props.className} ${props.notFullScreen ? "h-100" : "vh-100"}`}>
			<div className={`row align-items-center ${props.notFullScreen ? "h-100" : "vh-100"}`}>
				<div className="col d-flex flex-column align-items-center">
					{props.spinner ? (
						props.spinner
					) : (
						<div className="spinner-border m-2" role="status">
							<span className="sr-only"></span>
						</div>
					)}
					<div className="h3">{props.loadingLabel || Variable.LOADING_LABEL[ENV_NAME]}</div>
				</div>
			</div>
		</div>
	);
};
export default LoadingComponent;
