// Import from external link
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Button from "../../shared/component/ButtonComponent/Button";
import * as RoutePath from "../../shared/utils/routeLink";
import "./HomeComponent.scss";

const HomeComponent = ({ ENV_NAME, ...props }) => {
	return (
		<div className="container hc">
			<div className="mb-std">Homepage Job Seeker</div>
			<Button onClick={() => props.history.push(RoutePath.PROFILE)} type="primary" size="md" title="Go To Profile" />
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(HomeComponent));
