import { useEffect, useState } from "react";
import { BsExclamationTriangle } from "react-icons/bs";

import usePost from "shared/hooks/usePost";
import useTranslate from "shared/hooks/useTranslate";
import Input from "shared/component/InputComponent/Input";
import Button from "shared/component/ButtonComponent/Button";
import RadioInput from "shared/component/InputComponent/RadioInput";
import { Text, Title } from "shared/component/Typography/Typography";
import { PatchedWithdrawnRequest } from "types/API/TypesSwagger";

import "./WithdrawnFormPopupStyle.scss";

type PropTypes = {
	id: number;
	jobTitle: string;
	jobCompany: string;
	onWithdraw?: () => any;
};

const ReasonOption = [
	{
		label: "This job isn’t a good match",
		translateKey: "WITHDRAW_OPT_1",
		value: "opt-1",
	},
	{
		label: "Suspicious content from the employer",
		translateKey: "WITHDRAW_OPT_2",
		value: "opt-2",
	},
	{
		label: "Portal kerja helped me get a job",
		translateKey: "WITHDRAW_OPT_3",
		value: "opt-3",
	},
	{
		label: "Found a job another way",
		translateKey: "WITHDRAW_OPT_4",
		value: "opt-4",
	},
	{
		label: "Haven’t heard back from the employer",
		translateKey: "WITHDRAW_OPT_5",
		value: "opt-5",
	},
	{
		label: "Something else",
		translateKey: "WITHDRAW_OPT_FREE_TEXT",
		value: "free-text",
	},
];

const WithdrawnFormPopup = (props: PropTypes) => {
	const t = useTranslate();

	const [reason, setReason] = useState<string>();
	const [reasonText, setReasonText] = useState<string>();

	const withdrawnApplication = usePost<PatchedWithdrawnRequest>("postWithdrawnApplication");

	useEffect(() => {
		if (reason !== "free-text") {
			setReasonText("");
		}
	}, [reason]);

	const validateForm = (): boolean => {
		if (reason === undefined) return false;
		if (reason === "free-text" && reasonText === "") return false;

		return true;
	};

	const onWithdraw = async () => {
		props.onWithdraw?.();

		const reasonFind = ReasonOption.find((v) => v.value === reason);

		await withdrawnApplication.postFunction({
			body: {
				withdrawn_reason:
					reasonFind?.value === "free-text" ? reasonText : reasonFind?.label,
			},
			urlParams: `${props.id}`,
		});
	};

	return (
		<div className="withdrawn-popup">
			<div className="job-title-company">
				<Title size="xs">{props.jobTitle}</Title>
				<Text size="sm">{props.jobCompany}</Text>
			</div>

			<div className="warning-info">
				<div>
					<BsExclamationTriangle />
				</div>
				<Text size="sm">{t("WITHDRAW_REVIEW_WARNING")}</Text>
			</div>

			{/* Bullet info */}
			<div className="bullet-info">
				<ul>
					<li>
						<Text size="sm">{t("WITHDRAW_WARNING_1")}</Text>
					</li>
					<li>
						<Text size="sm">{t("WITHDRAW_WARNING_2")}</Text>
					</li>
					<li>
						<Text size="sm">{t("WITHDRAW_WARNING_3")}</Text>
					</li>
				</ul>
			</div>

			{/* Selection radio */}
			<div className="selection-radio">
				<div className="selection-radio-title">
					<Title size="xs">{t("WITHDRAW_OPT_TITLE")}</Title>
					<Text size="sm">{t("WITHDRAW_OPT_IMPROVE")}</Text>
				</div>
				<div className="selection-radio-options">
					{ReasonOption.map((v) => (
						<RadioInput
							key={v.value}
							label={t(v.translateKey)}
							id={v.value}
							name={v.value}
							checked={v.value === reason}
							onChange={() => setReason(v.value)}
							// required=""
						/>
					))}
				</div>
				{reason === "free-text" ? (
					<div>
						<Input
							inputType="textarea"
							characterLimit={400}
							value={reasonText}
							placeholder={t("WITHDRAW_FREE_TEXT_PLACEHOLDER")}
							isError={!validateForm()}
							noHint={!validateForm()}
							errorHintText={t("WITHDRAW_FREE_TEXT_ERROR_HINT")}
							onChange={(t: string) => setReasonText(t)}
						/>
					</div>
				) : null}
			</div>

			{/* Custom ok button */}
			<div className="withdraw-btn-cont">
				<Button
					type={!validateForm() ? "textGray" : "outline"}
					disabled={!validateForm()}
					size="md"
					onClick={onWithdraw}
					title={t("WITHDRAW_BTN_LABEL")}
				/>
			</div>
		</div>
	);
};

export default WithdrawnFormPopup;
