import clsx from "clsx";
import moment from "moment";
import * as Icons from "react-icons/bs";

import * as Variables from "shared/utils/variables";
import useTranslate from "shared/hooks/useTranslate";
import { Text, Title } from "shared/component/Typography/Typography";
import { CandidateHistoryStatus, CandidateStatusEnum } from "types/API/TypesSwagger";

import "./HistoryStatusStyle.scss";

type PropTypes = {
	jobTitle: string;
	jobCompany: string;
	statuses: CandidateHistoryStatus[];
};

const HistoryStatus = (props: PropTypes) => {
	const t = useTranslate();

	const MapTranslate: Record<Partial<CandidateStatusEnum>, keyof typeof Variables | string> = {
		ongoing: "PENDING_APPLIED",
		prospect: "PROSPECT",
		shortlist: "SHORTLIST",
		interview: "INTERVIEW",
		test: "",
		offered: "OFFERED",
		hired: "HIRED",
		rejected: "REJECTED",
	};

	return (
		<div className="history-status">
			<Title size="xs">{props.jobTitle}</Title>
			<Text size="sm">{props.jobCompany}</Text>
			<div className="timeline">
				{props.statuses.map((status, k) => (
					<div key={k} className={clsx("timeline-item", !!status.created_at && "active")}>
						<div>
							<Icons.BsCheckCircleFill />
							<div className="line-to-bottom" />
						</div>
						<div>
							<Title size="xs">
								{t(MapTranslate[status.candidate_status ?? ""])}
							</Title>
							<Text size="sm">
								{moment(status.created_at).format("DD MMM YYYY - h:mm a")}
							</Text>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default HistoryStatus;
