import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import FileCard from "shared/component/CardComponent/FileCard";
import { PlusIcon } from "shared/component/svg/Icons";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import { getIconByName, isFileSizeLessOrEqual } from "shared/utils/file";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import Image from "shared/component/UI/Image";
import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import { isValueAllowed } from "shared/utils/numberUtils";
import useToast from "shared/hooks/useToast";

const ALLOWED_FILES = [
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"text/plain",
	"application/msword",
	"image/png",
	"image/jpeg",
];

const LanguageModal = ({ ENV_NAME, show, languageId, onHide, onSave, onEdit, ...props }) => {
	const PROFICIENCY = [
		{ id: "beginner", name: Variable.BEGINNER[ENV_NAME] },
		{ id: "intermediate", name: Variable.INTERMEDIATE[ENV_NAME] },
		{ id: "fluent", name: Variable.FLUENT[ENV_NAME] },
		{ id: "expert", name: Variable.EXPERT[ENV_NAME] },
		{ id: "native", name: Variable.NATIVE[ENV_NAME] },
	];

	const [form, setForm] = useState({});
	const [monitor, setMonitor] = useState({});
	const [errors, setErrors] = useState({});
	const [isUploading, setIsUploading] = useState(false);
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState({
		show: false,
		data: null,
	});
	const uploadRef = useRef();
	const toast = useToast();

	useEffect(() => {
		if (show && languageId) retrieveLanguage(languageId);
		if (!show) resetState();
	}, [show, languageId]); // eslint-disable-line

	const retrieveLanguage = async (id) => {
		const res = await makeRequest(generateRequestOptions("fetchLanguage", { urlParams: id }));

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const onChange = (e, type) => {
		const { name, value } = e.target;
		if (type === "numberWithDecimal") {
			setForm((p) => ({
				...p,
				[name]: isValueAllowed(value, type) ? value : p[name],
			}));
		} else setForm((p) => ({ ...p, [name]: value }));
		setErrors({ ...errors, [name]: false });
		setMonitor({ ...monitor, [name]: true });
	};

	const onFileChange = async (e) => {
		console.log("Uploading File");
		const file = e.target.files[0];
		if (file) {
			if (!ALLOWED_FILES.includes(file?.type))
				return toast.error(Variable.UNSUPPORTED_FILE[ENV_NAME]);

			const FILE_SIZE = 1024 * 1024 * 20;
			if (!isFileSizeLessOrEqual(file, FILE_SIZE))
				return toast.error(Variable.FILE_SIZE_ERROR[ENV_NAME]);

			setIsUploadingFile(true);
			const fileObj = {
				language_document_proof: file,
				file_name: file?.name,
				id: Date.now(),
				is_newfile: true,
			};
			const formClone = { ...form };
			if (formClone.language_document_proof) formClone.language_document_proof.push(fileObj);
			else {
				formClone.language_document_proof = [{ ...fileObj }];
			}
			setForm(formClone);
		}
	};

	const validateAndSave = async () => {
		const errorsData = {};
		if (!form.name) errorsData["name"] = true;
		if (!form.spoken_proficiency) errorsData["spoken_proficiency"] = true;
		if (!form.written_proficiency) errorsData["written_proficiency"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);
		const docs = form?.language_document_proof
			?.filter((doc) => !doc?.language)
			?.map((doc) => doc?.language_document_proof);

		setIsUploading(true);
		if (languageId) {
			const body = {};
			Object.keys(monitor).forEach((key) => {
				if (key !== "language_document_proof") body[key] = form[key];
			});

			// if (isEmpty(body)) return onHide();

			onEdit && (await onEdit(body, languageId, docs));
			// onHide();
		} else {
			const formClone = { ...form };
			if (isUploadingFile) formClone.language_document_proof = docs; // Here, we are checking if we are adding any files or not

			onSave && (await onSave(formClone));
		}
		setIsUploading(false);
		setIsUploadingFile(false);
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			language_document_proof: form?.language_document_proof?.filter(
				(file) => file.id !== docId,
			),
		});
	};

	const handleFileDelete = async () => {
		// There will be two kinds of delete
		// if there exists an language for the document proof, then call the API
		// otherwise, filter out the language documents
		const docId = showDeleteModal?.data?.id;

		const documentProof = form.language_document_proof.find((doc) => doc.id === docId);
		if (documentProof?.language) {
			const res = await makeRequest(
				generateRequestOptions("deleteLanguageDocument", {
					urlParams: docId,
				}),
			);
			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowDeleteModal({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowDeleteModal({ show: false, data: null });
		}
	};

	// FUNC: Utilities
	const resetState = () => {
		setForm({});
		setMonitor({});
		setErrors({});
	};

	return (
		<ModalForm
			title={languageId ? Variable.EDIT_LANGUAGE[ENV_NAME] : Variable.ADD_LANGUAGES[ENV_NAME]}
			footer={
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => {
							onHide();
							resetState();
						}}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.SAVE[ENV_NAME]}
						disabled={isUploading}
					/>
				</div>
			}
			show={show}
			{...props}
		>
			<div className="pc-form">
				<Input
					label={Variable.LANGUAGE_NAME[ENV_NAME]}
					placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Bahasa Indonesia`}
					value={form?.name || ""}
					name="name"
					onChange={onChange}
					inputType="label"
					className="w-100"
					required
					isError={errors["name"]}
				/>
				<div className="pc-form-row">
					<Select
						label={Variable.SPOKEN_PROFICIENCY[ENV_NAME]}
						placeholder={Variable.SPOKEN_PROFICIENCY[ENV_NAME]}
						name="spoken_proficiency"
						value={form?.spoken_proficiency || "IDR"}
						items={PROFICIENCY}
						onChange={onChange}
						searchable={false}
						className="w-100"
						noDefault
						required
						isError={errors["spoken_proficiency"]}
					/>
					<Select
						label={Variable.WRITTEN_PROFICIENCY[ENV_NAME]}
						placeholder={Variable.WRITTEN_PROFICIENCY[ENV_NAME]}
						name="written_proficiency"
						value={form?.written_proficiency || "IDR"}
						items={PROFICIENCY}
						onChange={onChange}
						searchable={false}
						className="w-100"
						noDefault
						required
						isError={errors["written_proficiency"]}
					/>
				</div>

				<Input
					label={Variable.CERTIFICATION[ENV_NAME]}
					placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} TOEFL`}
					value={form?.language_proficiency_name || ""}
					name="language_proficiency_name"
					onChange={onChange}
					inputType="label"
					className="w-100"
				/>
				<Input
					label={Variable.CERTIFICATION_SCORE[ENV_NAME]}
					placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} 500`}
					value={form?.language_proficiency_score || ""}
					name="language_proficiency_score"
					onChange={(e) => onChange(e, "numberWithDecimal")}
					inputType="label"
					className="w-100"
				/>
				<Toggle
					type="switch"
					leftText={Variable.PRIMARY[ENV_NAME]}
					name="is_primary"
					checked={form?.is_primary}
					onChange={(e) => {
						setForm((p) => ({ ...p, is_primary: e.target.checked }));
						setMonitor({ ...monitor, is_primary: true });
					}}
				/>

				<div>
					<h5 className="text-md font-weight-medium text-label-gray mb-sm">
						{Variable.DOCUMENT[ENV_NAME]}
					</h5>
					<span className="text-sm">
						{Variable.DOCUMENT_EXPERIENCE_SUBTITLE[ENV_NAME]}
					</span>
				</div>
				<div className="input-btn">
					<Button
						type="secondary"
						size="md"
						title={
							<div className="flex-all-center gap-xxs">
								<PlusIcon
									width="1.8rem"
									height="1.8rem"
									stroke="white"
									strokeWidth="3"
								/>
								{Variable.ADD_FILE[ENV_NAME]}
							</div>
						}
						onClick={() => uploadRef?.current?.click()}
					/>
					<input
						id="upload-files"
						type="file"
						name="subjectIcon"
						className="inputfile d-none"
						onChange={(e) => {
							onFileChange(e);
							uploadRef.current.value = null;
						}}
						accept={ALLOWED_FILES.join(",")}
						ref={uploadRef}
					/>
				</div>
				{form?.language_document_proof?.map((i) => (
					<FileCard
						key={i?.id}
						title={i?.file_name}
						icon={getIconByName(i?.file_name)}
						onDelete={() => setShowDeleteModal({ show: true, data: i })}
						showGreenCheck={false}
						isUploading={i?.is_newfile && isUploading}
						isNewFile={i?.is_newfile}
					/>
				))}
			</div>
			<ModalInfo
				isShow={showDeleteModal?.show}
				isHide={() => setShowDeleteModal({ show: false, data: null })}
				onConfirm={handleFileDelete}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>
		</ModalForm>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(LanguageModal));
