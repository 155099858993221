import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { toast } from "react-toastify";

import useTranslate from "shared/hooks/useTranslate";
import { MY_JOBS } from "shared/utils/routeLink";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import Image from "shared/component/UI/Image";
import { Nullable } from "types/types";

import ApplyJobModal from "component/ApplyJobComponent/ApplyJobModal";

import GreenCheck from "../../../assets/svg/green-check.svg";
import DeleteIcon from "../../../assets/svg/delete-page-icon.svg";
import ApplicationCard from "./ApplicationCard";
import { useSelector } from "react-redux";
import { RootState } from "store/types/RootStateTypes";

export interface Application {
	company: { id: number; name: string; company_logo: string };
	id: number;
	job_ad: {
		id: number;
		job_title: string;
		posting_location_city: string;
		posting_location_district: string;
		display_salary: boolean;
		salary_amount: number;
		salary_type: string;
		salary_currency: string;
		salary_rate: string;
		salary_starting_amount: number;
		salary_maximum_amount: number;
	};
}

interface DialogWithData {
	show: boolean;
	data: Nullable<{ id: number }>;
}

const IncompleteApps = () => {
	const { userId } = useSelector((state: RootState) => ({
		userId: state?.auth?.userDetails?.id,
	}));

	const t = useTranslate();
	const history = useHistory();
	const [jobApp, setJobApp] = useState<Nullable<{ id: number; company: string }>>(null);
	const [applyJobModal, setApplyJobModal] = useState<{
		show: boolean;
		data: null | { id: number; title: string; isContinued: boolean };
	}>({
		show: false,
		data: null,
	});
	const [applications, setApplications] = useState<Application[]>([]);
	const [isApplied, setIsApplied] = useState(false);
	const [isFetched, setIsFetched] = useState(false);
	const [cancel, setCancel] = useState<DialogWithData>({
		show: false,
		data: null,
	});

	useEffect(() => {
		fetchApplications();
	}, []);

	useEffect(() => {
		if (isFetched && applications.length === 0) history.push(MY_JOBS);
	}, [applications, isFetched]);

	const fetchApplications = async () => {
		const res = await makeRequest(
			generateRequestOptions("getJobApplication", { queryParams: { stage: "pending" } }),
		);

		if (res?.code === 200) {
			setIsFetched(true);
			setApplications(res.data);
		} else toast.error(res.message);
	};

	const handleDeleteApplication = async () => {
		const res = await makeRequest(
			generateRequestOptions("cancelApplication", { urlParams: cancel?.data?.id }),
		);

		if (res?.code === 200) {
			setApplications(applications.filter((app) => app?.id !== cancel?.data?.id));
		} else toast.error(res.message);
	};

	return (
		<div className="d-flex flex-column w-100 h-100 my-jobs-component ia-comp">
			<div className="container">
				<div className="ia-comp-header">
					<BsChevronLeft
						className="cursorPointer"
						onClick={() => history.push(MY_JOBS)}
					/>
					<p className="mb-0">{t("APP_INCOMPLETE_HEAD")}</p>
				</div>
				<div className="ia-comp-div">
					{applications.map((app) => (
						<ApplicationCard
							item={app}
							key={app?.id}
							onUpdateCancel={() => setCancel({ show: true, data: { id: app?.id } })}
							onContinueApplication={() => {
								setApplyJobModal({
									show: true,
									data: {
										id: app?.job_ad?.id,
										title: app?.company?.name,
										isContinued: true,
									},
								});
								setJobApp({
									id: app?.job_ad?.id,
									company: app?.company?.name,
								});
							}}
						/>
					))}
				</div>

				{applyJobModal.show && (
					<ApplyJobModal
						show={applyJobModal.show}
						onHide={() => setApplyJobModal({ show: false, data: null })}
						userId={userId}
						onFetchJobs={fetchApplications}
						jobAppId={jobApp?.id}
						jobData={applyJobModal.data}
						onApply={() => setIsApplied(true)}
					/>
				)}
				{/* Job applied */}
				<ModalInfo
					isShow={isApplied}
					onConfirm={() => {
						setIsApplied(false);
						setJobApp(null);
						fetchApplications();
					}}
					type="other"
					title={`${t("APPLICATION_SENT_HEAD")} ${jobApp?.company || "-"}`}
					subtitle={t("APPLICATION_SENT_DESC")}
					customIcon={<Image src={GreenCheck} className="modal-image-icon" />}
					confirmButtonType="primary"
					confirmLabel={t("DONE")}
				/>

				{/* Cancel Job */}
				<ModalInfo
					isShow={cancel?.show}
					isHide={() => {
						handleDeleteApplication();
						setCancel({ show: false, data: null });
					}}
					onConfirm={() => {
						setCancel({ show: false, data: null });
					}}
					type="other"
					title={`${t("CANCELLING_JOB") || "-"}`}
					subtitle={t("CANCEL_JOB_DESC")}
					customIcon={<Image src={DeleteIcon} className="modal-image-icon" />}
					cancelLabel={t("YES_CANCEL_TEXT")}
					confirmButtonType="primary"
					confirmLabel={t("NO_TEXT")}
				/>
			</div>
		</div>
	);
};

export default IncompleteApps;
