import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Image from "../../../shared/component/UI/Image";
import DefaultProfPic from "../../../../src/assets/image/profiledefaultImage.png";
import { RepeatIcon, TrashIcon } from "../../../shared/component/svg/Icons";

const AvatarModal = ({ ENV_NAME, onHide, onSave, ...props }) => {
	const [form, setForm] = useState({});
	const [isEdited, setIsEdited] = useState(false)
	const uploadRef = useRef();

	// const onChange = (e) => {
	// 	setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
	// };

	const validateAndSave = () => {
		if(props.data.profile_picture !== form?.profile_picture) {
			let body = new FormData()
			body.append("profile_picture", form?.profile_picture)
			setIsEdited(false)
			onSave && onSave(body);
		}
		else {
			setIsEdited(false)
			onHide()
		}
	};

	useEffect(() => {
		if (props.show && props.data) {
			setForm(props.data);
		} else {
			setForm({});
		}
	}, [props.show, props.data]); // eslint-disable-line

	const onFileChange = (event) => {
		console.log(event)
		setForm({...form, profile_picture: event.target.files[0]})
		setIsEdited(true)
	};

	return (
		<ModalForm
			title={Variable.PROFILE_PHOTO[ENV_NAME]}
			footer={
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={()=>{setIsEdited(false);onHide();}}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.SAVE[ENV_NAME]}
					/>
				</div>
			}
			{...props}
		>
			<div className="pc-form">
				<div className="pc-form-row align-items-center gap-lg">
					<div className="pc-pic-big-cont">
						<Image className="pc-pic-big" src={isEdited ? URL.createObjectURL(form?.profile_picture) : form?.profile_picture ? form?.profile_picture : DefaultProfPic} />
					</div>
					<div className="d-flex flex-column gap-xxs">
						<div>
							<input
								id="upload-avatar"
								type="file"
								name="subjectIcon"
								className="inputfile d-none"
								onChange={onFileChange}
								accept="image/*"
								ref={uploadRef}
							/>
							<Button
								size="lg"
								type="textGray"
								className="w-100"
								btnClassName="w-100 text-label-black"
								onClick={() => uploadRef?.current?.click()}
								title={
									<div className="d-flex align-items-center gap-xs">
										<RepeatIcon width="1.8rem" height="1.8rem" stroke="#1D2939" strokeWidth="3" />
										{Variable.CHANGE_PHOTO[ENV_NAME]}
									</div>
								}
							/>
						</div>
						<Button
							size="lg"
							type="textGray"
							className="w-100"
							btnClassName={`w-100 ${form?.profile_picture ? "text-label-black" : "text-label-gray-300"}`}
							onClick={() => {setForm({...form, profile_picture: ""})}}
							title={
								<div className="d-flex align-items-center gap-xs">
									<TrashIcon
										width="1.8rem"
										height="1.8rem"
										stroke={form?.profile_picture ? "#1D2939" : "#D0D5DD"}
										strokeWidth="3"
									/>
									{Variable.DELETE_PHOTO[ENV_NAME]}
								</div>
							}
							disabled={!form?.profile_picture}
						/>
					</div>
				</div>
			</div>
		</ModalForm>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(AvatarModal));
