/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import makeRequest from "../utils/request";
import { generateRequestOptions } from "../utils/apiEndPoints";
import { useSelector } from "react-redux";
import { RootState } from "store/types/RootStateTypes";
import { Nullable } from "types/types";
import useToast from "./useToast";

export interface UserBadge {
	id: number;
	first_name: string;
	last_name: Nullable<string>;
	username: string;
	email?: string;
	position: Nullable<string>;
	company_name: Nullable<string>;
	profile_picture: Nullable<string>;
}

const useUserBadge = () => {
	const toast = useToast();
	const [userBadge, setUserBadge] = useState({} as UserBadge);

	useEffect(() => {
		const getUserBadge = async () => {
			const res = await makeRequest(generateRequestOptions("userBadge"));

			if (res.code === 200) setUserBadge(res.data);
			else toast.error(res.message);
		};

		getUserBadge();
	}, []);

	return userBadge;
};

export default useUserBadge;
