export const formatString = (str, ...args) => {
	// formatString("a{0}bcd{1}ef","foo", "bar");
	// formatString("Hello, {name}, are you feeling {adjective}?", { name: "Gabriel", adjective: "OK" });
	if (args.length) {
		var t = typeof args[0];
		var key;
		var _args = "string" === t || "number" === t ? Array.prototype.slice.call(args) : args[0];

		for (key in _args) {
			str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), _args[key]);
		}
	}

	return str;
};

export const getCurrencySymbol = (currency, locale) =>
	new Intl.NumberFormat(locale, { style: "currency", currency })
		.formatToParts(1)
		.find((x) => x.type === "currency").value;

export const getLocale = (ENV_NAME) => (ENV_NAME === "bhs" ? "id-ID" : "en-US");

/**
 * @desc Add digit grouping/insert digit on each 3 digits of number/string, added some fix as well
 * @desc Reference: https://stackoverflow.com/a/6786040
 * @param {number|string} num
 * @returns
 */
function commafy(num) {
	var str = num.toString().split(".");
	if (str[0].length >= 4) {
		str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
	}
	if (str[1] && str[1].length >= 5) {
		str[1] = str[1].replace(/(\d{3})/g, "$1 ");
	}
	return str.join(".");
}

export function nFormatter(num, digits, ENV_NAME) {
	const lookup = [
		{ value: 1, symbol: { eng: "", bhs: "" } },
		{ value: 1e3, symbol: { eng: " K", bhs: " Rb" } },
		{ value: 1e6, symbol: { eng: " M", bhs: " Jt" } },
		{ value: 1e9, symbol: { eng: " B", bhs: " M" } },
		{ value: 1e12, symbol: { eng: " T", bhs: " T" } },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value;
		});

	let returnValue = "0";

	if (item) {
		returnValue = (num / item.value).toFixed(digits).replace(rx, "$1");
		returnValue = commafy(returnValue);
		returnValue = `${returnValue} ${item.symbol?.[ENV_NAME]}`;
	}

	return returnValue;
}

export const listToStr = (list) => {
	if (!Array.isArray(list)) return "";
	return list?.join(", ");
};
