import React from "react";

import { ReactComponent as ComingSoonIllustration } from "assets/svg/coming_soon.svg";
import useTranslate from "shared/hooks/useTranslate";
import { Typography } from "shared/component/Typography/Typography";

const ComingSoon = () => {
	const t = useTranslate();

	return (
		<div className="coming-soon">
			<ComingSoonIllustration />
			<div className="coming-soon-label">
				<Typography.Title>{t("COMING_SOON")}</Typography.Title>
				<Typography.Text fontWeight="regular" size="sm">
					{t("COMING_SOON_AVAILABLE")}
				</Typography.Text>
			</div>
		</div>
	);
};

export default ComingSoon;
