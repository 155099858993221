import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as RoutePath from "./shared/utils/routeLink";
import MainComponent from "./container/MainComponent/MainComponent";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RoutePath.ROOT} component={MainComponent} />
      </Switch>
    </BrowserRouter>
  );
};
export default Router;
