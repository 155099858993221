import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";
import Icon from "../../shared/component/IconComponent/Icon";
import ModalInfo from "../../shared/component/ModalComponent/ModalInfo";

import * as ActionTypes from "../../store/actions/actionTypes";

import arrowLeft from "../../assets/svg/arrow_left.svg";

import "./ForgetPassComponent.scss";
import useToast from "shared/hooks/useToast";

const ForgetPassComponent = ({ setLoginModalShow, ENV_NAME, ...props }) => {
	const [email, setEmail] = useState("");
	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const toast = useToast();

	const goToHomepage = () => props.history.push(RoutePath.ROOT);

	const onChange = (e) => {
		setEmail(e.target.value);
		setErrorMessage("");
	};

	const sendResetPasswordEmail = async () => {
		if (!email) return setErrorMessage(Variable.EMAIL_INVALID[ENV_NAME]);

		const res = await makeRequest({
			...generateRequestOptions("sendResetPasswordEmail"),
			data: { email, user_type: "job_seeker" },
		});

		if (res.code === 200) setSuccess(true);
		else toast.error(res.message);
	};

	return (
		<>
			<div className="fpc">
				<div className="container d-flex justify-content-center">
					<div className="fpc-main">
						<Icon iconType="reset" borderType="outline" />
						<h1>{Variable.FORGOT_PASSWORD[ENV_NAME]}</h1>
						<h3>{Variable.HELP_TEXT_FORGOT[ENV_NAME]}</h3>
						<div className="fpc-main-input">
							<Input
								inputType="email"
								placeholder={Variable.ENTER_YOUR_EMAIL[ENV_NAME]}
								label={Variable.EMAIL[ENV_NAME]}
								value={email}
								onChange={onChange}
							/>
						</div>
						{errorMessage && <div className="error-msg">{errorMessage}</div>}
						<Button
							type="primary"
							title={Variable.RESET_PASSWORD[ENV_NAME]}
							size="md"
							className="d-flex flex-column w-100"
							onClick={sendResetPasswordEmail}
						/>
						<Button
							type="textGray"
							leftIcon={arrowLeft}
							title={Variable.BACK_TO_LOGIN[ENV_NAME]}
							size="md"
							className="fpc-main-button"
							onClick={() => {
								goToHomepage();
								setLoginModalShow(true);
							}}
						/>
					</div>
				</div>
			</div>
			{success && (
				<ModalInfo
					isShow={success}
					isHide={() => {
						setEmail("");
						setSuccess(false);
					}}
					type="verification"
					userEmail={email}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ForgetPassComponent));
