import { useHistory } from "react-router-dom";
import { PiPencilSimple, PiQuestion } from "react-icons/pi";
import PasswordInput from "shared/component/InputComponent/PasswordInput";
import useTranslate from "shared/hooks/useTranslate";
import { CHANGE_PASSWORD } from "shared/utils/routeLink";
import Tooltip from "shared/component/Tooltips/Tooltip";

interface Props {
	onDeactivateClose?: (status: string) => void;
}

const AccountTab = ({ onDeactivateClose }: Props) => {
	const t = useTranslate();
	const history = useHistory();

	return (
		<div className="acc-tab">
			<div>
				<h4>{t("MY_ACCOUNT")}</h4>
				<div className="my-account">
					<div
						className="deactivate d-flex align-items-center"
						onClick={() =>
							typeof onDeactivateClose === "function" &&
							onDeactivateClose("deactivate")
						}
					>
						<p>{t("DEACTIVATE_ACCOUNT")}</p>
						<Tooltip
							placement="bottom-left"
							description={`<ul><li>${t("DA_1")}</li><li>${t("DA_2")}</li></ul>`}
						>
							<PiQuestion
								width={18}
								height={18}
								color="#667085"
								className="tw cursor-pointer mx-2"
							/>
						</Tooltip>
					</div>
					<span className="separator">|</span>
					<div
						className="close d-flex align-items-center"
						onClick={() =>
							typeof onDeactivateClose === "function" && onDeactivateClose("close")
						}
					>
						<p>{t("CLOSE_ACCOUNT")}</p>
						<Tooltip
							placement="bottom-left"
							description={`<ul><li>${t("CA_1")}</li><li>${t("CA_2")}</li><li>${t(
								"CA_3",
							)}</li></ul>`}
						>
							<PiQuestion
								width={18}
								height={18}
								color="#f04438"
								className="tw cursor-pointer mx-2"
							/>
						</Tooltip>
					</div>
				</div>
			</div>
			<hr className="hr-line" />
			<div>
				<div className="d-flex justify-content-between align-items-center">
					<h4>{t("CHANGE_PASSWORD")}</h4>
					<PiPencilSimple
						size={20}
						color="667085"
						className="cursorPointer"
						onClick={() => history.push(CHANGE_PASSWORD)}
					/>
				</div>
				<PasswordInput
					label={t("PASSWORD")}
					placeholder="********"
					name="password"
					value=""
					hideShowPasswordIcon={true}
					readOnly
					disabled
				/>
			</div>
		</div>
	);
};

export default AccountTab;
