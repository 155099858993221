import type { Locale } from "react-date-object";

const indonesian_locale: Locale = {
	name: "indonesian_locale",
	months: [
		["Januari", "Jan"],
		["Februari", "Feb"],
		["Maret", "Mar"],
		["April", "Apr"],
		["Mei", "Mei"],
		["Juni", "Jun"],
		["Juli", "Jul"],
		["Agustus", "Agu"],
		["September", "Sep"],
		["Oktober", "Okt"],
		["November", "Nov"],
		["Desember", "Dec"],
	],
	weekDays: [
		["Sabtu", "Sab"],
		["Minggu", "Min"],
		["Senin", "Sen"],
		["Selasa", "Sel"],
		["Rabu", "Rab"],
		["Kamis", "Kam"],
		["Jumat", "Jum"],
	],
	digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	meridiems: [
		["AM", "am"],
		["PM", "pm"],
	],
};

export default indonesian_locale;
