import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useDropzone } from "react-dropzone";

import * as Variable from "../../../shared/utils/variables";
import * as ActionTypes from "../../../store/actions/actionTypes";
import { homeUrl } from "../../../shared/utils/apiEndPoints";

import "../SignUpComponent";
import Image from "../../../shared/component/UI/Image";
import profileImage from "../../../assets/image/profiledefaultImage.png";
import arrowLeft from "../../../assets/svg/arrow_left.svg";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import RadioInput from "../../../shared/component/InputComponent/RadioInput";
import Select from "../../../shared/component/SelectComponent/Select";
import ProgressBarComponent from "../../../shared/component/ProgressBarComponent/ProgressBarComponent";

const PersonalInformation = ({ move, ENV_NAME, ...props }) => {
	const PERSONAL_INFORMATION_STEP = [
		{ id: 1, name: Variable.PERSONAL_INFORMATION[ENV_NAME] },
		{ id: 2, name: Variable.CONTACT_INFO_LABEL[ENV_NAME] },
		{ id: 3, name: Variable.LOCATION[ENV_NAME] },
		{ id: 4, name: Variable.PROFILE_PICTURE[ENV_NAME] },
	];

	const [step, setStep] = useState(1);
	const [toNext, setToNext] = useState(PERSONAL_INFORMATION_STEP[0]?.id);

	const nextStep = () => {
		setStep((p) => Math.min(p + 1, PERSONAL_INFORMATION_STEP?.length));
	};

	const previousStep = () => {
		setStep((p) => Math.max(p - 1, 1));
	};

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: ".pdf",
		multiple: false,
		maxFiles: 1,
		maxSize: 20000000,
		onDrop: (_, fileRejections) => onFileReject(_, fileRejections),
	});

	const onFileReject = (_, fileRejections) => {
		if (fileRejections?.length > 0) {
			fileRejections?.[0]?.errors.forEach((i) => {
				if (i?.code === "file-invalid-type") {
					// setShowModal(true);
					console.log("first");
				}
			});
			return;
		}
	};

	return (
		<div className="multiComponent">
			<div>
				<Image src={arrowLeft} onClick={previousStep} className="mb-std cursorPointer" />
				<ProgressBarComponent percent={(step / PERSONAL_INFORMATION_STEP?.length) * 100} />
			</div>
			{toNext === PERSONAL_INFORMATION_STEP[0]?.id ? (
				<div>
					<h1>{Variable.PERSONAL_INFORMATION[ENV_NAME]}</h1>
					<div className="multiComponent-content">
						<Input
							label={Variable.FIRST_NAME[ENV_NAME]}
							placeholder={Variable.FIRST_NAME[ENV_NAME]}
							// value={form?.first_name || ""}
							// onChange={onChange}
							name="first_name"
							inputType="label"
							className="w-100"
							required
						/>
						<Input
							label={Variable.LAST_NAME[ENV_NAME]}
							placeholder={Variable.LAST_NAME[ENV_NAME]}
							// value={form?.last_name || ""}
							// onChange={onChange}
							name="last_name"
							inputType="label"
							className="w-100"
						/>
						<DatePickerDropdown
							className="pc-form-row"
							dayLabel={Variable.DATE_OF_BIRTH[ENV_NAME]}
							monthLabel={<>&nbsp;</>}
							yearLabel={<>&nbsp;</>}
							name="date_of_birth"
							// value={form?.date_of_birth}
							// onChange={onChange}
						/>
						<div>
							<h5 className="gender mb-sm">{Variable.GENDER[ENV_NAME]}</h5>
							<div className="pc-form-row">
								<RadioInput
									label={Variable.MALE[ENV_NAME]}
									id="Male"
									name="gender"
									checked={"Male"}
									// onChange={onChange}
								/>
								<RadioInput
									label={Variable.FEMALE[ENV_NAME]}
									id="Female"
									name="gender"
									checked={"Female"}
									// onChange={onChange}
								/>
							</div>
						</div>
						<Button
							title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
							btnClassName="px-lg"
							type="primary"
							size="sm"
							onClick={() => {
								nextStep();
								setToNext(PERSONAL_INFORMATION_STEP[1]?.id);
							}}
						/>
					</div>
				</div>
			) : toNext === PERSONAL_INFORMATION_STEP[1]?.id ? (
				<div>
					<h1>{Variable.CONTACT_INFO_LABEL[ENV_NAME]}</h1>
					<div className="multiComponent-content">
						<Input
							label={Variable.EMAIL[ENV_NAME]}
							placeholder={Variable.EMAIL_PLACEHOLDER[ENV_NAME]}
							// value={form?.first_name || ""}
							// onChange={onChange}
							name="email"
							inputType="label"
							className="w-100"
							required
						/>
						<Input
							label={Variable.USERNAME[ENV_NAME]}
							placeholder={Variable.USERNAME[ENV_NAME]}
							// value={form?.last_name || ""}
							// onChange={onChange}
							name="user_name"
							inputType="label"
							className="w-100"
						/>
						<Input
							label={Variable.MOBILE_PHONE_NUMBER[ENV_NAME]}
							placeholder={Variable.MOBILE_PHONE_NUMBER[ENV_NAME]}
							name="mobile_phone_number"
							// value={form?.mobile_phone_number || ""}
							// onChange={onChange}
							inputType="phone"
							spesificType="text"
							className="w-100"
							required
						/>
						<Input
							label={Variable.MOBILE_PHONE_NUMBER[ENV_NAME] + ` 2`}
							placeholder={Variable.MOBILE_PHONE_NUMBER[ENV_NAME] + ` 2`}
							name="mobile_phone_number_2"
							// value={form?.mobile_phone_number_2 || ""}
							// onChange={onChange}
							inputType="phone"
							spesificType="text"
							className="w-100"
						/>
						<Button
							title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
							btnClassName="px-lg"
							type="primary"
							size="sm"
							onClick={() => {
								nextStep();
								setToNext(PERSONAL_INFORMATION_STEP[2]?.id);
							}}
						/>
					</div>
				</div>
			) : toNext === PERSONAL_INFORMATION_STEP[2]?.id ? (
				<div>
					<h1>{Variable.LOCATION[ENV_NAME]}</h1>
					<div className="multiComponent-content">
						<Input
							label={Variable.COUNTRY[ENV_NAME]}
							placeholder={Variable.COUNTRY[ENV_NAME]}
							// value={form?.first_name || ""}
							// onChange={onChange}
							name="country"
							inputType="label"
							className="w-100"
							required
						/>
						<Input
							label={Variable.CITY[ENV_NAME]}
							placeholder={Variable.CITY[ENV_NAME]}
							// value={form?.first_name || ""}
							// onChange={onChange}
							name="city"
							inputType="label"
							className="w-100"
							required
						/>
						<Input
							label={Variable.STREET_ADDRESS[ENV_NAME]}
							placeholder={Variable.STREET_ADDRESS[ENV_NAME]}
							// value={form?.last_name || ""}
							// onChange={onChange}
							name="street_adddress"
							inputType="label"
							className="w-100"
						/>
						<Input
							label={Variable.POSTAL_CODE[ENV_NAME]}
							placeholder={Variable.POSTAL_CODE[ENV_NAME]}
							name="postal_code"
							// value={form?.mobile_phone_number || ""}
							// onChange={onChange}
							inputType="label"
							spesificType="number"
							className="w-100"
						/>
						<Select
							label={Variable.NATIONALITY[ENV_NAME]}
							placeholder={Variable.NATIONALITY[ENV_NAME]}
							name="nationality"
							// value={form?.nationality || ""}
							// onChange={onChange}
							// items={countryList}
							searchable={false}
							noDefault
						/>
						<Button
							title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
							btnClassName="px-lg"
							type="primary"
							size="sm"
							onClick={() => {
								nextStep();
								setToNext(PERSONAL_INFORMATION_STEP[3]?.id);
							}}
						/>
					</div>
				</div>
			) : toNext === PERSONAL_INFORMATION_STEP[3]?.id ? (
				<div className="d-flex flex-column">
					<h1>{Variable.PROFILE_PICTURE[ENV_NAME]}</h1>
					<div className="upload uploadDefault">
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div className="upload-img d-flex align-items-center justify-content-center">
									<Image src={profileImage} />
								</div>
								<div className="upload-title d-flex flex-column align-items-center justify-content-center">
									<h3>
										{Variable.DRAG_FILE_LABEL[ENV_NAME]}
										<span className="cursorPointer uploadLink">
											{Variable.CHOOSE_FILE[ENV_NAME]}
										</span>
									</h3>
									<h4>{Variable.MAXIMUM_FILE[ENV_NAME]}</h4>
								</div>
							</div>
						</section>
						<Button
							title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
							btnClassName="px-lg"
							type="primary"
							size="sm"
							onClick={move}
						/>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(PersonalInformation));
