// @ts-ignore
import React, { useEffect, useState } from "react";
import cookies from "react-cookies";
import moment from "moment";
import "moment/locale/id";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import { toast } from "react-toastify";

import { homeUrl } from "../../shared/utils/apiEndPoints";
import * as ActionTypes from "../../store/actions/actionTypes";
import * as RoutePath from "../../shared/utils/routeLink";

import "./MainComponent.scss";
import LoadingComponent from "../../shared/component/LoadingComponent/LoadingComponent";
import Header from "../HeaderComponent/HeaderComponent";
import DummyComponent from "../../component/DummyComponent/DummyComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import { SCROLL_TO_TOP } from "../../shared/utils/variables";
// import { CaretDown } from "../../shared/component/svg/Icons";
import HomeComponent from "../../component/HomeComponent/HomeComponent";
import ProfileComponent from "../../component/ProfileComponent/ProfileComponent";
import SignUpComponent from "../../container/SignUpComponent/SignUpComponent";
import ForgetPassComponent from "../../container/ForgetPassComponent/ForgetPassComponent";
import ModalLogin from "../../shared/component/ModalComponent/ModalLogin";
import DetailsRegisterComponent from "../SignUpComponent/DetailsRegisterComponent";
import LogStatusHandler from "./LogStatusHandler";
import MyJobsComponent from "../../component/MyJobsComponent/MyJobsComponent";
import ResetPassword from "../ResetPasswordComponent/ResetPassword";
import Settings from "../../component/Settings/Settings";
import ChangePassword from "component/ChangePasswordComponent/ChangePassword";
import IncompleteApps from "component/MyJobsComponent/IncompleteApplication/IncompleteApps";

import { PiCaretUpBold } from "react-icons/pi";
import PrivacyPolicy from "component/PrivacyPolicy";
import TermsOfUse from "component/TermsofUse";
import Button from "shared/component/ButtonComponent/Button";

// This function will return the Component on any given address
const getActiveComp = (name) => {
	// Do this if you have an id
	if (name && name.includes(RoutePath.PROFILE)) {
		name = RoutePath.PROFILE;
	}
	if (name && name.includes(RoutePath.RESET_PASSWORD)) {
		name = RoutePath.RESET_PASSWORD;
	}
	switch (name) {
		case RoutePath.ROOT: {
			if (homeUrl !== "localhost") {
				window.open("https://" + homeUrl, "_self");
				return {
					activeComponent: LoadingComponent,
					isActiveComponent: name,
				};
			} else {
				return {
					activeComponent: HomeComponent,
					isActiveComponent: RoutePath.ROOT,
				};
			}
		}
		case RoutePath.PROFILE: {
			return {
				activeComponent: ProfileComponent,
				isActiveComponent: RoutePath.PROFILE,
			};
		}
		case RoutePath.SETTINGS: {
			return {
				activeComponent: Settings,
				isActiveComponent: RoutePath.SETTINGS,
			};
		}
		case RoutePath.CHANGE_PASSWORD: {
			return {
				activeComponent: ChangePassword,
				isActiveComponent: RoutePath.CHANGE_PASSWORD,
			};
		}
		case RoutePath.PRIVACY_POLICY: {
			return {
				activeComponent: PrivacyPolicy,
				isActiveComponent: RoutePath.PRIVACY_POLICY,
			};
		}
		case RoutePath.TERMS_OF_USE: {
			return {
				activeComponent: TermsOfUse,
				isActiveComponent: RoutePath.TERMS_OF_USE,
			};
		}
		case RoutePath.FORGOT_PASSWORD: {
			return {
				activeComponent: ForgetPassComponent,
				isActiveComponent: RoutePath.FORGOT_PASSWORD,
			};
		}
		case RoutePath.SIGN_UP: {
			return {
				activeComponent: SignUpComponent,
				isActiveComponent: RoutePath.SIGN_UP,
				hideHeader: true,
				hideFooter: true,
			};
		}
		case RoutePath.DETAILS_REGISTER: {
			return {
				activeComponent: DetailsRegisterComponent,
				isActiveComponent: RoutePath.DETAILS_REGISTER,
				hideHeader: true,
				hideFooter: true,
			};
		}
		case RoutePath.ALL_COMPONENT: {
			return {
				activeComponent: DummyComponent,
				isActiveComponent: RoutePath.ALL_COMPONENT,
			};
		}
		case RoutePath.MY_JOBS: {
			return {
				activeComponent: MyJobsComponent,
				isActiveComponent: RoutePath.MY_JOBS,
				hideFooter: true,
			};
		}
		case RoutePath.RESET_PASSWORD: {
			return {
				activeComponent: ResetPassword,
				isActiveComponent: RoutePath.RESET_PASSWORD,
				hideFooter: true,
			};
		}
		case RoutePath.INCOMPLETE_APPLICATIONS: {
			return {
				activeComponent: IncompleteApps,
				isActiveComponent: RoutePath.INCOMPLETE_APPLICATIONS,
				hideFooter: true,
			};
		}
		default: {
			return {
				activeComponent: LoadingComponent,
				isActiveComponent: name,
			};
		}
	}
};

const MainComponent = ({ ENV_NAME, ...props }) => {
	let location = useLocation();
	const [component, setComponent] = useState({
		activeComponent: LoadingComponent,
		isActiveComponent: "",
		hideFooter: false,
		hideHeader: false,
	});
	const [showScrollButton, setShowScrollButton] = useState(false);

	const CheckIsNotLoggedIn =
		homeUrl !== "localhost" && (!cookies.load("ptkjauthtoken") || !props?.userDetails);

	useEffect(() => {
		if (homeUrl !== "localhost" && !cookies.load("ptkjauthtoken")) {
			window.open("https://" + homeUrl, "_self");
			return;
		}

		// We remove console log functionality in production
		const handleScrollButtonVisibility = () => {
			setShowScrollButton(window.scrollY > window.screen.height * 0.5);
		};

		// if (homeUrl === "portalsekolah.co.id") {
		// 	console.log = function () {};
		// }
		window.addEventListener("scroll", handleScrollButtonVisibility);

		return () => {
			window.removeEventListener("scroll", handleScrollButtonVisibility);
		};
	}, []);

	// For every link change, we set the component according to the page
	useEffect(() => {
		let component = getActiveComp(location.pathname);

		setComponent(component);
		window.scrollTo({ top: 0, left: 0, behavior: "instant" });
	}, [location]);

	// I don't really know what's the best way to detect the cookie change
	// But for every cookie change, we set our language prefs aswell
	// @ts-ignore
	useEffect(() => {
		let lang = cookies.load("lang");
		let curr = cookies.load("curr");
		let momentLocale = moment.locale() === "id" ? "bhs" : "eng";
		if (lang !== ENV_NAME || lang !== momentLocale) {
			props.changeLanguage(lang);
			if (lang !== "eng") {
				moment.locale("id");
				moment.updateLocale("id", {
					longDateFormat: {
						LT: "HH:mm",
						LTS: "HH:mm:ss",
						L: "DD/MM/YYYY",
						LL: "D MMMM YYYY",
						LLL: "D MMMM YYYY [pukul] HH:mm",
						LLLL: "dddd, D MMMM YYYY [pukul] HH:mm",
					},
				});
			} else moment.locale("en");
		}

		if (curr && curr?.id !== props.curr?.id) {
			props.changeCurrency(curr);
		}

		// Check user type, and make sure the token and id is present
		// TODO if necessary: Kick if the user is not logged in, but only on a certain page
		if (cookies.load("ptkjauthtoken") && props?.userDetails?.id) {
			let tokenData;
			try {
				tokenData = JSON.parse(window.atob(cookies.load("ptkjauthtoken").split(".")[1]));
			} catch {
				return false;
			}
			// We only check if the user id in token is the same as the id in the redux
			let checkUserId = `${tokenData.user_id}` === `${props?.userDetails?.id}`;
			// Redirect to main page in case role is not the job_seeker
			if (
				checkUserId &&
				props?.userDetails?.role &&
				props?.userDetails?.role !== "job_seeker"
			) {
				window.open("https://" + homeUrl, "_self");
			}
		}
	});

	if (CheckIsNotLoggedIn) {
		return (
			<>
				<LogStatusHandler />
				<LoadingComponent />
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>Portal Kerja</title>
			</Helmet>
			{/* This is to check the login status of a user */}
			<LogStatusHandler />

			<div className="page-wrapper">
				<div className="header-content-wrapper">
					{!component.hideHeader && <Header />}

					<ModalLogin
						// @ts-ignore
						show={props.loginModalShow}
						onHide={() => props.setLoginModalShow(false)}
					/>
					<component.activeComponent />
				</div>

				{!component.hideFooter && <FooterComponent />}
			</div>

			<Button
				type="primary"
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}}
				title={<PiCaretUpBold color="#ffffff" size={20} />}
				btnClassName={`btn btn-scrollTop ${showScrollButton ? "" : "hide"}`}
				titleTooltip={SCROLL_TO_TOP[ENV_NAME]}
			></Button>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		...state,
		loginModalShow: state.auth.loginModalShow,
		curr: state.auth.currency,
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
		userDetails: state.auth.userDetails,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {
		changeLanguage: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_LANGUAGE,
				payload: payload,
			}),
		changeCurrency: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_CURRENCY,
				payload: payload,
			}),
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(
	mapStateToProps,
	mapStateToDispatch,
	// @ts-ignore
)(withRouter(MainComponent));
