import { FunctionComponent, ReactElement } from 'react'
import './index.scss'
import PrivacyPolicyBanner from '../../assets/image/privacy_policy_banner.svg'

const PrivacyPolicy: FunctionComponent = (): ReactElement => {
//   useEffect(() => {
//     const width = window.screen.width
//     alert(`width: ${width}`)
//   }, [])
  return (
    <div className='privacy_policy'>
        <div className="privacy_policy_header">
            <p>kebijakan privasi</p>
            <div>
                <img src={PrivacyPolicyBanner} alt="Banner" />
            </div>
        </div>
        <div className="privacy_policy_content">
            <div className="item">
                <h3>Kebijakan Privasi</h3>
                <h5>Kebijakan Privasi ini diubah terakhir kali pada tanggal 5 September 2023.</h5>
                <p>
                    Kami memahami betapa penting dan berharganya privasi, oleh karena itu PT. Visi Prima Nusantara selaku penyedia layanan <strong>“Portal Kerja”</strong> menghormati dan berusaha melindungi keamanan data Anda (“Pemberi Kerja” dan “Pencari Kerja”) secara pribadi maupun instansi terkait data, informasi, maupun keterangan yang Anda berikan pada saat mengakses layanan <strong>“Portal Kerja”</strong>.
                </p>
                <p>
                    Untuk kenyamanan bersama, kami menganjurkan kepada Anda untuk membaca dan memahami Kebijakan Privasi ini sebelum menggunakan layanan <strong>“Portal Kerja”</strong>. Kebijakan Privasi ini berlaku pada layanan website kami, <strong>portalkerja.co.id</strong>, dan seluruh subdomain yang terkait, beserta aplikasi kami, <strong>“Portal Kerja”</strong>, baik di Android maupun iOS (selanjutnya kami sebut layanan). <strong>Dengan mengakses atau menggunakan layanan “Portal Kerja”, Anda dianggap telah membaca dan memahami Kebijakan Privasi ini.</strong> Apabila telah membaca dan memahami Kebijakan Privasi ini dan Anda tidak menyetujui sebagian atau seluruh Kebijakan Privasi dari kami, maka Anda disarankan untuk tidak menggunakan layanan <strong>“Portal Kerja”.</strong>
                </p>
                <div className="list_of_contents">
                    <p>Daftar Isi</p>
                    <ol className="items_wrapper">
                        <li className='item'>
                            <a className='link' href="#one">Definisi dan kata kunci</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#two">Data pribadi yang dapat kami kumpulkan</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#three">Cara kami mendapatkan data Anda</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#four">Penggunaan data pribadi Anda</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#five">Pengungkapan data pribadi Anda</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#six">Penyimpanan data pribadi Anda</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#seven">Keamanan</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#eight">Hak Anda</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#nine">Perubahan Kebijakan Privasi</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#ten">Cara menghubungi kami</a>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="item" id='one'>
                <div className="item_data">
                    <div className="title">
                        <h5>1.</h5>
                        <h5>Definisi dan kata kunci</h5>
                        <div/>
                        <p>Untuk membantu memahami Kebijakan Privasi ini secara jelas, setiap kali istilah berikut direferensikan mengacu pada pengertian berikut:</p>
                    </div>
                    <div className="hr_line" />
                    <ul className="data_content">
                       <li>
                            <strong className='yellow'>Cookie: &nbsp;</strong>
                            data berukuran kecil yang dihasilkan oleh website dan disimpan pada halaman browser. Ini digunakan untuk mengidentifikasi browser Anda, menyediakan analitik, mengingat informasi tentang Anda seperti pengaturan bahasa atau informasi login.
                        </li> 
                       <li>
                            <strong className='yellow'>Pengguna: &nbsp;</strong>
                            mengacu pada organisasi, instansi, atau perorangan yang terdaftar untuk menggunakan layanan <strong>“Portal Kerja”</strong> yang selanjutnya dibedakan menjadi “Pemberi Kerja” yakni pihak Pengguna yang menerbitkan lowongan kerja serta melakukan manajemen perekrutan calon karyawan pada layanan <strong>“Portal Kerja”</strong>, serta “Pencari Kerja” yang merupakan pihak Pengguna yang mencari lowongan kerja pada layanan <strong>“Portal Kerja”</strong>.
                        </li> 
                       <li>
                            <strong className='yellow'>Perangkat: &nbsp;</strong>
                            setiap peralatan atau perangkat yang terhubung internet seperti handphone, tablet, komputer, atau perangkat lainnya yang dapat digunakan untuk mengunjungi <strong>“Portal Kerja”</strong> dan menggunakan layanannya.
                       </li> 
                       <li>
                            <strong className='yellow'>Alamat IP: &nbsp;</strong>
                            Setiap perangkat yang terhubung dengan internet diberikan sebuah kombinasi angka yang dikenal dengan alamat IP (Internet Protocol). Angka-angka ini biasanya disusun dalam blok geografis. Sebuah alamat IP biasanya digunakan untuk mengidentifikasi lokasi dari sebuah perangkat yang terhubung ke internet.
                       </li> 
                       <li>
                            <strong className='yellow'>Data Pribadi: &nbsp;</strong>
                            setiap informasi baik secara langsung, tidak langsung, atau dalam hubungan dengan informasi lain dari setiap Pengguna dalam mengakses layanan <strong>“Portal Kerja”</strong>.
                       </li> 
                       <li>
                            <strong className='yellow'>Layanan: &nbsp;</strong>
                            mengacu pada layanan yang disediakan oleh Portal Kerja sebagaimana dideskripsikan dalam istilah ini dan pada seluruh aplikasi <strong>“Portal Kerja”</strong>.
                       </li> 
                       <li>
                            <strong className='yellow'>Layanan pihak ketiga: &nbsp;</strong> 
                            mengacu pada pihak lain yang bekerja sama dengan kami, yakni departemen ketenagakerjaan, mitra bisnis, penyedia layanan teknis, jasa pembayaran, pihak penyedia jasa analitik, dan penyedia search engine. Tidak terlepas pula pada pihak pengiklan, sponsor, partner promosi dan pemasaran, serta pihak lainnya yang memungkinkan mereka untuk memberikan konten, produk, atau jasa yang mungkin menarik bagi Pengguna.
                       </li> 
                       <li>
                            <strong className='yellow'>Anda: &nbsp;</strong>
                            Perorangan atau entitas yang terdaftar pada layanan <strong>“Portal Kerja”</strong> untuk menggunakan layanan <strong>“Portal Kerja”</strong>.
                       </li>
                    </ul>
                </div>
            </div>
            <div className="item" id='two'>
                <div className="item_data">
                    <div className="title">
                        <h5>2.</h5>
                        <h5>Data pribadi yang dapat kami kumpulkan</h5>
                        <div/>
                        <p>Data pribadi Anda dapat kami kumpulkan melalui beberapa cara, antara lain:</p>
                    </div>
                    <div className="hr_line" />
                    <div className="data_content custom">
                        <div className="item_custom">
                            <p className="alpha">a.</p>
                            <p className="alpha">Data yang Anda berikan kepada kami</p>
                            <div/>
                            <div className="alpha_desc">
                                Kami mengumpulkan setiap data yang Anda berikan atau masukkan secara langsung pada layanan <strong>“Portal Kerja”,</strong> mencakup: identitas pribadi, identitas perusahaan/instansi/organisasi/badan usaha, profil perusahaan/instansi/organisasi/badan usaha, profil pribadi, nama lengkap, username, password, nomor telepon seluler, alamat surat elektronik, foto, deskripsi pribadi, riwayat pekerjaan, riwayat pendidikan, resume, portofolio, serta data lainnya.
                            </div>
                        </div>
                        <div className="item_custom">
                            <p className="alpha">b.</p>
                            <p className="alpha">Data yang kami kumpulkan secara otomatis</p>
                            <div/>
                            <div className="alpha_desc">
                                Kami mengumpulkan beberapa data secara otomatis pada saat Anda menggunakan layanan <strong>“Portal Kerja”</strong>, mencakup:
                                <ul className="alpha_desc" style={{ paddingLeft: '3.5rem', marginBottom: '10px' }}>
                                    <li>
                                        <strong style={{ fontWeight: '600' }}>Data sistem</strong> <br />
                                        Berupa data teknis mengenai komputer atau perangkat Anda saat menggunakan layanan <strong>“Portal Kerja”</strong>, seperti: jenis perangkat, alamat Protokol Internet (IP Address), jenis dan versi sistem operasi, jenis dan versi browser, jaringan dan koneksi.
                                    </li>
                                    <li>
                                        <strong style={{ fontWeight: '600' }}>Data penggunaan</strong> <br />
                                        Berupa statistik penggunaan Anda pada layanan <strong>“Portal Kerja”</strong>, yang mencakup: fitur atau halaman yang diakses, waktu yang dihabiskan pada fitur atau halaman pada layanan, data pencarian, data klik, tanggal dan waktu kunjungan serta permintaan layanan.
                                    </li>
                                </ul>
                                Beberapa informasi yang dapat kami ambil secara otomatis ketika Anda menggunakan bagian layanan tertentu, merupakan bagian dari peningkatan pengalaman pengguna, meskipun fitur tersebut sifatnya tidak wajib: <br />
                                <ul className="alpha_desc" style={{ paddingLeft: '3.5rem', marginTop: '4px', display: 'grid', gap: '2px' }}>
                                    <li>
                                        Lokasi (GPS): data lokasi membantu memberikan representasi lokasi Anda pada saat menggunakan layanan <strong>“Portal Kerja”</strong> untuk keperluan analitik yang diperlukan oleh kami maupun pengguna lain.
                                    </li>
                                    <li>
                                        Kamera: memberikan izin kamera memungkinkan pengguna untuk mengambil gambar dari kamera langsung di layanan kami. Izin ini juga terkait dengan layanan yang terkait dengan konferensi video yang    memerlukan akses kamera.
                                    </li>
                                    <li>
                                        Audio (Speaker dan mikrofon): izin akses terhadap audio diperlukan ketika pengguna hendak menggunakan layanan konferensi video. Apabila pengguna tidak mengaktifkan izin ini, pengguna masih tetap dapat menggunakan fitur tersebut namun dengan keterbatasan tertentu.
                                    </li>
                                    <li>
                                        Galeri foto (Gambar): dengan memberikan izin untuk mengakses galeri foto, maka pengguna dapat mengganti foto profil atau upload gambar yang ada pada galeri ke fitur-fitur tertentu pada layanan yang memungkinkan terjadinya proses upload file.
                                    </li>
                                    <li>
                                        Penyimpanan: izin akses terhadap media penyimpanan diperlukan pada beberapa bagian, seperti ketika pengguna hendak menyimpan file konten supaya dapat diakses secara offline, mengganti foto profil dari media penyimpanan, mengunggah file resume, portfolio, sertifikat atau dokumen tertentu, serta proses lain yang akan menyimpan data pada media penyimpanan di perangkat pengguna.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="item_custom">
                            <p className="alpha">c.</p>
                            <p className="alpha">Data yang kami terima dari sumber lain</p>
                            <div/>
                            <ul className="alpha_desc">
                                <li>
                                    Kami dapat menerima informasi mengenai Anda dari pihak ketiga yang bekerja sama dengan kami, seperti departemen ketenagakerjaan, mitra bisnis, penyedia layanan teknis, jasa pembayaran, pihak penyedia jasa analitik, penyedia search engine, serta pihak lainnya yang memungkinkan mereka untuk memberikan data analitik kepada kami atau kami olah sendiri. Serta pihak lain seperti pengiklan, sponsor, partner promosi dan pemasaran atau pihak lain yang memberikan konten, produk, atau jasa yang mungkin menarik bagi Pengguna.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item" id='three'>
                <div className="item_data">
                    <div className="title">
                        <h5>3.</h5>
                        <h5>Cara kami mendapatkan data Anda</h5>
                        <div/>
                        <p>Kami menggunakan alat seperti seperti Cookie serta layanan analisis untuk mengumpulkan data yang telah disebutkan sebelumnya.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='four'>
                <div className="item_data">
                    <div className="title">
                        <h5>4.</h5>
                        <h5>Penggunaan data pribadi Anda</h5>
                        <div/>
                        <p>Kami menggunakan data pribadi Anda yang kami peroleh untuk beberapa tujuan, yakni sebagai berikut:</p>
                    </div>
                    <div className="hr_line" />
                    <ul className="data_content" style={{ display: 'grid', gap: '4px' }}>
                        <li>Mengidentifikasi pengguna unik di seluruh perangkat</li>
                        <li>Berkomunikasi dengan Anda</li>
                        <li>Mengatur preferensi akun Anda</li>
                        <li>Menganalisis tren dan trafik, serta melacak data penggunaan layanan</li>
                        <li>Menyajikan, personalisasi, dan menyempurnakan layanan kami</li>
                        <li>Meningkatkan mutu layanan kami serta mengembangkan produk, layanan, dan fitur baru</li>
                        <li>Meningkatkan keselamatan, integritas, dan keamanan pengguna, karyawan kami, pihak ketiga, publik, atau layanan kami apabila dibutuhkan oleh aparat hukum, maupun atas kebijakan kami.</li>
                    </ul>
                </div>
            </div>
            <div className="item" id='five'>
                <div className="item_data">
                    <div className="title">
                        <h5>5.</h5>
                        <h5>Pengungkapan data pribadi Anda</h5>
                        <div/>
                        <p>Semua data pribadi Anda akan tetap aman, namun kami memiliki hak membagikan data tertentu tentang Anda kepada beberapa pihak antara lain kepada:</p>
                    </div>
                    <div className="hr_line" />
                    <div className="data_content custom">
                        <div className="item_custom">
                            <p className="alpha">a.</p>
                            <p className="alpha">Pengguna lain</p>
                            <div/>
                            <div className="alpha_desc">
                                Untuk konteks pengungkapan data “Pencari Kerja” kepada “Pemberi Kerja” adalah informasi pribadi yang dimasukkan pada layanan <strong>“Portal Kerja”</strong> dalam lingkup melamar pekerjaan, termasuk nama, foto profil, nomor kontak, alamat surat elektronik, domisili, preferensi kerja, serta informasi lainnya pada profil “Pencari Kerja” untuk tujuan yang masih sesuai dengan penggunaan layanan <strong>“Portal Kerja”</strong>. <br />
                                Untuk konteks pengungkapan data “Pemberi Kerja” kepada “Pencari Kerja” adalah informasi yang terkait profil perusahaan/organisasi/instansi/badan usaha atau profil informasi pribadi yang mewakili perusahaan/organisasi/instansi/badan usaha tersebut seputar lowongan kerja, antara lain pertanyaan, tes, jadwal wawancara, serta informasi lainnya yang masih sesuai dengan penggunaan layanan <strong>“Portal Kerja”</strong>.
                            </div>
                        </div>
                        <div className="item_custom">
                            <p className="alpha">b.</p>
                            <p className="alpha">Penyedia layanan, kontraktor, dan agen</p>
                            <div/>
                            <div className="alpha_desc">
                                Kami memiliki hak untuk memberikan data kepada pihak penyedia layanan seperti layanan email dan hosting, serta layanan pemasaran dan periklanan.
                            </div>
                        </div>
                        <div className="item_custom">
                            <p className="alpha">c.</p>
                            <p className="alpha">Mitra bisnis</p>
                            <div/>
                            <div className="alpha_desc">
                                Kami bekerja sama dengan situs web dan platform lainnya untuk mendistribusikan layanan kami dan meningkatkan kunjungan ke <strong>“Portal Kerja”</strong>. Dalam hal ini, kami memiliki hak untuk membagikan data lokasi Anda kepada mitra bisnis kami tersebut.
                            </div>
                        </div>
                        <div className="item_custom">
                            <p className="alpha">d.</p>
                            <p className="alpha">Layanan analitik data</p>
                            <div/>
                            <div className="alpha_desc">
                                Kami memiliki hak membagikan data tertentu, seperti data akun mau pun data sistem seperti yang telah disebutkan pada nomor 2, kepada pihak penyedia layanan analitik data, dengan menghapus beberapa hal pada data seperti nama dan alamat surat elektronik lalu menggantikannya dengan ID token yang dibutuhkan oleh pihak penyedia layanan analitik data.
                            </div>
                        </div>
                        <div className="item_custom">
                            <p className="alpha">e.</p>
                            <p className="alpha">Aparatur Hukum dan pihak keamanan</p>
                            <div/>
                            <div className="alpha_desc">
                                Kami dapat mengungkapkan data pribadi Anda kepada pihak ketiga (atas kebijakan kami) dengan pertimbangan sebagai berikut:
                                <ul className="alpha_desc">
                                    <li>Diizinkan atau diminta oleh hukum</li>
                                    <li>Diperlukan sebagai bagian dari permintaan, perintah, atau proses pengadilan, pemerintahan, atau hukum</li>
                                    <li>Diperlukan sebagai bagian dari pemanggilan, surat perintah yang berlaku, atau permintaan lainnya yang berlaku secara sah</li>
                                    <li>Diperlukan untuk melaksanakan Ketentuan Penggunaan, Kebijakan Privasi kami, dan perjanjian hukum lainnya</li>
                                    <li>Diperlukan untuk mencegah, menemukan, menelusuri, atau mengatasi penyalahgunaan, kemungkinan pelanggaran hukum (atau aturan atau peraturan), penipuan, atau masalah keamanan atau teknis</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item" id='six'>
                <div className="item_data">
                    <div className="title">
                        <h5>6.</h5>
                        <h5>Penyimpanan data pribadi Anda</h5>
                        <div/>
                        <p>Data pribadi Anda tetap akan kami simpan selama akun yang Anda gunakan tidak ditutup. Dalam situasi di mana Anda tidak dapat mengakses akun Anda, Anda dapat melakukan penggantian kata sandi pada halaman login, atau dapat menyampaikan permintaan kepada kami melalui <a href="https://portalkerja.co.id/help" target='_blank' style={{ color: '#667085' }}>tautan berikut.</a></p>
                    </div>
                </div>
            </div>
            <div className="item" id='seven'>
                <div className="item_data">
                    <div className="title">
                        <h5>7.</h5>
                        <h5>Keamanan</h5>
                        <div/>
                        <p>
                            Kami menggunakan sistem pengamanan yang dirancang untuk melindungi data pribadi dari kehilangan, penyalahgunaan, maupun akses yang tidak diizinkan. Kami akan mengambil tindakan untuk menghindari terjadinya hal-hal seperti itu. Namun, tidak ada sistem yang 100% aman, artinya kami tidak dapat benar-benar menjamin bahwa komunikasi antara Anda dan <strong>“Portal Kerja”</strong>, layanan, atau informasi apa pun yang diberikan kepada kami, akan bebas dari akses tanpa izin oleh pihak lain. Hal yang sangat berperan penting dari sistem keamanan data akun Anda adalah kata sandi, dan Anda bertanggung jawab untuk melindungi kata sandi tersebut. Sebaiknya Anda tidak membagikan kata sandi akun Anda kepada pihak manapun, namun jika Anda yakin bahwa akun Anda telah dibobol, sebaiknya Anda langsung mengganti kata sandi tersebut dan menghubungi kami untuk melaporkan kejadian atau kekhawatiran apa pun. <br />
                            Kami tidak bisa dituntut dalam hal apapun berkaitan dengan kerugian yang dialami oleh Pengguna terkait kebocoran data, keamanan sistem, kerusakan atau kerugian, termasuk potensi kerugian dan potensi kehilangan keuntungan yang disebabkan oleh gangguan keamanan yang ada. Apabila Pengguna ingin menyampaikan bahwa akun mereka mengalami peretasan, atau akses yang tidak bertanggung jawab yang diakibatkan oleh kami atau sistem kami, maka Pengguna harus memberikan bukti bahwa pihak kami yang melakukan tindakan pembocoran data tersebut.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item" id='eight'>
                <div className="item_data">
                    <div className="title">
                        <h5>8.</h5>
                        <h5>Hak Anda</h5>
                        <div/>
                        <p>Anda memiliki hak untuk mengajukan pengubahan atau penghapusan data pribadi Anda pada layanan kami. Apabila Anda memutuskan untuk menghapus data pribadi Anda, hal tersebut dapat mengakibatkan penghentian akun Anda sehingga Anda tidak dapat lagi menggunakan layanan kami. Kami akan mengirimkan pemberitahuan mengenai konsekuensi dari penghapusan akun tersebut, sehingga Anda dapat mempertimbangkan apakah akan tetap menghapus data pribadi Anda atau tidak.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='nine'>
                <div className="item_data">
                    <div className="title">
                        <h5>9.</h5>
                        <h5>Perubahan Kebijakan Privasi</h5>
                        <div/>
                        <p>Kami dapat melakukan perubahan pada Kebijakan Privasi ini sewaktu-waktu tanpa pemberitahuan kepada pengguna maupun persetujuan pengguna terlebih dahulu. Kami akan mengirimkan pemberitahuan kepada Anda melalui email atau ditampilkan pada layanan, apabila kami membuat perubahan yang mendasar. Mohon dapat mengunjungi kembali halaman ini secara berkala, untuk melihat adanya pengubahan pada Kebijakan Privasi layanan kami.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='ten'>
                <div className="item_data">
                    <div className="title">
                        <h5>10.</h5>
                        <h5>Cara menghubungi kami</h5>
                        <div/>
                        <p>Jika Anda mengalami kendala atau pertanyaan mengenai Kebijakan Privasi ini, Anda dapat menghubungi kami melalui alamat surat elektronik <a href="" style={{ color: '#667085' }}>info@portalkerja.co.id.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPolicy