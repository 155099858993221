// Import from external link
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isEmpty, orderBy } from "lodash";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router";
import downloadjs from "downloadjs";

import LoadingComponent from "../../shared/component/LoadingComponent/LoadingComponent";
import ProfileFrameComponent from "../../shared/component/ProfileFrameComponent/ProfileFrameComponent";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import {
	ArrowLeftIcon,
	BirthCakeIcon,
	BuildingOutlineIcon,
	CaretDown,
	ClipIcon,
	DownloadIcon,
	FemaleIcon,
	FileIcon,
	FlagIcon,
	GradCapIconOutline,
	JobBuildingOutlineIcon,
	MailIcon,
	MaleIcon,
	MoneyOutlineIcon,
	OpenEyeIcon,
	PencilIcon,
	PhoneIcon,
	PinIcon,
	PlusIcon,
	PreviewResumeIcon,
	RepeatIcon,
	TrashIcon,
} from "../../shared/component/svg/Icons";
import Image from "../../shared/component/UI/Image";
import * as RoutePath from "../../shared/utils/routeLink";
import { formatString, getCurrencySymbol, getLocale, listToStr } from "../../shared/utils/string";
import * as Variable from "../../shared/utils/variables";
import ProfileItemComponent from "./comps/ProfileItemComponent";
import "./ProfileComponent.scss";
import DefaultProfPic from "../../assets/image/profiledefaultImage.png";
import RemoveMediaIcon from "../../assets/image/remove-media.png";
import DeleteWarnIcon from "../../assets/svg/delete-warn.svg";
import Button from "../../shared/component/ButtonComponent/Button";
import Truncate from "react-truncate";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ExpItemComponent from "./comps/ExpItemComponent";
import Anchor from "../../shared/component/UI/Anchor";
import { getIconByName } from "../../shared/utils/file";
import { titleCaseEachWord } from "../../shared/utils/titleCaseEachWord";

// Modals
import PersonalInfoModal from "./modals/PersonalInfoModal";
import ExperienceModal from "./modals/ExperienceModal";
import LanguageModal from "./modals/LanguageModal";
import SkillModal from "./modals/SkillModal";
import EducationModal from "./modals/EducationModal";
import JobPrefModal from "./modals/JobPrefModal";
import ResumePrefModal from "./modals/ResumePrefModal";
import AvatarModal from "./modals/AvatarModal";
import ModalInfo from "../../shared/component/ModalComponent/ModalInfo";
import OtherFileModal from "./modals/OtherFileModal";
import useToast from "shared/hooks/useToast";
import { b64toBlob } from "./../../shared/utils/b64toBlob";
import formatNumber from "../../shared/utils/formatNumber";
import useTranslate from "shared/hooks/useTranslate";
import useFetch from "shared/hooks/useFetch";
import useUserBadge from "shared/hooks/useUserBadge";

// Type that we take from the URL
const DETAIL_TYPE = {
	EXPERIENCE: "pengalaman",
	EDUCATION: "pendidikan",
	SKILLS: "keahlian",
	LANGUAGES: "bahasa",
};

// Type to show/hide modal
const MODAL_TYPE = {
	AVATAR: "avatar",
	PERSONAL: "personal",
	EXPERIENCE: "experience",
	EDUCATION: "education",
	SKILLS: "skills",
	LANGUAGES: "languages",
	JOB_PREF: "job_pref",
	RESUME_PREF: "resume_pref",
	OTHER_RESUME: "other_resume",
	PORTFOLIO: "portfolio",
};

const PROFICIENCY = {
	beginner: Variable.BEGINNER,
	intermediate: Variable.INTERMEDIATE,
	advance: Variable.ADVANCED,
	fluent: Variable.FLUENT,
	expert: Variable.EXPERT,
	native: Variable.NATIVE,
};

const WORK_MODE = {
	remote: Variable.REMOTE_LABEL,
	hybrid: Variable.HYBRID_LABEL,
	on_site: Variable.ONSITE_LABEL,
};

const VISIBILITY = {
	public: Variable.PUBLIC,
	private: Variable.PRIVATE,
};

const renderLocation = (address, city, postalCode, country) => {
	let arr = [address, city, postalCode, country]?.filter((i) => i);
	return listToStr(arr);
};

const timeDuration = (start, end, ENV_NAME = "bhs") => {
	const diff = moment(start ?? undefined)?.diff(moment(end));
	const yearDiff = moment.duration(diff)?.years();
	const monthDiff = moment.duration(diff)?.months() % 12;
	if (yearDiff === 0) {
		return `${monthDiff} ${
			monthDiff <= 1 ? Variable.MONTH[ENV_NAME] : Variable.MONTHS[ENV_NAME]
		}`;
	}
	return `${yearDiff} ${Variable.YEAR[ENV_NAME]} ${monthDiff} ${
		monthDiff <= 1 ? Variable.MONTH[ENV_NAME] : Variable.MONTHS[ENV_NAME]
	}`;
};

function PrevArrow(props) {
	const { onClick } = props;
	return (
		<button className={`nav-button prev ${onClick ? "" : "d-none"}`} onClick={onClick}>
			<CaretDown width="2rem" height="2rem" strokeWidth="3" stroke="#193560" />
		</button>
	);
}

function NextArrow(props) {
	const { onClick } = props;
	return (
		<button className={`nav-button next ${onClick ? "" : "d-none"}`} onClick={onClick}>
			<CaretDown width="2rem" height="2rem" strokeWidth="3" stroke="#193560" />
		</button>
	);
}

const Truncation = ({ children, ENV_NAME = "bhs", ...props }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<Truncate
			lines={showMore ? -1 : 2}
			trimWhitespace
			ellipsis={
				<>
					...
					<span
						className="cursorPointer ms-xxs text-label-blue font-weight-semibold"
						onClick={() => setShowMore(true)}
						style={{ color: "#3E6DBC" }}
					>
						{Variable.READ_MORE[ENV_NAME]}
					</span>
				</>
			}
			{...props}
		>
			{children}
		</Truncate>
	);
};

const ProfileComponent = ({ ENV_NAME, ...props }) => {
	const t = useTranslate();

	const [isLoading, setIsLoading] = useState(false);
	const [profile, setProfile] = useState(false);
	const [company, setCompany] = useState(null);
	const [showDelete, setShowDelete] = useState({
		show: false,
		type: null,
		data: null,
	});
	const [showSuccess, setShowSuccess] = useState(false);
	const [detailType, setDetailType] = useState(null);
	const [showModal, setShowModal] = useState({});
	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const [fileModal, setFileModal] = useState({
		show: false,
		type: null,
		key: null,
	});
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState({
		show: false,
		type: null,
		id: null,
	});
	const [skillAdded, setSkillAdded] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const location = useLocation();
	const [isUploading, setIsUploading] = useState(false);
	const toast = useToast();

	const getSlideCount = () =>
		window.screen.width < 960 ? (window.screen.width < 450 ? 1 : 2) : 4;

	useEffect(() => {
		if (!location?.pathname?.includes(RoutePath.PROFILE)) return;
		if (location?.pathname?.includes("/detail")) {
			let type = location?.pathname?.split("/detail/")?.[1] ?? null;
			if (Object.values(DETAIL_TYPE)?.includes(type)) setDetailType(type);
			else goToDetail();
		} else setDetailType(null);

		getProfileData();
		getCompanyData();
	}, [location, props?.userDetails?.id]); // eslint-disable-line

	const getCompanyData = async () => {
		const res = await makeRequest(generateRequestOptions("userBadge"));

		if (res.code === 200) setCompany(res.data);
		else toast.error(res.message);
	};

	const getProfileData = async () => {
		const { id: userId } = props?.userDetails;
		if (!userId) return;

		try {
			setIsLoading(true);
			let res = await makeRequest({
				...generateRequestOptions("getProfile", {
					urlParams: userId,
				}),
			});
			if (res?.code === 200) {
				setProfile(res?.data);
			} else {
				toast.error(res?.message || Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME]);
				props.history.push(RoutePath.ROOT);
			}
		} catch (error) {
			console.error(error?.message);
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} finally {
			setIsLoading(false);
		}
	};

	const editProfileData = async (body) => {
		let res = await makeRequest({
			...generateRequestOptions("editUserProfile"),
			body,
		});

		if (res.code === 200) {
			showModalHelper(MODAL_TYPE.PERSONAL, false);
			showModalHelper(MODAL_TYPE.AVATAR, false);
			toast.success(t("UPDATE_PERSONAL_INFO_MSG"));
			getProfileData();
		} else toast.error(res.message);
	};

	const addData = (endpoint, message) => async (body) => {
		const formData = new FormData();

		for (const item in body) {
			if (item === "education_document_proof")
				body[item].forEach((doc) => formData.append("education_document_proof", doc));
			else if (item === "experience_document_proof")
				body[item].forEach((doc) =>
					formData.append("experience_document_proof", doc[item]),
				);
			else if (item === "skill_document_proof")
				body[item].forEach((doc) => formData.append("skill_document_proof", doc));
			else if (item === "language_document_proof")
				body[item].forEach((doc) => formData.append("language_document_proof", doc));
			else formData.append(item, body[item]);
		}
		setShowLoader(true);
		let res = await makeRequest({
			...generateRequestOptions(endpoint),
			body: formData,
		});

		if (res.code === 200) {
			getProfileData();
			setShowModal({});
			toast.success(message.replace("{{method}}", t("ADDED_MSG")));
			if (showModal[MODAL_TYPE.SKILLS]) setSkillAdded(true);
		} else toast.error(res.message);
		setShowLoader(false);
	};

	const editData =
		(endpoint, message) =>
		async (body, id, files = []) => {
			let res = await makeRequest({
				...generateRequestOptions(endpoint, {
					urlParams: id,
				}),
				body,
			});

			if (res?.code === 200) {
				// Here, if we are uploading files while editing education, experience, skill and language
				// we need to call the create api separately for each file
				if (files.length) {
					setShowLoader(true);
					const uploadPromises = files.map((file) => {
						return uploadDocs(
							endpoint.includes("Education")
								? "addEducationDocument"
								: endpoint.includes("Experience")
								? "addExperienceDocument"
								: endpoint.includes("Skill")
								? "addSkillDocument"
								: endpoint.includes("Language")
								? "addLanguageDocument"
								: "",
							id,
							file,
						);
					});

					await Promise.all(uploadPromises);
					setShowLoader(false);
				}

				await getProfileData();
				await setShowModal({});
				toast.success(message.replace("{{method}}", t("UPDATED_MSG")));
			} else toast.error(res?.message);
		};

	const uploadDocs = async (endpoint, id, file) => {
		const formData = new FormData();
		if (endpoint.includes("Education")) {
			formData.append("education_document_proof", file);
			formData.append("education", id);
		} else if (endpoint.includes("Experience")) {
			formData.append("experience_document_proof", file);
			formData.append("experience", id);
		} else if (endpoint.includes("Skill")) {
			formData.append("skill_document_proof", file);
			formData.append("skill", id);
		} else if (endpoint.includes("Language")) {
			formData.append("language_document_proof", file);
			formData.append("language", id);
		}

		const res = await makeRequest({
			...generateRequestOptions(endpoint),
			body: formData,
		});

		if (res.code !== 200) toast.error(res.message);
	};

	const deleteStuff = async () => {
		let res = await makeRequest({
			...generateRequestOptions(
				showDelete.type === "experience"
					? "deleteExperience"
					: showDelete.type === "skills"
					? "deleteSkill"
					: showDelete.type === "languages"
					? "deleteLanguage"
					: showDelete.type === "experience"
					? "deleteExperience"
					: showDelete.type === "education"
					? "deleteEducation"
					: "",
				{
					urlParams: showDelete?.data?.id,
				},
			),
		});

		if (res.code === 200) {
			getProfileData();
			setShowDelete({ show: false, type: null, data: null });
		} else toast.error(res.message);
	};

	// Here, we are handling the uploading of portfolio and other resume.
	// This can be expanded to handle other file uploads.
	// Don't forget to update the endpoint.
	const uploadFile = async (body, title) => {
		if (!fileModal?.type) return;
		setIsUploading(true);
		let res = await makeRequest({
			...generateRequestOptions(
				title === MODAL_TYPE.OTHER_RESUME
					? "updateResume"
					: profile?.portfolio?.length
					? "updatePortfolio"
					: "addPortfolio",
				{
					...(title === MODAL_TYPE.PORTFOLIO && profile?.portfolio?.length
						? { urlParams: profile?.portfolio[0]?.id }
						: {}),
				},
			),
			body,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		if (res.code === 200) {
			title === MODAL_TYPE.OTHER_RESUME
				? toast.success(t("OTHER_FILES_MSG").replace("{{method}}", t("UPDATED_MSG")))
				: profile?.portfolio?.length
				? toast.success(t("NEW_PORTFOLIO_MSG").replace("{{method}}", t("UPDATED_MSG")))
				: toast.success(t("NEW_PORTFOLIO_MSG").replace("{{method}}", t("ADDED_MSG")));
			showFileModal(false, null);
			getProfileData();
		}
		setIsUploading(false);
	};

	const handleDeleteResume = async () => {
		const res = await makeRequest(generateRequestOptions("deleteResume"));

		if (res.code === 200) getProfileData();
		else toast.error(res.message);
	};

	const handleDeletePortfolio = async () => {
		const portfolioId = profile.portfolio[0]?.id;

		const res = await makeRequest({
			...generateRequestOptions("deletePortfolio", {
				urlParams: portfolioId,
			}),
		});

		if (res.code === 200) getProfileData();
		else toast.error(res.message);
	};

	const goToDetail = (type) => {
		if (type) return props.history.push(RoutePath.PROFILE + "/detail/" + type);
		return props.history.push(RoutePath.PROFILE);
	};

	const renderAddButton = (label, onClick) => (
		<Button
			size="lg"
			type="textLink"
			className="w-100"
			btnClassName="w-100"
			onClick={onClick}
			title={
				<div className="flex-all-center gap-xxs">
					<PlusIcon width="1.8rem" height="1.8rem" stroke="#193560" strokeWidth="3" />
					{label}
				</div>
			}
		/>
	);

	const getModalDeleteLabel = (type) => {
		switch (type) {
			case MODAL_TYPE.SKILLS:
				return Variable.DELETE_SKILL[ENV_NAME];
			case MODAL_TYPE.LANGUAGES:
				return Variable.DELETE_LANGUAGE[ENV_NAME];
			case MODAL_TYPE.EXPERIENCE:
				return Variable.DELETE_EXPERIENCE[ENV_NAME];
			case MODAL_TYPE.EDUCATION:
				return Variable.DELETE_EDUCATION[ENV_NAME];
			case MODAL_TYPE.OTHER_RESUME:
			case MODAL_TYPE.PORTFOLIO:
				return Variable.DELETING_FILE[ENV_NAME];
			default:
				return " ";
		}
	};

	const langProficiencyLabelHelper = (str = "") => {
		if (ENV_NAME === "bhs") {
			return str?.toLowerCase();
		}
		return str;
	};

	const showModalHelper = (type, show, data = undefined) =>
		setShowModal((p) => ({ ...p, [type]: { show, data } }));

	const showFileModal = (show, type, key = null) => setFileModal((p) => ({ show, type, key }));

	const handleFileExport = async () => {
		const res = await makeRequest(
			generateRequestOptions("exportResume", {
				queryParams: {
					candiate_id: profile.personal_info.id,
					response_type: "json/base64",
				},
			}),
		);

		try {
			downloadjs(b64toBlob(res.data.file_data), res.data.file_name);
			// toast.success(Variable.EXPORT_SUCCESS[ENV_NAME]);
		} catch (err) {
			toast.error(err.message);
		}
	};

	const handlePreview = async () => {
		const res = await makeRequest(
			generateRequestOptions("exportResume", {
				queryParams: {
					candiate_id: profile.personal_info.id,
					response_type: "file/stream",
				},
			}),
		);

		try {
			const fileStreamData = res;
			const mimeType = "application/pdf";
			const blob = new Blob([fileStreamData], { type: mimeType });
			const pdfBlobURL = URL.createObjectURL(blob);
			window.open(pdfBlobURL);
		} catch (err) {
			toast.error(err.message);
		}
	};

	const orderNewestToOldest = (arr, key, method = "asc") => {
		return orderBy(arr, [key], [method]);
	};

	return (
		<div className="profile-comp">
			{isLoading ? (
				<LoadingComponent />
			) : (
				<div className="container d-flex flex-column gap-sm">
					{/* MAIN PROFILE CONTAINER (NAME, AVATAR, RESUME DOWNLOAD) */}
					<ProfileFrameComponent show={detailType === null}>
						<div className="d-flex gap-std align-items-center">
							<div className="pc-pic-cont">
								<Image
									className="pc-pic"
									src={profile?.personal_info?.profile_picture || DefaultProfPic}
									onClick={() =>
										showModalHelper(MODAL_TYPE.AVATAR, true, profile?.profile)
									}
								/>
							</div>
							<div className="flex-grow-1 d-flex flex-column gap-xxs">
								<h3 className="mb-0 display-xs font-weight-semibold">
									{`${profile?.personal_info?.first_name || ""} ${
										profile?.personal_info?.last_name || ""
									}`}
								</h3>
								<div className="text-md pc-username">{`@${profile?.personal_info?.username}`}</div>
								{company?.company_name && (
									<div className="pc-curr-occupation">
										<div>{company?.company_name}</div>
										<div>|</div>
										<div>{company?.position}</div>
									</div>
								)}
							</div>
							<div className="d-flex gap-xs">
								<button className="btn pfc-btn" onClick={handlePreview}>
									<PreviewResumeIcon
										stroke="#667085"
										width="2.4rem"
										height="2.4rem"
									/>
								</button>
								<button className="btn pfc-btn" onClick={handleFileExport}>
									<DownloadIcon stroke="#667085" width="2.4rem" height="2.4rem" />
								</button>
							</div>
						</div>
					</ProfileFrameComponent>

					{/* PERSONAL INFORMATION (EMAIL, PHONE, GENDER, ETC) */}
					<ProfileFrameComponent
						show={detailType === null}
						title={Variable.PERSONAL_INFORMATION[ENV_NAME]}
						onEdit={() => showModalHelper(MODAL_TYPE.PERSONAL, true)}
					>
						<div className="pc-grids">
							<ProfileItemComponent
								icon={<MailIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />}
								label={Variable.MAIL_ADDRESS[ENV_NAME]}
								value={profile?.personal_info?.email}
							/>
							<ProfileItemComponent
								icon={<PhoneIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />}
								label={Variable.MOBILE_PHONE_NUMBER[ENV_NAME]}
								value={
									listToStr(
										[
											profile?.personal_info?.mobile_phone_number || "",
											profile?.personal_info?.mobile_phone_number_2 || "",
										]?.filter((i) => i),
									) || "-"
								}
							/>
							<ProfileItemComponent
								icon={<FlagIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />}
								label={Variable.NATIONALITY[ENV_NAME]}
								value={
									profile?.personal_info?.nationality
										? regionNames.of(profile?.personal_info?.nationality)
										: "-"
								}
							/>
							<ProfileItemComponent
								icon={
									<BirthCakeIcon
										stroke="#3E6DBC"
										width="2.4rem"
										height="2.4rem"
									/>
								}
								label={Variable.BIRTH[ENV_NAME]}
								value={
									profile?.personal_info?.date_of_birth
										? moment(profile?.personal_info?.date_of_birth)?.format(
												"LL",
										  )
										: "-"
								}
							/>
							<ProfileItemComponent
								icon={
									profile?.personal_info?.gender === "male" ? (
										<MaleIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />
									) : profile?.personal_info?.gender === "female" ? (
										<FemaleIcon
											stroke="#3E6DBC"
											width="2.4rem"
											height="2.4rem"
										/>
									) : (
										<MaleIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />
									)
								}
								label={Variable.GENDER[ENV_NAME]}
								value={
									(profile?.personal_info?.gender === "male"
										? Variable.MALE[ENV_NAME]
										: profile?.personal_info?.gender === "female" &&
										  Variable.FEMALE[ENV_NAME]) || "-"
								}
							/>
						</div>
						<div className="divider" />
						<ProfileItemComponent
							icon={<PinIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />}
							label={Variable.LOCATION[ENV_NAME]}
							value={
								renderLocation(
									profile?.personal_info?.street_address,
									profile?.personal_info?.city,
									profile?.personal_info?.postal_code,
									regionNames.of(profile?.personal_info?.country || "ID"),
								) || "-"
							}
						/>
					</ProfileFrameComponent>

					{/* EXPERIENCE (PAST JOBS) */}
					<ProfileFrameComponent
						show={detailType === null || detailType === DETAIL_TYPE.EXPERIENCE}
						title={Variable.EXPERIENCE[ENV_NAME]}
						onEdit={
							detailType === DETAIL_TYPE.EXPERIENCE
								? undefined
								: () => goToDetail(DETAIL_TYPE.EXPERIENCE)
						}
						onAdd={() => showModalHelper(MODAL_TYPE.EXPERIENCE, true)}
						onBack={detailType === DETAIL_TYPE.EXPERIENCE ? () => goToDetail() : null}
					>
						{profile?.experience_history?.length > 0
							? (detailType === DETAIL_TYPE.EXPERIENCE
									? orderNewestToOldest(
											profile?.experience_history,
											"started_at",
											"desc",
									  )?.slice(0, 5) || []
									: orderNewestToOldest(
											profile?.experience_history,
											"started_at",
											"desc",
									  )?.slice(0, 5)
							  )?.map((i, idx) => (
									<React.Fragment key={"experience" + i?.id}>
										<ExpItemComponent
											icon={
												<JobBuildingOutlineIcon
													width="2.4rem"
													height="2.4rem"
													stroke="#3E6DBC"
													className="pi-icon"
												/>
											}
											title={i?.company_name || ""}
											otherInfos={
												<>
													<div className="flex-wrap d-flex text-label-black">
														<div className="text-sm">
															{i?.job_title || ""}
														</div>
														<div className="text-sm mx-xxs">•</div>
														<div className="text-sm">
															{`${moment(i?.started_at)?.format(
																"MMM YYYY",
															)} - ${
																i?.is_currently_working
																	? Variable.PRESENT[ENV_NAME]
																	: moment(i?.ended_at)?.format(
																			"MMM YYYY",
																	  )
															}`}
														</div>
														<div className="text-sm mx-xxs">•</div>
														<div className="text-sm mx-xxs">
															{timeDuration(
																i?.is_currently_working
																	? undefined
																	: i?.ended_at,
																i?.started_at,
																ENV_NAME,
															)}
														</div>
													</div>
													<div className="text-sm text-label-gray-400">
														{renderLocation(
															i?.street_address,
															i?.city,
															null,
															regionNames.of(i?.country || ""),
														)}
													</div>
													{i?.salary && (
														<div className="text-sm text-label-gray-400">
															{`${
																Variable.MONTHLY_SALARY[ENV_NAME]
															}: ${getCurrencySymbol(
																i?.currency || "IDR",
																getLocale(ENV_NAME),
															)} ${formatNumber(
																i?.salary,
																i?.currency,
															)}`}
														</div>
													)}
												</>
											}
											onEdit={
												detailType === DETAIL_TYPE.EXPERIENCE
													? () =>
															showModalHelper(
																MODAL_TYPE.EXPERIENCE,
																true,
																i,
															)
													: undefined
											}
											onDelete={
												detailType === DETAIL_TYPE.EXPERIENCE
													? () =>
															setShowDelete({
																show: true,
																type: MODAL_TYPE.EXPERIENCE,
																data: i,
															})
													: undefined
											}
										>
											<div className="text-sm truncation-text-sm">
												{detailType === DETAIL_TYPE.EXPERIENCE ? (
													i?.description
												) : (
													<Truncation ENV_NAME={ENV_NAME}>
														{i?.description || ""}
													</Truncation>
												)}
											</div>
											{i?.experience_document_proof?.length > 0 ? (
												<div className="mt-std carousel-cont">
													<Slider
														dots={false}
														infinite={false}
														speed={250}
														slidesToShow={getSlideCount()}
														slidesToScroll={1}
														prevArrow={<PrevArrow />}
														nextArrow={<NextArrow />}
														className="pc-carousel"
													>
														{(i?.experience_document_proof || [])?.map(
															(j, idx) => (
																<div
																	className="carousel-card"
																	key={
																		"files" + i?.id + "-" + idx
																	}
																>
																	<Anchor
																		href={
																			j?.experience_document_proof
																		}
																		className="text-decoration-none"
																		target="_blank"
																	>
																		<div className="pc-carousel-card">
																			<Image
																				src={getIconByName(
																					j?.file_name,
																				)}
																			/>
																			<div className="pcc-file-name">
																				{j?.file_name || ""}
																			</div>
																		</div>
																	</Anchor>
																</div>
															),
														)}
													</Slider>
												</div>
											) : (
												<></>
											)}
										</ExpItemComponent>
										{idx < profile?.experience_history?.length - 1 && (
											<div className="divider" />
										)}
									</React.Fragment>
							  ))
							: renderAddButton(Variable.ADD_EXPERIENCE[ENV_NAME], () =>
									showModalHelper(MODAL_TYPE.EXPERIENCE, true),
							  )}

						{profile?.experience_history?.length > 5 &&
							detailType !== DETAIL_TYPE.EXPERIENCE && (
								<Button
									size="lg"
									type="textLink"
									className="w-100"
									btnClassName="w-100"
									onClick={() => goToDetail(DETAIL_TYPE.EXPERIENCE)}
									title={
										<div className="flex-all-center gap-xxs">
											{Variable.SHOW_ALL_EXPERIENCES[ENV_NAME]}
											<ArrowLeftIcon
												width="1.8rem"
												height="1.8rem"
												stroke="#193560"
												strokeWidth="3"
												style={{ transform: "rotate(180deg)" }}
											/>
										</div>
									}
								/>
							)}
					</ProfileFrameComponent>

					{/* EDUCATION */}
					<ProfileFrameComponent
						show={detailType === null || detailType === DETAIL_TYPE.EDUCATION}
						title={Variable.EDUCATION[ENV_NAME]}
						onEdit={
							detailType === DETAIL_TYPE.EDUCATION
								? undefined
								: () => goToDetail(DETAIL_TYPE.EDUCATION)
						}
						onAdd={() => showModalHelper(MODAL_TYPE.EDUCATION, true)}
						onBack={detailType === DETAIL_TYPE.EDUCATION ? () => goToDetail() : null}
					>
						{profile?.education_history?.length > 0
							? (detailType === DETAIL_TYPE.EDUCATION
									? orderNewestToOldest(
											profile?.education_history,
											"started_at",
											"desc",
									  ) || []
									: orderNewestToOldest(
											profile?.education_history,
											"started_at",
											"desc",
									  )?.slice(0, 5)
							  )?.map((i, idx) => (
									<React.Fragment key={"education" + i?.id}>
										<ExpItemComponent
											icon={
												<GradCapIconOutline
													width="2.4rem"
													height="2.4rem"
													stroke="#3E6DBC"
													className="pi-icon"
												/>
											}
											title={i?.institution_name || ""}
											otherInfos={
												<>
													<div className="text-sm text-label-gray-400">
														{renderLocation(
															i?.address,
															i?.city,
															null,
															regionNames.of(i?.country || ""),
														)}
													</div>
													<div className="otherInfo">
														{i?.institution_level && (
															<p>{i?.institution_level}</p>
														)}
														{i?.major && <p>{i?.major}</p>}
														{i?.field_of_study && (
															<p>{i?.field_of_study}</p>
														)}
													</div>
												</>
											}
											onEdit={
												detailType === DETAIL_TYPE.EDUCATION
													? () =>
															showModalHelper(
																MODAL_TYPE.EDUCATION,
																true,
																i,
															)
													: undefined
											}
											onDelete={
												detailType === DETAIL_TYPE.EDUCATION
													? () =>
															setShowDelete({
																show: true,
																type: MODAL_TYPE.EDUCATION,
																data: i,
															})
													: undefined
											}
										>
											<div className="text-sm text-label-gray-400">
												{`${moment(i?.started_at)?.format("MMM YYYY")} - ${
													i?.is_still_enrolled
														? Variable.PRESENT[ENV_NAME]
														: moment(i?.graduated_at)?.format(
																"MMM YYYY",
														  )
												}`}
											</div>
											{!["SD", "SMP", "SMA/SMU/SMK/STM"].includes(
												i?.institution_level,
											) &&
												i?.gpa !== "0.00" && (
													<div className="text-sm text-label-gray-400">
														{`${Variable.GRADE[ENV_NAME]}: ${
															i?.gpa || "-"
														}`}
													</div>
												)}

											{i?.education_document_proof?.length > 0 ? (
												<div className="mt-std carousel-cont">
													<Slider
														dots={false}
														infinite={false}
														speed={250}
														slidesToShow={getSlideCount()}
														slidesToScroll={1}
														prevArrow={<PrevArrow />}
														nextArrow={<NextArrow />}
														className="pc-carousel"
													>
														{(i?.education_document_proof || [])?.map(
															(j, idx) => (
																<div
																	className="carousel-card"
																	key={
																		"files-education" +
																		i?.id +
																		"-" +
																		idx
																	}
																>
																	<Anchor
																		href={
																			j?.education_document_proof
																		}
																		className="text-decoration-none"
																		target="_blank"
																	>
																		<div className="pc-carousel-card">
																			<Image
																				src={getIconByName(
																					j?.education_document_proof,
																				)}
																			/>
																			<div className="pcc-file-name">
																				{j?.file_name || ""}
																			</div>
																		</div>
																	</Anchor>
																</div>
															),
														)}
													</Slider>
												</div>
											) : (
												<></>
											)}
										</ExpItemComponent>
										{idx < profile?.education_history?.length - 1 && (
											<div className="divider" />
										)}
									</React.Fragment>
							  ))
							: renderAddButton(Variable.ADD_EDUCATION[ENV_NAME], () =>
									showModalHelper(MODAL_TYPE.EDUCATION, true),
							  )}

						{profile?.education_history?.length > 5 &&
							detailType !== DETAIL_TYPE.EDUCATION && (
								<Button
									size="lg"
									type="textLink"
									className="w-100"
									btnClassName="w-100"
									onClick={() => goToDetail(DETAIL_TYPE.EDUCATION)}
									title={
										<div className="flex-all-center gap-xxs">
											{Variable.SHOW_ALL_EDUCATIONS[ENV_NAME]}
											<ArrowLeftIcon
												width="1.8rem"
												height="1.8rem"
												stroke="#193560"
												strokeWidth="3"
												style={{ transform: "rotate(180deg)" }}
											/>
										</div>
									}
								/>
							)}
					</ProfileFrameComponent>

					{/* SKILLS */}
					<ProfileFrameComponent
						show={detailType === null || detailType === DETAIL_TYPE.SKILLS}
						title={Variable.SKILLS[ENV_NAME]}
						onEdit={
							detailType === DETAIL_TYPE.SKILLS
								? undefined
								: () => goToDetail(DETAIL_TYPE.SKILLS)
						}
						onAdd={() => showModalHelper(MODAL_TYPE.SKILLS, true)}
						onBack={detailType === DETAIL_TYPE.SKILLS ? () => goToDetail() : null}
					>
						{profile?.skill?.length > 0
							? (detailType === DETAIL_TYPE.SKILLS
									? profile?.skill || []
									: (profile?.skill || [])?.slice(0, 5)
							  )?.map((i, idx) => (
									<React.Fragment key={"skills-" + i?.id}>
										<ExpItemComponent
											title={i?.name}
											otherInfos={
												<>
													<div className="flex-wrap d-flex text-label-gray-400">
														<div className="text-sm">
															{langProficiencyLabelHelper(
																PROFICIENCY[i?.level]?.[ENV_NAME],
															)}
														</div>
													</div>
												</>
											}
											onEdit={
												detailType === DETAIL_TYPE.SKILLS
													? () =>
															showModalHelper(
																MODAL_TYPE.SKILLS,
																true,
																i,
															)
													: undefined
											}
											onDelete={
												detailType === DETAIL_TYPE.SKILLS
													? () =>
															setShowDelete({
																show: true,
																type: MODAL_TYPE.SKILLS,
																data: i,
															})
													: undefined
											}
										>
											{i?.skill_document_proof?.length > 0 ? (
												<div className="mt-std carousel-cont">
													<Slider
														dots={false}
														infinite={false}
														speed={250}
														slidesToShow={getSlideCount()}
														slidesToScroll={1}
														prevArrow={<PrevArrow />}
														nextArrow={<NextArrow />}
														className="pc-carousel"
													>
														{(i?.skill_document_proof || [])?.map(
															(j, idx) => (
																<div
																	className="carousel-card"
																	key={
																		"files-languages-" +
																		i?.id +
																		"-" +
																		idx
																	}
																>
																	<Anchor
																		href={
																			j?.skill_document_proof
																		}
																		className="text-decoration-none"
																		target="_blank"
																	>
																		<div className="pc-carousel-card">
																			<Image
																				src={getIconByName(
																					j?.file_name,
																				)}
																			/>
																			<div className="pcc-file-name">
																				{j?.file_name || ""}
																			</div>
																		</div>
																	</Anchor>
																</div>
															),
														)}
													</Slider>
												</div>
											) : undefined}
										</ExpItemComponent>
										{idx < profile?.skill?.length - 1 && (
											<div className="divider" />
										)}
									</React.Fragment>
							  ))
							: renderAddButton(Variable.ADD_SKILLS[ENV_NAME], () =>
									showModalHelper(MODAL_TYPE.SKILLS, true),
							  )}
						{profile?.skill?.length > 5 && detailType !== DETAIL_TYPE.SKILLS && (
							<Button
								size="lg"
								type="textLink"
								className="w-100"
								btnClassName="w-100"
								onClick={() => goToDetail(DETAIL_TYPE.SKILLS)}
								title={
									<div className="flex-all-center gap-xxs">
										{Variable.SHOW_ALL_SKILLS[ENV_NAME]}
										<ArrowLeftIcon
											width="1.8rem"
											height="1.8rem"
											stroke="#193560"
											strokeWidth="3"
											style={{ transform: "rotate(180deg)" }}
										/>
									</div>
								}
							/>
						)}
					</ProfileFrameComponent>

					{/* LANGUAGE PROFICIENCY */}
					<ProfileFrameComponent
						show={detailType === null || detailType === DETAIL_TYPE.LANGUAGES}
						title={Variable.LANGUAGES[ENV_NAME]}
						onEdit={
							detailType === DETAIL_TYPE.LANGUAGES
								? undefined
								: () => goToDetail(DETAIL_TYPE.LANGUAGES)
						}
						onAdd={() => showModalHelper(MODAL_TYPE.LANGUAGES, true)}
						onBack={detailType === DETAIL_TYPE.LANGUAGES ? () => goToDetail() : null}
					>
						{profile?.language?.length > 0
							? (detailType === DETAIL_TYPE.LANGUAGES
									? profile?.language || []
									: (profile?.language || [])?.slice(0, 5)
							  )?.map((i, idx) => (
									<React.Fragment key={"languages-" + i?.id}>
										<ExpItemComponent
											title={`${i?.name}${
												i?.is_primary
													? ` (${Variable.PRIMARY[ENV_NAME]})`
													: ""
											}`}
											otherInfos={
												<>
													<div className="flex-wrap d-flex text-label-gray-400">
														<div className="text-sm">
															{formatString(
																Variable.SPOKEN_PROFICIENCY_REPL[
																	ENV_NAME
																],
																langProficiencyLabelHelper(
																	PROFICIENCY[
																		i?.spoken_proficiency
																	]?.[ENV_NAME],
																),
															)}
														</div>
														<div className="text-sm mx-xxs">|</div>
														<div className="text-sm">
															{formatString(
																Variable.WRITTEN_PROFICIENCY_REPL[
																	ENV_NAME
																],
																langProficiencyLabelHelper(
																	PROFICIENCY[
																		i?.written_proficiency
																	]?.[ENV_NAME],
																),
															)}
														</div>
													</div>
													{i?.language_proficiency_name && (
														<div className="text-sm mt-std">
															{`${Variable.CERTIFICATION[ENV_NAME]}: `}
															<strong>
																{i?.language_proficiency_name ||
																	"-"}
															</strong>
														</div>
													)}
													{i?.language_proficiency_score && (
														<div className="text-sm">
															{`${Variable.CERTIFICATION_SCORE[ENV_NAME]}: `}
															<strong>
																{i?.language_proficiency_score ||
																	"-"}
															</strong>
														</div>
													)}
												</>
											}
											onEdit={
												detailType === DETAIL_TYPE.LANGUAGES
													? () =>
															showModalHelper(
																MODAL_TYPE.LANGUAGES,
																true,
																i,
															)
													: undefined
											}
											onDelete={
												detailType === DETAIL_TYPE.LANGUAGES
													? () =>
															setShowDelete({
																show: true,
																type: MODAL_TYPE.LANGUAGES,
																data: i,
															})
													: undefined
											}
										>
											{i?.language_document_proof?.length > 0 ? (
												<div className="mt-std carousel-cont">
													<Slider
														dots={false}
														infinite={false}
														speed={250}
														slidesToShow={getSlideCount()}
														slidesToScroll={1}
														prevArrow={<PrevArrow />}
														nextArrow={<NextArrow />}
														className="pc-carousel"
													>
														{(i?.language_document_proof || [])?.map(
															(j, idx) => (
																<div
																	className="carousel-card"
																	key={
																		"files-languages-" +
																		i?.id +
																		"-" +
																		idx
																	}
																>
																	<Anchor
																		href={
																			j?.language_document_proof
																		}
																		className="text-decoration-none"
																		target="_blank"
																	>
																		<div className="pc-carousel-card">
																			<Image
																				src={getIconByName(
																					j?.file_name,
																				)}
																			/>
																			<div className="pcc-file-name">
																				{j?.file_name || ""}
																			</div>
																		</div>
																	</Anchor>
																</div>
															),
														)}
													</Slider>
												</div>
											) : undefined}
										</ExpItemComponent>
										{idx < profile?.language?.length - 1 && (
											<div className="divider" />
										)}
									</React.Fragment>
							  ))
							: renderAddButton(Variable.ADD_LANGUAGES[ENV_NAME], () =>
									showModalHelper(MODAL_TYPE.LANGUAGES, true),
							  )}
						{profile?.language?.length > 5 && detailType !== DETAIL_TYPE.LANGUAGES && (
							<Button
								size="lg"
								type="textLink"
								className="w-100"
								btnClassName="w-100"
								onClick={() => goToDetail(DETAIL_TYPE.LANGUAGES)}
								title={
									<div className="flex-all-center gap-xxs">
										{Variable.SHOW_ALL_LANGUAGES[ENV_NAME]}
										<ArrowLeftIcon
											width="1.8rem"
											height="1.8rem"
											stroke="#193560"
											strokeWidth="3"
											style={{ transform: "rotate(180deg)" }}
										/>
									</div>
								}
							/>
						)}
					</ProfileFrameComponent>

					{/* JOB PREFERENCES (PREFERED SALARY, ETC) */}
					<ProfileFrameComponent
						show={detailType === null}
						title={Variable.JOB_PREFERENCES[ENV_NAME]}
						onEdit={() =>
							showModalHelper(MODAL_TYPE.JOB_PREF, true, profile?.job_preference)
						}
					>
						<div className="d-flex flex-column gap-pc">
							{profile?.job_preference?.expected_salary && (
								<ProfileItemComponent
									icon={
										<MoneyOutlineIcon
											stroke="#3E6DBC"
											width="2.4rem"
											height="2.4rem"
										/>
									}
									label={Variable.SALARY_EXPECTATION[ENV_NAME]}
									value={`${
										Variable.MONTHLY_SALARY[ENV_NAME]
									}: ${getCurrencySymbol(
										profile?.job_preference?.currency || "IDR",
										getLocale(ENV_NAME),
									)} ${formatNumber(
										profile?.job_preference?.expected_salary,
										profile?.job_preference?.currency,
									)}`}
								/>
							)}
							{profile?.job_preference?.preferred_work_model && (
								<ProfileItemComponent
									icon={
										<BuildingOutlineIcon
											stroke="#3E6DBC"
											width="2.4rem"
											height="2.4rem"
										/>
									}
									label={Variable.WORK_MODE_LABEL[ENV_NAME]}
									value={
										WORK_MODE[profile?.job_preference?.preferred_work_model]?.[
											ENV_NAME
										]
									}
								/>
							)}
							{profile?.job_preference?.preferred_work_location && (
								<ProfileItemComponent
									icon={
										<PinIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />
									}
									label={`${Variable.PREFERED_WORK_LOCATION[ENV_NAME]} 1`}
									value={profile?.job_preference?.preferred_work_location}
								/>
							)}
							{profile?.job_preference?.preferred_work_location_2 && (
								<ProfileItemComponent
									icon={
										<PinIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />
									}
									label={`${Variable.PREFERED_WORK_LOCATION[ENV_NAME]} 2`}
									value={profile?.job_preference?.preferred_work_location_2}
								/>
							)}
							{profile?.job_preference?.preferred_work_location_3 && (
								<ProfileItemComponent
									icon={
										<PinIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />
									}
									label={`${Variable.PREFERED_WORK_LOCATION[ENV_NAME]} 3`}
									value={profile?.job_preference?.preferred_work_location_3}
								/>
							)}
							{!profile?.job_preference?.expected_salary &&
								!profile?.job_preference?.preferred_work_model &&
								!profile?.job_preference?.preferred_work_location &&
								!profile?.job_preference?.preferred_work_location_2 &&
								!profile?.job_preference?.preferred_work_location_3 &&
								renderAddButton(Variable.ADD_JOB_PREFERENCES[ENV_NAME], () =>
									showModalHelper(MODAL_TYPE.JOB_PREF, true),
								)}
						</div>
					</ProfileFrameComponent>

					{/* OTHER RESUME (The one uploaded by the user manually) */}
					<ProfileFrameComponent
						show={detailType === null}
						title={Variable.OTHER_RESUME[ENV_NAME]}
						dropdown={
							profile?.resume?.resume_file
								? [
										<Anchor
											className="text-decoration-none"
											href={profile?.resume?.resume_file}
											target="_blank"
											download
										>
											<div className="pc-dropdown-item">
												<DownloadIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#667085"
													strokeWidth="3"
												/>
												{Variable.DOWNLOAD[ENV_NAME]}
											</div>
										</Anchor>,
										<div
											className="pc-dropdown-item"
											onClick={() =>
												showFileModal(
													true,
													MODAL_TYPE.OTHER_RESUME,
													"resume_file",
												)
											}
										>
											<RepeatIcon
												width="1.6rem"
												height="1.6rem"
												stroke="#667085"
												strokeWidth="3"
											/>
											{Variable.CHANGE_FILE[ENV_NAME]}
										</div>,
										<div
											className="pc-dropdown-item"
											onClick={() =>
												setShowConfirmDeleteDialog({
													show: true,
													type: MODAL_TYPE.OTHER_RESUME,
													id: "",
												})
											}
										>
											<TrashIcon
												width="1.6rem"
												height="1.6rem"
												stroke="#667085"
												strokeWidth="3"
											/>
											{Variable.DELETE_FILE[ENV_NAME]}
										</div>,
								  ]
								: undefined
						}
					>
						{/* <input
							type="file"
							hidden
							id="other-resume-input"
							accept=".txt, .pdf, .doc, .docx, .rtf"
							onChange={(e) => uploadOtherResume(e.target.files[0])}
						/> */}
						{profile?.resume?.resume_file ? (
							<ExpItemComponent
								icon={<ClipIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />}
								title={profile?.resume?.file_name}
							/>
						) : (
							<div className="d-flex flex-column gap-pc">
								{renderAddButton(Variable.UPLOAD_OTHER_RESUME[ENV_NAME], () =>
									showFileModal(true, MODAL_TYPE.OTHER_RESUME, "resume_file"),
								)}
							</div>
						)}
					</ProfileFrameComponent>

					{/* PORTFOLIO */}
					<ProfileFrameComponent
						show={detailType === null}
						title={Variable.PORTFOLIO[ENV_NAME]}
						dropdown={
							profile?.portfolio?.length > 0
								? [
										<Anchor
											className="text-decoration-none"
											download
											href={
												profile?.portfolio?.[profile.portfolio.length - 1]
													?.portfolio_file
											}
											target="_blank"
										>
											<div className="pc-dropdown-item">
												<DownloadIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#667085"
													strokeWidth="3"
												/>
												{Variable.DOWNLOAD[ENV_NAME]}
											</div>
										</Anchor>,
										<div
											className="pc-dropdown-item"
											onClick={() =>
												showFileModal(
													true,
													MODAL_TYPE.PORTFOLIO,
													"portfolio_file",
												)
											}
										>
											<RepeatIcon
												width="1.6rem"
												height="1.6rem"
												stroke="#667085"
												strokeWidth="3"
											/>
											{Variable.CHANGE_FILE[ENV_NAME]}
										</div>,
										<div
											className="pc-dropdown-item"
											onClick={() =>
												setShowConfirmDeleteDialog({
													show: true,
													type: MODAL_TYPE.PORTFOLIO,
													id: profile.portfolio[0].id,
												})
											}
										>
											<TrashIcon
												width="1.6rem"
												height="1.6rem"
												stroke="#667085"
												strokeWidth="3"
											/>
											{Variable.DELETE_FILE[ENV_NAME]}
										</div>,
								  ]
								: undefined
						}
					>
						{/* <input
							type="file"
							hidden
							id="portfolio-input"
							accept=".txt, .pdf, .doc, .docx, .rtf"
							onChange={(e) => {
								uploadPortfolio(e.target.files[0]);
							}}
						/> */}
						{profile?.portfolio?.length > 0 ? (
							<>
								{profile?.portfolio.map((p) => (
									<ExpItemComponent
										key={p.id}
										icon={
											<ClipIcon
												stroke="#3E6DBC"
												width="2.4rem"
												height="2.4rem"
											/>
										}
										title={p?.file_name}
									/>
								))}
							</>
						) : (
							<div className="d-flex flex-column gap-pc">
								{renderAddButton(Variable.UPLOAD_PORTFOLIO[ENV_NAME], () =>
									showFileModal(true, MODAL_TYPE.PORTFOLIO, "portfolio_file"),
								)}
								<div className="text-sm flex-all-center flex-column gap-xxs text-label-gray">
									<div>{Variable.PORT_FILE_RESTRICTION_1[ENV_NAME]}</div>
									<div>{Variable.PORT_FILE_RESTRICTION_2[ENV_NAME]}</div>
								</div>
							</div>
						)}
					</ProfileFrameComponent>

					{/* RESUME PREFERENCES */}
					<ProfileFrameComponent
						show={detailType === null}
						title={Variable.RESUME_PREFERENCES[ENV_NAME]}
						onEdit={() =>
							showModalHelper(MODAL_TYPE.RESUME_PREF, true, profile?.resume)
						}
					>
						<ExpItemComponent
							icon={<FileIcon stroke="#3E6DBC" width="2.4rem" height="2.4rem" />}
							title={Variable.MY_RESUME[ENV_NAME]}
							otherInfos={
								<>
									<div className="text-sm">
										{profile?.resume?.ready_to_work
											? Variable.READY_TO_WORK[ENV_NAME]
											: Variable.NOT_READY_TO_WORK[ENV_NAME]}
									</div>
									<div className="text-sm text-label-gray-400">
										{`${Variable.LAST_UPDATED[ENV_NAME]}: ${moment(
											profile?.resume?.last_updated_resume,
										)?.format("LL")}`}
									</div>
									<div className="text-sm d-flex align-items-center text-label-gray-400 gap-sm">
										<OpenEyeIcon
											stroke="#667085"
											width="1.6rem"
											height="1.6rem"
										/>
										{VISIBILITY[profile?.resume?.visibility]?.[ENV_NAME] || ""}
									</div>
								</>
							}
						/>
					</ProfileFrameComponent>
				</div>
			)}

			{/* Here lies modals */}
			<PersonalInfoModal
				show={showModal[MODAL_TYPE.PERSONAL]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.PERSONAL, false)}
				data={profile?.personal_info}
				onSave={editProfileData}
			/>

			<ExperienceModal
				show={showModal[MODAL_TYPE.EXPERIENCE]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.EXPERIENCE, false)}
				experienceId={showModal[MODAL_TYPE.EXPERIENCE]?.data?.id}
				showLoader={showLoader}
				onSave={addData("addExperience", t("NEW_EXPERIENCE_MSG"))}
				onEdit={editData("editExperience", t("NEW_EXPERIENCE_MSG"))}
			/>

			<EducationModal
				show={showModal[MODAL_TYPE.EDUCATION]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.EDUCATION, false)}
				educationId={showModal[MODAL_TYPE.EDUCATION]?.data?.id}
				showLoader={showLoader}
				onSave={addData("addEducation", t("NEW_EDUCATION_MSG"))}
				onEdit={editData("editEducation", t("NEW_EDUCATION_MSG"))}
			/>

			<SkillModal
				show={showModal[MODAL_TYPE.SKILLS]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.SKILLS, false)}
				skillId={showModal[MODAL_TYPE.SKILLS]?.data?.id}
				skillAdded={skillAdded}
				showLoader={showLoader}
				onSave={addData("addSkill", t("NEW_SKILL_MSG"))}
				onEdit={editData("editSkill", t("NEW_SKILL_MSG"))}
			/>

			<LanguageModal
				show={showModal[MODAL_TYPE.LANGUAGES]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.LANGUAGES, false)}
				languageId={showModal[MODAL_TYPE.LANGUAGES]?.data?.id}
				showLoader={showLoader}
				onSave={addData("addLanguage", t("NEW_LANG_MSG"))}
				onEdit={editData("editLanguage", t("NEW_LANG_MSG"))}
			/>

			<JobPrefModal
				show={showModal[MODAL_TYPE.JOB_PREF]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.JOB_PREF, false)}
				data={showModal[MODAL_TYPE.JOB_PREF]?.data}
				jobPrefId={profile?.job_preference?.id}
				onSave={addData("addJobPreference", t("NEW_PREFERENCE_JOB_MSG"))}
				onEdit={editData("editJobPreference", t("NEW_PREFERENCE_JOB_MSG"))}
			/>

			<ResumePrefModal
				show={showModal[MODAL_TYPE.RESUME_PREF]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.RESUME_PREF, false)}
				data={showModal[MODAL_TYPE.RESUME_PREF]?.data}
				onSave={addData("updateResumePreferences", t("UPDATE_RESUME_PREF_MSG"))}
			/>

			<AvatarModal
				show={showModal[MODAL_TYPE.AVATAR]?.show}
				onHide={() => showModalHelper(MODAL_TYPE.AVATAR, false)}
				data={profile?.personal_info}
				onSave={editProfileData}
			/>

			<OtherFileModal
				show={fileModal?.show}
				title={fileModal?.type}
				keyName={fileModal?.key}
				onHide={() => showFileModal(false, null)}
				onSave={uploadFile}
				isLoading={isUploading}
			/>

			<ModalInfo
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
				isShow={showDelete?.show}
				isHide={() => setShowDelete({ show: false, type: null, data: null })}
				title={getModalDeleteLabel(showDelete?.type)}
				onConfirm={deleteStuff}
				type="delete"
			/>

			<ModalInfo
				customIcon={<Image src={DeleteWarnIcon} className="modal-image-icon" />}
				isShow={showConfirmDeleteDialog?.show}
				isHide={() => setShowConfirmDeleteDialog({ show: false, type: null, data: null })}
				title={getModalDeleteLabel(showConfirmDeleteDialog?.type)}
				subtitle={`${Variable.DELETE_CONFIRM_DESC[ENV_NAME]} ${titleCaseEachWord(
					showConfirmDeleteDialog?.type,
				)}?`}
				onConfirm={() => {
					switch (showConfirmDeleteDialog.type) {
						case MODAL_TYPE.OTHER_RESUME:
							handleDeleteResume();
							break;
						case MODAL_TYPE.PORTFOLIO:
							handleDeletePortfolio();
							break;
						default:
							return;
					}
					setShowConfirmDeleteDialog({ show: false, type: null, data: null });
				}}
				cancelLabel={Variable.DONT_DELETE[ENV_NAME]}
			/>

			{/* Add another skill confirm modal */}
			<ModalInfo
				isShow={skillAdded}
				isHide={() => {
					setSkillAdded(false);
				}}
				onConfirm={() => {
					setSkillAdded(false);
					showModalHelper(MODAL_TYPE.SKILLS, true);
				}}
				title={Variable.SKILL_ADDED[ENV_NAME]}
				subtitle={Variable.YOU_CAN_ADD_OTHER_SKILLS[ENV_NAME]}
				confirmLabel={Variable.ADD_SKILLS[ENV_NAME]}
				cancelLabel={Variable.NO_THANKS[ENV_NAME]}
				confirmButtonType="primary"
				className="confirmSkill"
			/>

			<ModalInfo isShow={showSuccess} isHide={() => setShowSuccess(false)} type="error" />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
		userDetails: state.auth.userDetails,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ProfileComponent));
