import { useSelector } from "react-redux";
import Button from "../../shared/component/ButtonComponent/Button";
import { EDIT_LABEL } from "../../shared/utils/variables";

const ReviewSection = ({ title, onEdit, children }) => {
	const { ENV_NAME } = useSelector((state) => ({
		ENV_NAME: state.auth.selectedEnvironment,
	}));

	return (
		<section>
			<div className="section-header">
				<h4>{title}</h4>
				<Button
					type="textGray"
					title={EDIT_LABEL[ENV_NAME]}
					className="btn-edit"
					onClick={onEdit}
				></Button>
			</div>

			{children}
		</section>
	);
};

export default ReviewSection;
