import React from "react";
import { useHistory } from "react-router-dom/";

import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";
import Image from "../../shared/component/UI/Image";

import GreenCheck from "../../assets/svg/green-check.svg";
import Button from "../../shared/component/ButtonComponent/Button";

function ResetPasswordSuccess({ ENV_NAME }) {
	const history = useHistory();

	return (
		<div className="rpc-success">
			<Image src={GreenCheck} />

			<div className="rpc-success-desc">
				<h3>{Variable.PASSWORD_RESET[ENV_NAME]}</h3>
				<p>{Variable.PASSWORD_RESET_DESC[ENV_NAME]}</p>
			</div>
			<Button
				type="primary"
				title={Variable.CONTINUE[ENV_NAME]}
				size="sm"
				btnClassName="w-100 flex-grow-1"
				className="flex-grow-1 continue-btn"
				onClick={() => history.push(RoutePath.ROOT)}
			></Button>
		</div>
	);
}

export default ResetPasswordSuccess;
