export const formatUptoNDecimal = (number, scale = 2) => {
	return number?.toFixed(scale)?.replace(/[.,]00$/, "");
};

export const numberToRomanNumeral = (num: number) => {
	if (isNaN(num)) return NaN;
	let digits = String(+num).split(""),
		key = [
			"",
			"C",
			"CC",
			"CCC",
			"CD",
			"D",
			"DC",
			"DCC",
			"DCCC",
			"CM",
			"",
			"X",
			"XX",
			"XXX",
			"XL",
			"L",
			"LX",
			"LXX",
			"LXXX",
			"XC",
			"",
			"I",
			"II",
			"III",
			"IV",
			"V",
			"VI",
			"VII",
			"VIII",
			"IX",
		],
		roman = "",
		i = 3;

	while (i--) roman = (key[+(digits.pop() ?? "0") + i * 10] || "") + roman;

	return Array(+digits.join("") + 1).join("M") + roman;
};

export const zeroPad = (num: number, places = 2) => String(num).padStart(places, "0");

export const isValueAllowed = (value: string, type = "", customRegex: RegExp | null = null) => {
	switch (type) {
		case "numberWithDecimal":
			return /^(\d*(?:[.,]\d*)?|[.,]\d+)$/.test(value);
		case "numberWithoutDecimal":
			return /^(\d*)$/.test(value);
		case "phone":
			return /^(\+?\d*)$/.test(value);
		case "custom":
			return customRegex?.test(value);
		default:
			return false;
	}
};

// convert string number to currency format
export const convertToCurrency = (number: number) => {
	return String(number)
		?.replace(/\D/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * @deprecated
 * @desc Concat salary with 6 digit and append abbreviation for million, Not being used anymore
 * @param num
 * @param abbrv
 * @returns
 */
export const numberToMillion = (num: number, abbrv: string = "jt") => {
	const jt = 1_000_000;
	if (num < jt) return num;

	return `${String(num / jt).replace(".", ",")} ${abbrv}`;
};
