import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import { homeUrl } from "../../shared/utils/apiEndPoints";

import "./SignUpComponent.scss";
import logoBW from "../../assets/svg/homepage_bw.svg";
import arrowLeft from "../../assets/svg/arrow_left.svg";
import radioDefault from "../../assets/svg/radio_default.svg";
import radioActive from "../../assets/svg/radio_active.svg";
import radioFilled from "../../assets/svg/radio_filled.svg";
import radioSkip from "../../assets/svg/radio_skip.svg";
import Image from "../../shared/component/UI/Image";
import ProgressBarComponent from "../../shared/component/ProgressBarComponent/ProgressBarComponent";
import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";
import PersonalInformation from "./Children/PersonalInformation";
import Education from "./Children/Education";
import Experience from "./Children/Experience";
import Skills from "./Children/Skills";

const DetailsRegisterComponent = ({ ENV_NAME, ...props }) => {
  const MAX_STEP = 7;
  const [step, setStep] = useState(0);

  const LIST_STEP = [
    { stepValue: 1, name: Variable.ABOUT_ME_LABEL[ENV_NAME] },
    { stepValue: 2, name: Variable.EDUCATION[ENV_NAME] },
    { stepValue: 3, name: Variable.EXPERIENCE[ENV_NAME] },
    { stepValue: 4, name: Variable.SKILLS[ENV_NAME] },
    { stepValue: 5, name: Variable.LANGUAGE[ENV_NAME] },
    { stepValue: 6, name: Variable.PORTFOLIO_RESUME_LABEL[ENV_NAME] },
    { stepValue: 7, name: Variable.JOB_RESUME_LABEL[ENV_NAME] },
  ];

  const nextStep = () => {
    setStep((p) => Math.min(p + 1, MAX_STEP));
  };

  const goToHomeComponent = () => {
    props.history.push(RoutePath.ROOT);
  };

  return (
    <div className={`suc drComp`}>
      <div className="suc-main">
        <div className="suc-left container">
          <div className="suc-left-content">
            <Image
              src={logoBW}
              className="logoSUC cursorPointer"
              onClick={() => goToHomeComponent()}
            />
            <div className="suc-left-progress">
              {LIST_STEP?.map((ls, lsIdx) => (
                <div className="d-flex align-items-center" key={lsIdx}>
                  <Image
                    src={
                      LIST_STEP[0]?.stepValue === step
                        ? radioActive
                        : radioDefault
                    }
                  />
                  <label className="d-flex align-items-center m-0">
                    {ls?.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="suc-right container">
          <div>
            {step === 0 ? (
              <PersonalInformation step={step} move={nextStep} />
            ) : step === 1 ? (
              <Education step={step} move={nextStep} />
            ) : step === 2 ? (
              <Experience step={step} move={nextStep} />
            ) : step === 3 ? (
              <Skills step={step} move={nextStep} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {
    setLoginModalShow: (payload = true) => {
      if (payload) {
        dispatch({
          type: ActionTypes.OPEN_LOGIN_MODAL,
        });
      } else {
        dispatch({
          type: ActionTypes.CLOSE_LOGIN_MODAL,
        });
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(DetailsRegisterComponent));
