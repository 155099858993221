import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Select from "../../../shared/component/SelectComponent/Select";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import Card from "../../../shared/component/CardComponent/Card";
import moment from "moment";

const ResumePrefModal = ({ ENV_NAME, onHide, onSave, ...props }) => {
	const NOTICE_PERIOD = [
		{ id: "1_week", name: `1 ${Variable.WEEK[ENV_NAME]}` },
		{ id: "2_weeks", name: `2 ${Variable.WEEKS[ENV_NAME]}` },
		{ id: "3_weeks", name: `3 ${Variable.WEEKS[ENV_NAME]}` },
		{ id: "4_weeks", name: `4 ${Variable.WEEKS[ENV_NAME]}` },
		{ id: "1_month", name: `1 ${Variable.MONTH[ENV_NAME]}` },
		{ id: "2_months", name: `2 ${Variable.MONTHS[ENV_NAME]}` },
		{ id: "3_months", name: `3 ${Variable.MONTHS[ENV_NAME]}` },
		{ id: "4_months", name: `4 ${Variable.MONTHS[ENV_NAME]}` },
	];
	const [form, setForm] = useState({});
	const onChange = (e) => {
		setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
	};

	const validateAndSave = () => {
		let body = {};
		Object.keys(props.data).forEach((key) => {
			if (props.data[key] !== form[key]) body[key] = form[key];
		});
		onSave && onSave(body, form.id);
		onHide();
	};

	useEffect(() => {
		if (props.show && props.data) {
			setForm(props.data);
		} else {
			setForm({});
		}
	}, [props.show, props.data]); // eslint-disable-line

	return (
		<ModalForm
			title={Variable.RESUME_PREFERENCES[ENV_NAME]}
			footer={
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={onHide}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.SAVE[ENV_NAME]}
					/>
				</div>
			}
			{...props}
		>
			<div className="pc-form">
				<Toggle
					type="switch"
					leftText={Variable.READY_TO_WORK[ENV_NAME]}
					name="ready_to_work"
					checked={form?.ready_to_work}
					onChange={(e) =>
						setForm((p) => ({ ...p, ready_to_work: e.target.checked }))
					}
				/>
				{!form?.ready_to_work && (
					<Select
						label={Variable.NOTICE_PERIOD[ENV_NAME]}
						placeholder={Variable.NOTICE_PERIOD[ENV_NAME]}
						name="notice_period"
						value={form?.notice_period || ""}
						items={NOTICE_PERIOD}
						onChange={onChange}
						searchable={false}
						className="w-100"
						noDefault
					/>
				)}
				<div>
					<label className="as-label">
						{Variable.RESUME_VISIBILITY[ENV_NAME]}
					</label>
					<div className="d-flex flex-column gap-sm">
						<Card
							type="public"
							active={form?.visibility === "public"}
							onClick={() => setForm((p) => ({ ...p, visibility: "public" }))}
						/>

						<Card
							type="private"
							active={form?.visibility === "private"}
							onClick={() => setForm((p) => ({ ...p, visibility: "private" }))}
						/>
					</div>
				</div>
				{form?.last_updated_resume && (
					<div className="text-sm text-label-gray">
						{`${Variable.LAST_MODIFIED[ENV_NAME]}: ${moment(
							form?.last_updated_resume
						).format("LL")}`}
					</div>
				)}
			</div>
		</ModalForm>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapStateToDispatch
)(withRouter(ResumePrefModal));
