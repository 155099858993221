import { last } from "lodash";
import { useEffect, useRef, useState } from "react";

import Loader from "../../assets/svg/loader.svg";
import Image from "../../shared/component/UI/Image";
import uploadResume from "../../assets/svg/upload_resume.svg";
import Button from "../../shared/component/ButtonComponent/Button";

import useToast from "shared/hooks/useToast";
import useTranslate from "shared/hooks/useTranslate";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { getIconByName, isFileSizeLessOrEqual } from "../../shared/utils/file";

import "./ApplyJobModal.scss";

const ALLOWED_FILES = [
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"image/jpeg",
	"image/png",
];

function CoverLetter({ jobAppId, ...props }) {
	const t = useTranslate();
	const MAX_FILE_SIZE = 20000000; // 20MB
	const [form, setForm] = useState({
		file: "",
		file_name: "",
	});
	const [tempFileUrl, setTempFileUrl] = useState(null);
	const [isEdited, setIsEdited] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const uploadRef = useRef(null);
	const toast = useToast();

	useEffect(() => {
		if (jobAppId) retrieveFormData();
	}, [jobAppId]);

	useEffect(() => {
		if (!form?.file?.toString()?.startsWith("https")) {
			const fileUrl = form?.file ? URL.createObjectURL(form?.file) : undefined;
			setTempFileUrl(fileUrl);

			return () => {
				URL.revokeObjectURL(fileUrl);
			};
		}
	}, [form]);

	const retrieveFormData = async () => {
		const res = await makeRequest(
			generateRequestOptions("getCoverLetter", { urlParams: jobAppId }),
		);

		if (res.code === 200) {
			setForm({
				file: res.data?.cover_letter,
				file_name: last(res.data?.cover_letter?.split("/")),
			});
		} else toast.error(res.message);
	};

	const validateAndSave = async () => {
		// if the form is not edited just skip this step
		if (!isEdited) return props.onUpdateActiveForm(6);

		const formData = new FormData();
		formData.append("cover_letter", form.file);

		setIsUploading(true);
		setIsSubmitting(true);
		const res = await makeRequest({
			...generateRequestOptions("updateCoverLetter", { urlParams: jobAppId }),
			body: formData,
		});

		if (res.code === 200) {
			props.onUpdateActiveForm(6);
			resetState();
			setIsEdited(false);
		} else toast.error(res.message);
		setIsSubmitting(false);
		setIsUploading(false);
	};

	const onFileChange = async (e) => {
		const file = e.target.files[0];

		if (file) {
			if (!ALLOWED_FILES.includes(file?.type)) return toast.error(t("UNSUPPORTED_FILE"));

			const isValidFile = isFileSizeLessOrEqual(file, MAX_FILE_SIZE);
			if (!isValidFile) return toast.error(t("FILE_SIZE_ERROR"));

			const newForm = {
				file,
				file_name: file?.name,
			};
			setForm(newForm);
			setIsEdited(true);
		}
		uploadRef.current.value = null;
	};

	const resetState = () => setForm({ file: "", file_name: "" });

	return (
		<>
			<div className="contact-form resume">
				<div className="body">
					{form.file ? (
						<div className="file-card">
							<div className="file-name">
								<Image src={getIconByName(form?.file_name)} />
								<p>{form?.file_name}</p>
							</div>
							<div className="file-card-btns">
								<Button
									type="textGray"
									title={t("VIEW")}
									className="btn-view"
									onClick={() => window.open(tempFileUrl || form?.file, "_blank")}
								/>
								<div>|</div>

								<input
									id="upload-files"
									type="file"
									name="subjectIcon"
									className="inputfile d-none"
									onChange={onFileChange}
									accept={ALLOWED_FILES.join(",")}
									ref={uploadRef}
								/>
								<Button
									type="textGray"
									title={t("CHANGE")}
									className="btn-view"
									onClick={() => uploadRef?.current?.click()}
								/>
							</div>

							{/* uploading animation */}
							{isUploading && (
								<div className="loader-wrapper">
									<div className="loader-container">
										<Image src={Loader} alt="File uploading" />
									</div>
								</div>
							)}
						</div>
					) : (
						<>
							<input
								id="upload-files"
								type="file"
								name="subjectIcon"
								className="inputfile d-none"
								onChange={onFileChange}
								accept={ALLOWED_FILES.join(",")}
								ref={uploadRef}
							/>
							<Button
								leftIcon={uploadResume}
								title={t("UPLOAD_COVER_LETTER")}
								type="secondary"
								size="md"
								onClick={() => uploadRef?.current?.click()}
							/>
							<p className="mt-3">{t("COVER_LETTER_FORMAT")}</p>
						</>
					)}
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => props.onUpdateActiveForm(4)}
						title={t("BACK")}
						disabled={isSubmitting}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={t("NEXT")}
						disabled={isSubmitting}
					/>
				</div>
			</div>
		</>
	);
}

export default CoverLetter;
