import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useDropzone } from "react-dropzone";
import { FiCheckCircle } from "react-icons/fi";
import { isEqual, lowerCase } from "lodash";
import { Oval } from "react-loader-spinner";

import * as Variable from "../../../shared/utils/variables";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import Button from "../../../shared/component/ButtonComponent/Button";
import Image from "../../../shared/component/UI/Image";
import { titleCaseEachWord } from "../../../shared/utils/titleCaseEachWord";

import uploadImage from "../../../assets/svg/upload_image.svg";
import uploadImageSuccess from "../../../assets/svg/upload_image_success.svg";
import uploadImageFailed from "../../../assets/svg/upload_image_failed.svg";

import "../ProfileComponent.scss";
import { formatString } from "shared/utils/string";

const LoadingState = ({ ENV_NAME, fileName }) => {
	return (
		<div className="drop-area">
			<Oval
				height={118}
				width={118}
				color="#475D80"
				wrapperStyle={{
					margin: "2.4rem 0",
				}}
				wrapperClass=""
				visible={true}
				ariaLabel="oval-loading"
				secondaryColor="#EAECF0"
				strokeWidth={4}
				strokeWidthSecondary={4}
			/>
			<p className="drop-area__text font-weight-normal">
				{Variable.UPLOADING_FILE[ENV_NAME]} <strong>{fileName}</strong>
			</p>
		</div>
	);
};

const OtherFileModal = ({ ENV_NAME, title, keyName, onHide, onSave, isLoading, ...props }) => {
	const [templateFile, setTemplateFile] = useState([]);
	const [isRejected, setIsRejected] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: {
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
			"application/msword": [".doc"],
			"application/rtf": [".rtf"],
			"application/pdf": [".pdf"],
		},
		multiple: false,
		maxFiles: 1,
		maxSize: 20000000,
		onDrop: (acceptedFiles, fileRejections) => onFileReject(acceptedFiles, fileRejections),
	});

	useEffect(() => {
		if (acceptedFiles?.length > 0 && !isEqual(acceptedFiles, templateFile)) {
			setTemplateFile(acceptedFiles);
			setIsRejected(false);
			setShowErrorMessage(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles]);

	// Function to call if the file we selectede doesn't fulfill the criteria
	const onFileReject = (acceptedFiles, fileRejections) => {
		if (fileRejections.length > 0) {
			fileRejections?.[0]?.errors.forEach((i) => {
				if (i.code === "file-invalid-type" || i.code === "file-too-large") {
					setIsRejected(true);
					setShowErrorMessage(true);
				}
			});
			return;
		}
	};

	const resetState = () => {
		setTemplateFile([]);
		setIsRejected(false);
		setShowErrorMessage(false);
	};

	const validateAndSave = async (title) => {
		if (isRejected) return setShowErrorMessage(true);

		const formData = new FormData();
		formData.append(keyName, templateFile[0]);
		onSave && (await onSave(formData, title));
		!isRejected && resetState();
	};

	return (
		<ModalForm
			title={`${Variable.UPLOAD[ENV_NAME]} ${titleCaseEachWord(title)}`}
			footer={
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => {
							onHide();
							resetState();
						}}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => validateAndSave(title)}
						title={Variable.SAVE[ENV_NAME]}
						disabled={isLoading}
					/>
				</div>
			}
			{...props}
		>
			{isLoading ? (
				<div className="pc-form other-resume">
					<LoadingState ENV_NAME={ENV_NAME} fileName={templateFile[0]?.name} />
				</div>
			) : (
				<div className="pc-form other-resume">
					<div className="pc-form-head">
						<h4>{Variable.DOCUMENT[ENV_NAME]}</h4>
						<p>{Variable.OTHER_RESUME_DESC[ENV_NAME]}</p>
					</div>
					{templateFile.length === 0 && !isRejected ? (
						<div className="drop-area" {...getRootProps()}>
							<input {...getInputProps()} />
							<Image src={uploadImage} className="placeholder-image" />
							<p>
								{Variable.DRAG_FILE_LABEL[ENV_NAME]}{" "}
								<strong>{Variable.CHOOSE_FILE[ENV_NAME]}</strong>{" "}
							</p>
							<p>
								{formatString(
									Variable.FILE_FORMAT_SIZE_2[ENV_NAME],
									" DOC, DOCX, TXT, RTF",
									"PDF",
									20,
								)}
							</p>
						</div>
					) : (
						<div>
							<div
								className={`drop-area drop-area--${
									isRejected ? "failed" : "success"
								}`}
							>
								<Image
									src={isRejected ? uploadImageFailed : uploadImageSuccess}
									className="placeholder-image"
								/>
								{!isRejected && (
									<p className="file-name">
										{templateFile?.[0]?.name}
										<FiCheckCircle className="check-icon" />
									</p>
								)}
								<div className="replace-file" {...getRootProps()}>
									<input {...getInputProps()} />
									<label>Replace File</label>
								</div>
							</div>
							{showErrorMessage && (
								<p className="upload-failed-msg">
									{formatString(
										Variable.UPLOAD_FILE_FAILED_MSG[ENV_NAME],
										lowerCase(title.split("_").join(" ")),
									)}
								</p>
							)}
						</div>
					)}
				</div>
			)}
		</ModalForm>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(OtherFileModal));
