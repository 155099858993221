import { useEffect, useState } from "react";
import { IoBookmark } from "react-icons/io5";

import { JobAdList, SalaryTypeEnum } from "types/API/TypesSwagger";
import usePost from "shared/hooks/usePost";
import useFetch from "shared/hooks/useFetch";
import Image from "shared/component/UI/Image";
import * as Variables from "shared/utils/variables";
import { homeUrl } from "shared/utils/apiEndPoints";
import useTranslate from "shared/hooks/useTranslate";
import { classHelper } from "shared/utils/stringUtils";
import { useEnvironment } from "shared/hooks/useEnvironment";
import LoadingComponent from "shared/component/LoadingComponent/LoadingComponent";
import ModalConfirmation from "shared/component/ModalComponent/ModalConfirmation";
import Cash from "assets/svg/cash.svg";
import { ReactComponent as EmptyBookmarkIllu } from "assets/svg/empty_bookmark_illu.svg";

import { SalaryRangeFormatUtil } from "./SalaryRangeFormatUtil";
import { TabPropsTypes } from "./Types";
import PaginationWrapper from "./PaginationWrapper";
import EmptyTabState from "./EmptyTabState";

type BookmarkItemType = {
	loading?: boolean;
	ENV: string;
	onUnBookmark?: () => any;
};

const BookmarkItem = (props: JobAdList & BookmarkItemType) => (
	<div className={classHelper("job-div", props.loading && "loading")}>
		<LoadingComponent notFullScreen={true} />
		<div className="d-flex justify-content-between">
			<div className="d-flex align-items-start">
				<Image
					className=""
					src={props.company.company_logo}
					style={{ height: "5.4rem", width: "5.4rem", objectFit: "contain" }}
				/>
				<div className="role-details">
					<p
						className="role-name cursorPointer"
						onClick={() =>
							window.open(
								`https://jobmarket.${homeUrl}/info-kerja/${props.id}`,
								"_self",
							)
						}
					>
						{props.job_title}
					</p>
					<p className="text-lite">{props.company.name}</p>
					<p className="text-lite">
						{[props.posting_location_district, props.posting_location_city]
							.filter((v) => v)
							.join(", ")}
					</p>
					{props?.display_salary && (
						<div className="d-flex align-items-center">
							<Image className="" src={Cash} />
							<span className="text-medium">
								{SalaryRangeFormatUtil({
									currency: String(props.salary_currency),
									salaryStart: props.salary_starting_amount ?? 0,
									salaryEnd: props.salary_maximum_amount ?? 0,
									salaryRate: String(props.salary_rate),
									salaryType: props.salary_type as SalaryTypeEnum,
									salaryAmount: props.salary_amount ?? 0,
								})}
							</span>
						</div>
					)}
				</div>
			</div>
			<div className="d-flex align-items-start">
				{/* <div className="compare-btn">{t("ADD_TO_COMPARE")}</div> */}
				<IoBookmark
					className={"bookmark-logo"}
					size={20}
					color="#fc9607"
					onClick={props.onUnBookmark}
				/>
			</div>
		</div>
	</div>
);

const BookmarkTab = (props: TabPropsTypes) => {
	const ENV = useEnvironment();
	const t = useTranslate();

	const [showConfirmJobId, setShowConfirmJobId] = useState<undefined | number>();
	const [page, setPage] = useState<number>(1);

	const page_size = 20;
	const queryParams = {
		keyword: props.keyword,
		page,
		page_size,
		sort_and_order_by: props.sortOrderBy,
	};

	const { loading, getDatas, response } = useFetch<JobAdList[]>("getBookmarkedJobs", {
		queryParams,
	});
	const { postFunction: removeBookmark, loading: removeBookmarkLoading } =
		usePost<void>("removeBookmark");

	useEffect(() => {
		getDatas({ queryParams });
	}, [props.keyword, props.sortOrderBy]);

	const showConfirmUnbookmark = (jobId: number) => () => {
		setShowConfirmJobId(jobId);
	};

	const handleUnbookmark = async () => {
		if (showConfirmJobId === undefined) return;

		await removeBookmark({ urlParams: `${showConfirmJobId}` });
		setShowConfirmJobId(undefined);

		await getDatas({ queryParams });
		await props.refreshBadge?.();
	};

	const renderData =
		(response?.data?.length ?? 0) <= 0 ? (
			<EmptyTabState
				title={t("BOOKMARK_EMPTY_TITLE")}
				desc={t("BOOKMARK_EMPTY_DESC")}
				buttonBrowse={true}
				image={<EmptyBookmarkIllu />}
			/>
		) : (
			response?.data?.map((v, k) => (
				<BookmarkItem
					key={k}
					{...v}
					ENV={ENV}
					loading={removeBookmarkLoading}
					onUnBookmark={showConfirmUnbookmark(v.id)}
				/>
			))
		);

	return (
		<>
			<ModalConfirmation
				title={Variables.ARE_YOU_SURE[ENV]}
				ENV_NAME={ENV}
				description={Variables.UNBOOKMARKING_DESC[ENV]}
				okLabel={t("UNBOOKMARK_OK_LABEL")}
				onCancel={() => setShowConfirmJobId(undefined)}
				onOk={handleUnbookmark}
				show={showConfirmJobId !== undefined}
				type={"error"}
			/>
			{loading ? <LoadingComponent notFullScreen={true} /> : renderData}
			<PaginationWrapper
				page_size={page_size}
				total_items={response?.total_items ?? 1}
				ENV={ENV}
				onChangePage={(pageNumber) => {
					setPage(pageNumber);
					getDatas({
						queryParams: { ...queryParams, page: pageNumber },
					});
				}}
			/>
		</>
	);
};

export default BookmarkTab;
