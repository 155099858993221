/**
 * Takes a string and a separator as arguments. Returns a string with capitalized first character of each word as output.
 *
 * @param inputStr string
 * @param separator string
 * @returns string
 */

const titleCaseEachWord = (inputStr, separator = "_") => {
	const words = inputStr?.split(separator);
	const outputStr = words
		?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		?.join(" ");
	return outputStr;
};

export { titleCaseEachWord };
