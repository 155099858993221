import clsx from "clsx";

import "./InputComponent.scss";
import { ChangeEvent } from "react";
import { classHelper } from "shared/utils/stringUtils";

type PropTypes = {
	label: string;
	id: number | string;
	name: string;
	checked?: boolean;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	required?: boolean;
	isError?: boolean;
	disabled?: boolean;
	className?: React.HTMLAttributes<HTMLDivElement>["className"];
	inputClass?: string;
	labelClass?: string;
};

const RadioInput = ({
	label,
	id,
	name,
	checked,
	value,
	onChange,
	required,
	isError,
	disabled,
	...props
}: PropTypes) => {
	return (
		<div className={clsx("form-group J-radioInputComp", props.className)}>
			<input
				type="radio"
				className={classHelper("J-radioInputComp-input cursorPointer", props.inputClass)}
				id={`${id}`}
				name={name}
				checked={checked || false}
				onChange={onChange}
				disabled={disabled}
				required={required}
				value={id}
			/>
			<label
				className={clsx("J-radioInputComp-label cursorPointer", isError && "field-error")}
				htmlFor={`${id}`}
			>
				{label}
			</label>
		</div>
	);
};

export default RadioInput;
