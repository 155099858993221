import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as Variable from "../../../shared/utils/variables.js";
import * as ActionTypes from "../../../store/actions/actionTypes";
import { homeUrl } from "../../../shared/utils/apiEndPoints";

import "../SignUpComponent";
import arrowLeft from "../../../assets/svg/arrow_left.svg";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import Image from "../../../shared/component/UI/Image.js";
import ProgressBarComponent from "../../../shared/component/ProgressBarComponent/ProgressBarComponent.js";
import Toggle from "../../../shared/component/ToggleComponent/Toggle.js";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown.js";
import Select from "../../../shared/component/SelectComponent/Select.js";
import { PlusIcon } from "../../../shared/component/svg/Icons.js";

const Education = ({ move, ENV_NAME, ...props }) => {
	const EDUCATION_STEP = [
		{ id: 1, name: Variable.EDUCATION[ENV_NAME] },
		{ id: 2, name: Variable.EDUCATION[ENV_NAME] },
	];

	const [step, setStep] = useState(1);
	const [toNext, setToNext] = useState(EDUCATION_STEP[0]?.id);

	const nextStep = () => {
		setStep((p) => Math.min(p + 1, EDUCATION_STEP?.length));
	};

	const previousStep = () => {
		setStep((p) => Math.max(p - 1, 0));
	};

	const validateAndSave = () => {
		console.log("first");
	};

	return (
		<div className="multiComponent">
			<div>
				<Image
					src={arrowLeft}
					onClick={previousStep}
					className="mb-std cursorPointer"
				/>
				<ProgressBarComponent percent={(step / EDUCATION_STEP?.length) * 100} />
			</div>
			{toNext === EDUCATION_STEP[0]?.id ? (
				<div>
					<h1>{Variable.EDUCATION[ENV_NAME]}</h1>
					<div className="multiComponent-content">
						<Input
							label={Variable.SCHOOL_COLLEGE_NAME[ENV_NAME]}
							placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Universitas Indonesia`}
							name="institution_name"
							// value={form?.institution_name || ""}
							// onChange={onChange}
							inputType="label"
							className="w-100"
							required
						/>
						<Input
							label={Variable.COUNTRY[ENV_NAME]}
							placeholder={Variable.COUNTRY[ENV_NAME]}
							name="country"
							// value={form?.country || ""}
							// onChange={onChange}
							inputType="label"
							className="w-100"
							required
						/>
						<Toggle
							type="switch"
							leftText={Variable.CURRENTLY_ENROLLED[ENV_NAME]}
							name="is_still_enrolled"
							// checked={form?.is_still_enrolled}
							// onChange={(e) => setForm((p) => ({ ...p, is_still_enrolled: e.target.checked }))}
						/>
						<DatePickerDropdown
							className="pc-form-row"
							dayLabel={<>&nbsp;</>}
							monthLabel={Variable.EDUCATION_FROM[ENV_NAME]}
							yearLabel={<>&nbsp;</>}
							name="started_at"
							// value={form?.started_at}
							// onChange={onChange}
							hideDate
							required
						/>
						{/* {!form?.is_currently_working && (
						<DatePickerDropdown
							className="pc-form-row"
							dayLabel={<>&nbsp;</>}
							monthLabel={Variable.EDUCATION_TO[ENV_NAME]}
							yearLabel={<>&nbsp;</>}
							name="graduated_at"
							value={form?.ended_at}
							onChange={onChange}
							hideDate
						/>
					)} */}
						<Select
							label={Variable.FIELD_OF_STUDY[ENV_NAME]}
							placeholder={Variable.FIELD_OF_STUDY[ENV_NAME]}
							name="field_of_study"
							// value={form?.field_of_study}
							// items={FIELD_OF_STUDY?.map((i) => ({ id: i, name: i }))}
							// onChange={onChange}
							searchable={false}
							className="w-100"
							noDefault
						/>
						<Input
							label={Variable.MAJOR[ENV_NAME]}
							placeholder={Variable.MAJOR[ENV_NAME]}
							name="major"
							// value={form?.major || ""}
							// onChange={onChange}
							inputType="label"
							className="w-100"
						/>
						<div className="pc-form-row">
							<Select
								label={Variable.LEVEL_OF_EDUCATION[ENV_NAME]}
								placeholder={Variable.LEVEL_OF_EDUCATION[ENV_NAME]}
								name="level_of_education"
								// value={form?.level_of_education || ""}
								// items={LEVEL_OF_EDUCATION?.map((i) => ({ id: i, name: i }))}
								// onChange={onChange}
								searchable={false}
								className="w-100"
								noDefault
							/>
							<Input
								label={Variable.GRADE[ENV_NAME]}
								placeholder={Variable.GRADE[ENV_NAME]}
								name="gpa"
								// value={form?.gpa || ""}
								// onChange={onChange}
								inputType="label"
								className="w-100"
								required
							/>
						</div>
						<div>
							<h5 className="text-md font-weight-medium text-label-gray mb-sm">
								{Variable.DOCUMENT[ENV_NAME]}
							</h5>
							<span className="text-sm">
								{Variable.DOCUMENT_EDUCATION_SUBTITLE[ENV_NAME]}
							</span>
						</div>
						<div className="input-btn">
							<Button
								type="secondary"
								size="md"
								title={
									<div className="flex-all-center gap-xxs">
										<PlusIcon
											width="1.8rem"
											height="1.8rem"
											stroke="white"
											strokeWidth="3"
										/>
										{Variable.ADD_FILE[ENV_NAME]}
									</div>
								}
								// onClick={() => uploadRef?.current?.click()}
							/>
							<input
								id="upload-files"
								type="file"
								name="subjectIcon"
								className="inputfile d-none"
								accept="image/*"
								// onChange={onFileChange}
								// ref={uploadRef}
							/>
						</div>
					</div>
					<div className="multiComponent-btnContainer">
						<Button
							title={Variable.SKIP_LABEL[ENV_NAME]}
							btnClassName="px-xl"
							type="outline"
							size="sm"
							onClick={() => {
								nextStep();
								setToNext(EDUCATION_STEP[1]?.id);
							}}
						/>
						<Button
							title={Variable.SAVE[ENV_NAME]}
							className="marLeft-auto"
							btnClassName="px-xl"
							type="primary"
							size="sm"
							onClick={() => {
								validateAndSave();
							}}
						/>
					</div>
				</div>
			) : toNext === EDUCATION_STEP[1]?.id ? (
				<div>
					<h1>{Variable.EDUCATION[ENV_NAME]}</h1>
					<div className="multiComponent-content">
						<Input
							label={Variable.EMAIL[ENV_NAME]}
							placeholder={Variable.EMAIL_PLACEHOLDER[ENV_NAME]}
							// value={form?.first_name || ""}
							// onChange={onChange}
							name="email"
							inputType="label"
							className="w-100"
							required
						/>
						<div className="input-btn pt-xs pb-md">
							<Button
								type="secondary"
								size="md"
								title={
									<div className="flex-all-center gap-xxs">
										<PlusIcon
											width="1.8rem"
											height="1.8rem"
											stroke="white"
											strokeWidth="3"
										/>
										{Variable.ADD_EDUCATION[ENV_NAME]}
									</div>
								}
								// onClick={() => uploadRef?.current?.click()}
							/>
							<input
								id="upload-files"
								type="file"
								name="subjectIcon"
								className="inputfile d-none"
								accept="image/*"
								// onChange={onFileChange}
								// ref={uploadRef}
							/>
						</div>
						<Button
							title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
							btnClassName="px-lg"
							type="primary"
							size="sm"
							onClick={() => {
								move();
							}}
						/>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapStateToDispatch
)(withRouter(Education));
