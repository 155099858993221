/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import clsx from "clsx";

import Cash from "assets/svg/cash.svg";
import DateIcon from "assets/svg/date-icon.svg";
import Applicants from "assets/svg/applicant-icon.svg";
import { ReactComponent as EmptyAppliedIllu } from "assets/svg/empty_applied_illu.svg";
import useFetch from "shared/hooks/useFetch";
import Image from "shared/component/UI/Image";
import { useFlags } from "shared/hooks/useFlags";
import * as Variables from "shared/utils/variables";
import { homeUrl } from "shared/utils/apiEndPoints";
import useTranslate from "shared/hooks/useTranslate";
import { useEnvironment } from "shared/hooks/useEnvironment";
import ModalInfo from "shared/component/ModalComponent/ModalInfo2";
import LoadingComponent from "shared/component/LoadingComponent/LoadingComponent";
import ThreeDotsMenu, { PropTypes as ThreeDotsMenuTypes } from "shared/component/ThreeDotsMenu";
import {
	CandidateStatusEnumUpdated,
	IAppliedList,
	IAppliedListListResponse,
} from "types/API/JobAppAppliedListType";
import { CandidateHistoryStatus, SalaryTypeEnum } from "types/API/TypesSwagger";

import { TabPropsTypes } from "./Types";
import HistoryStatus from "./HistoryStatus";
import PaginationWrapper from "./PaginationWrapper";
import WithdrawnFormPopup from "./WithdrawnFormPopup";
import EmptyTabState from "./EmptyTabState";
import ReportJob from "./ReportJob";
import { SalaryRangeFormatUtil } from "./SalaryRangeFormatUtil";

const statusColor: Record<keyof CandidateStatusEnumUpdated | "Ongoing", string> = {
	ShowAllStatuses: "",
	Pending: "msg-neutral",
	Ongoing: "msg-neutral",
	Viewed: "msg-neutral",
	Prospect: "msg-blue",
	Shortlist: "msg-blue",
	Interview: "msg-blue",
	Offered: "msg-green",
	Hired: "msg-green",
	Rejected: "msg-red",
};

const statusLabelTranslate: Record<
	keyof CandidateStatusEnumUpdated | "Ongoing",
	keyof typeof Variables
> = {
	Pending: "PENDING_APPLIED",
	Ongoing: "PENDING_APPLIED",
	Viewed: "VIEWED",
	Prospect: "PROSPECT",
	Shortlist: "SHORTLIST",
	Interview: "INTERVIEW",
	Offered: "OFFERED",
	Hired: "HIRED",
	Rejected: "REJECTED",
	ShowAllStatuses: "PENDING_APPLIED",
};

const AppliedItem = (props: IAppliedList & { ENV: string; refetchDatas?: () => any }) => {
	const t = useTranslate();

	const [showHistory, setShowHistory] = useState(false);
	const [showWithdraw, setShowWithdraw] = useState(false);
	const [showReportJob, setShowReportJob] = useState(false);

	const candidateHistory = useFetch<CandidateHistoryStatus[]>(
		"candidateHistoryStatus",
		{ queryParams: { job_application: props.id } },
		{ lazy: true },
	);
	const { flag } = useFlags();

	const handleShowHistory = () => {
		setShowHistory(true);
		candidateHistory.getDatas();
	};

	const contextMenus: ThreeDotsMenuTypes["options"] = [
		{
			key: "Withdraw",
			label: t("APPLIED_CTX_WITHDRAW"),
			onClick: () => setShowWithdraw(true),
		},
		{
			key: "Check history status",
			label: t("APPLIED_CTX_CHECK_HISTORY"),
			onClick: handleShowHistory,
		},
	];

	if (flag("myJobs.appliedTab.repostJobAd")) {
		contextMenus.push({
			key: "Report this job ad",
			label: t("APPLIED_CTX_REPORT"),
			onClick: () => setShowReportJob(true),
		});
	}

	let statusLabel = t(statusLabelTranslate[capitalize(props.status)]);
	if (["Ongoing", "Pending"].indexOf(capitalize(props.status)) < 0) {
		statusLabel += t("STATUS_LABEL_ON_DATE", moment(props.status_datetime).format("D MMM Y"));
	}

	return (
		<div className="job-div">
			<div className="d-flex justify-content-between">
				<div className="d-flex align-items-start">
					<Image
						className=""
						src={props.job_ad.company.company_logo}
						style={{ height: "5.4rem", width: "5.4rem", objectFit: "contain" }}
					/>
					<div className="role-details">
						<p
							className="role-name cursorPointer"
							onClick={() =>
								window.open(
									`https://jobmarket.${homeUrl}/info-kerja/${props.job_ad.id}`,
									"_self",
								)
							}
						>
							{props.job_ad.job_title}
						</p>
						<p className="text-lite">{props.job_ad.company.name}</p>
						<p className="text-lite">
							{[
								props.job_ad.posting_location_district,
								props.job_ad.posting_location_city,
							]
								.filter((v) => v)
								.join(", ")}
						</p>
						{props.job_ad.display_salary && (
							<div className="d-flex align-items-center">
								<Image className="" src={Cash} />
								<span className="text-medium">
									{SalaryRangeFormatUtil({
										currency: String(props.job_ad.salary_currency),
										salaryStart: props.job_ad.salary_starting_amount ?? 0,
										salaryEnd: props.job_ad.salary_maximum_amount ?? 0,
										salaryRate: String(props.job_ad.salary_rate),
										salaryType: props.job_ad.salary_type as SalaryTypeEnum,
										salaryAmount: props.job_ad.salary_amount ?? 0,
									})}
								</span>
							</div>
						)}
						<div className="applied-details">
							<Image className="" src={DateIcon} />
							<span>
								{t(
									"INTERVIEW_DATE_APPLIED",
									moment(props.submitted_at).format("D MMM Y"),
								)}
							</span>
							<Image className={"mar-left"} src={Applicants} />
							<span>
								{t(
									"INTERVIEW_APPLICANTS_LABEL",
									`${props.job_ad.applicants}`,
									(props.job_ad.applicants ?? 0) > 1 ? "s" : "",
								)}
							</span>
						</div>
						{props.last_employer_active && (
							<p className="emp-update">
								{/* Employer actively processing applicants 12 day a go */}
								{props.last_employer_active}
							</p>
						)}
					</div>
				</div>
				<div className="d-flex flex-column justify-content-between align-items-end">
					<div className={clsx("msg-div", statusColor[capitalize(props.status)])}>
						{statusLabel}
					</div>
					<div>
						<ThreeDotsMenu options={contextMenus} />
					</div>
				</div>
			</div>
			<ModalInfo
				title={t("APPLIED_MODAL_HISTORY_STATUS_TITLE")}
				show={showHistory}
				onOk={() => setShowHistory(false)}
			>
				{candidateHistory.loading ? (
					<LoadingComponent notFullScreen />
				) : (
					<HistoryStatus
						jobTitle={props.job_ad.job_title}
						jobCompany={props.job_ad.company.name}
						statuses={candidateHistory.data ?? []}
					/>
				)}
			</ModalInfo>
			<ModalInfo
				title={t("APPLIED_MODAL_WITHDRAW_TITLE")}
				show={showWithdraw}
				dialogClassName="withdraw-popup-modal"
				footer={false}
				onOk={() => setShowWithdraw(false)}
			>
				<WithdrawnFormPopup
					id={props.id}
					jobTitle={props.job_ad.job_title}
					jobCompany={props.job_ad.company.name}
					onWithdraw={() => {
						setShowWithdraw(false);
						props.refetchDatas?.();
					}}
				/>
			</ModalInfo>
			<ModalInfo
				header={false}
				footer={false}
				show={showReportJob}
				dialogClassName="report-jobad-popup-modal"
				onOk={() => setShowReportJob(false)}
			>
				<ReportJob
					jobId={props.job_ad.id}
					onCancel={() => setShowReportJob(false)}
					onSuccessReport={() => {
						setShowReportJob(false);
						props.refetchDatas?.();
					}}
				/>
			</ModalInfo>
		</div>
	);
};

const AppliedTab = (props: TabPropsTypes) => {
	const t = useTranslate();

	const [page, setPage] = useState<number>(1);

	const page_size = 20;
	const queryParams = {
		keyword: props.keyword,
		page,
		page_size,
		sort_and_order_by: props.sortOrderBy,
		filter: props.jobStatus === "all" ? undefined : props.jobStatus,
		date_range_after: props.dateRangeStart,
		date_range_before: props.dateRangeEnd,
	};

	const ENV = useEnvironment();
	const { loading, getDatas, response } = useFetch<IAppliedListListResponse>("getAppliedJobs", {
		queryParams,
	});

	useEffect(() => {
		getDatas({ queryParams });
	}, [
		props.keyword,
		props.sortOrderBy,
		props.jobStatus,
		props.dateRangeStart,
		props.dateRangeEnd,
	]);

	const handleRefetchDatas = () => {
		getDatas();
		props.refreshBadge?.();
	};

	const renderData =
		(response?.data?.length ?? 0) <= 0 ? (
			<EmptyTabState
				title={t("APPLIED_EMPTY_TITLE")}
				desc={t("BOOKMARK_EMPTY_DESC")}
				buttonBrowse={true}
				image={<EmptyAppliedIllu />}
			/>
		) : (
			response?.data?.map((v, k) => (
				<AppliedItem key={k} {...v} ENV={ENV} refetchDatas={handleRefetchDatas} />
			))
		);

	return (
		<>
			{loading ? <LoadingComponent notFullScreen={true} /> : renderData}
			<PaginationWrapper
				page_size={page_size}
				total_items={response?.total_items ?? 1}
				ENV={ENV}
				onChangePage={(pageNumber) => {
					setPage(pageNumber);
					getDatas({
						queryParams: { ...queryParams, page: pageNumber },
					});
				}}
			/>
		</>
	);
};

export default AppliedTab;
