// Import from external link
import React from "react";
import { classHelper } from "../../../shared/utils/stringUtils";
import "../ProfileComponent.scss";

const ProfileItemComponent = (props) => {
	return (
		<div className={classHelper("profile-item-comp", props.className)}>
			{props.icon}
			{props.children ?? (
				<div className="d-flex flex-column gap-xxs flex-grow-1">
					<div className="text-label-gray text-sm">{props.label}</div>
					<div className="text-md font-weight-semibold">{props.value || ""}</div>
				</div>
			)}
		</div>
	);
};

export default ProfileItemComponent;
