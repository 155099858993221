import { useRef, useState } from "react";
import clsx from "clsx";

import "./TabNavigation.scss";

export type TabItem = {
	id: number;
	label: string;
	badge?: string | number;
};

export type TabNavigationType = {
	tabs: TabItem[];
	onTabClick: (id: TabItem["id"]) => any;
};

const TabNavigation = ({ tabs, onTabClick }: TabNavigationType) => {
	const tabNavigationRef = useRef<HTMLDivElement>(null);

	const [selectedTabId, setSelectedTabId] = useState(0);

	const getIndicatorWidth = (id: TabItem["id"]): number =>
		tabNavigationRef?.current?.querySelector?.(`#tab_id-${id}`)?.getBoundingClientRect?.()
			?.width || 0;

	const getIndicatorLeftPos = (id: TabItem["id"]): number =>
		tabNavigationRef?.current?.querySelector?.<HTMLElement>(`#tab_id-${id}`)?.offsetLeft || 0;

	return (
		<div className="job-ads-listing__header-div" ref={tabNavigationRef}>
			{tabs.map((item) => (
				<div
					key={item.id}
					id={`tab_id-${item.id}`}
					onClick={() => {
						if (item.id === selectedTabId) return;

						setSelectedTabId(item.id);
						onTabClick(item.id);
					}}
					className={clsx(
						`job-ads-listing__header-div__header-item tw capitalize`,
						selectedTabId === item.id && "selected",
					)}
				>
					{item.label}
					{item.badge !== undefined ? <span>{item.badge || 0}</span> : null}
				</div>
			))}
			<div
				className="job-ads-listing__header-div__border-bottom-blue"
				style={{
					width: getIndicatorWidth(selectedTabId),
					left: getIndicatorLeftPos(selectedTabId),
				}}
			/>
		</div>
	);
};

export default TabNavigation;
