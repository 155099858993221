export const LOGIN_SUCCEED_SAGA = "LOGIN_SUCCEED_SAGA";
export const LOGIN_SUCCEED_REDUX = "LOGIN_SUCCEED_REDUX";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCEED = "LOGOUT_SUCCEED";
export const SET_USER = "SET_USER";
export const UPDATE_LOGOUT = "UPDATE_LOGOUT";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_SELECTED_LANGUAGE = "UPDATE_SELECTED_LANGUAGE";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

// Tab Menu
export const ACTIVE_MENU_TAB = "ACTIVE_MENU_TAB";