/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptExactTime {
	interview: number;
	job_application: number;
	is_accepted?: boolean;
}

export interface AcceptExactTimeRequest {
	interview: number;
	job_application: number;
	is_accepted?: boolean;
}

export interface AddBusinessLicense {
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	business_or_registration_license?: string | null;
	id: number;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	verification_status: string;
}

export interface AddBusinessLicenseRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	business_or_registration_license?: File | null;
}

export interface AddCollaborators {
	collaborators?: (number | null)[];
}

export interface AddCompanyStaff {
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	user_type: UserTypeEnum;
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
	position: string;
	department: string;
	staff_user_permissions: StaffUserPermissionsEnum[];
}

export interface AddCompanyStaffRequest {
	/**
	 * @minLength 1
	 * @maxLength 300
	 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	user_type: UserTypeEnum;
	/**
	 * Email address
	 * @format email
	 * @minLength 1
	 * @maxLength 254
	 */
	email: string;
	/** @minLength 1 */
	position: string;
	/** @minLength 1 */
	department: string;
	staff_user_permissions: StaffUserPermissionsEnum[];
}

export interface AddTaxCard {
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	tax_card_image?: string | null;
	id: number;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	verification_status: string;
}

export interface AddTaxCardRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	tax_card_image?: File | null;
}

export interface AnswerPackage {
	id: number;
	/**
	 * @min 0
	 * @max 100
	 */
	test_score?: number | null;
	is_submitted?: boolean;
	/** @format date-time */
	submitted_at?: string | null;
	is_final_score?: boolean;
}

export interface AppliedJobs {
	id: number;
	job_ad: JobAdList;
	/** @format date-time */
	submitted_at?: string | null;
	is_viewed?: boolean;
	/** @format date-time */
	viewed_at?: string | null;
	status: string;
	last_employer_active: string;
	/** @format date-time */
	status_datetime: string;
}

export interface BasicUserProfile {
	id: number;
	/**
	 * Required. 250 characters or fewer. Letters, digits and @/./+/-/_ only.
	 * @maxLength 250
	 * @pattern ^[\w.@+-]+$
	 */
	username: string;
	user_type: UserTypeEnum;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	company?: number | null;
	staff_user_permissions?: StaffUserPermissionsEnum[] | null;
	is_deactivated?: boolean;
	profile_completion_points: number;
	user_personal_info_flag: any[];
	job_preference_id: number;
	portfolio_id: number;
	education_history_id: any[];
	experience_history_id: any[];
	language_id: any[];
	skill_id: any[];
	resume_id: number;
	is_initial_login?: boolean;
}

export enum BlankEnum {
	Value = "",
}

export interface CancelInterviewBulk {
	applicants: any[];
}

export interface CandidateApplicationHistory {
	job_ad: JobAdBasic;
	company: CompanyAddress;
	candidate_status: CandidateStatusOnJobApplicationRetrieve[];
	job_poster_feedback: string;
	/** @format date-time */
	submitted_at: string;
}

export interface CandidateHistoryStatus {
	id: number;
	candidate_status?: CandidateStatusEnum;
	job_application: number;
	is_active?: boolean;
	/** @format date-time */
	created_at: string;
}

export enum CandidateStatusEnum {
	Ongoing = "ongoing",
	Prospect = "prospect",
	Shortlist = "shortlist",
	Interview = "interview",
	Test = "test",
	Offered = "offered",
	Hired = "hired",
	Rejected = "rejected",
}

export interface CandidateStatusOnJobApplicationCreate {
	candidate_status?: CandidateStatusEnum;
	job_application: number;
	additional_info: string;
}

export interface CandidateStatusOnJobApplicationCreateRequest {
	candidate_status?: CandidateStatusEnum;
	job_application: number;
	interview_id?: number;
}

export interface CandidateStatusOnJobApplicationRetrieve {
	id: number;
	candidate_status?: CandidateStatusEnum;
	is_active?: boolean;
	/** @format date-time */
	created_at: string;
	created_by: UserSlim;
}

export interface CandidatesTest {
	id: number;
	test: number;
	job_application: number;
	/** @format date-time */
	created_at: string;
}

export interface CandidatesTestRequest {
	test: number;
	job_application: number;
}

export interface CarryOverJobAd {
	id: number;
	job_title: string;
	posting_location_country: string;
	posting_location_city: string;
	posting_location_district: string;
	posting_location_other_location: string;
	status: StatusEnum;
	package_type: string;
	expired_at: string;
	min_days_urgency: number;
	max_days_urgency: number;
	reactivate_counter: number;
	past_expired_timestamp: any[];
	past_published_timestamp: any[];
	/** @format date-time */
	employer_assist_on_at: string;
	/** @format date-time */
	employer_assist_off_at: string;
	employer_assist_last_updated_by: UserSlim;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/** @format date-time */
	published_at?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	hires?: number | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	/** @format date-time */
	last_active_employer?: string | null;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
}

export interface ClosedAccount {
	closed_account_answer?: string | null;
}

export interface Company {
	id: number;
	is_verified: boolean;
	/** @maxLength 400 */
	name: string;
	/** @maxLength 400 */
	industry: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	founded?: number | null;
	company_overview: string;
	company_address?: string | null;
	/** @maxLength 100 */
	company_contact_number?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	company_website?: string | null;
	number_of_employees?: NumberOfEmployeesEnum | BlankEnum | NullEnum | null;
	/** @format uri */
	company_logo?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	youtube_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	twitter_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	instagram_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	facebook_account?: string | null;
	/** @maxLength 200 */
	company_city?: string | null;
	/** @maxLength 200 */
	company_district?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	google_map_location_link?: string | null;
	is_active?: boolean;
}

export interface CompanyAccountActivationStatus {
	is_deactivated?: boolean;
}

export interface CompanyAddress {
	id: number;
	/** @maxLength 400 */
	name: string;
	company_address?: string | null;
	/** @maxLength 200 */
	company_city?: string | null;
	/** @format uri */
	company_logo?: string | null;
	/** @maxLength 200 */
	company_district?: string | null;
	created_by: UserSlim;
}

export interface CompanyDeactivation {
	is_active?: boolean;
}

export interface CompanyIndustry {
	/** @maxLength 400 */
	industry_en: string;
	/** @maxLength 400 */
	industry_id?: string | null;
}

export interface CompanyMedia {
	id: number;
	/** @format uri */
	company_image?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	company_video?: string | null;
	company: number;
}

export interface CompanyMediaRequest {
	/** @format binary */
	company_image?: File | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	company_video?: string | null;
	company: number;
}

export interface CompanyProfile {
	id: number;
	is_verified: boolean;
	/** @maxLength 400 */
	name: string;
	/** @maxLength 400 */
	industry: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	founded?: number | null;
	company_overview: string;
	company_address?: string | null;
	/** @maxLength 100 */
	company_contact_number?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	company_website?: string | null;
	number_of_employees?: NumberOfEmployeesEnum | BlankEnum | NullEnum | null;
	/** @format uri */
	company_logo?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	youtube_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	twitter_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	instagram_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	facebook_account?: string | null;
	/** @maxLength 200 */
	company_city?: string | null;
	/** @maxLength 200 */
	company_district?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	google_map_location_link?: string | null;
	is_active?: boolean;
}

export interface CompleteUserProfile {
	id: number;
	/** @format date-time */
	last_login?: string | null;
	/**
	 * Superuser status
	 * Designates that this user has all permissions without explicitly assigning them.
	 */
	is_superuser?: boolean;
	/**
	 * Required. 250 characters or fewer. Letters, digits and @/./+/-/_ only.
	 * @maxLength 250
	 * @pattern ^[\w.@+-]+$
	 */
	username: string;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	user_type: UserTypeEnum;
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
	/**
	 * Staff status
	 * Designates whether the user can log into this admin site.
	 */
	is_staff?: boolean;
	staff_user_permissions?: StaffUserPermissionsEnum[] | null;
	/**
	 * Active
	 * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
	 */
	is_active?: boolean;
	/** @format date-time */
	date_joined?: string;
	/** @maxLength 100 */
	mobile_phone_number?: string | null;
	/** @maxLength 100 */
	mobile_phone_number_2?: string | null;
	country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	nationality?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	city?: string | null;
	street_address?: string | null;
	/** @maxLength 400 */
	postal_code?: string | null;
	/** @maxLength 400 */
	preferred_work_location?: string | null;
	/** @format uri */
	profile_picture?: string | null;
	/** @format date */
	date_of_birth?: string | null;
	gender?: GenderEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	is_deactivated?: boolean;
	deactivate_answer?: DeactivateAnswerEnum | BlankEnum | NullEnum | null;
	deactivate_feedback?: string | null;
	is_closed?: boolean;
	closed_account_answer?: string | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	push_notifications_enabled?: boolean;
	recieve_job_alert_emails?: boolean;
	recieve_commercial_emails?: boolean;
	recieve_opportunity_knocks_emails?: boolean;
	recieve_profile_update_emails?: boolean;
	is_initial_login?: boolean;
	company?: number | null;
	/** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
	groups?: number[];
	/** Specific permissions for this user. */
	user_permissions?: number[];
}

export interface ConfirmResume {
	id: number;
	/** @format uri */
	resume_file?: string;
	stage: string;
}

export interface ContactDetail {
	id: number;
	stage: string;
}

export interface CopyPreloadedQuestionTest {
	job_ad?: number | null;
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	id: number;
	quiz_title: string;
	is_external_quiz: boolean;
	external_url: string;
	is_time_limited: boolean;
	time_limit: number;
	instructions: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
	is_shuffled: boolean;
	is_preload_test: boolean;
	topic_info: any[];
}

export interface CopyPreloadedQuestionTestRequest {
	test_id: number;
	job_ad?: number | null;
	job_applications?: any[];
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
}

export interface CoverLetter {
	id: number;
	stage: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	cover_letter?: string | null;
}

export interface CreateAnswerPackage {
	id: number;
	is_submitted: boolean;
	job_application: number;
	test: number;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	additional_upload_data?: string | null;
}

export interface CreateAnswerPackageRequest {
	job_application: number;
	test: number;
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	additional_upload_data?: File | null;
}

export interface CreateJobApplication {
	id: number;
	stage: string;
	job_ad: number;
}

export interface CreateJobApplicationRequest {
	job_ad: number;
}

export enum CurrencyEnum {
	Idr = "idr",
	Usd = "usd",
	Sgd = "sgd",
	Myr = "myr",
	Inr = "inr",
	Php = "php",
	Aud = "aud",
	Thb = "thb",
	Hkd = "hkd",
	Eur = "eur",
	Cny = "cny",
	Jpy = "jpy",
	Gbp = "gbp",
	Krw = "krw",
	Vnd = "vnd",
	Bdt = "bdt",
	Nzd = "nzd",
}

export interface CustomQuestionInput {
	id: number;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	question_number: number;
	question_text?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	points?: number | null;
	test_type: TestTypeEnum;
	question_type: QuestionType78BEnum;
	test: number[];
	boolean_answer_key?: boolean | null;
	fill_in_the_blank_answer_key?: (string | null)[];
	question_choices?: MCQChoices[];
	matching_questions_list: MatchingQuestionKey[];
	matching_answers_list: any[];
}

export interface CustomQuestionInputRequest {
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	question_number: number;
	question_text?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	points?: number | null;
	test_type: TestTypeEnum;
	question_type: QuestionType78BEnum;
	test: number[];
	boolean_answer_key?: boolean | null;
	fill_in_the_blank_answer_key?: (string | null)[];
	question_choices?: MCQChoicesRequest[];
	matching_question_input?: MatchingQuestionInputRequest[];
}

export interface DeactivateAccount {
	deactivate_answer?: DeactivateAnswerEnum | BlankEnum | NullEnum | null;
	deactivate_feedback?: string | null;
}

export enum DeactivateAnswerEnum {
	INeedABreak = "i_need_a_break",
	ImGettingTooManyEmails = "im_getting_too_many_emails",
	IHaveAPrivacyConcerns = "i_have_a_privacy_concerns",
	IHaveASafetyConcerns = "i_have_a_safety_concerns",
	Other = "other",
}

export interface DeactivateJobAd {
	id: number;
	status?: StatusEnum;
}

export interface DeclineInterviewJobSeeker {
	id: number;
}

export interface DetailQuestion {
	id: number;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	question_number: number;
	question_text?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	points?: number | null;
	question_type: QuestionType78BEnum;
	test: number[];
	question_choices: MCQChoicesSerializerWithoutKey[];
	matching_questions_list: MatchingQuestionKey[];
	matching_answers_list: any[];
}

export interface DetailTestAndQuestions {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	job_ad?: number | null;
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_external_quiz?: boolean;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	external_url?: string | null;
	is_time_limited?: boolean;
	/**
	 * in minutes
	 * @min -2147483648
	 * @max 2147483647
	 */
	time_limit?: number | null;
	instructions: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
	is_shuffled?: boolean;
	is_preload_test?: boolean;
	questions: DetailQuestion[];
	question_stats: string;
}

export enum DeviceTypeEnum {
	Ios = "ios",
	Android = "android",
	Web = "web",
}

export enum DistributionTimeEnum {
	ApplyProcess = "apply_process",
	HiringProcess = "hiring_process",
}

export interface DownloadOfferLetter {
	file_name: string;
	file_type: string;
	file_data: string;
	file_resource: string;
	polling_required: boolean;
	status: string;
}

export interface DownloadOfferLetterRequest {
	/** @minLength 1 */
	offer_letter_date: string;
	agreed_salary?: number;
	job_application: number;
	/** @minLength 1 */
	job_type?: string;
	/** @minLength 1 */
	position_title?: string;
	/** @minLength 1 */
	probation_period: string;
	/** @minLength 1 */
	join_date: string;
	/** @minLength 1 */
	special_condition?: string;
}

export interface Education {
	id: number;
	/** @maxLength 255 */
	institution_name: string;
	country: PostingLocationCountryEnum;
	institution_level?: InstitutionLevelEnum | BlankEnum | NullEnum | null;
	field_of_study?: FieldOfStudyEnum | BlankEnum | NullEnum | null;
	is_still_enrolled?: boolean;
	/** @format date */
	started_at: string;
	/** @format date */
	graduated_at?: string | null;
	/** @maxLength 255 */
	major?: string | null;
	/**
	 * @format decimal
	 * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
	 */
	gpa?: string;
}

export interface EducationDocument {
	id: number;
	file_name: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	education_document_proof: string;
	education: number;
}

export interface EducationDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	education_document_proof: File;
	education: number;
}

export interface EducationRequest {
	education_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	institution_name: string;
	country: PostingLocationCountryEnum;
	institution_level?: InstitutionLevelEnum | BlankEnum | NullEnum | null;
	field_of_study?: FieldOfStudyEnum | BlankEnum | NullEnum | null;
	is_still_enrolled?: boolean;
	/** @format date */
	started_at: string;
	/** @format date */
	graduated_at?: string | null;
	/** @maxLength 255 */
	major?: string | null;
	/**
	 * @format decimal
	 * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
	 */
	gpa?: string;
}

export interface Experience {
	id: number;
	working_interval: number;
	/** @maxLength 255 */
	job_title: string;
	/** @maxLength 255 */
	company_name: string;
	country: PostingLocationCountryEnum;
	/** @maxLength 255 */
	city: string;
	/** @format date */
	started_at: string;
	is_currently_working?: boolean;
	/** @format date */
	ended_at?: string | null;
	description?: string | null;
	/** @maxLength 100 */
	company_postcode?: string | null;
	currency?: CurrencyEnum | BlankEnum | NullEnum | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	salary?: number | null;
}

export interface ExperienceDocument {
	id: number;
	file_name: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	experience_document_proof: string;
	experience: number;
}

export interface ExperienceDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	experience_document_proof: File;
	experience: number;
}

export enum ExperienceLevelEnum {
	EntryLevel = "entry_level",
	Associate = "associate",
	Intermediate = "intermediate",
	Senior = "senior",
	Director = "director",
	Executive = "executive",
}

export interface ExperienceRequest {
	experience_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	job_title: string;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	company_name: string;
	country: PostingLocationCountryEnum;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	city: string;
	/** @format date */
	started_at: string;
	is_currently_working?: boolean;
	/** @format date */
	ended_at?: string | null;
	description?: string | null;
	/** @maxLength 100 */
	company_postcode?: string | null;
	currency?: CurrencyEnum | BlankEnum | NullEnum | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	salary?: number | null;
}

export interface ExposedKeyDetailQuestion {
	id: number;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	question_number: number;
	question_text?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	points?: number | null;
	question_type: QuestionType78BEnum;
	test: number[];
	boolean_answer_key?: boolean | null;
	fill_in_the_blank_answer_key?: (string | null)[];
	question_choices: MCQChoices[];
	matching_questions_list: MatchingQuestionKey[];
}

export interface ExposedKeyDetailTestAndQuestions {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	job_ad?: number | null;
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_external_quiz?: boolean;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	external_url?: string | null;
	is_time_limited?: boolean;
	/**
	 * in minutes
	 * @min -2147483648
	 * @max 2147483647
	 */
	time_limit?: number | null;
	instructions: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
	is_shuffled?: boolean;
	is_preload_test?: boolean;
	questions: ExposedKeyDetailQuestion[];
}

export interface FetchInterviewSlot {
	id: number;
	/** @format time */
	start_time: string;
	/** @format time */
	end_time: string;
	/** @format date */
	date: string;
	timezone?: TimezoneEnum;
	candidates: any[];
	status: string;
}

export interface FetchInterviewSlotRequest {
	/** @format time */
	start_time: string;
	/** @format time */
	end_time: string;
	/** @format date */
	date: string;
	timezone?: TimezoneEnum;
}

export interface FetchJobAdJobSeeker {
	id: number;
	company: CompanyAddress;
	/** @maxLength 255 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	min_days_urgency?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	max_days_urgency?: number | null;
	skill_tags?: string[] | null;
	/** @format date-time */
	last_active_employer?: string | null;
	posting_timestamp: string;
	applicants: number;
	is_applied: boolean;
	is_submitted: boolean;
	is_bookmarked: boolean;
	is_reported: boolean;
	/** @format date-time */
	published_at?: string | null;
}

export interface FetchPortalUser {
	/** @format email */
	email: string;
	first_name: string;
	last_name: string;
	username: string;
	user_type: string;
	mobile_no: string;
	graduation_year: string;
	province: string;
	city: string;
	school: string;
}

export interface FetchPortalUserRequest {
	/**
	 * @format email
	 * @minLength 1
	 */
	email: string;
	/** @minLength 1 */
	timestamp: string;
	/** @minLength 1 */
	signature: string;
}

export enum FieldOfStudyEnum {
	IlmuPengetahuanAlamIPA = "Ilmu Pengetahuan Alam (IPA)",
	MatematikanDanIlmuAlamMIA = "Matematikan dan Ilmu Alam (MIA)",
	IlmuPengetahuanSosialIPS = "Ilmu Pengetahuan Sosial (IPS)",
	IlmuIlmuSosialIIS = "Ilmu-Ilmu Sosial (IIS)",
	BahasaDanSastra = "Bahasa dan Sastra",
	AgrikulturAquakulturPerhutanan = "Agrikultur/Aquakultur/Perhutanan",
	Apoteker = "Apoteker",
	Arsitektur = "Arsitektur",
	Biologi = "Biologi",
	BioTeknologi = "BioTeknologi",
	BisnisAdministrasiManajemen = "Bisnis/Administrasi/Manajemen",
	Ekonomi = "Ekonomi",
	Farmasi = "Farmasi",
	Filosofi = "Filosofi",
	Fisika = "Fisika",
	GeologiGeofisika = "Geologi/Geofisika",
	Hukum = "Hukum",
	IlmuManajemenOlahraga = "Ilmu & Manajemen Olahraga",
	IlmuGeografi = "Ilmu Geografi",
	IlmuKelautan = "Ilmu Kelautan",
	IlmuKomputerTeknologiInformasi = "Ilmu Komputer/Teknologi Informasi",
	IlmuPengetahuanTeknologi = "Ilmu Pengetahuan & Teknologi",
	IlmuPolitik = "Ilmu Politik",
	IlmuSosialSosiologi = "Ilmu Sosial/Sosiologi",
	Jurnalisme = "Jurnalisme",
	Kedokteran = "Kedokteran",
	KedokteranGigi = "Kedokteran Gigi",
	KedokteranHewan = "Kedokteran Hewan",
	KemanusiaanPengetahuanBudaya = "Kemanusiaan/Pengetahuan Budaya",
	Keperawatan = "Keperawatan",
	KeuanganAkuntansiPerbankan = "Keuangan/Akuntansi/Perbankan",
	Kimia = "Kimia",
	Komersial = "Komersial",
	KomunikasiMassa = "Komunikasi Massa",
	LinguistikBahasa = "Linguistik/Bahasa",
	LogistikTransportasi = "Logistik/Transportasi",
	ManajemenHR = "Manajemen HR",
	ManajemenPelayananMakananMinuman = "Manajemen Pelayanan Makanan & Minuman",
	ManajemenPerpustakaan = "Manajemen Perpustakaan",
	Matematika = "Matematika",
	MusikSeniPanggung = "Musik/Seni Panggung",
	OperasiPesawatTerbangManajemenBandara = "Operasi Pesawat Terbang/Manajemen Bandara",
	Optometri = "Optometri",
	PelayananManajemenPerlindungan = "Pelayanan & Manajemen Perlindungan",
	Pemasaran = "Pemasaran",
	PendidikanPengajaranPelatihan = "Pendidikan/Pengajaran/Pelatihan",
	PengembanganPropertiManajemenRealEstate = "Pengembangan Properti/Manajemen Real Estate",
	PerhotelanPariwisataManajemenHotel = "Perhotelan/Pariwisata/Manajemen Hotel",
	PeriklananMedia = "Periklanan/Media",
	PersonalService = "Personal Service",
	Psikologi = "Psikologi",
	Sejarah = "Sejarah",
	Sekretari = "Sekretari",
	SeniDesainMultimediaKreatif = "Seni/Desain/Multimedia Kreatif",
	StudiPerkotaanPerencanaanKota = "Studi Perkotaan/Perencanaan Kota",
	SurveiKuantitas = "Survei Kuantitas",
	TeknikAviasiPenerbanganAstronotika = "Teknik (Aviasi/Penerbangan/Astronotika)",
	TeknikBioteknologiBiomedikal = "Teknik (Bioteknologi/Biomedikal)",
	TeknikElektro = "Teknik (Elektro)",
	TeknikFabrikasiPeralatanMetalPencelupanPengelasan = "Teknik (Fabrikasi/Peralatan Metal & Pencelupan/Pengelasan)",
	TeknikIlmuMateri = "Teknik (Ilmu Materi)",
	TeknikIndustri = "Teknik (Industri)",
	TeknikKelautan = "Teknik (Kelautan)",
	TeknikKomputerTelekomunikasi = "Teknik (Komputer/Telekomunikasi)",
	TeknikLainnya = "Teknik (Lainnya)",
	TeknikLingkunganKesehatanKeamanan = "Teknik (Lingkungan/Kesehatan/Keamanan)",
	TeknikMechatronikElektromekanikal = "Teknik (Mechatronik/Elektromekanikal)",
	TeknikMekanikal = "Teknik (Mekanikal)",
	TeknikPertambanganMineral = "Teknik (Pertambangan/Mineral)",
	TeknikPetroleumMinyakGas = "Teknik (Petroleum/Minyak/Gas)",
	TeknikKimia = "Teknik Kimia",
	TeknikSipil = "Teknik Sipil",
	TeknologiPanganNutrisiGizi = "Teknologi Pangan/Nutrisi/Gizi",
	TekstilFashionDesign = "Tekstil/Fashion Design",
	TerapiFisikFisioterapi = "Terapi Fisik/Fisioterapi",
	Lainnya = "Lainnya",
}

export enum GenderEnum {
	Male = "male",
	Female = "female",
}

export interface GetNotification {
	id: number;
	push_notifications_enabled?: boolean;
	recieve_job_alert_emails?: boolean;
	recieve_commercial_emails?: boolean;
	recieve_opportunity_knocks_emails?: boolean;
	recieve_profile_update_emails?: boolean;
}

export interface HelpCategory {
	id: number;
	category_title: string;
	/** @format uri */
	category_icon: string;
	CategoryTopics: HelpTopic[];
	CategoryFAQs: HelpFAQwithAnswer[];
}

export interface HelpFAQwithAnswer {
	id: number;
	category: number;
	question: string;
	answer: string;
	question_text: string;
	answer_text: string;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	question_order: number;
}

export interface HelpFAQwithoutAnswer {
	id: number;
	category: number;
	question: string;
	question_text: string;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	question_order: number;
}

export interface HelpTopic {
	id: number;
	category: number;
	topic_title: string;
	topic_description: string;
}

export interface InputForMatching {
	id: number;
	answer: number;
	matching_question: number;
	/** @maxLength 400 */
	matching_answer: string;
}

export interface InputForMatchingRequest {
	matching_question: number;
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	matching_answer: string;
}

export interface InputTestAnswer {
	id: number;
	question: number;
	answer_package: number;
	text_answer?: string | null;
	fill_in_the_blank_answer?: (string | null)[];
	boolean_answer?: boolean | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	upload_answer?: string | null;
}

export interface InputTestAnswerRequest {
	question: number;
	answer_package: number;
	text_answer?: string | null;
	fill_in_the_blank_answer?: (string | null)[];
	boolean_answer?: boolean | null;
	mcq_answer?: any[];
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	upload_answer?: File | null;
	candidate_matching_answer?: InputForMatchingRequest[];
}

export enum InstitutionLevelEnum {
	SD = "SD",
	SMP = "SMP",
	SMASMUSMKSTM = "SMA/SMU/SMK/STM",
	DiplomaD3 = "Diploma - D3",
	DiplomaD4 = "Diploma - D4",
	SarjanaS1 = "Sarjana - S1",
	MagisterS2 = "Magister - S2",
	DoktorS3 = "Doktor - S3",
}

export interface Interview {
	id: number;
	meeting_type: MeetingTypeEnum;
	schedule_name: string;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	message?: string | null;
	job_ad: number;
	job_applications: number[];
	/** @maxLength 200 */
	schedule_color?: string | null;
}

export interface InterviewAndSlotCreate {
	id: number;
	schedule_name: string;
}

export interface InterviewAndSlotCreateRequest {
	/** @minLength 1 */
	schedule_name: string;
	slot: InterviewSlotBulkCreateRequest[];
}

export interface InterviewFetch {
	id: number;
	meeting_type: MeetingTypeEnum;
	/** @maxLength 400 */
	schedule_name?: string | null;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	message?: string | null;
	/**
	 * @min 2
	 * @max 9999
	 */
	max_capacity_in_slots?: number | null;
	display_remaining_capacity?: boolean;
	job_applications: number[];
	job_ad: number;
	allow_interview_reschedule?: boolean;
	/** @format date */
	max_date_to_interview?: string | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	no_of_reschedule_allowed?: number | null;
	/** @maxLength 200 */
	schedule_color?: string | null;
}

export interface InterviewFetchForJobSeeker {
	id: number;
	meeting_type: MeetingTypeEnum;
	/** @maxLength 400 */
	schedule_name?: string | null;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	message?: string | null;
	/**
	 * @min 2
	 * @max 9999
	 */
	max_capacity_in_slots?: number | null;
	display_remaining_capacity?: boolean;
	job_ad: number;
	allow_interview_reschedule?: boolean;
	/** @format date */
	max_date_to_interview?: string | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	no_of_reschedule_allowed?: number | null;
	/** @maxLength 200 */
	schedule_color?: string | null;
}

export interface InterviewRequest {
	meeting_type: MeetingTypeEnum;
	/** @minLength 1 */
	schedule_name: string;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	message?: string | null;
	job_ad: number;
	job_applications: number[];
	/** @maxLength 200 */
	schedule_color?: string | null;
}

export interface InterviewSchedule {
	id: number;
	interview_schedule_type: string;
	meeting_type: MeetingTypeEnum;
	/** @maxLength 400 */
	schedule_name?: string | null;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 200 */
	schedule_color?: string | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	status_exact: string;
	job_applications: JobApplicantSlim[];
	interview_slots: FetchInterviewSlot[];
	job_ad: number;
}

export enum InterviewScheduleTypeEnum {
	Exact = "exact",
	Slots = "slots",
}

export interface InterviewSlot {
	id: number;
	interview: number;
	/** @format time */
	start_time: string;
	/** @format time */
	end_time: string;
	/** @format date */
	date: string;
	timezone?: TimezoneEnum;
}

export interface InterviewSlotBulkCreate {
	/** @format time */
	start_time: string;
	/** @format time */
	end_time: string;
	/** @format date */
	date: string;
	timezone?: TimezoneEnum;
}

export interface InterviewSlotBulkCreateRequest {
	/** @format time */
	start_time: string;
	/** @format time */
	end_time: string;
	/** @format date */
	date: string;
	timezone?: TimezoneEnum;
}

export interface InterviewSlotRequest {
	interview: number;
	/** @format time */
	start_time: string;
	/** @format time */
	end_time: string;
	/** @format date */
	date: string;
	timezone?: TimezoneEnum;
}

export interface JobAd {
	id: number;
	company: CompanyAddress;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	/** @maxLength 255 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	min_days_urgency?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	max_days_urgency?: number | null;
	status?: StatusEnum;
	skill_tags?: string[] | null;
	/** @format date-time */
	published_at?: string | null;
	/** @format date-time */
	expired_at?: string | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	reactivate_counter?: number;
	past_expired_timestamp?: string[] | null;
	past_published_timestamp?: string[] | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	hires?: number | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	/** @format date-time */
	last_active_employer?: string | null;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
	/** @format date-time */
	employer_assist_on_at?: string | null;
	/** @format date-time */
	employer_assist_off_at?: string | null;
	created_by: number;
	updated_by?: number | null;
	employer_assist_last_updated_by?: number | null;
}

export interface JobAdBasic {
	id: number;
	/** @maxLength 255 */
	job_title: string;
}

export interface JobAdCompare {
	id: number;
	/** @maxLength 255 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	display_salary?: boolean;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
}

export interface JobAdCreate {
	id: number;
	status: string;
	package_type: string;
	published_at: string;
	expired_at: string;
	min_days_urgency: number;
	max_days_urgency: number;
	reactivate_counter: number;
	past_expired_timestamp: any[];
	past_published_timestamp: any[];
	hires: number;
	/** @format date-time */
	last_active_employer: string;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	/** @maxLength 255 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
	/** @format date-time */
	employer_assist_on_at?: string | null;
	/** @format date-time */
	employer_assist_off_at?: string | null;
	employer_assist_last_updated_by?: number | null;
}

export interface JobAdCreateRequest {
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
	/** @format date-time */
	employer_assist_on_at?: string | null;
	/** @format date-time */
	employer_assist_off_at?: string | null;
	employer_assist_last_updated_by?: number | null;
}

export interface JobAdDetail {
	id: number;
	/** @maxLength 255 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
}

export interface JobAdList {
	id: number;
	/** @maxLength 255 */
	job_title: string;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	job_type?: JobTypeEnum[] | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	company: CompanyAddress;
	score: number;
	status?: StatusEnum;
	/** @format date-time */
	published_at?: string | null;
}

export interface JobAdListForCompany {
	id: number;
	/** @maxLength 255 */
	job_title: string;
	company: CompanyAddress;
	status?: StatusEnum;
	created_by: UserSlim;
	/** @format date-time */
	published_at?: string | null;
	/** @format date-time */
	expired_at?: string | null;
	viewers_counter: number;
	applies_counter: number;
	unrated_counter: number;
	good_fit_counter: number;
	perhaps_counter: number;
	unfit_counter: number;
	/** @format date-time */
	updated_at: string;
	is_scheduled: boolean;
}

export interface JobAdUpdate {
	id: number;
	status: string;
	package_type: string;
	published_at: string;
	expired_at: string;
	min_days_urgency: number;
	max_days_urgency: number;
	past_expired_timestamp: any[];
	past_published_timestamp: any[];
	reactivate_counter: number;
	hires: number;
	/** @format date-time */
	last_active_employer: string;
	/** @format date-time */
	employer_assist_on_at: string;
	/** @format date-time */
	employer_assist_off_at: string;
	employer_assist_last_updated_by: UserSlim;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	/** @maxLength 255 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 255 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
}

export interface JobAdUpdateRequest {
	hires: number;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	job_title: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	posting_location_city: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
}

export interface JobApplicantSlim {
	id: number;
	created_by: UserSlim;
}

export interface JobApplication {
	id: number;
	job_ad: JobAdDetail;
	company: CompanyAddress;
	resume_file: Resume;
	pre_screening_answer: PreScreeningAnswerandQuestionSlim;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	stage?: StageEnum;
	/** @maxLength 200 */
	self_promote_note?: string | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	cover_letter?: string | null;
	job_poster_feedback?: JobPosterFeedbackEnum;
	/** @format date-time */
	submitted_at?: string | null;
	is_withdrawn?: boolean;
	/** @format date-time */
	withdrawn_at?: string | null;
	/** @maxLength 400 */
	withdrawn_reason?: string | null;
	is_viewed?: boolean;
	/** @format date-time */
	viewed_at?: string | null;
	/** @maxLength 400 */
	job_poster_feedback_info?: string | null;
	created_by: number;
	updated_by?: number | null;
	job_poster_feedback_by?: number | null;
	viewed_by?: number | null;
}

export interface JobApplicationComment {
	id: number;
	comment: string;
	job_application: number;
	/** @format date-time */
	created_at: string;
	created_by: UserSlim;
}

export interface JobApplicationCommentRequest {
	/** @minLength 1 */
	comment: string;
	job_application: number;
}

export interface JobApplicationForCompanyUser {
	id: number;
	created_by: CompleteUserProfile;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	stage?: StageEnum;
	/** @maxLength 200 */
	self_promote_note?: string | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	cover_letter?: string | null;
	job_poster_feedback?: JobPosterFeedbackEnum;
	/** @format date-time */
	submitted_at?: string | null;
	is_withdrawn?: boolean;
	/** @format date-time */
	withdrawn_at?: string | null;
	/** @maxLength 400 */
	withdrawn_reason?: string | null;
	is_viewed?: boolean;
	/** @format date-time */
	viewed_at?: string | null;
	/** @maxLength 400 */
	job_poster_feedback_info?: string | null;
	updated_by?: number | null;
	job_ad: number;
	job_poster_feedback_by?: number | null;
	viewed_by?: number | null;
}

export interface JobBookmark {
	id: number;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	job_ad: number;
	created_by: number;
}

export interface JobBookmarkRequest {
	job_ad: number;
}

export interface JobInterviewTabList {
	job_title: string;
	meeting_type: string;
	/** @format date-time */
	submitted_at: string;
	applicant_number: number;
	status: string;
	interview_schedule_type: string;
	job_application_id: number;
	job_interview_id: number;
	location: string;
	phone_number: string;
	video_call_link: string;
	message: string;
}

export enum JobPosterFeedbackEnum {
	Unrated = "unrated",
	GoodFit = "good_fit",
	Perhaps = "perhaps",
	Unfit = "unfit",
}

export interface JobPosterResendSignUp {
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
}

export interface JobPosterResendSignUpRequest {
	/**
	 * Email address
	 * @format email
	 * @minLength 1
	 * @maxLength 254
	 */
	email: string;
}

export interface JobPosterSignUp {
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	mobile_phone_number: string;
	company?: number | null;
}

export interface JobPosterSignUpRequest {
	/**
	 * Email address
	 * @format email
	 * @minLength 1
	 * @maxLength 254
	 */
	email: string;
	/** @minLength 1 */
	password: string;
	/**
	 * @minLength 1
	 * @maxLength 300
	 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/** @minLength 1 */
	mobile_phone_number: string;
	company?: number | null;
	/** @minLength 1 */
	business_name: string;
	/** @minLength 1 */
	industry: string;
}

export interface JobPreference {
	id: number;
	currency: CurrencyEnum;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	expected_salary: number;
	preferred_work_model: PreferredWorkModelEnum;
	/** @maxLength 100 */
	preferred_work_location?: string | null;
	/** @maxLength 100 */
	preferred_work_location_2?: string | null;
	/** @maxLength 100 */
	preferred_work_location_3?: string | null;
	preferred_job_types?: PreferredJobTypesEnum[] | null;
}

export interface JobPreferenceRequest {
	currency: CurrencyEnum;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	expected_salary: number;
	preferred_work_model: PreferredWorkModelEnum;
	/** @maxLength 100 */
	preferred_work_location?: string | null;
	/** @maxLength 100 */
	preferred_work_location_2?: string | null;
	/** @maxLength 100 */
	preferred_work_location_3?: string | null;
	preferred_job_types?: PreferredJobTypesEnum[] | null;
}

export interface JobSearchKeyword {
	keyword: string;
	occurrence: number;
}

export interface JobSeekerResendVerification {
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
}

export interface JobSeekerResendVerificationRequest {
	/**
	 * Email address
	 * @format email
	 * @minLength 1
	 * @maxLength 254
	 */
	email: string;
}

export interface JobSeekerSignUp {
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	mobile_phone_number: string;
	/** @format date */
	date_of_birth?: string;
	gender?: GenderEnum;
}

export interface JobSeekerSignUpRequest {
	/**
	 * Email address
	 * @format email
	 * @minLength 1
	 * @maxLength 254
	 */
	email: string;
	/** @minLength 1 */
	password: string;
	/**
	 * @minLength 1
	 * @maxLength 300
	 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/** @minLength 1 */
	mobile_phone_number: string;
	/** @format date */
	date_of_birth?: string;
	gender?: GenderEnum;
	is_portal_account?: boolean;
}

export interface JobTestList {
	job_ad: JobAdList;
	tests: TestDetailForJobSeeker[];
}

export enum JobTypeEnum {
	FullTime = "full_time",
	PartTime = "part_time",
	Freelance = "freelance",
	Contract = "contract",
	Internship = "internship",
	Temporary = "temporary",
}

export interface Language {
	id: number;
	/** @maxLength 255 */
	name: string;
	spoken_proficiency: SpokenProficiencyEnum;
	written_proficiency: WrittenProficiencyEnum;
	is_primary?: boolean;
	/** @maxLength 200 */
	language_proficiency_name?: string | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	language_proficiency_score?: number | null;
}

export interface LanguageDocument {
	id: number;
	file_name: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	language_document_proof: string;
	language: number;
}

export interface LanguageDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	language_document_proof: File;
	language: number;
}

export interface LanguageRequest {
	language_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	name: string;
	spoken_proficiency: SpokenProficiencyEnum;
	written_proficiency: WrittenProficiencyEnum;
	is_primary?: boolean;
	/** @maxLength 200 */
	language_proficiency_name?: string | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	language_proficiency_score?: number | null;
}

export enum LevelEnum {
	Beginner = "beginner",
	Intermediate = "intermediate",
	Advance = "advance",
}

export interface ListApplicantsForCompany {
	id: number;
	created_by: UserSlim;
	job_poster_feedback?: JobPosterFeedbackEnum;
	current_job_title: string;
	current_company: string;
	/** @format date-time */
	applied_at: string;
	expected_pre_screening_score: number;
	user_pre_screening_score: number;
	current_candidate_status: CandidateStatusOnJobApplicationRetrieve[];
	is_viewed?: boolean;
	/** @format date-time */
	viewed_at?: string | null;
	viewed_by: UserSlim;
}

export interface ListTest {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	topic_info?: (string | null)[];
}

export interface Location {
	/** @maxLength 100 */
	province: string;
	/** @maxLength 100 */
	city: string;
	/** @maxLength 100 */
	district: string;
}

export interface Login {
	id: number;
	email: string;
	user_type: string;
	token: string;
	refresh: string;
	first_name: string;
	last_name: string;
	country: string;
	city: string;
	street_address: string;
	mobile_phone_number: string;
	company: string;
	profile_completion_points: number;
	user_personal_info_flag: any[];
	is_initial_login: boolean;
}

export interface LoginRequest {
	/** @minLength 1 */
	email: string;
	/** @minLength 1 */
	user_type: string;
	/** @minLength 1 */
	password: string;
}

export interface LogoutRequest {
	/** @minLength 1 */
	refresh_token: string;
}

export interface MCQChoices {
	id: number;
	choices: string;
	is_answer?: boolean;
}

export interface MCQChoicesRequest {
	/** @minLength 1 */
	choices: string;
	is_answer?: boolean;
}

export interface MCQChoicesSerializerWithoutKey {
	id: number;
	choices: string;
}

export interface MarkNotification {
	code: number;
	error: boolean;
	message: string;
	data: any[];
	array_count: number;
}

export interface MatchingQuestionInput {
	id: number;
	key?: string | null;
	/** @maxLength 400 */
	value?: string | null;
}

export interface MatchingQuestionInputRequest {
	key?: string | null;
	/** @maxLength 400 */
	value?: string | null;
}

export interface MatchingQuestionKey {
	id: number;
	key?: string | null;
}

export interface MatchingQuestionKeyRequest {
	key?: string | null;
}

export enum MeetingTypeEnum {
	OnSite = "on_site",
	PhoneCall = "phone_call",
	VideoCall = "video_call",
}

export interface MyJobsBadge {
	bookmark_counter: number;
	applied_counter: number;
	interview_counter: number;
	withdrawn_counter: number;
}

export enum NoticePeriodEnum {
	Value1Week = "1_week",
	Value2Weeks = "2_weeks",
	Value3Weeks = "3_weeks",
	Value4Weeks = "4_weeks",
	Value1Month = "1_month",
	Value2Months = "2_months",
	Value3Months = "3_months",
	Value4Months = "4_months",
}

export interface Notification {
	id: number;
	/** @maxLength 400 */
	title: string;
	description?: string | null;
	/** @maxLength 200 */
	content_type?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	content_id?: number | null;
	/** @maxLength 200 */
	module_name?: string | null;
	is_obj_deleted?: boolean;
}

export interface NotificationList {
	id: number;
	/** @maxLength 400 */
	title: string;
	description?: string | null;
	/** @maxLength 200 */
	content_type?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	content_id?: number | null;
	/** @maxLength 200 */
	module_name?: string | null;
	is_obj_deleted?: boolean;
	/** @format date-time */
	created_at: string;
	is_read: boolean;
	is_seen: boolean;
	timestamp: string;
}

export interface NotificationRecipient {
	id: number;
	notification: Notification;
	is_read: boolean;
	is_seen: boolean;
}

export interface NotificationRequest {
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	title: string;
	description?: string | null;
	/** @maxLength 200 */
	content_type?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	content_id?: number | null;
	/** @maxLength 200 */
	module_name?: string | null;
	is_obj_deleted?: boolean;
}

export type NullEnum = null;

export enum NumberOfEmployeesEnum {
	NotSpecified = "Not Specified",
	Value150Employees = "1 - 50 Employees",
	Value51200Employees = "51 - 200 Employees",
	Value201500Employees = "201 - 500 Employees",
	Value5011000Employees = "501 - 1000 Employees",
	Value10012000Employees = "1001 - 2000 Employees",
	Value20015000Employees = "2001 - 5000 Employees",
	Value500110000Employees = "5001 - 10000 Employees",
	Value10000Employees = "10000+ Employees",
}

export interface OfferingLetterCreate {
	id: number;
	/** @format date */
	offer_letter_date: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	agreed_salary?: number | null;
	job_application: number;
	/** @maxLength 400 */
	job_type?: string | null;
	/** @maxLength 400 */
	position_title?: string | null;
	/** @maxLength 400 */
	probation_period: string;
	/** @format date */
	join_date: string;
	special_condition?: string | null;
	is_sent?: boolean;
}

export interface OfferingLetterCreateRequest {
	/** @format date */
	offer_letter_date: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	agreed_salary?: number | null;
	job_application: number;
	/** @maxLength 400 */
	job_type?: string | null;
	/** @maxLength 400 */
	position_title?: string | null;
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	probation_period: string;
	/** @format date */
	join_date: string;
	special_condition?: string | null;
	is_sent?: boolean;
}

export interface OfferingLetterPlaceholder {
	position_name: string;
	agreed_salary: number;
	job_type: string;
}

export interface PatchedAddCollaboratorsRequest {
	collaborators?: (number | null)[];
}

export interface PatchedCancelInterviewBulkRequest {
	/** @minLength 1 */
	message?: string;
	applicants?: any[];
}

export interface PatchedCancelInterviewJobSeekerRequest {
	/** @minLength 1 */
	cancel_reason?: string;
}

export interface PatchedCarryOverJobAdRequest {
	status?: StatusEnum;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/** @format date-time */
	published_at?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	hires?: number | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	/** @format date-time */
	last_active_employer?: string | null;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
}

/** Serializer to allow users to update their password. */
export interface PatchedChangePasswordRequest {
	/** @minLength 1 */
	old_password?: string;
	/** @minLength 1 */
	new_password?: string;
	/** @minLength 1 */
	confirm_password?: string;
}

export interface PatchedClosedAccountRequest {
	closed_account_answer?: string | null;
	/** @minLength 1 */
	password?: string;
}

export interface PatchedCompanyAccountActivationStatusRequest {
	is_deactivated?: boolean;
}

export interface PatchedCompanyDeactivationRequest {
	is_active?: boolean;
}

export interface PatchedCompanyMediaRequest {
	/** @format binary */
	company_image?: File | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	company_video?: string | null;
	company?: number;
}

export interface PatchedCompanyRequest {
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	name?: string;
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	industry?: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	founded?: number | null;
	/** @minLength 1 */
	company_overview?: string;
	company_address?: string | null;
	/** @maxLength 100 */
	company_contact_number?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	company_website?: string | null;
	number_of_employees?: NumberOfEmployeesEnum | BlankEnum | NullEnum | null;
	/** @format binary */
	company_logo?: File | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	youtube_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	twitter_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	instagram_account?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	facebook_account?: string | null;
	/** @maxLength 200 */
	company_city?: string | null;
	/** @maxLength 200 */
	company_district?: string | null;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	google_map_location_link?: string | null;
	is_active?: boolean;
}

export interface PatchedConfirmResumeRequest {
	/** @format binary */
	resume_file?: File;
}

export interface PatchedContactDetailRequest {
	/** @minLength 1 */
	mobile_phone_number?: string;
	/** @minLength 1 */
	mobile_phone_number_2?: string | null;
}

export interface PatchedCoverLetterRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	cover_letter?: File | null;
}

export interface PatchedDeactivateAccountRequest {
	/** @minLength 1 */
	password?: string;
	deactivate_answer?: DeactivateAnswerEnum | BlankEnum | NullEnum | null;
	deactivate_feedback?: string | null;
}

export interface PatchedDeactivateJobAdRequest {
	status?: StatusEnum;
}

export interface PatchedDeclineInterviewJobSeekerRequest {
	/** @minLength 1 */
	decline_reason?: string;
}

export interface PatchedEducationDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	education_document_proof?: File;
	education?: number;
}

export interface PatchedEducationRequest {
	education_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	institution_name?: string;
	country?: PostingLocationCountryEnum;
	institution_level?: InstitutionLevelEnum | BlankEnum | NullEnum | null;
	field_of_study?: FieldOfStudyEnum | BlankEnum | NullEnum | null;
	is_still_enrolled?: boolean;
	/** @format date */
	started_at?: string;
	/** @format date */
	graduated_at?: string | null;
	/** @maxLength 255 */
	major?: string | null;
	/**
	 * @format decimal
	 * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
	 */
	gpa?: string;
}

export interface PatchedExperienceDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	experience_document_proof?: File;
	experience?: number;
}

export interface PatchedExperienceRequest {
	experience_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	job_title?: string;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	company_name?: string;
	country?: PostingLocationCountryEnum;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	city?: string;
	/** @format date */
	started_at?: string;
	is_currently_working?: boolean;
	/** @format date */
	ended_at?: string | null;
	description?: string | null;
	/** @maxLength 100 */
	company_postcode?: string | null;
	currency?: CurrencyEnum | BlankEnum | NullEnum | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	salary?: number | null;
}

export interface PatchedJobAdUpdateRequest {
	hires?: number;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	job_title?: string;
	posting_location_country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	posting_location_city?: string;
	/** @maxLength 255 */
	posting_location_district?: string | null;
	/** @maxLength 255 */
	posting_location_other_location?: string | null;
	work_model?: WorkModelEnum | BlankEnum | NullEnum | null;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	job_type?: JobTypeEnum[] | null;
	/**
	 * @min 1
	 * @max 168
	 */
	hours_per_week?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_period?: number | null;
	/** @maxLength 255 */
	contract_period_unit?: string | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	contract_amount?: number | null;
	display_salary?: boolean;
	salary_type?: SalaryTypeEnum | BlankEnum | NullEnum | null;
	salary_currency?: SalaryCurrencyEnum | BlankEnum | NullEnum | null;
	salary_rate?: SalaryRateEnum | BlankEnum | NullEnum | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_starting_amount?: number | null;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	salary_maximum_amount?: number | null;
	job_requirements?: string | null;
	job_description?: string | null;
	job_benefits?: string | null;
	job_requirements_plain?: string | null;
	job_description_plain?: string | null;
	job_benefits_plain?: string | null;
	urgency?: UrgencyEnum | BlankEnum | NullEnum | null;
	skill_tags?: string[] | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	form_percentage?: number;
	employer_assist?: boolean;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	auto_decline_days?: number | null;
}

export interface PatchedJobApplicationCommentRequest {
	/** @minLength 1 */
	comment?: string;
	job_application?: number;
}

export interface PatchedJobPreferenceRequest {
	currency?: CurrencyEnum;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	expected_salary?: number;
	preferred_work_model?: PreferredWorkModelEnum;
	/** @maxLength 100 */
	preferred_work_location?: string | null;
	/** @maxLength 100 */
	preferred_work_location_2?: string | null;
	/** @maxLength 100 */
	preferred_work_location_3?: string | null;
	preferred_job_types?: PreferredJobTypesEnum[] | null;
}

export interface PatchedLanguageDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	language_document_proof?: File;
	language?: number;
}

export interface PatchedLanguageRequest {
	language_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	name?: string;
	spoken_proficiency?: SpokenProficiencyEnum;
	written_proficiency?: WrittenProficiencyEnum;
	is_primary?: boolean;
	/** @maxLength 200 */
	language_proficiency_name?: string | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	language_proficiency_score?: number | null;
}

export interface PatchedNotificationRecipientRequest {
	ids?: any[];
}

export interface PatchedPortfolioRequest {
	/**
	 * @format binary
	 * @pattern (?:doc|docx|txt|rtf|pdf)$
	 */
	portfolio_file?: File;
}

export interface PatchedPreScreeningAnswerRequest {
	mcq_answer?: any[];
	fill_in_the_blanks_answer?: any[];
	/** @minLength 1 */
	text_answer?: string;
	tf_answer?: boolean;
	numeric_answer?: number;
	question?: number;
	job_ad?: number;
	job_application?: number | null;
}

export interface PatchedPreScreeningQuestionRequest {
	/** @minLength 1 */
	question_text?: string;
	is_active?: boolean;
	mcq_choices?: (string | null)[];
	mandatory_choices?: (string | null)[];
	is_mandatory?: boolean;
	fill_in_the_blanks_options?: (string | null)[];
	boolean_accepted_answer?: boolean | null;
	question_category?: QuestionCategoryEnum;
	question_type?: PreScreeningQuestionQuestionTypeEnum;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	numeric_accepted_answer?: number | null;
	max_numeric_value?: boolean;
	min_numeric_value?: boolean;
	job_ad?: number | null;
}

export interface PatchedPublishJobAdRequest {
	/** @format date-time */
	published_at?: string | null;
}

export interface PatchedReactivateAccountJobSeekerRequest {
	is_deactivated?: boolean;
}

export interface PatchedReportJobAdRequest {
	reason?: ReasonEnum;
	/** @minLength 1 */
	comment?: string;
	/** @maxLength 100 */
	report_status?: string | null;
	job_ad?: number;
}

export interface PatchedRescheduleInterviewRequest {
	/** @minLength 1 */
	message?: string;
	job_applications?: any[];
}

export interface PatchedResumeRequest {
	/**
	 * @format binary
	 * @pattern (?:doc|docx|txt|rtf|pdf)$
	 */
	resume_file?: File;
	visibility?: VisibilityEnum;
	ready_to_work?: boolean;
	notice_period?: NoticePeriodEnum | BlankEnum | NullEnum | null;
	/** @format date-time */
	last_updated_resume?: string | null;
}

export interface PatchedSelfPromotionRequest {
	/** @minLength 1 */
	self_promote_note?: string | null;
}

export interface PatchedSetCandidateCategoryRequest {
	job_poster_feedback?: JobPosterFeedbackEnum;
}

export interface PatchedSkillDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	skill_document_proof?: File;
	skill?: number;
}

export interface PatchedSkillRequest {
	skill_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	name?: string;
	level?: LevelEnum;
}

export interface PatchedSubmitRequest {
	/** @minLength 1 */
	stage?: string;
}

export interface PatchedUpdateCollaboratorsRequest {
	collaborators?: (number | null)[];
}

export interface PatchedUpdateInterviewRequest {
	meeting_type?: MeetingTypeEnum;
	/** @maxLength 400 */
	schedule_name?: string | null;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	message?: string | null;
	job_ad?: number;
	/** @maxLength 200 */
	schedule_color?: string | null;
}

export interface PatchedUpdateInterviewScheduleTypeRequest {
	interview_schedule_type?: InterviewScheduleTypeEnum | BlankEnum | NullEnum | null;
}

export interface PatchedUpdateNotificationRequest {
	push_notifications_enabled?: boolean;
	recieve_job_alert_emails?: boolean;
	recieve_commercial_emails?: boolean;
	recieve_opportunity_knocks_emails?: boolean;
	recieve_profile_update_emails?: boolean;
}

export interface PatchedUpdateQuestionRequest {
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	question_number?: number;
	question_text?: string | null;
	question_type?: QuestionType78BEnum;
	question_choices?: UpdateMCQChoicesRequest[];
	matching_questions_list?: UpdateMatchingQuestionKeyRequest[];
}

export interface PatchedUpdateTestAnswerRequest {
	text_answer?: string | null;
	fill_in_the_blank_answer?: (string | null)[];
	boolean_answer?: boolean | null;
	mcq_answer?: any[];
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	upload_answer?: File | null;
	candidate_matching_answer?: UpdateForMatchingRequest[];
}

export interface PatchedUpdateTestRequest {
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	quiz_title?: string;
	job_ad?: number | null;
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_external_quiz?: boolean;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	external_url?: string | null;
	is_time_limited?: boolean;
	/**
	 * in minutes
	 * @min -2147483648
	 * @max 2147483647
	 */
	time_limit?: number | null;
	/** @minLength 1 */
	instructions?: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score?: number;
	is_shuffled?: boolean;
	topic_info?: (string | null)[];
}

export interface PatchedUserFCMDeviceRequest {
	device_type?: DeviceTypeEnum | BlankEnum | NullEnum | null;
	device_token?: string | null;
}

export interface PatchedUserProfileForCompanyAdminRequest {
	/** @minLength 1 */
	first_name?: string;
	/** @minLength 1 */
	username?: string;
	/** @maxLength 300 */
	last_name?: string | null;
	staff_user_permissions?: StaffUserPermissionsEnum[] | null;
	/** @maxLength 100 */
	mobile_phone_number?: string | null;
	/** @maxLength 100 */
	mobile_phone_number_2?: string | null;
	country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	nationality?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	city?: string | null;
	street_address?: string | null;
	/** @maxLength 400 */
	postal_code?: string | null;
	/** @maxLength 400 */
	preferred_work_location?: string | null;
	/** @format binary */
	profile_picture?: File | null;
	/** @format date */
	date_of_birth?: string | null;
	gender?: GenderEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
}

export interface PatchedUserProfileRequest {
	/** @minLength 1 */
	first_name?: string;
	/** @minLength 1 */
	username?: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/** @maxLength 100 */
	mobile_phone_number?: string | null;
	/** @maxLength 100 */
	mobile_phone_number_2?: string | null;
	country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	nationality?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	city?: string | null;
	street_address?: string | null;
	/** @maxLength 400 */
	postal_code?: string | null;
	/** @maxLength 400 */
	preferred_work_location?: string | null;
	/** @format binary */
	profile_picture?: File | null;
	/** @format date */
	date_of_birth?: string | null;
	gender?: GenderEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	is_initial_login?: boolean;
}

export interface PatchedVerificationCompanyRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	tax_card_image?: File | null;
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	business_or_registration_license?: File | null;
}

export interface PatchedWithdrawnRequest {
	/** @minLength 1 */
	withdrawn_reason?: string;
}

export interface PickTimeSlot {
	id: number;
	interview_slot?: number | null;
	interview: number;
	job_application: number;
}

export interface PickTimeSlotRequest {
	interview_slot?: number | null;
	interview: number;
	job_application: number;
}

export interface PortalSignUp {
	email: string;
}

export interface PortalSignUpRequest {
	/** @minLength 1 */
	email: string;
}

export interface Portfolio {
	id: number;
	file_name: string;
	/**
	 * @format uri
	 * @pattern (?:doc|docx|txt|rtf|pdf)$
	 */
	portfolio_file: string;
}

export interface PortfolioRequest {
	/**
	 * @format binary
	 * @pattern (?:doc|docx|txt|rtf|pdf)$
	 */
	portfolio_file: File;
}

export enum PostingLocationCountryEnum {
	AF = "AF",
	AX = "AX",
	AL = "AL",
	DZ = "DZ",
	AS = "AS",
	AD = "AD",
	AO = "AO",
	AI = "AI",
	AQ = "AQ",
	AG = "AG",
	AR = "AR",
	AM = "AM",
	AW = "AW",
	AU = "AU",
	AT = "AT",
	AZ = "AZ",
	BS = "BS",
	BH = "BH",
	BD = "BD",
	BB = "BB",
	BY = "BY",
	BE = "BE",
	BZ = "BZ",
	BJ = "BJ",
	BM = "BM",
	BT = "BT",
	BO = "BO",
	BQ = "BQ",
	BA = "BA",
	BW = "BW",
	BV = "BV",
	BR = "BR",
	IO = "IO",
	BN = "BN",
	BG = "BG",
	BF = "BF",
	BI = "BI",
	KH = "KH",
	CM = "CM",
	CA = "CA",
	CV = "CV",
	KY = "KY",
	CF = "CF",
	TD = "TD",
	CL = "CL",
	CN = "CN",
	CX = "CX",
	CC = "CC",
	CO = "CO",
	KM = "KM",
	CG = "CG",
	CD = "CD",
	CK = "CK",
	CR = "CR",
	CI = "CI",
	HR = "HR",
	CU = "CU",
	CW = "CW",
	CY = "CY",
	CZ = "CZ",
	DK = "DK",
	DJ = "DJ",
	DM = "DM",
	DO = "DO",
	EC = "EC",
	EG = "EG",
	SV = "SV",
	GQ = "GQ",
	ER = "ER",
	EE = "EE",
	ET = "ET",
	FK = "FK",
	FO = "FO",
	FJ = "FJ",
	FI = "FI",
	FR = "FR",
	GF = "GF",
	PF = "PF",
	TF = "TF",
	GA = "GA",
	GM = "GM",
	GE = "GE",
	DE = "DE",
	GH = "GH",
	GI = "GI",
	GR = "GR",
	GL = "GL",
	GD = "GD",
	GP = "GP",
	GU = "GU",
	GT = "GT",
	GG = "GG",
	GN = "GN",
	GW = "GW",
	GY = "GY",
	HT = "HT",
	HM = "HM",
	VA = "VA",
	HN = "HN",
	HK = "HK",
	HU = "HU",
	IS = "IS",
	IN = "IN",
	ID = "ID",
	IR = "IR",
	IQ = "IQ",
	IE = "IE",
	IM = "IM",
	IL = "IL",
	IT = "IT",
	JM = "JM",
	JP = "JP",
	JE = "JE",
	JO = "JO",
	KZ = "KZ",
	KE = "KE",
	KI = "KI",
	KP = "KP",
	KR = "KR",
	XK = "XK",
	KW = "KW",
	KG = "KG",
	LA = "LA",
	LV = "LV",
	LB = "LB",
	LS = "LS",
	LR = "LR",
	LY = "LY",
	LI = "LI",
	LT = "LT",
	LU = "LU",
	MO = "MO",
	MK = "MK",
	MG = "MG",
	MW = "MW",
	MY = "MY",
	MV = "MV",
	ML = "ML",
	MT = "MT",
	MH = "MH",
	MQ = "MQ",
	MR = "MR",
	MU = "MU",
	YT = "YT",
	MX = "MX",
	FM = "FM",
	MD = "MD",
	MC = "MC",
	MN = "MN",
	ME = "ME",
	MS = "MS",
	MA = "MA",
	MZ = "MZ",
	MM = "MM",
	NA = "NA",
	NR = "NR",
	NP = "NP",
	NL = "NL",
	AN = "AN",
	NC = "NC",
	NZ = "NZ",
	NI = "NI",
	NE = "NE",
	NG = "NG",
	NU = "NU",
	NF = "NF",
	MP = "MP",
	NO = "NO",
	OM = "OM",
	PK = "PK",
	PW = "PW",
	PS = "PS",
	PA = "PA",
	PG = "PG",
	PY = "PY",
	PE = "PE",
	PH = "PH",
	PN = "PN",
	PL = "PL",
	PT = "PT",
	PR = "PR",
	QA = "QA",
	RE = "RE",
	RO = "RO",
	RU = "RU",
	RW = "RW",
	BL = "BL",
	SH = "SH",
	KN = "KN",
	LC = "LC",
	MF = "MF",
	PM = "PM",
	VC = "VC",
	WS = "WS",
	SM = "SM",
	ST = "ST",
	SA = "SA",
	SN = "SN",
	RS = "RS",
	CS = "CS",
	SC = "SC",
	SL = "SL",
	SG = "SG",
	SX = "SX",
	SK = "SK",
	SI = "SI",
	SB = "SB",
	SO = "SO",
	ZA = "ZA",
	GS = "GS",
	SS = "SS",
	ES = "ES",
	LK = "LK",
	SD = "SD",
	SR = "SR",
	SJ = "SJ",
	SZ = "SZ",
	SE = "SE",
	CH = "CH",
	SY = "SY",
	TW = "TW",
	TJ = "TJ",
	TZ = "TZ",
	TH = "TH",
	TL = "TL",
	TG = "TG",
	TK = "TK",
	TO = "TO",
	TT = "TT",
	TN = "TN",
	TR = "TR",
	TM = "TM",
	TC = "TC",
	TV = "TV",
	UG = "UG",
	UA = "UA",
	AE = "AE",
	GB = "GB",
	US = "US",
	UM = "UM",
	UY = "UY",
	UZ = "UZ",
	VU = "VU",
	VE = "VE",
	VN = "VN",
	VG = "VG",
	VI = "VI",
	WF = "WF",
	EH = "EH",
	YE = "YE",
	ZM = "ZM",
	ZW = "ZW",
}

export interface PreScreeningAnswer {
	id: number;
	mcq_answer?: any[];
	fill_in_the_blanks_answer?: any[];
	text_answer?: string;
	tf_answer?: boolean;
	numeric_answer?: number;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	created_by: number;
	updated_by: number | null;
	question: number;
	job_ad: number;
	job_application?: number | null;
}

export interface PreScreeningAnswerRequest {
	mcq_answer?: any[];
	fill_in_the_blanks_answer?: any[];
	/** @minLength 1 */
	text_answer?: string;
	tf_answer?: boolean;
	numeric_answer?: number;
	question: number;
	job_ad: number;
	job_application?: number | null;
}

export interface PreScreeningAnswerandQuestionSlim {
	id: number;
	question_data: PreScreeningQuestionSlim;
	mcq_answer?: (string | null)[];
	fill_in_the_blanks_answer?: (string | null)[];
	text_answer?: string | null;
	tf_answer?: boolean | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	numeric_answer?: number | null;
	/** @format date-time */
	created_at: string;
	created_by: number;
}

export interface PreScreeningList {
	id: number;
	question: PreScreeningQuestion;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	text_answer: string | null;
	mcq_answer?: (string | null)[];
	fill_in_the_blanks_answer?: (string | null)[];
	tf_answer?: boolean | null;
	/**
	 * @min 0
	 * @max 2147483647
	 */
	numeric_answer?: number | null;
	created_by: number;
	updated_by?: number | null;
	job_ad: number;
	job_application?: number | null;
}

export interface PreScreeningQuestion {
	id: number;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	question_text: string;
	is_active?: boolean;
	mcq_choices?: (string | null)[];
	mandatory_choices?: (string | null)[];
	is_mandatory?: boolean;
	fill_in_the_blanks_options?: (string | null)[];
	boolean_accepted_answer?: boolean | null;
	question_category: QuestionCategoryEnum;
	question_type: PreScreeningQuestionQuestionTypeEnum;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	numeric_accepted_answer?: number | null;
	max_numeric_value?: boolean;
	min_numeric_value?: boolean;
	/** @default false */
	is_preloaded: boolean;
	created_by: number;
	updated_by: number | null;
	job_ad: number | null;
	company: number | null;
}

export interface PreScreeningQuestionCopy {
	id: number;
	job_ad?: number | null;
	mandatory_choices?: any[];
	is_mandatory?: boolean;
	question_text?: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	numeric_accepted_answer?: number | null;
	boolean_accepted_answer?: boolean | null;
}

export interface PreScreeningQuestionCopyRequest {
	id: number;
	job_ad?: number | null;
	mandatory_choices?: any[];
	is_mandatory?: boolean;
	/** @minLength 1 */
	question_text?: string;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	numeric_accepted_answer?: number | null;
	boolean_accepted_answer?: boolean | null;
}

export enum PreScreeningQuestionQuestionTypeEnum {
	Mcq = "mcq",
	FillInTheBlanks = "fill_in_the_blanks",
	ShortAnswer = "short_answer",
	TrueFalse = "true_false",
	Numeric = "numeric",
	Progressive = "progressive",
	SingleSelect = "single_select",
}

export interface PreScreeningQuestionRequest {
	/** @minLength 1 */
	question_text: string;
	is_active?: boolean;
	mcq_choices?: (string | null)[];
	mandatory_choices?: (string | null)[];
	is_mandatory?: boolean;
	fill_in_the_blanks_options?: (string | null)[];
	boolean_accepted_answer?: boolean | null;
	question_category: QuestionCategoryEnum;
	question_type: PreScreeningQuestionQuestionTypeEnum;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	numeric_accepted_answer?: number | null;
	max_numeric_value?: boolean;
	min_numeric_value?: boolean;
	job_ad: number | null;
}

export interface PreScreeningQuestionSlim {
	id: number;
	question_text: string;
}

export enum PreferredJobTypesEnum {
	FullTime = "full_time",
	PartTime = "part_time",
	Freelance = "freelance",
	Contract = "contract",
	Internship = "internship",
	Temporary = "temporary",
}

export enum PreferredWorkModelEnum {
	Onsite = "onsite",
	Hybrid = "hybrid",
	Remote = "remote",
}

export interface ProposedNewTime {
	interview: number;
	job_application: number;
	reason_for_proposed_new_time?: string | null;
}

export interface ProposedNewTimeRequest {
	interview: number;
	job_application: number;
	reason_for_proposed_new_time?: string | null;
}

export interface PublishJobAd {
	id: number;
	/** @format date-time */
	published_at?: string | null;
}

export enum QuestionCategoryEnum {
	Education = "education",
	InterviewAvailability = "interview_availability",
	ShiftAvailability = "shift_availability",
	WillingnessToTravel = "willingness_to_travel",
	Experience = "experience",
	Language = "language",
	LicenseCertification = "license_certification",
	Other = "other",
}

export enum QuestionType78BEnum {
	SingleSelect = "single_select",
	FillInTheBlank = "fill_in_the_blank",
	Matching = "matching",
	Boolean = "boolean",
	Mcq = "mcq",
	Essay = "essay",
	UploadFile = "upload_file",
}

export interface QuickAssist {
	id: number;
}

export interface ReactivateAccountJobSeeker {
	is_deactivated?: boolean;
}

export enum ReasonEnum {
	SpamOrScam = "spam_or_scam",
	DiscriminationOrOffensive = "discrimination_or_offensive",
	Misleading = "misleading",
	Other = "other",
}

export interface ReportJobAd {
	id: number;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	reason: ReasonEnum;
	comment: string;
	/** @maxLength 100 */
	report_status?: string | null;
	created_by: number;
	updated_by: number | null;
	job_ad: number;
}

export interface ReportJobAdRequest {
	reason: ReasonEnum;
	/** @minLength 1 */
	comment: string;
	/** @maxLength 100 */
	report_status?: string | null;
	job_ad: number;
}

export interface RescheduleInterview {
	interview_slots: FetchInterviewSlot[];
}

export interface RescheduleInterviewByJobSeeker {
	id: number;
	interview_slot?: number | null;
	interview: number;
	job_application: number;
}

export interface RescheduleInterviewByJobSeekerRequest {
	interview_slot?: number | null;
	interview: number;
	job_application: number;
}

export interface ResetPassword {
	user_id: string;
	user_type: string;
	timestamp: number;
	signature: string;
	password: string;
}

export interface ResetPasswordRequest {
	/** @minLength 1 */
	user_id: string;
	/** @minLength 1 */
	user_type: string;
	timestamp: number;
	/** @minLength 1 */
	signature: string;
	/** @minLength 1 */
	password: string;
}

export interface Resume {
	id: number;
	/**
	 * @format uri
	 * @pattern (?:doc|docx|txt|rtf|pdf)$
	 */
	resume_file?: string;
	file_name: string;
	visibility: VisibilityEnum;
	ready_to_work: boolean;
	notice_period?: NoticePeriodEnum | BlankEnum | NullEnum | null;
	/** @format date-time */
	last_updated_resume?: string | null;
}

export interface RetrieveAnswerPackageForJobPoster {
	id: number;
	job_application: number;
	test: number;
	/**
	 * @min 0
	 * @max 100
	 */
	test_score?: number | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	additional_upload_data?: string | null;
	is_submitted?: boolean;
	/** @format date-time */
	submitted_at?: string | null;
	is_final_score?: boolean;
	test_answers: RetrieveTestAnswerForJobPoster[];
}

export interface RetrieveTestAnswerForJobPoster {
	id: number;
	question: ExposedKeyDetailQuestion;
	answer_package: number;
	text_answer?: string | null;
	fill_in_the_blank_answer?: (string | null)[];
	boolean_answer?: boolean | null;
	mcq_answer: number[];
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	points?: number | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	upload_answer?: string | null;
	candidate_matching_answer: TestAnswerForMatching[];
}

export enum SalaryCurrencyEnum {
	Idr = "idr",
	Usd = "usd",
	Sgd = "sgd",
	Myr = "myr",
	Inr = "inr",
	Php = "php",
	Aud = "aud",
	Thb = "thb",
	Hkd = "hkd",
	Eur = "eur",
	Cny = "cny",
	Jpy = "jpy",
	Gbp = "gbp",
	Krw = "krw",
	Vnd = "vnd",
	Bdt = "bdt",
	Nzd = "nzd",
}

export enum SalaryRateEnum {
	PerHour = "per_hour",
	PerDay = "per_day",
	PerWeek = "per_week",
	PerMonth = "per_month",
	PerYear = "per_year",
}

export enum SalaryTypeEnum {
	ExactAmount = "exact_amount",
	RangeAmount = "range_amount",
	StartingAmount = "starting_amount",
	MaximumAmount = "maximum_amount",
}

export interface SelfPromotion {
	id: number;
	stage: string;
	self_promote_note?: string | null;
}

export interface SendCandidateLink {
	recipients: any[];
	job_application_id: any[];
}

export interface SendCandidateLinkRequest {
	recipients: any[];
	job_application_id: any[];
}

export interface SendResetPasswordLink {
	/** @format email */
	email: string;
	user_type: string;
}

export interface SendResetPasswordLinkRequest {
	/**
	 * @format email
	 * @minLength 1
	 */
	email: string;
	/** @minLength 1 */
	user_type: string;
}

export interface SetCandidateCategory {
	id: number;
	job_poster_feedback?: JobPosterFeedbackEnum;
}

export interface SetCandidateCategoryBulk {
	id: number;
	job_poster_feedback: string;
}

export interface SetCandidateCategoryBulkRequest {
	id: number;
	/** @minLength 1 */
	job_poster_feedback: string;
}

export interface ShortDetailJobAd {
	id: number;
	employer_assist?: boolean;
	interview_schedule_type?: InterviewScheduleTypeEnum | BlankEnum | NullEnum | null;
}

export interface Skill {
	id: number;
	/** @maxLength 255 */
	name: string;
	level: LevelEnum;
}

export interface SkillDocument {
	id: number;
	file_name: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	skill_document_proof: string;
	skill: number;
}

export interface SkillDocumentRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	skill_document_proof: File;
	skill: number;
}

export interface SkillRequest {
	skill_document_proof?: File[];
	/**
	 * @minLength 1
	 * @maxLength 255
	 */
	name: string;
	level: LevelEnum;
}

export enum SlotTargetEnum {
	OneToOne = "one-to-one",
	Group = "group",
}

export enum SpokenProficiencyEnum {
	Beginner = "beginner",
	Intermediate = "intermediate",
	Fluent = "fluent",
	Expert = "expert",
	Native = "native",
}

export enum StaffUserPermissionsEnum {
	AllJobs = "all_jobs",
	SpecificJobs = "specific_jobs",
	JobPosting = "job_posting",
	TalentSearchUtilization = "talent_search_utilization",
	UserManagement = "user_management",
	BillingManagement = "billing_management",
	CompanyProfileManagement = "company_profile_management",
}

export enum StageEnum {
	Draft = "draft",
	ContactDetail = "contact_detail",
	ConfirmResume = "confirm_resume",
	AnswerTestQuestion = "answer_test_question",
	AnswerPreScreening = "answer_pre_screening",
	SelfPromoteNote = "self_promote_note",
	CoverLetter = "cover_letter",
	Submitted = "submitted",
}

export enum StatusEnum {
	Draft = "draft",
	Active = "active",
	Inactive = "inactive",
}

export interface Submit {
	id: number;
	stage: string;
}

export interface SubmitAnswer {
	id: number;
	is_submitted: boolean;
	/** @format date-time */
	submitted_at: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	additional_upload_data?: string | null;
}

export interface SubmitAnswerRequest {
	/**
	 * @format binary
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	additional_upload_data?: File | null;
}

export interface Test {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	job_ad?: number | null;
	job_applications: any[];
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_external_quiz?: boolean;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	external_url?: string | null;
	is_time_limited?: boolean;
	/**
	 * in minutes
	 * @min -2147483648
	 * @max 2147483647
	 */
	time_limit?: number | null;
	instructions: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
	is_shuffled?: boolean;
	is_preload_test: boolean;
	topic_info?: (string | null)[];
}

export interface TestAnswer {
	id: number;
	question: number;
	answer_package: number;
	text_answer?: string | null;
	fill_in_the_blank_answer?: (string | null)[];
	boolean_answer?: boolean | null;
	mcq_answer: number[];
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	upload_answer?: string | null;
	candidate_matching_answer: TestAnswerForMatching[];
}

export interface TestAnswerForMatching {
	id: number;
	answer: number;
	matching_question: number;
	/** @maxLength 400 */
	matching_answer: string;
}

export interface TestDetailForJobSeeker {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	topic_info?: (string | null)[];
	/** @format date-time */
	sent_at: string;
	test_score: number;
	/** @format date-time */
	submitted_at: string;
}

export interface TestRequest {
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	quiz_title: string;
	job_ad?: number | null;
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_external_quiz?: boolean;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	external_url?: string | null;
	is_time_limited?: boolean;
	/**
	 * in minutes
	 * @min -2147483648
	 * @max 2147483647
	 */
	time_limit?: number | null;
	/** @minLength 1 */
	instructions: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
	is_shuffled?: boolean;
	topic_info?: (string | null)[];
}

export interface TestStatus {
	id: number;
	created_by: UserSlim;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	submitted_at?: string | null;
	/**
	 * @min 0
	 * @max 100
	 */
	test_score?: number | null;
}

export interface TestStatusByJobPoster {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	not_submitted: TestStatus[];
	submitted: TestStatus[];
	graded: TestStatus[];
	not_submitted_count: number;
	submitted_count: number;
	graded_count: number;
}

export enum TestTypeEnum {
	Practice = "practice",
	Assessment = "assessment",
}

export enum TimezoneEnum {
	AfricaAbidjan = "Africa/Abidjan",
	AfricaAccra = "Africa/Accra",
	AfricaAddisAbaba = "Africa/Addis_Ababa",
	AfricaAlgiers = "Africa/Algiers",
	AfricaAsmara = "Africa/Asmara",
	AfricaAsmera = "Africa/Asmera",
	AfricaBamako = "Africa/Bamako",
	AfricaBangui = "Africa/Bangui",
	AfricaBanjul = "Africa/Banjul",
	AfricaBissau = "Africa/Bissau",
	AfricaBlantyre = "Africa/Blantyre",
	AfricaBrazzaville = "Africa/Brazzaville",
	AfricaBujumbura = "Africa/Bujumbura",
	AfricaCairo = "Africa/Cairo",
	AfricaCasablanca = "Africa/Casablanca",
	AfricaCeuta = "Africa/Ceuta",
	AfricaConakry = "Africa/Conakry",
	AfricaDakar = "Africa/Dakar",
	AfricaDarEsSalaam = "Africa/Dar_es_Salaam",
	AfricaDjibouti = "Africa/Djibouti",
	AfricaDouala = "Africa/Douala",
	AfricaElAaiun = "Africa/El_Aaiun",
	AfricaFreetown = "Africa/Freetown",
	AfricaGaborone = "Africa/Gaborone",
	AfricaHarare = "Africa/Harare",
	AfricaJohannesburg = "Africa/Johannesburg",
	AfricaJuba = "Africa/Juba",
	AfricaKampala = "Africa/Kampala",
	AfricaKhartoum = "Africa/Khartoum",
	AfricaKigali = "Africa/Kigali",
	AfricaKinshasa = "Africa/Kinshasa",
	AfricaLagos = "Africa/Lagos",
	AfricaLibreville = "Africa/Libreville",
	AfricaLome = "Africa/Lome",
	AfricaLuanda = "Africa/Luanda",
	AfricaLubumbashi = "Africa/Lubumbashi",
	AfricaLusaka = "Africa/Lusaka",
	AfricaMalabo = "Africa/Malabo",
	AfricaMaputo = "Africa/Maputo",
	AfricaMaseru = "Africa/Maseru",
	AfricaMbabane = "Africa/Mbabane",
	AfricaMogadishu = "Africa/Mogadishu",
	AfricaMonrovia = "Africa/Monrovia",
	AfricaNairobi = "Africa/Nairobi",
	AfricaNdjamena = "Africa/Ndjamena",
	AfricaNiamey = "Africa/Niamey",
	AfricaNouakchott = "Africa/Nouakchott",
	AfricaOuagadougou = "Africa/Ouagadougou",
	AfricaPortoNovo = "Africa/Porto-Novo",
	AfricaSaoTome = "Africa/Sao_Tome",
	AfricaTimbuktu = "Africa/Timbuktu",
	AfricaTripoli = "Africa/Tripoli",
	AfricaTunis = "Africa/Tunis",
	AfricaWindhoek = "Africa/Windhoek",
	AmericaAdak = "America/Adak",
	AmericaAnchorage = "America/Anchorage",
	AmericaAnguilla = "America/Anguilla",
	AmericaAntigua = "America/Antigua",
	AmericaAraguaina = "America/Araguaina",
	AmericaArgentinaBuenosAires = "America/Argentina/Buenos_Aires",
	AmericaArgentinaCatamarca = "America/Argentina/Catamarca",
	AmericaArgentinaComodRivadavia = "America/Argentina/ComodRivadavia",
	AmericaArgentinaCordoba = "America/Argentina/Cordoba",
	AmericaArgentinaJujuy = "America/Argentina/Jujuy",
	AmericaArgentinaLaRioja = "America/Argentina/La_Rioja",
	AmericaArgentinaMendoza = "America/Argentina/Mendoza",
	AmericaArgentinaRioGallegos = "America/Argentina/Rio_Gallegos",
	AmericaArgentinaSalta = "America/Argentina/Salta",
	AmericaArgentinaSanJuan = "America/Argentina/San_Juan",
	AmericaArgentinaSanLuis = "America/Argentina/San_Luis",
	AmericaArgentinaTucuman = "America/Argentina/Tucuman",
	AmericaArgentinaUshuaia = "America/Argentina/Ushuaia",
	AmericaAruba = "America/Aruba",
	AmericaAsuncion = "America/Asuncion",
	AmericaAtikokan = "America/Atikokan",
	AmericaAtka = "America/Atka",
	AmericaBahia = "America/Bahia",
	AmericaBahiaBanderas = "America/Bahia_Banderas",
	AmericaBarbados = "America/Barbados",
	AmericaBelem = "America/Belem",
	AmericaBelize = "America/Belize",
	AmericaBlancSablon = "America/Blanc-Sablon",
	AmericaBoaVista = "America/Boa_Vista",
	AmericaBogota = "America/Bogota",
	AmericaBoise = "America/Boise",
	AmericaBuenosAires = "America/Buenos_Aires",
	AmericaCambridgeBay = "America/Cambridge_Bay",
	AmericaCampoGrande = "America/Campo_Grande",
	AmericaCancun = "America/Cancun",
	AmericaCaracas = "America/Caracas",
	AmericaCatamarca = "America/Catamarca",
	AmericaCayenne = "America/Cayenne",
	AmericaCayman = "America/Cayman",
	AmericaChicago = "America/Chicago",
	AmericaChihuahua = "America/Chihuahua",
	AmericaCiudadJuarez = "America/Ciudad_Juarez",
	AmericaCoralHarbour = "America/Coral_Harbour",
	AmericaCordoba = "America/Cordoba",
	AmericaCostaRica = "America/Costa_Rica",
	AmericaCreston = "America/Creston",
	AmericaCuiaba = "America/Cuiaba",
	AmericaCuracao = "America/Curacao",
	AmericaDanmarkshavn = "America/Danmarkshavn",
	AmericaDawson = "America/Dawson",
	AmericaDawsonCreek = "America/Dawson_Creek",
	AmericaDenver = "America/Denver",
	AmericaDetroit = "America/Detroit",
	AmericaDominica = "America/Dominica",
	AmericaEdmonton = "America/Edmonton",
	AmericaEirunepe = "America/Eirunepe",
	AmericaElSalvador = "America/El_Salvador",
	AmericaEnsenada = "America/Ensenada",
	AmericaFortNelson = "America/Fort_Nelson",
	AmericaFortWayne = "America/Fort_Wayne",
	AmericaFortaleza = "America/Fortaleza",
	AmericaGlaceBay = "America/Glace_Bay",
	AmericaGodthab = "America/Godthab",
	AmericaGooseBay = "America/Goose_Bay",
	AmericaGrandTurk = "America/Grand_Turk",
	AmericaGrenada = "America/Grenada",
	AmericaGuadeloupe = "America/Guadeloupe",
	AmericaGuatemala = "America/Guatemala",
	AmericaGuayaquil = "America/Guayaquil",
	AmericaGuyana = "America/Guyana",
	AmericaHalifax = "America/Halifax",
	AmericaHavana = "America/Havana",
	AmericaHermosillo = "America/Hermosillo",
	AmericaIndianaIndianapolis = "America/Indiana/Indianapolis",
	AmericaIndianaKnox = "America/Indiana/Knox",
	AmericaIndianaMarengo = "America/Indiana/Marengo",
	AmericaIndianaPetersburg = "America/Indiana/Petersburg",
	AmericaIndianaTellCity = "America/Indiana/Tell_City",
	AmericaIndianaVevay = "America/Indiana/Vevay",
	AmericaIndianaVincennes = "America/Indiana/Vincennes",
	AmericaIndianaWinamac = "America/Indiana/Winamac",
	AmericaIndianapolis = "America/Indianapolis",
	AmericaInuvik = "America/Inuvik",
	AmericaIqaluit = "America/Iqaluit",
	AmericaJamaica = "America/Jamaica",
	AmericaJujuy = "America/Jujuy",
	AmericaJuneau = "America/Juneau",
	AmericaKentuckyLouisville = "America/Kentucky/Louisville",
	AmericaKentuckyMonticello = "America/Kentucky/Monticello",
	AmericaKnoxIN = "America/Knox_IN",
	AmericaKralendijk = "America/Kralendijk",
	AmericaLaPaz = "America/La_Paz",
	AmericaLima = "America/Lima",
	AmericaLosAngeles = "America/Los_Angeles",
	AmericaLouisville = "America/Louisville",
	AmericaLowerPrinces = "America/Lower_Princes",
	AmericaMaceio = "America/Maceio",
	AmericaManagua = "America/Managua",
	AmericaManaus = "America/Manaus",
	AmericaMarigot = "America/Marigot",
	AmericaMartinique = "America/Martinique",
	AmericaMatamoros = "America/Matamoros",
	AmericaMazatlan = "America/Mazatlan",
	AmericaMendoza = "America/Mendoza",
	AmericaMenominee = "America/Menominee",
	AmericaMerida = "America/Merida",
	AmericaMetlakatla = "America/Metlakatla",
	AmericaMexicoCity = "America/Mexico_City",
	AmericaMiquelon = "America/Miquelon",
	AmericaMoncton = "America/Moncton",
	AmericaMonterrey = "America/Monterrey",
	AmericaMontevideo = "America/Montevideo",
	AmericaMontreal = "America/Montreal",
	AmericaMontserrat = "America/Montserrat",
	AmericaNassau = "America/Nassau",
	AmericaNewYork = "America/New_York",
	AmericaNipigon = "America/Nipigon",
	AmericaNome = "America/Nome",
	AmericaNoronha = "America/Noronha",
	AmericaNorthDakotaBeulah = "America/North_Dakota/Beulah",
	AmericaNorthDakotaCenter = "America/North_Dakota/Center",
	AmericaNorthDakotaNewSalem = "America/North_Dakota/New_Salem",
	AmericaNuuk = "America/Nuuk",
	AmericaOjinaga = "America/Ojinaga",
	AmericaPanama = "America/Panama",
	AmericaPangnirtung = "America/Pangnirtung",
	AmericaParamaribo = "America/Paramaribo",
	AmericaPhoenix = "America/Phoenix",
	AmericaPortAuPrince = "America/Port-au-Prince",
	AmericaPortOfSpain = "America/Port_of_Spain",
	AmericaPortoAcre = "America/Porto_Acre",
	AmericaPortoVelho = "America/Porto_Velho",
	AmericaPuertoRico = "America/Puerto_Rico",
	AmericaPuntaArenas = "America/Punta_Arenas",
	AmericaRainyRiver = "America/Rainy_River",
	AmericaRankinInlet = "America/Rankin_Inlet",
	AmericaRecife = "America/Recife",
	AmericaRegina = "America/Regina",
	AmericaResolute = "America/Resolute",
	AmericaRioBranco = "America/Rio_Branco",
	AmericaRosario = "America/Rosario",
	AmericaSantaIsabel = "America/Santa_Isabel",
	AmericaSantarem = "America/Santarem",
	AmericaSantiago = "America/Santiago",
	AmericaSantoDomingo = "America/Santo_Domingo",
	AmericaSaoPaulo = "America/Sao_Paulo",
	AmericaScoresbysund = "America/Scoresbysund",
	AmericaShiprock = "America/Shiprock",
	AmericaSitka = "America/Sitka",
	AmericaStBarthelemy = "America/St_Barthelemy",
	AmericaStJohns = "America/St_Johns",
	AmericaStKitts = "America/St_Kitts",
	AmericaStLucia = "America/St_Lucia",
	AmericaStThomas = "America/St_Thomas",
	AmericaStVincent = "America/St_Vincent",
	AmericaSwiftCurrent = "America/Swift_Current",
	AmericaTegucigalpa = "America/Tegucigalpa",
	AmericaThule = "America/Thule",
	AmericaThunderBay = "America/Thunder_Bay",
	AmericaTijuana = "America/Tijuana",
	AmericaToronto = "America/Toronto",
	AmericaTortola = "America/Tortola",
	AmericaVancouver = "America/Vancouver",
	AmericaVirgin = "America/Virgin",
	AmericaWhitehorse = "America/Whitehorse",
	AmericaWinnipeg = "America/Winnipeg",
	AmericaYakutat = "America/Yakutat",
	AmericaYellowknife = "America/Yellowknife",
	AntarcticaCasey = "Antarctica/Casey",
	AntarcticaDavis = "Antarctica/Davis",
	AntarcticaDumontDUrville = "Antarctica/DumontDUrville",
	AntarcticaMacquarie = "Antarctica/Macquarie",
	AntarcticaMawson = "Antarctica/Mawson",
	AntarcticaMcMurdo = "Antarctica/McMurdo",
	AntarcticaPalmer = "Antarctica/Palmer",
	AntarcticaRothera = "Antarctica/Rothera",
	AntarcticaSouthPole = "Antarctica/South_Pole",
	AntarcticaSyowa = "Antarctica/Syowa",
	AntarcticaTroll = "Antarctica/Troll",
	AntarcticaVostok = "Antarctica/Vostok",
	ArcticLongyearbyen = "Arctic/Longyearbyen",
	AsiaAden = "Asia/Aden",
	AsiaAlmaty = "Asia/Almaty",
	AsiaAmman = "Asia/Amman",
	AsiaAnadyr = "Asia/Anadyr",
	AsiaAqtau = "Asia/Aqtau",
	AsiaAqtobe = "Asia/Aqtobe",
	AsiaAshgabat = "Asia/Ashgabat",
	AsiaAshkhabad = "Asia/Ashkhabad",
	AsiaAtyrau = "Asia/Atyrau",
	AsiaBaghdad = "Asia/Baghdad",
	AsiaBahrain = "Asia/Bahrain",
	AsiaBaku = "Asia/Baku",
	AsiaBangkok = "Asia/Bangkok",
	AsiaBarnaul = "Asia/Barnaul",
	AsiaBeirut = "Asia/Beirut",
	AsiaBishkek = "Asia/Bishkek",
	AsiaBrunei = "Asia/Brunei",
	AsiaCalcutta = "Asia/Calcutta",
	AsiaChita = "Asia/Chita",
	AsiaChoibalsan = "Asia/Choibalsan",
	AsiaChongqing = "Asia/Chongqing",
	AsiaChungking = "Asia/Chungking",
	AsiaColombo = "Asia/Colombo",
	AsiaDacca = "Asia/Dacca",
	AsiaDamascus = "Asia/Damascus",
	AsiaDhaka = "Asia/Dhaka",
	AsiaDili = "Asia/Dili",
	AsiaDubai = "Asia/Dubai",
	AsiaDushanbe = "Asia/Dushanbe",
	AsiaFamagusta = "Asia/Famagusta",
	AsiaGaza = "Asia/Gaza",
	AsiaHarbin = "Asia/Harbin",
	AsiaHebron = "Asia/Hebron",
	AsiaHoChiMinh = "Asia/Ho_Chi_Minh",
	AsiaHongKong = "Asia/Hong_Kong",
	AsiaHovd = "Asia/Hovd",
	AsiaIrkutsk = "Asia/Irkutsk",
	AsiaIstanbul = "Asia/Istanbul",
	AsiaJakarta = "Asia/Jakarta",
	AsiaJayapura = "Asia/Jayapura",
	AsiaJerusalem = "Asia/Jerusalem",
	AsiaKabul = "Asia/Kabul",
	AsiaKamchatka = "Asia/Kamchatka",
	AsiaKarachi = "Asia/Karachi",
	AsiaKashgar = "Asia/Kashgar",
	AsiaKathmandu = "Asia/Kathmandu",
	AsiaKatmandu = "Asia/Katmandu",
	AsiaKhandyga = "Asia/Khandyga",
	AsiaKolkata = "Asia/Kolkata",
	AsiaKrasnoyarsk = "Asia/Krasnoyarsk",
	AsiaKualaLumpur = "Asia/Kuala_Lumpur",
	AsiaKuching = "Asia/Kuching",
	AsiaKuwait = "Asia/Kuwait",
	AsiaMacao = "Asia/Macao",
	AsiaMacau = "Asia/Macau",
	AsiaMagadan = "Asia/Magadan",
	AsiaMakassar = "Asia/Makassar",
	AsiaManila = "Asia/Manila",
	AsiaMuscat = "Asia/Muscat",
	AsiaNicosia = "Asia/Nicosia",
	AsiaNovokuznetsk = "Asia/Novokuznetsk",
	AsiaNovosibirsk = "Asia/Novosibirsk",
	AsiaOmsk = "Asia/Omsk",
	AsiaOral = "Asia/Oral",
	AsiaPhnomPenh = "Asia/Phnom_Penh",
	AsiaPontianak = "Asia/Pontianak",
	AsiaPyongyang = "Asia/Pyongyang",
	AsiaQatar = "Asia/Qatar",
	AsiaQostanay = "Asia/Qostanay",
	AsiaQyzylorda = "Asia/Qyzylorda",
	AsiaRangoon = "Asia/Rangoon",
	AsiaRiyadh = "Asia/Riyadh",
	AsiaSaigon = "Asia/Saigon",
	AsiaSakhalin = "Asia/Sakhalin",
	AsiaSamarkand = "Asia/Samarkand",
	AsiaSeoul = "Asia/Seoul",
	AsiaShanghai = "Asia/Shanghai",
	AsiaSingapore = "Asia/Singapore",
	AsiaSrednekolymsk = "Asia/Srednekolymsk",
	AsiaTaipei = "Asia/Taipei",
	AsiaTashkent = "Asia/Tashkent",
	AsiaTbilisi = "Asia/Tbilisi",
	AsiaTehran = "Asia/Tehran",
	AsiaTelAviv = "Asia/Tel_Aviv",
	AsiaThimbu = "Asia/Thimbu",
	AsiaThimphu = "Asia/Thimphu",
	AsiaTokyo = "Asia/Tokyo",
	AsiaTomsk = "Asia/Tomsk",
	AsiaUjungPandang = "Asia/Ujung_Pandang",
	AsiaUlaanbaatar = "Asia/Ulaanbaatar",
	AsiaUlanBator = "Asia/Ulan_Bator",
	AsiaUrumqi = "Asia/Urumqi",
	AsiaUstNera = "Asia/Ust-Nera",
	AsiaVientiane = "Asia/Vientiane",
	AsiaVladivostok = "Asia/Vladivostok",
	AsiaYakutsk = "Asia/Yakutsk",
	AsiaYangon = "Asia/Yangon",
	AsiaYekaterinburg = "Asia/Yekaterinburg",
	AsiaYerevan = "Asia/Yerevan",
	AtlanticAzores = "Atlantic/Azores",
	AtlanticBermuda = "Atlantic/Bermuda",
	AtlanticCanary = "Atlantic/Canary",
	AtlanticCapeVerde = "Atlantic/Cape_Verde",
	AtlanticFaeroe = "Atlantic/Faeroe",
	AtlanticFaroe = "Atlantic/Faroe",
	AtlanticJanMayen = "Atlantic/Jan_Mayen",
	AtlanticMadeira = "Atlantic/Madeira",
	AtlanticReykjavik = "Atlantic/Reykjavik",
	AtlanticSouthGeorgia = "Atlantic/South_Georgia",
	AtlanticStHelena = "Atlantic/St_Helena",
	AtlanticStanley = "Atlantic/Stanley",
	AustraliaACT = "Australia/ACT",
	AustraliaAdelaide = "Australia/Adelaide",
	AustraliaBrisbane = "Australia/Brisbane",
	AustraliaBrokenHill = "Australia/Broken_Hill",
	AustraliaCanberra = "Australia/Canberra",
	AustraliaCurrie = "Australia/Currie",
	AustraliaDarwin = "Australia/Darwin",
	AustraliaEucla = "Australia/Eucla",
	AustraliaHobart = "Australia/Hobart",
	AustraliaLHI = "Australia/LHI",
	AustraliaLindeman = "Australia/Lindeman",
	AustraliaLordHowe = "Australia/Lord_Howe",
	AustraliaMelbourne = "Australia/Melbourne",
	AustraliaNSW = "Australia/NSW",
	AustraliaNorth = "Australia/North",
	AustraliaPerth = "Australia/Perth",
	AustraliaQueensland = "Australia/Queensland",
	AustraliaSouth = "Australia/South",
	AustraliaSydney = "Australia/Sydney",
	AustraliaTasmania = "Australia/Tasmania",
	AustraliaVictoria = "Australia/Victoria",
	AustraliaWest = "Australia/West",
	AustraliaYancowinna = "Australia/Yancowinna",
	BrazilAcre = "Brazil/Acre",
	BrazilDeNoronha = "Brazil/DeNoronha",
	BrazilEast = "Brazil/East",
	BrazilWest = "Brazil/West",
	CET = "CET",
	CST6CDT = "CST6CDT",
	CanadaAtlantic = "Canada/Atlantic",
	CanadaCentral = "Canada/Central",
	CanadaEastern = "Canada/Eastern",
	CanadaMountain = "Canada/Mountain",
	CanadaNewfoundland = "Canada/Newfoundland",
	CanadaPacific = "Canada/Pacific",
	CanadaSaskatchewan = "Canada/Saskatchewan",
	CanadaYukon = "Canada/Yukon",
	ChileContinental = "Chile/Continental",
	ChileEasterIsland = "Chile/EasterIsland",
	Cuba = "Cuba",
	EET = "EET",
	EST = "EST",
	EST5EDT = "EST5EDT",
	Egypt = "Egypt",
	Eire = "Eire",
	EtcGMT = "Etc/GMT",
	EtcGMT0 = "Etc/GMT+0",
	EtcGMT1 = "Etc/GMT+1",
	EtcGMT10 = "Etc/GMT+10",
	EtcGMT11 = "Etc/GMT+11",
	EtcGMT12 = "Etc/GMT+12",
	EtcGMT2 = "Etc/GMT+2",
	EtcGMT3 = "Etc/GMT+3",
	EtcGMT4 = "Etc/GMT+4",
	EtcGMT5 = "Etc/GMT+5",
	EtcGMT6 = "Etc/GMT+6",
	EtcGMT7 = "Etc/GMT+7",
	EtcGMT8 = "Etc/GMT+8",
	EtcGMT9 = "Etc/GMT+9",
	EtcGMT01 = "Etc/GMT-0",
	EtcGMT13 = "Etc/GMT-1",
	EtcGMT104 = "Etc/GMT-10",
	EtcGMT115 = "Etc/GMT-11",
	EtcGMT126 = "Etc/GMT-12",
	EtcGMT137 = "Etc/GMT-13",
	EtcGMT14 = "Etc/GMT-14",
	EtcGMT28 = "Etc/GMT-2",
	EtcGMT39 = "Etc/GMT-3",
	EtcGMT410 = "Etc/GMT-4",
	EtcGMT511 = "Etc/GMT-5",
	EtcGMT612 = "Etc/GMT-6",
	EtcGMT713 = "Etc/GMT-7",
	EtcGMT814 = "Etc/GMT-8",
	EtcGMT915 = "Etc/GMT-9",
	EtcGMT016 = "Etc/GMT0",
	EtcGreenwich = "Etc/Greenwich",
	EtcUCT = "Etc/UCT",
	EtcUTC = "Etc/UTC",
	EtcUniversal = "Etc/Universal",
	EtcZulu = "Etc/Zulu",
	EuropeAmsterdam = "Europe/Amsterdam",
	EuropeAndorra = "Europe/Andorra",
	EuropeAstrakhan = "Europe/Astrakhan",
	EuropeAthens = "Europe/Athens",
	EuropeBelfast = "Europe/Belfast",
	EuropeBelgrade = "Europe/Belgrade",
	EuropeBerlin = "Europe/Berlin",
	EuropeBratislava = "Europe/Bratislava",
	EuropeBrussels = "Europe/Brussels",
	EuropeBucharest = "Europe/Bucharest",
	EuropeBudapest = "Europe/Budapest",
	EuropeBusingen = "Europe/Busingen",
	EuropeChisinau = "Europe/Chisinau",
	EuropeCopenhagen = "Europe/Copenhagen",
	EuropeDublin = "Europe/Dublin",
	EuropeGibraltar = "Europe/Gibraltar",
	EuropeGuernsey = "Europe/Guernsey",
	EuropeHelsinki = "Europe/Helsinki",
	EuropeIsleOfMan = "Europe/Isle_of_Man",
	EuropeIstanbul = "Europe/Istanbul",
	EuropeJersey = "Europe/Jersey",
	EuropeKaliningrad = "Europe/Kaliningrad",
	EuropeKiev = "Europe/Kiev",
	EuropeKirov = "Europe/Kirov",
	EuropeKyiv = "Europe/Kyiv",
	EuropeLisbon = "Europe/Lisbon",
	EuropeLjubljana = "Europe/Ljubljana",
	EuropeLondon = "Europe/London",
	EuropeLuxembourg = "Europe/Luxembourg",
	EuropeMadrid = "Europe/Madrid",
	EuropeMalta = "Europe/Malta",
	EuropeMariehamn = "Europe/Mariehamn",
	EuropeMinsk = "Europe/Minsk",
	EuropeMonaco = "Europe/Monaco",
	EuropeMoscow = "Europe/Moscow",
	EuropeNicosia = "Europe/Nicosia",
	EuropeOslo = "Europe/Oslo",
	EuropeParis = "Europe/Paris",
	EuropePodgorica = "Europe/Podgorica",
	EuropePrague = "Europe/Prague",
	EuropeRiga = "Europe/Riga",
	EuropeRome = "Europe/Rome",
	EuropeSamara = "Europe/Samara",
	EuropeSanMarino = "Europe/San_Marino",
	EuropeSarajevo = "Europe/Sarajevo",
	EuropeSaratov = "Europe/Saratov",
	EuropeSimferopol = "Europe/Simferopol",
	EuropeSkopje = "Europe/Skopje",
	EuropeSofia = "Europe/Sofia",
	EuropeStockholm = "Europe/Stockholm",
	EuropeTallinn = "Europe/Tallinn",
	EuropeTirane = "Europe/Tirane",
	EuropeTiraspol = "Europe/Tiraspol",
	EuropeUlyanovsk = "Europe/Ulyanovsk",
	EuropeUzhgorod = "Europe/Uzhgorod",
	EuropeVaduz = "Europe/Vaduz",
	EuropeVatican = "Europe/Vatican",
	EuropeVienna = "Europe/Vienna",
	EuropeVilnius = "Europe/Vilnius",
	EuropeVolgograd = "Europe/Volgograd",
	EuropeWarsaw = "Europe/Warsaw",
	EuropeZagreb = "Europe/Zagreb",
	EuropeZaporozhye = "Europe/Zaporozhye",
	EuropeZurich = "Europe/Zurich",
	GB = "GB",
	GBEire = "GB-Eire",
	GMT = "GMT",
	GMT0 = "GMT+0",
	GMT017 = "GMT-0",
	GMT018 = "GMT0",
	Greenwich = "Greenwich",
	HST = "HST",
	Hongkong = "Hongkong",
	Iceland = "Iceland",
	IndianAntananarivo = "Indian/Antananarivo",
	IndianChagos = "Indian/Chagos",
	IndianChristmas = "Indian/Christmas",
	IndianCocos = "Indian/Cocos",
	IndianComoro = "Indian/Comoro",
	IndianKerguelen = "Indian/Kerguelen",
	IndianMahe = "Indian/Mahe",
	IndianMaldives = "Indian/Maldives",
	IndianMauritius = "Indian/Mauritius",
	IndianMayotte = "Indian/Mayotte",
	IndianReunion = "Indian/Reunion",
	Iran = "Iran",
	Israel = "Israel",
	Jamaica = "Jamaica",
	Japan = "Japan",
	Kwajalein = "Kwajalein",
	Libya = "Libya",
	MET = "MET",
	MST = "MST",
	MST7MDT = "MST7MDT",
	MexicoBajaNorte = "Mexico/BajaNorte",
	MexicoBajaSur = "Mexico/BajaSur",
	MexicoGeneral = "Mexico/General",
	NZ = "NZ",
	NZCHAT = "NZ-CHAT",
	Navajo = "Navajo",
	PRC = "PRC",
	PST8PDT = "PST8PDT",
	PacificApia = "Pacific/Apia",
	PacificAuckland = "Pacific/Auckland",
	PacificBougainville = "Pacific/Bougainville",
	PacificChatham = "Pacific/Chatham",
	PacificChuuk = "Pacific/Chuuk",
	PacificEaster = "Pacific/Easter",
	PacificEfate = "Pacific/Efate",
	PacificEnderbury = "Pacific/Enderbury",
	PacificFakaofo = "Pacific/Fakaofo",
	PacificFiji = "Pacific/Fiji",
	PacificFunafuti = "Pacific/Funafuti",
	PacificGalapagos = "Pacific/Galapagos",
	PacificGambier = "Pacific/Gambier",
	PacificGuadalcanal = "Pacific/Guadalcanal",
	PacificGuam = "Pacific/Guam",
	PacificHonolulu = "Pacific/Honolulu",
	PacificJohnston = "Pacific/Johnston",
	PacificKanton = "Pacific/Kanton",
	PacificKiritimati = "Pacific/Kiritimati",
	PacificKosrae = "Pacific/Kosrae",
	PacificKwajalein = "Pacific/Kwajalein",
	PacificMajuro = "Pacific/Majuro",
	PacificMarquesas = "Pacific/Marquesas",
	PacificMidway = "Pacific/Midway",
	PacificNauru = "Pacific/Nauru",
	PacificNiue = "Pacific/Niue",
	PacificNorfolk = "Pacific/Norfolk",
	PacificNoumea = "Pacific/Noumea",
	PacificPagoPago = "Pacific/Pago_Pago",
	PacificPalau = "Pacific/Palau",
	PacificPitcairn = "Pacific/Pitcairn",
	PacificPohnpei = "Pacific/Pohnpei",
	PacificPonape = "Pacific/Ponape",
	PacificPortMoresby = "Pacific/Port_Moresby",
	PacificRarotonga = "Pacific/Rarotonga",
	PacificSaipan = "Pacific/Saipan",
	PacificSamoa = "Pacific/Samoa",
	PacificTahiti = "Pacific/Tahiti",
	PacificTarawa = "Pacific/Tarawa",
	PacificTongatapu = "Pacific/Tongatapu",
	PacificTruk = "Pacific/Truk",
	PacificWake = "Pacific/Wake",
	PacificWallis = "Pacific/Wallis",
	PacificYap = "Pacific/Yap",
	Poland = "Poland",
	Portugal = "Portugal",
	ROC = "ROC",
	ROK = "ROK",
	Singapore = "Singapore",
	Turkey = "Turkey",
	UCT = "UCT",
	USAlaska = "US/Alaska",
	USAleutian = "US/Aleutian",
	USArizona = "US/Arizona",
	USCentral = "US/Central",
	USEastIndiana = "US/East-Indiana",
	USEastern = "US/Eastern",
	USHawaii = "US/Hawaii",
	USIndianaStarke = "US/Indiana-Starke",
	USMichigan = "US/Michigan",
	USMountain = "US/Mountain",
	USPacific = "US/Pacific",
	USSamoa = "US/Samoa",
	UTC = "UTC",
	Universal = "Universal",
	WSU = "W-SU",
	WET = "WET",
	Zulu = "Zulu",
}

export interface TokenRefresh {
	access: string;
}

export interface TokenRefreshRequest {
	/** @minLength 1 */
	refresh: string;
}

export interface TokenVerifyRequest {
	/** @minLength 1 */
	token: string;
}

export interface UpdateAnswerScore {
	id: number;
	points: number;
}

export interface UpdateAnswerScoreRequest {
	id: number;
	points: number;
}

export interface UpdateCollaborators {
	collaborators?: (number | null)[];
}

export interface UpdateForMatching {
	id: number;
	/** @maxLength 400 */
	matching_answer: string;
}

export interface UpdateForMatchingRequest {
	id: number;
	/**
	 * @minLength 1
	 * @maxLength 400
	 */
	matching_answer: string;
}

export interface UpdateInterview {
	id: number;
	meeting_type: MeetingTypeEnum;
	/** @maxLength 400 */
	schedule_name?: string | null;
	slot_target?: SlotTargetEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	video_call_link?: string | null;
	/** @format date */
	date_schedule_exact?: string | null;
	/** @format time */
	start_time_exact?: string | null;
	/** @format time */
	end_time_exact?: string | null;
	location?: string | null;
	/** @maxLength 30 */
	phone_number?: string | null;
	/** @maxLength 300 */
	interview_pic?: string | null;
	message?: string | null;
	job_ad: number;
	/** @maxLength 200 */
	schedule_color?: string | null;
}

export interface UpdateInterviewScheduleType {
	id: number;
	interview_schedule_type?: InterviewScheduleTypeEnum | BlankEnum | NullEnum | null;
}

export interface UpdateMCQChoices {
	id: number;
	choices: string;
	is_answer: boolean;
}

export interface UpdateMCQChoicesRequest {
	id: number;
	/** @minLength 1 */
	choices: string;
	is_answer: boolean;
}

export interface UpdateMatchingQuestionKey {
	id: number;
	key?: string | null;
	/** @maxLength 400 */
	value?: string | null;
}

export interface UpdateMatchingQuestionKeyRequest {
	id: number;
	key?: string | null;
	/** @maxLength 400 */
	value?: string | null;
}

export interface UpdateNotification {
	id: number;
	push_notifications_enabled?: boolean;
	recieve_job_alert_emails?: boolean;
	recieve_commercial_emails?: boolean;
	recieve_opportunity_knocks_emails?: boolean;
	recieve_profile_update_emails?: boolean;
}

export interface UpdateQuestion {
	id: number;
	/**
	 * @min -2147483648
	 * @max 2147483647
	 */
	question_number: number;
	question_text?: string | null;
	question_type: QuestionType78BEnum;
	question_choices?: UpdateMCQChoices[];
	matching_questions_list?: UpdateMatchingQuestionKey[];
}

export interface UpdateTest {
	id: number;
	/** @maxLength 400 */
	quiz_title: string;
	job_ad?: number | null;
	distribution_time?: DistributionTimeEnum | BlankEnum | NullEnum | null;
	is_external_quiz?: boolean;
	/**
	 * @format uri
	 * @maxLength 200
	 */
	external_url?: string | null;
	is_time_limited?: boolean;
	/**
	 * in minutes
	 * @min -2147483648
	 * @max 2147483647
	 */
	time_limit?: number | null;
	instructions: string;
	is_mandatory?: boolean;
	/**
	 * @min 1
	 * @max 100
	 */
	min_passing_score: number;
	is_shuffled?: boolean;
	is_preload_test: boolean;
	topic_info?: (string | null)[];
}

export interface UpdateTestAnswer {
	id: number;
	text_answer?: string | null;
	fill_in_the_blank_answer?: (string | null)[];
	boolean_answer?: boolean | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	upload_answer?: string | null;
}

export enum UrgencyEnum {
	Value1To3Days = "1_to_3_days",
	Value3To7Days = "3_to_7_days",
	Value1To2Weeks = "1_to_2_weeks",
	Value2To4Weeks = "2_to_4_weeks",
	MoreThan4Weeks = "more_than_4_weeks",
}

export interface UserActivationRequest {
	/** @minLength 1 */
	user_type: string;
	/** @minLength 1 */
	user_id: string;
	timestamp: number;
	/** @minLength 1 */
	signature: string;
}

export interface UserCollaborators {
	id: number;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/**
	 * Required. 250 characters or fewer. Letters, digits and @/./+/-/_ only.
	 * @maxLength 250
	 * @pattern ^[\w.@+-]+$
	 */
	username: string;
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	user_type: UserTypeEnum;
	staff_user_permissions?: StaffUserPermissionsEnum[] | null;
}

export interface UserCompanyPosition {
	id: number;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/**
	 * Required. 250 characters or fewer. Letters, digits and @/./+/-/_ only.
	 * @maxLength 250
	 * @pattern ^[\w.@+-]+$
	 */
	username: string;
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
	/** @maxLength 400 */
	position?: string;
	/** @format uri */
	profile_picture?: string | null;
	company_name: string;
}

export interface UserFCMDevice {
	device_type?: DeviceTypeEnum | BlankEnum | NullEnum | null;
	device_token?: string | null;
}

export interface UserFCMDeviceRequest {
	device_type?: DeviceTypeEnum | BlankEnum | NullEnum | null;
	device_token?: string | null;
}

export interface UserProfile {
	id: number;
	first_name?: string;
	username?: string;
	company: string;
	/** @format email */
	email: string;
	is_active: boolean;
	date_joined: string;
	is_deactivated: boolean;
	user_type: string;
	staff_user_permissions: string[];
	/** @maxLength 300 */
	last_name?: string | null;
	/** @maxLength 100 */
	mobile_phone_number?: string | null;
	/** @maxLength 100 */
	mobile_phone_number_2?: string | null;
	country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	nationality?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	city?: string | null;
	street_address?: string | null;
	/** @maxLength 400 */
	postal_code?: string | null;
	/** @maxLength 400 */
	preferred_work_location?: string | null;
	/** @format uri */
	profile_picture?: string | null;
	/** @format date */
	date_of_birth?: string | null;
	gender?: GenderEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	is_initial_login?: boolean;
}

export interface UserProfileForCompanyAdmin {
	id: number;
	first_name?: string;
	username?: string;
	company: string;
	/** @format email */
	email: string;
	is_active: boolean;
	date_joined: string;
	is_deactivated: boolean;
	user_type: string;
	/** @maxLength 300 */
	last_name?: string | null;
	staff_user_permissions?: StaffUserPermissionsEnum[] | null;
	/** @maxLength 100 */
	mobile_phone_number?: string | null;
	/** @maxLength 100 */
	mobile_phone_number_2?: string | null;
	country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	nationality?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	city?: string | null;
	street_address?: string | null;
	/** @maxLength 400 */
	postal_code?: string | null;
	/** @maxLength 400 */
	preferred_work_location?: string | null;
	/** @format uri */
	profile_picture?: string | null;
	/** @format date */
	date_of_birth?: string | null;
	gender?: GenderEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
}

export interface UserProfileRequest {
	/** @minLength 1 */
	first_name?: string;
	/** @minLength 1 */
	username?: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/** @maxLength 100 */
	mobile_phone_number?: string | null;
	/** @maxLength 100 */
	mobile_phone_number_2?: string | null;
	country?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	nationality?: PostingLocationCountryEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	city?: string | null;
	street_address?: string | null;
	/** @maxLength 400 */
	postal_code?: string | null;
	/** @maxLength 400 */
	preferred_work_location?: string | null;
	/** @format binary */
	profile_picture?: File | null;
	/** @format date */
	date_of_birth?: string | null;
	gender?: GenderEnum | BlankEnum | NullEnum | null;
	/** @maxLength 400 */
	position?: string;
	/** @maxLength 400 */
	department?: string;
	experience_level?: ExperienceLevelEnum | BlankEnum | NullEnum | null;
	is_initial_login?: boolean;
}

export interface UserSlim {
	id: number;
	user_type: UserTypeEnum;
	/** @maxLength 300 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/**
	 * Email address
	 * @format email
	 * @maxLength 254
	 */
	email: string;
}

export interface UserSlimRequest {
	user_type: UserTypeEnum;
	/**
	 * @minLength 1
	 * @maxLength 300
	 */
	first_name: string;
	/** @maxLength 300 */
	last_name?: string | null;
	/**
	 * Email address
	 * @format email
	 * @minLength 1
	 * @maxLength 254
	 */
	email: string;
}

export enum UserTypeEnum {
	JobSeeker = "job_seeker",
	CompanyAdmin = "company_admin",
	CompanyStaff = "company_staff",
}

export interface VerificationCompany {
	verification_status: string;
	/** @format date-time */
	created_at: string;
	/** @format date-time */
	updated_at: string;
	messages: string;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	tax_card_image?: string | null;
	/**
	 * @format uri
	 * @pattern (?:jpeg|jpg|png|doc|docx|txt|rtf|pdf)$
	 */
	business_or_registration_license?: string | null;
}

export interface ViewCollaborators {
	company_member: UserCollaborators[];
	collaborators: UserCollaborators[];
	created_by: UserCollaborators;
}

export enum VisibilityEnum {
	Private = "private",
	Public = "public",
}

export interface Withdrawn {
	id: number;
	withdrawn_reason: string;
}

export interface WithdrawnJobs {
	id: number;
	job_ad: JobAdList;
	/** @format date-time */
	submitted_at?: string | null;
	/** @format date-time */
	withdrawn_at?: string | null;
}

export enum WorkModelEnum {
	Onsite = "onsite",
	Hybrid = "hybrid",
	Remote = "remote",
}

export enum WrittenProficiencyEnum {
	Beginner = "beginner",
	Intermediate = "intermediate",
	Fluent = "fluent",
	Expert = "expert",
	Native = "native",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: "same-origin",
		headers: {},
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(
			typeof value === "number" ? value : `${value}`,
		)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
		return keys
			.map((key) =>
				Array.isArray(query[key])
					? this.addArrayQueryParam(query, key)
					: this.addQueryParam(query, key),
			)
			.join("&");
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : "";
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === "object" || typeof input === "string")
				? JSON.stringify(input)
				: input,
		[ContentType.Text]: (input: any) =>
			input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === "object" && property !== null
						? JSON.stringify(property)
						: `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(
			`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
			{
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
				},
				signal:
					(cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) ||
					null,
				body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
			},
		).then(async (response) => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then((data) => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch((e) => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title Portal Kerja
 * @version 1.0.0
 *
 * DRF RESTful APIs Schema
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	api = {
		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags api
		 * @name ApiPushNotificationRetrieveUpdateDeleteUserFcmDeviceRetrieve
		 * @request GET:/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/{id}/
		 * @secure
		 */
		apiPushNotificationRetrieveUpdateDeleteUserFcmDeviceRetrieve: (
			id: number,
			params: RequestParams = {},
		) =>
			this.request<UserFCMDevice, any>({
				path: `/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/${id}/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags api
		 * @name ApiPushNotificationRetrieveUpdateDeleteUserFcmDeviceUpdate
		 * @request PUT:/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/{id}/
		 * @secure
		 */
		apiPushNotificationRetrieveUpdateDeleteUserFcmDeviceUpdate: (
			id: number,
			data: UserFCMDeviceRequest,
			params: RequestParams = {},
		) =>
			this.request<UserFCMDevice, any>({
				path: `/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/${id}/`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags api
		 * @name ApiPushNotificationRetrieveUpdateDeleteUserFcmDevicePartialUpdate
		 * @request PATCH:/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/{id}/
		 * @secure
		 */
		apiPushNotificationRetrieveUpdateDeleteUserFcmDevicePartialUpdate: (
			id: number,
			data: PatchedUserFCMDeviceRequest,
			params: RequestParams = {},
		) =>
			this.request<UserFCMDevice, any>({
				path: `/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/${id}/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags api
		 * @name ApiPushNotificationRetrieveUpdateDeleteUserFcmDeviceDestroy
		 * @request DELETE:/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/{id}/
		 * @secure
		 */
		apiPushNotificationRetrieveUpdateDeleteUserFcmDeviceDestroy: (
			id: number,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/api/push_notification/RetrieveUpdateDeleteUserFCMDevice/${id}/`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags api
		 * @name ApiPushNotificationCreateUserFcmDeviceCreate
		 * @request POST:/api/push_notification/createUserFCMDevice/
		 * @secure
		 */
		apiPushNotificationCreateUserFcmDeviceCreate: (
			data: UserFCMDeviceRequest,
			params: RequestParams = {},
		) =>
			this.request<UserFCMDevice, any>({
				path: `/api/push_notification/createUserFCMDevice/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	company = {
		/**
		 * @description Return paginated list of objects
		 *
		 * @tags company
		 * @name CompanyAllRetrieve
		 * @request GET:/company/all/
		 * @secure
		 */
		companyAllRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CompanyProfile, any>({
				path: `/company/all/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags company
		 * @name CompanyDeactivatePartialUpdate
		 * @request PATCH:/company/deactivate/
		 * @secure
		 */
		companyDeactivatePartialUpdate: (
			data: PatchedCompanyDeactivationRequest,
			params: RequestParams = {},
		) =>
			this.request<CompanyDeactivation, any>({
				path: `/company/deactivate/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags company
		 * @name CompanyGalleryRetrieve
		 * @request GET:/company/gallery/{id}
		 * @secure
		 */
		companyGalleryRetrieve: (
			id: string,
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CompanyMedia, any>({
				path: `/company/gallery/${id}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags company
		 * @name CompanyGalleryAddCreate
		 * @request POST:/company/gallery/add/
		 * @secure
		 */
		companyGalleryAddCreate: (data: CompanyMediaRequest, params: RequestParams = {}) =>
			this.request<CompanyMedia, any>({
				path: `/company/gallery/add/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags company
		 * @name CompanyGalleryEditUpdate
		 * @request PUT:/company/gallery/edit/{id}
		 * @secure
		 */
		companyGalleryEditUpdate: (
			id: string,
			data: CompanyMediaRequest,
			params: RequestParams = {},
		) =>
			this.request<CompanyMedia, any>({
				path: `/company/gallery/edit/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags company
		 * @name CompanyGalleryEditPartialUpdate
		 * @request PATCH:/company/gallery/edit/{id}
		 * @secure
		 */
		companyGalleryEditPartialUpdate: (
			id: string,
			data: PatchedCompanyMediaRequest,
			params: RequestParams = {},
		) =>
			this.request<CompanyMedia, any>({
				path: `/company/gallery/edit/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags company
		 * @name CompanyGalleryEditDestroy
		 * @request DELETE:/company/gallery/edit/{id}
		 * @secure
		 */
		companyGalleryEditDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/company/gallery/edit/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags company
		 * @name CompanyGetCompanyRetrieve
		 * @request GET:/company/get-company/{id}
		 * @secure
		 */
		companyGetCompanyRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<CompanyProfile, any>({
				path: `/company/get-company/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags company
		 * @name CompanyIndustryRetrieve
		 * @request GET:/company/industry/
		 * @secure
		 */
		companyIndustryRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CompanyIndustry, any>({
				path: `/company/industry/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags company
		 * @name CompanyMiniDetailRetrieve
		 * @request GET:/company/mini-detail/
		 * @secure
		 */
		companyMiniDetailRetrieve: (params: RequestParams = {}) =>
			this.request<CompanyAddress, any>({
				path: `/company/mini-detail/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags company
		 * @name CompanyUpdatePartialUpdate
		 * @request PATCH:/company/update/
		 * @secure
		 */
		companyUpdatePartialUpdate: (data: PatchedCompanyRequest, params: RequestParams = {}) =>
			this.request<Company, any>({
				path: `/company/update/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags company
		 * @name CompanyVerificationAddBusinessLicenseCreate
		 * @request POST:/company/verification/add-business-license/
		 * @secure
		 */
		companyVerificationAddBusinessLicenseCreate: (
			data: AddBusinessLicenseRequest,
			params: RequestParams = {},
		) =>
			this.request<AddBusinessLicense, any>({
				path: `/company/verification/add-business-license/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags company
		 * @name CompanyVerificationAddTaxCardCreate
		 * @request POST:/company/verification/add-tax-card/
		 * @secure
		 */
		companyVerificationAddTaxCardCreate: (
			data: AddTaxCardRequest,
			params: RequestParams = {},
		) =>
			this.request<AddTaxCard, any>({
				path: `/company/verification/add-tax-card/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags company
		 * @name CompanyVerificationGetRetrieve
		 * @request GET:/company/verification/get/
		 * @secure
		 */
		companyVerificationGetRetrieve: (params: RequestParams = {}) =>
			this.request<VerificationCompany, any>({
				path: `/company/verification/get/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags company
		 * @name CompanyVerificationUpdatePartialUpdate
		 * @request PATCH:/company/verification/update/
		 * @secure
		 */
		companyVerificationUpdatePartialUpdate: (
			data: PatchedVerificationCompanyRequest,
			params: RequestParams = {},
		) =>
			this.request<VerificationCompany, any>({
				path: `/company/verification/update/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	help = {
		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags help
		 * @name HelpFaqDetailRetrieve
		 * @request GET:/help/FAQ-detail/{id}
		 * @secure
		 */
		helpFaqDetailRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<HelpFAQwithAnswer, any>({
				path: `/help/FAQ-detail/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags help
		 * @name HelpFaqWithAnswerListRetrieve
		 * @request GET:/help/FAQ-with-answer-list/
		 * @secure
		 */
		helpFaqWithAnswerListRetrieve: (
			query?: {
				category_id?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<HelpFAQwithAnswer, any>({
				path: `/help/FAQ-with-answer-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags help
		 * @name HelpFaqWithoutAnswerListRetrieve
		 * @request GET:/help/FAQ-without-answer-list/
		 * @secure
		 */
		helpFaqWithoutAnswerListRetrieve: (
			query?: {
				category_id?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<HelpFAQwithoutAnswer, any>({
				path: `/help/FAQ-without-answer-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags help
		 * @name HelpCategoryListRetrieve
		 * @request GET:/help/category-list/
		 * @secure
		 */
		helpCategoryListRetrieve: (
			query?: {
				category_topic_FAQ_search?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<HelpCategory, any>({
				path: `/help/category-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags help
		 * @name HelpTopicDetailRetrieve
		 * @request GET:/help/topic-detail/{id}
		 * @secure
		 */
		helpTopicDetailRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<HelpTopic, any>({
				path: `/help/topic-detail/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags help
		 * @name HelpTopicListRetrieve
		 * @request GET:/help/topic-list/
		 * @secure
		 */
		helpTopicListRetrieve: (
			query?: {
				category_id?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<HelpTopic, any>({
				path: `/help/topic-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	jobAd = {
		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningAnswerRetrieve
		 * @request GET:/job_ad/RetrieveUpdatePreScreeningAnswer/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningAnswerRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<PreScreeningAnswer, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningAnswer/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningAnswerUpdate
		 * @request PUT:/job_ad/RetrieveUpdatePreScreeningAnswer/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningAnswerUpdate: (
			id: string,
			data: PreScreeningAnswerRequest,
			params: RequestParams = {},
		) =>
			this.request<PreScreeningAnswer, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningAnswer/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningAnswerPartialUpdate
		 * @request PATCH:/job_ad/RetrieveUpdatePreScreeningAnswer/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningAnswerPartialUpdate: (
			id: string,
			data: PatchedPreScreeningAnswerRequest,
			params: RequestParams = {},
		) =>
			this.request<PreScreeningAnswer, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningAnswer/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningAnswerDestroy
		 * @request DELETE:/job_ad/RetrieveUpdatePreScreeningAnswer/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningAnswerDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningAnswer/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningQuestionRetrieve
		 * @request GET:/job_ad/RetrieveUpdatePreScreeningQuestion/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningQuestionRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<PreScreeningQuestion, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningQuestion/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningQuestionUpdate
		 * @request PUT:/job_ad/RetrieveUpdatePreScreeningQuestion/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningQuestionUpdate: (
			id: string,
			data: PreScreeningQuestionRequest,
			params: RequestParams = {},
		) =>
			this.request<PreScreeningQuestion, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningQuestion/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningQuestionPartialUpdate
		 * @request PATCH:/job_ad/RetrieveUpdatePreScreeningQuestion/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningQuestionPartialUpdate: (
			id: string,
			data: PatchedPreScreeningQuestionRequest,
			params: RequestParams = {},
		) =>
			this.request<PreScreeningQuestion, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningQuestion/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRetrieveUpdatePreScreeningQuestionDestroy
		 * @request DELETE:/job_ad/RetrieveUpdatePreScreeningQuestion/{id}
		 * @secure
		 */
		jobAdRetrieveUpdatePreScreeningQuestionDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_ad/RetrieveUpdatePreScreeningQuestion/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_ad
		 * @name JobAdAddBookmarkCreate
		 * @request POST:/job_ad/add-bookmark/
		 * @secure
		 */
		jobAdAddBookmarkCreate: (data: JobBookmarkRequest, params: RequestParams = {}) =>
			this.request<JobBookmark, any>({
				path: `/job_ad/add-bookmark/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdAddCollaboratorsPartialUpdate
		 * @request PATCH:/job_ad/add-collaborators/{id}
		 * @secure
		 */
		jobAdAddCollaboratorsPartialUpdate: (
			id: string,
			data: PatchedAddCollaboratorsRequest,
			params: RequestParams = {},
		) =>
			this.request<AddCollaborators, any>({
				path: `/job_ad/add-collaborators/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdAddInterviewTypePartialUpdate
		 * @request PATCH:/job_ad/add-interview-type/{id}
		 * @secure
		 */
		jobAdAddInterviewTypePartialUpdate: (
			id: string,
			data: PatchedUpdateInterviewScheduleTypeRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateInterviewScheduleType, any>({
				path: `/job_ad/add-interview-type/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Bookmark tab API.
		 *
		 * @tags job_ad
		 * @name JobAdBookmarkListRetrieve
		 * @request GET:/job_ad/bookmark-list/
		 * @secure
		 */
		jobAdBookmarkListRetrieve: (
			query?: {
				keyword?: string;
				/** Filter Job ad based on job title or company name. */
				keywords?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Sort and ordering data, if you want to sort descending, put (-) prefix on the query param value, please check the option below. */
				sort_and_order_by?: "-company_name" | "-job_title" | "company_name" | "job_title";
			},
			params: RequestParams = {},
		) =>
			this.request<JobAdList, any>({
				path: `/job_ad/bookmark-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Re-Active expired job ads.
		 *
		 * @tags job_ad
		 * @name JobAdCarryOverPartialUpdate
		 * @request PATCH:/job_ad/carry-over/{id}
		 * @secure
		 */
		jobAdCarryOverPartialUpdate: (
			id: string,
			data: PatchedCarryOverJobAdRequest,
			params: RequestParams = {},
		) =>
			this.request<CarryOverJobAd, any>({
				path: `/job_ad/carry-over/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Compare one job ads with another. the salary has 4 type : * exact_amount: will be represent with salary_amount object * range_amount: will be represent with salary_starting_amount and salary_maximum_amount * starting_amount: which the salary minimum threshold that company willing to give and will be represent with salary_starting_amount only * maximum_amount: which the salary maximum threshold that company willing to give and will be represent with salary_maximum_amount only if the job ads display_salary = False then salary_amount, salary_starting_amount, and salary_maximum_amount will be null
		 *
		 * @tags job_ad
		 * @name JobAdCompareJobAdsRetrieve
		 * @request GET:/job_ad/compare-job-ads/
		 * @secure
		 */
		jobAdCompareJobAdsRetrieve: (
			query: {
				/** Filter Job ad based on job ads id */
				id: number[];
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<JobAdCompare, any>({
				path: `/job_ad/compare-job-ads/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_ad
		 * @name JobAdCopyPreloadedPrescreeningQuestionCreate
		 * @request POST:/job_ad/copy-preloaded-prescreening-question/
		 * @secure
		 */
		jobAdCopyPreloadedPrescreeningQuestionCreate: (
			data: PreScreeningQuestionCopyRequest[],
			params: RequestParams = {},
		) =>
			this.request<PreScreeningQuestionCopy, any>({
				path: `/job_ad/copy-preloaded-prescreening-question/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_ad
		 * @name JobAdCreateCreate
		 * @request POST:/job_ad/create/
		 * @secure
		 */
		jobAdCreateCreate: (data: JobAdCreateRequest, params: RequestParams = {}) =>
			this.request<JobAdCreate, any>({
				path: `/job_ad/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_ad
		 * @name JobAdCreatePrescreeningAnswerCreate
		 * @request POST:/job_ad/create-prescreening-answer/
		 * @secure
		 */
		jobAdCreatePrescreeningAnswerCreate: (
			data: PreScreeningAnswerRequest[],
			params: RequestParams = {},
		) =>
			this.request<PreScreeningAnswer, any>({
				path: `/job_ad/create-prescreening-answer/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_ad
		 * @name JobAdCreatePrescreeningQuestionCreate
		 * @request POST:/job_ad/create-prescreening-question/
		 * @secure
		 */
		jobAdCreatePrescreeningQuestionCreate: (
			data: PreScreeningQuestionRequest[],
			params: RequestParams = {},
		) =>
			this.request<PreScreeningQuestion, any>({
				path: `/job_ad/create-prescreening-question/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdDeactivatePartialUpdate
		 * @request PATCH:/job_ad/deactivate/{id}
		 * @secure
		 */
		jobAdDeactivatePartialUpdate: (
			id: string,
			data: PatchedDeactivateJobAdRequest,
			params: RequestParams = {},
		) =>
			this.request<DeactivateJobAd, any>({
				path: `/job_ad/deactivate/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdDeleteDestroy
		 * @request DELETE:/job_ad/delete/{id}
		 * @secure
		 */
		jobAdDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_ad/delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdGetRetrieve
		 * @request GET:/job_ad/get/{id}
		 * @secure
		 */
		jobAdGetRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<JobAd, any>({
				path: `/job_ad/get/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdGetShortDetailRetrieve
		 * @request GET:/job_ad/get/short-detail/{id}
		 * @secure
		 */
		jobAdGetShortDetailRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ShortDetailJobAd, any>({
				path: `/job_ad/get/short-detail/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdJobsBadgeCounterRetrieve
		 * @request GET:/job_ad/jobs/badge-counter/
		 * @secure
		 */
		jobAdJobsBadgeCounterRetrieve: (
			query?: {
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Ordering */
				sort_and_order_by?: (
					| "-company_name"
					| "-job_title"
					| "company_name"
					| "job_title"
				)[];
			},
			params: RequestParams = {},
		) =>
			this.request<MyJobsBadge, any>({
				path: `/job_ad/jobs/badge-counter/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdJobsPopularRetrieve
		 * @request GET:/job_ad/jobs/popular/
		 * @secure
		 */
		jobAdJobsPopularRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				use_distinctions?: boolean;
			},
			params: RequestParams = {},
		) =>
			this.request<JobAdList, any>({
				path: `/job_ad/jobs/popular/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdJobsTrendingRetrieve
		 * @request GET:/job_ad/jobs/trending/
		 * @secure
		 */
		jobAdJobsTrendingRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				use_distinctions?: boolean;
			},
			params: RequestParams = {},
		) =>
			this.request<JobAdList, any>({
				path: `/job_ad/jobs/trending/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdJobsTrendingSearchRetrieve
		 * @request GET:/job_ad/jobs/trending-search/
		 * @secure
		 */
		jobAdJobsTrendingSearchRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** A search term. */
				search?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<JobSearchKeyword, any>({
				path: `/job_ad/jobs/trending-search/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdListRetrieve
		 * @request GET:/job_ad/list/
		 * @secure
		 */
		jobAdListRetrieve: (
			query?: {
				experience_level?: (
					| "associate"
					| "director"
					| "entry_level"
					| "executive"
					| "intermediate"
					| "senior"
					| null
				)[];
				job_title?: string;
				job_type?: (
					| "contract"
					| "freelance"
					| "full_time"
					| "internship"
					| "part_time"
					| "temporary"
					| null
				)[];
				/** Ordering */
				order_by?: (
					| "-expired_at"
					| "-job_title"
					| "-published_at"
					| "expired_at"
					| "job_title"
					| "published_at"
				)[];
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				posting_location_city?: string;
				posting_location_country?: (
					| "AD"
					| "AE"
					| "AF"
					| "AG"
					| "AI"
					| "AL"
					| "AM"
					| "AN"
					| "AO"
					| "AQ"
					| "AR"
					| "AS"
					| "AT"
					| "AU"
					| "AW"
					| "AX"
					| "AZ"
					| "BA"
					| "BB"
					| "BD"
					| "BE"
					| "BF"
					| "BG"
					| "BH"
					| "BI"
					| "BJ"
					| "BL"
					| "BM"
					| "BN"
					| "BO"
					| "BQ"
					| "BR"
					| "BS"
					| "BT"
					| "BV"
					| "BW"
					| "BY"
					| "BZ"
					| "CA"
					| "CC"
					| "CD"
					| "CF"
					| "CG"
					| "CH"
					| "CI"
					| "CK"
					| "CL"
					| "CM"
					| "CN"
					| "CO"
					| "CR"
					| "CS"
					| "CU"
					| "CV"
					| "CW"
					| "CX"
					| "CY"
					| "CZ"
					| "DE"
					| "DJ"
					| "DK"
					| "DM"
					| "DO"
					| "DZ"
					| "EC"
					| "EE"
					| "EG"
					| "EH"
					| "ER"
					| "ES"
					| "ET"
					| "FI"
					| "FJ"
					| "FK"
					| "FM"
					| "FO"
					| "FR"
					| "GA"
					| "GB"
					| "GD"
					| "GE"
					| "GF"
					| "GG"
					| "GH"
					| "GI"
					| "GL"
					| "GM"
					| "GN"
					| "GP"
					| "GQ"
					| "GR"
					| "GS"
					| "GT"
					| "GU"
					| "GW"
					| "GY"
					| "HK"
					| "HM"
					| "HN"
					| "HR"
					| "HT"
					| "HU"
					| "ID"
					| "IE"
					| "IL"
					| "IM"
					| "IN"
					| "IO"
					| "IQ"
					| "IR"
					| "IS"
					| "IT"
					| "JE"
					| "JM"
					| "JO"
					| "JP"
					| "KE"
					| "KG"
					| "KH"
					| "KI"
					| "KM"
					| "KN"
					| "KP"
					| "KR"
					| "KW"
					| "KY"
					| "KZ"
					| "LA"
					| "LB"
					| "LC"
					| "LI"
					| "LK"
					| "LR"
					| "LS"
					| "LT"
					| "LU"
					| "LV"
					| "LY"
					| "MA"
					| "MC"
					| "MD"
					| "ME"
					| "MF"
					| "MG"
					| "MH"
					| "MK"
					| "ML"
					| "MM"
					| "MN"
					| "MO"
					| "MP"
					| "MQ"
					| "MR"
					| "MS"
					| "MT"
					| "MU"
					| "MV"
					| "MW"
					| "MX"
					| "MY"
					| "MZ"
					| "NA"
					| "NC"
					| "NE"
					| "NF"
					| "NG"
					| "NI"
					| "NL"
					| "NO"
					| "NP"
					| "NR"
					| "NU"
					| "NZ"
					| "OM"
					| "PA"
					| "PE"
					| "PF"
					| "PG"
					| "PH"
					| "PK"
					| "PL"
					| "PM"
					| "PN"
					| "PR"
					| "PS"
					| "PT"
					| "PW"
					| "PY"
					| "QA"
					| "RE"
					| "RO"
					| "RS"
					| "RU"
					| "RW"
					| "SA"
					| "SB"
					| "SC"
					| "SD"
					| "SE"
					| "SG"
					| "SH"
					| "SI"
					| "SJ"
					| "SK"
					| "SL"
					| "SM"
					| "SN"
					| "SO"
					| "SR"
					| "SS"
					| "ST"
					| "SV"
					| "SX"
					| "SY"
					| "SZ"
					| "TC"
					| "TD"
					| "TF"
					| "TG"
					| "TH"
					| "TJ"
					| "TK"
					| "TL"
					| "TM"
					| "TN"
					| "TO"
					| "TR"
					| "TT"
					| "TV"
					| "TW"
					| "TZ"
					| "UA"
					| "UG"
					| "UM"
					| "US"
					| "UY"
					| "UZ"
					| "VA"
					| "VC"
					| "VE"
					| "VG"
					| "VI"
					| "VN"
					| "VU"
					| "WF"
					| "WS"
					| "XK"
					| "YE"
					| "YT"
					| "ZA"
					| "ZM"
					| "ZW"
					| null
				)[];
				posting_location_district?: string;
				posting_location_other_location?: string;
				skill_tags?: string;
				status?: ("active" | "draft" | "inactive")[];
				work_model?: ("hybrid" | "onsite" | "remote" | null)[];
			},
			params: RequestParams = {},
		) =>
			this.request<JobAdListForCompany, any>({
				path: `/job_ad/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_ad
		 * @name JobAdListPreloadedPrescreeningQuestionRetrieve
		 * @request GET:/job_ad/list-preloaded-prescreening-question/
		 * @secure
		 */
		jobAdListPreloadedPrescreeningQuestionRetrieve: (params: RequestParams = {}) =>
			this.request<PreScreeningQuestion, any>({
				path: `/job_ad/list-preloaded-prescreening-question/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdListPrescreeningAnswerRetrieve
		 * @request GET:/job_ad/list-prescreening-answer/{job_ad_id}
		 * @secure
		 */
		jobAdListPrescreeningAnswerRetrieve: (
			jobAdId: string,
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PreScreeningList, any>({
				path: `/job_ad/list-prescreening-answer/${jobAdId}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdListPrescreeningQuestionRetrieve
		 * @request GET:/job_ad/list-prescreening-question/{job_ad_id}
		 * @secure
		 */
		jobAdListPrescreeningQuestionRetrieve: (
			jobAdId: string,
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PreScreeningQuestion, any>({
				path: `/job_ad/list-prescreening-question/${jobAdId}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdPublishPartialUpdate
		 * @request PATCH:/job_ad/publish/{id}
		 * @secure
		 */
		jobAdPublishPartialUpdate: (
			id: string,
			data: PatchedPublishJobAdRequest,
			params: RequestParams = {},
		) =>
			this.request<PublishJobAd, any>({
				path: `/job_ad/publish/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdPublishedGetRetrieve
		 * @request GET:/job_ad/published-get/{id}
		 * @secure
		 */
		jobAdPublishedGetRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<FetchJobAdJobSeeker, any>({
				path: `/job_ad/published-get/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_ad
		 * @name JobAdPublishedListRetrieve
		 * @request GET:/job_ad/published-list/
		 * @secure
		 */
		jobAdPublishedListRetrieve: (
			query?: {
				applicants_number_max?: string;
				applicants_number_min?: string;
				experience_level?: (
					| "associate"
					| "director"
					| "entry_level"
					| "executive"
					| "intermediate"
					| "senior"
					| null
				)[];
				job_type?: (
					| "contract"
					| "freelance"
					| "full_time"
					| "internship"
					| "part_time"
					| "temporary"
					| null
				)[];
				keywords?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				posting_location_city?: string;
				posting_timestamp?:
					| "any"
					| "last_14_days"
					| "last_24_hours"
					| "last_30_days"
					| "last_3_days"
					| "last_7_days";
				/**
				 * @min -2147483648
				 * @max 2147483647
				 */
				salary_amount_max?: number | null;
				/**
				 * @min -2147483648
				 * @max 2147483647
				 */
				salary_amount_min?: number | null;
				use_distinctions?: boolean;
				work_model?: ("hybrid" | "onsite" | "remote" | null)[];
			},
			params: RequestParams = {},
		) =>
			this.request<JobAdList, any>({
				path: `/job_ad/published-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdRemoveBookmarkDestroy
		 * @request DELETE:/job_ad/remove-bookmark/{job_ad_id}
		 * @secure
		 */
		jobAdRemoveBookmarkDestroy: (jobAdId: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_ad/remove-bookmark/${jobAdId}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_ad
		 * @name JobAdReportCreate
		 * @request POST:/job_ad/report/
		 * @secure
		 */
		jobAdReportCreate: (data: ReportJobAdRequest, params: RequestParams = {}) =>
			this.request<ReportJobAd, any>({
				path: `/job_ad/report/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdReportDeleteDestroy
		 * @request DELETE:/job_ad/report/delete/{id}
		 * @secure
		 */
		jobAdReportDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_ad/report/delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdReportGetRetrieve
		 * @request GET:/job_ad/report/get/{id}
		 * @secure
		 */
		jobAdReportGetRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ReportJobAd, any>({
				path: `/job_ad/report/get/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Get list of report.
		 *
		 * @tags job_ad
		 * @name JobAdReportListRetrieve
		 * @request GET:/job_ad/report/list/
		 * @secure
		 */
		jobAdReportListRetrieve: (
			query?: {
				/** Filter report job ads by job_ad_id */
				job_ad?: number;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ReportJobAd, any>({
				path: `/job_ad/report/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdReportUpdatePartialUpdate
		 * @request PATCH:/job_ad/report/update/{id}
		 * @secure
		 */
		jobAdReportUpdatePartialUpdate: (
			id: string,
			data: PatchedReportJobAdRequest,
			params: RequestParams = {},
		) =>
			this.request<ReportJobAd, any>({
				path: `/job_ad/report/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_ad
		 * @name JobAdUpdateBulkPrescreeningAnswerUpdate
		 * @request PUT:/job_ad/update-bulk-prescreening-answer/
		 * @secure
		 */
		jobAdUpdateBulkPrescreeningAnswerUpdate: (
			data: PreScreeningAnswerRequest[],
			params: RequestParams = {},
		) =>
			this.request<PreScreeningAnswer, any>({
				path: `/job_ad/update-bulk-prescreening-answer/`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdUpdateCollaboratorsPartialUpdate
		 * @request PATCH:/job_ad/update-collaborators/{id}
		 * @secure
		 */
		jobAdUpdateCollaboratorsPartialUpdate: (
			id: string,
			data: PatchedUpdateCollaboratorsRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateCollaborators, any>({
				path: `/job_ad/update-collaborators/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdUpdateUpdate
		 * @request PUT:/job_ad/update/{id}
		 * @secure
		 */
		jobAdUpdateUpdate: (id: string, data: JobAdUpdateRequest, params: RequestParams = {}) =>
			this.request<JobAdUpdate, any>({
				path: `/job_ad/update/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_ad
		 * @name JobAdUpdatePartialUpdate
		 * @request PATCH:/job_ad/update/{id}
		 * @secure
		 */
		jobAdUpdatePartialUpdate: (
			id: string,
			data: PatchedJobAdUpdateRequest,
			params: RequestParams = {},
		) =>
			this.request<JobAdUpdate, any>({
				path: `/job_ad/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_ad
		 * @name JobAdViewCollaboratorsRetrieve
		 * @request GET:/job_ad/view-collaborators/{id}
		 * @secure
		 */
		jobAdViewCollaboratorsRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ViewCollaborators, any>({
				path: `/job_ad/view-collaborators/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	jobApplication = {
		/**
		 * @description <b>11th Step</b><br><br>Add / update cover letter.<br><b>to remove the existing cover letter, just request the API without payload.<br>it will automatically remove existing cover letter in storage from previous request / session.</b><br><br>after this API was requested, application stage will saved as <b>'cover_letter'</b> stage
		 *
		 * @tags job_application
		 * @name JobApplicationAddCoverLetterPartialUpdate
		 * @request PATCH:/job_application/add-cover-letter/{id}
		 * @secure
		 */
		jobApplicationAddCoverLetterPartialUpdate: (
			id: string,
			data: PatchedCoverLetterRequest,
			params: RequestParams = {},
		) =>
			this.request<CoverLetter, any>({
				path: `/job_application/add-cover-letter/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationApplicationListRetrieve
		 * @request GET:/job_application/application-list/
		 * @secure
		 */
		jobApplicationApplicationListRetrieve: (
			query?: {
				job_ad_id?: string;
				job_title?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				stage?:
					| "answer_pre_screening"
					| "answer_test_question"
					| "confirm_resume"
					| "contact_detail"
					| "cover_letter"
					| "draft"
					| "pending"
					| "self_promote_note"
					| "submitted";
			},
			params: RequestParams = {},
		) =>
			this.request<JobApplication, any>({
				path: `/job_application/application-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationAppliedTabListRetrieve
		 * @request GET:/job_application/applied-tab-list/
		 * @secure
		 */
		jobApplicationAppliedTabListRetrieve: (
			query?: {
				/** @format date */
				date_range_after?: string;
				/** @format date */
				date_range_before?: string;
				filter?:
					| "hired"
					| "interview"
					| "offered"
					| "ongoing"
					| "pending"
					| "prospect"
					| "rejected"
					| "shortlist"
					| "viewed";
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Ordering */
				sort_and_order_by?: (
					| "-company_name"
					| "-date_applies"
					| "-job_title"
					| "company_name"
					| "date_applies"
					| "job_title"
				)[];
			},
			params: RequestParams = {},
		) =>
			this.request<AppliedJobs, any>({
				path: `/job_application/applied-tab-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationCancelJobApplicationDestroy
		 * @request DELETE:/job_application/cancel-job-application/{id}
		 * @secure
		 */
		jobApplicationCancelJobApplicationDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_application/cancel-job-application/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateHistoryStatusRetrieve
		 * @request GET:/job_application/candidate-history-status/
		 * @secure
		 */
		jobApplicationCandidateHistoryStatusRetrieve: (
			query: {
				job_application: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CandidateHistoryStatus, any>({
				path: `/job_application/candidate-history-status/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementApplicationHistoryRetrieve
		 * @request GET:/job_application/candidate-management/application-history/{user_id}
		 * @secure
		 */
		jobApplicationCandidateManagementApplicationHistoryRetrieve: (
			userId: string,
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CandidateApplicationHistory, any>({
				path: `/job_application/candidate-management/application-history/${userId}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementDetailApplicantRetrieve
		 * @request GET:/job_application/candidate-management/detail-applicant/{id}
		 * @secure
		 */
		jobApplicationCandidateManagementDetailApplicantRetrieve: (
			id: string,
			params: RequestParams = {},
		) =>
			this.request<JobApplicationForCompanyUser, any>({
				path: `/job_application/candidate-management/detail-applicant/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementEmployerAssistQuickMoveToUnfitPartialUpdate
		 * @request PATCH:/job_application/candidate-management/employer-assist/quick-move-to-unfit/{id}
		 * @secure
		 */
		jobApplicationCandidateManagementEmployerAssistQuickMoveToUnfitPartialUpdate: (
			id: string,
			params: RequestParams = {},
		) =>
			this.request<QuickAssist, any>({
				path: `/job_application/candidate-management/employer-assist/quick-move-to-unfit/${id}`,
				method: "PATCH",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementEmployerAssistQuickRejectionPartialUpdate
		 * @request PATCH:/job_application/candidate-management/employer-assist/quick-rejection/{id}
		 * @secure
		 */
		jobApplicationCandidateManagementEmployerAssistQuickRejectionPartialUpdate: (
			id: string,
			params: RequestParams = {},
		) =>
			this.request<QuickAssist, any>({
				path: `/job_application/candidate-management/employer-assist/quick-rejection/${id}`,
				method: "PATCH",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementListApplicantsSlimRetrieve
		 * @request GET:/job_application/candidate-management/list-applicants-slim/{job_ad_id}
		 * @secure
		 */
		jobApplicationCandidateManagementListApplicantsSlimRetrieve: (
			jobAdId: string,
			query?: {
				candidate_name?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<JobApplicantSlim, any>({
				path: `/job_application/candidate-management/list-applicants-slim/${jobAdId}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Show all applicants within job_ad_id
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementListApplicantsRetrieve
		 * @request GET:/job_application/candidate-management/list-applicants/{job_ad_id}
		 * @secure
		 */
		jobApplicationCandidateManagementListApplicantsRetrieve: (
			jobAdId: string,
			query?: {
				candidate_name?: string;
				candidate_status?: (
					| "hired"
					| "interview"
					| "offered"
					| "ongoing"
					| "prospect"
					| "rejected"
					| "shortlist"
				)[];
				job_poster_feedback?: "good_fit" | "perhaps" | "unfit" | "unrated";
				last_updated_resume?: 12 | 3 | 6 | 9;
				min_test_score?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Filter job ad applicants by question id and answer values */
				question_filter?: Record<string, any>;
				/**
				 * @min 0
				 * @max 2147483647
				 */
				range_salary_max?: number;
				/**
				 * @min 0
				 * @max 2147483647
				 */
				range_salary_min?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ListApplicantsForCompany, any>({
				path: `/job_application/candidate-management/list-applicants/${jobAdId}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementMarkStatusCreate
		 * @request POST:/job_application/candidate-management/mark-status/
		 * @secure
		 */
		jobApplicationCandidateManagementMarkStatusCreate: (
			data: CandidateStatusOnJobApplicationCreateRequest[],
			params: RequestParams = {},
		) =>
			this.request<CandidateStatusOnJobApplicationCreate, any>({
				path: `/job_application/candidate-management/mark-status/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementSendCandidateLinkCreate
		 * @request POST:/job_application/candidate-management/send-candidate-link/
		 * @secure
		 */
		jobApplicationCandidateManagementSendCandidateLinkCreate: (
			data: SendCandidateLinkRequest,
			params: RequestParams = {},
		) =>
			this.request<SendCandidateLink, any>({
				path: `/job_application/candidate-management/send-candidate-link/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementSetBulkCandidateCategoryCreate
		 * @request POST:/job_application/candidate-management/set-bulk-candidate-category/
		 * @secure
		 */
		jobApplicationCandidateManagementSetBulkCandidateCategoryCreate: (
			data: SetCandidateCategoryBulkRequest[],
			params: RequestParams = {},
		) =>
			this.request<SetCandidateCategoryBulk, any>({
				path: `/job_application/candidate-management/set-bulk-candidate-category/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationCandidateManagementSetCandidateCategoryPartialUpdate
		 * @request PATCH:/job_application/candidate-management/set-candidate-category/{id}
		 * @secure
		 */
		jobApplicationCandidateManagementSetCandidateCategoryPartialUpdate: (
			id: string,
			data: PatchedSetCandidateCategoryRequest,
			params: RequestParams = {},
		) =>
			this.request<SetCandidateCategory, any>({
				path: `/job_application/candidate-management/set-candidate-category/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationCommentsCreateCreate
		 * @request POST:/job_application/comments/create/
		 * @secure
		 */
		jobApplicationCommentsCreateCreate: (
			data: JobApplicationCommentRequest,
			params: RequestParams = {},
		) =>
			this.request<JobApplicationComment, any>({
				path: `/job_application/comments/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationCommentsDeleteDestroy
		 * @request DELETE:/job_application/comments/delete/{id}
		 * @secure
		 */
		jobApplicationCommentsDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_application/comments/delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationCommentsListRetrieve
		 * @request GET:/job_application/comments/list/
		 * @secure
		 */
		jobApplicationCommentsListRetrieve: (
			query?: {
				job_application_id?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				user_id?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<JobApplicationComment, any>({
				path: `/job_application/comments/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_application
		 * @name JobApplicationCommentsRetrieveRetrieve
		 * @request GET:/job_application/comments/retrieve/{id}
		 * @secure
		 */
		jobApplicationCommentsRetrieveRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<JobApplicationComment, any>({
				path: `/job_application/comments/retrieve/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationCommentsUpdatePartialUpdate
		 * @request PATCH:/job_application/comments/update/{id}
		 * @secure
		 */
		jobApplicationCommentsUpdatePartialUpdate: (
			id: string,
			data: PatchedJobApplicationCommentRequest,
			params: RequestParams = {},
		) =>
			this.request<JobApplicationComment, any>({
				path: `/job_application/comments/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>5th Step</b><br><br>Add / update existing resume file with a new one.<br><br>after this API was requested, application stage will saved as <b>'confirm_resume'</b> stage
		 *
		 * @tags job_application
		 * @name JobApplicationConfirmResumeFilePartialUpdate
		 * @request PATCH:/job_application/confirm-resume-file/{id}
		 * @secure
		 */
		jobApplicationConfirmResumeFilePartialUpdate: (
			id: string,
			data: PatchedConfirmResumeRequest,
			params: RequestParams = {},
		) =>
			this.request<ConfirmResume, any>({
				path: `/job_application/confirm-resume-file/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>3rd Step</b><br><br>Add / update existing contact details with a new one.<br><br>after this API was requested, application stage will saved as <b>'contact_detail'</b> stage
		 *
		 * @tags job_application
		 * @name JobApplicationContactDetailPartialUpdate
		 * @request PATCH:/job_application/contact-detail/{id}
		 * @secure
		 */
		jobApplicationContactDetailPartialUpdate: (
			id: string,
			data: PatchedContactDetailRequest,
			params: RequestParams = {},
		) =>
			this.request<ContactDetail, any>({
				path: `/job_application/contact-detail/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>1st Step</b><br>Initialize the job application form with <b>user id</b> and <b>job ads id</b> as payload.<br><br>after this API was requested, application stage will saved as <b>'draft'</b> stage
		 *
		 * @tags job_application
		 * @name JobApplicationCreateJobApplicationCreate
		 * @request POST:/job_application/create-job-application/
		 * @secure
		 */
		jobApplicationCreateJobApplicationCreate: (
			data: CreateJobApplicationRequest,
			params: RequestParams = {},
		) =>
			this.request<CreateJobApplication, any>({
				path: `/job_application/create-job-application/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewTabListRetrieve
		 * @request GET:/job_application/interview-tab-list/
		 * @secure
		 */
		jobApplicationInterviewTabListRetrieve: (
			query?: {
				/** @format date */
				date_range_after?: string;
				/** @format date */
				date_range_before?: string;
				interview_status?:
					| "accepted"
					| "cancelled"
					| "declined"
					| "pending"
					| "purposed_new_time";
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Ordering */
				sort_and_order_by?: (
					| "-company_name"
					| "-date_applies"
					| "-date_withdrawn"
					| "-job_title"
					| "company_name"
					| "date_applies"
					| "date_withdrawn"
					| "job_title"
				)[];
			},
			params: RequestParams = {},
		) =>
			this.request<JobInterviewTabList, any>({
				path: `/job_application/interview-tab-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewDeleteDestroy
		 * @request DELETE:/job_application/interview/delete/{id}
		 * @secure
		 */
		jobApplicationInterviewDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_application/interview/delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewExactAcceptCreate
		 * @request POST:/job_application/interview/exact/accept/
		 * @secure
		 */
		jobApplicationInterviewExactAcceptCreate: (
			data: AcceptExactTimeRequest,
			params: RequestParams = {},
		) =>
			this.request<AcceptExactTime, any>({
				path: `/job_application/interview/exact/accept/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewExactCreateCreate
		 * @request POST:/job_application/interview/exact/create/
		 * @secure
		 */
		jobApplicationInterviewExactCreateCreate: (
			data: InterviewRequest,
			params: RequestParams = {},
		) =>
			this.request<Interview, any>({
				path: `/job_application/interview/exact/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewExactProposeNewTimeCreate
		 * @request POST:/job_application/interview/exact/propose-new-time/
		 * @secure
		 */
		jobApplicationInterviewExactProposeNewTimeCreate: (
			data: ProposedNewTimeRequest,
			params: RequestParams = {},
		) =>
			this.request<ProposedNewTime, any>({
				path: `/job_application/interview/exact/propose-new-time/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewJobPosterCancelPartialUpdate
		 * @request PATCH:/job_application/interview/job-poster/cancel/{id}
		 * @secure
		 */
		jobApplicationInterviewJobPosterCancelPartialUpdate: (
			id: string,
			data: PatchedCancelInterviewBulkRequest,
			params: RequestParams = {},
		) =>
			this.request<CancelInterviewBulk, any>({
				path: `/job_application/interview/job-poster/cancel/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewJobSeekerCancelPartialUpdate
		 * @request PATCH:/job_application/interview/job-seeker/cancel/{id}
		 * @secure
		 */
		jobApplicationInterviewJobSeekerCancelPartialUpdate: (
			id: string,
			data: PatchedCancelInterviewJobSeekerRequest,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/job_application/interview/job-seeker/cancel/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewJobSeekerDeclinePartialUpdate
		 * @request PATCH:/job_application/interview/job-seeker/decline/{id}
		 * @secure
		 */
		jobApplicationInterviewJobSeekerDeclinePartialUpdate: (
			id: string,
			data: PatchedDeclineInterviewJobSeekerRequest,
			params: RequestParams = {},
		) =>
			this.request<DeclineInterviewJobSeeker, any>({
				path: `/job_application/interview/job-seeker/decline/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewJobSeekerRetrieveRetrieve
		 * @request GET:/job_application/interview/job-seeker/retrieve/{id}
		 * @secure
		 */
		jobApplicationInterviewJobSeekerRetrieveRetrieve: (
			id: string,
			params: RequestParams = {},
		) =>
			this.request<InterviewFetchForJobSeeker, any>({
				path: `/job_application/interview/job-seeker/retrieve/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewListRetrieve
		 * @request GET:/job_application/interview/list/
		 * @secure
		 */
		jobApplicationInterviewListRetrieve: (
			query?: {
				category?: "past" | "upcoming";
				/** @format date */
				date_range_after?: string;
				/** @format date */
				date_range_before?: string;
				exact_date?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<Interview, any>({
				path: `/job_application/interview/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewRetrieveRetrieve
		 * @request GET:/job_application/interview/retrieve/{id}
		 * @secure
		 */
		jobApplicationInterviewRetrieveRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<InterviewFetch, any>({
				path: `/job_application/interview/retrieve/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewScheduleRetrieve
		 * @request GET:/job_application/interview/schedule/
		 * @secure
		 */
		jobApplicationInterviewScheduleRetrieve: (
			query?: {
				category?: "past" | "upcoming";
				/** @format date */
				date_range_after?: string;
				/** @format date */
				date_range_before?: string;
				exact_date?: string;
				interview_status?: "accepted" | "declined" | "pending";
				job_ad?: number;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<InterviewSchedule, any>({
				path: `/job_application/interview/schedule/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotAcceptCreate
		 * @request POST:/job_application/interview/slot/accept/
		 * @secure
		 */
		jobApplicationInterviewSlotAcceptCreate: (
			data: PickTimeSlotRequest,
			params: RequestParams = {},
		) =>
			this.request<PickTimeSlot, any>({
				path: `/job_application/interview/slot/accept/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotAddSlotsCreate
		 * @request POST:/job_application/interview/slot/add-slots/
		 * @secure
		 */
		jobApplicationInterviewSlotAddSlotsCreate: (
			data: InterviewSlotRequest[],
			params: RequestParams = {},
		) =>
			this.request<InterviewSlot, any>({
				path: `/job_application/interview/slot/add-slots/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotCreateCreate
		 * @request POST:/job_application/interview/slot/create/
		 * @secure
		 */
		jobApplicationInterviewSlotCreateCreate: (
			data: InterviewAndSlotCreateRequest,
			params: RequestParams = {},
		) =>
			this.request<InterviewAndSlotCreate, any>({
				path: `/job_application/interview/slot/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotListRetrieve
		 * @request GET:/job_application/interview/slot/list/{id}
		 * @secure
		 */
		jobApplicationInterviewSlotListRetrieve: (
			id: string,
			query?: {
				category?: "past" | "upcoming";
				/** @format date */
				date_range_after?: string;
				/** @format date */
				date_range_before?: string;
				exact_date?: string;
				is_available?: boolean;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<InterviewSlot, any>({
				path: `/job_application/interview/slot/list/${id}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotListJobSeekerRetrieve
		 * @request GET:/job_application/interview/slot/list/job-seeker/{interview_id}
		 * @secure
		 */
		jobApplicationInterviewSlotListJobSeekerRetrieve: (
			interviewId: string,
			query?: {
				category?: "past" | "upcoming";
				/** @format date */
				date_range_after?: string;
				/** @format date */
				date_range_before?: string;
				exact_date?: string;
				is_available?: boolean;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<InterviewSlot, any>({
				path: `/job_application/interview/slot/list/job-seeker/${interviewId}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotReschedulePartialUpdate
		 * @request PATCH:/job_application/interview/slot/reschedule/{id}
		 * @secure
		 */
		jobApplicationInterviewSlotReschedulePartialUpdate: (
			id: string,
			data: PatchedRescheduleInterviewRequest,
			params: RequestParams = {},
		) =>
			this.request<RescheduleInterview, any>({
				path: `/job_application/interview/slot/reschedule/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewSlotRescheduleJobSeekerCreate
		 * @request POST:/job_application/interview/slot/reschedule/job-seeker/
		 * @secure
		 */
		jobApplicationInterviewSlotRescheduleJobSeekerCreate: (
			data: RescheduleInterviewByJobSeekerRequest,
			params: RequestParams = {},
		) =>
			this.request<RescheduleInterviewByJobSeeker, any>({
				path: `/job_application/interview/slot/reschedule/job-seeker/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_application
		 * @name JobApplicationInterviewUpdatePartialUpdate
		 * @request PATCH:/job_application/interview/update/{id}
		 * @secure
		 */
		jobApplicationInterviewUpdatePartialUpdate: (
			id: string,
			data: PatchedUpdateInterviewRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateInterview, any>({
				path: `/job_application/interview/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_application
		 * @name JobApplicationOfferingLetterGetPlaceholderRetrieve
		 * @request GET:/job_application/offering-letter/get-placeholder/{job_application_id}
		 * @secure
		 */
		jobApplicationOfferingLetterGetPlaceholderRetrieve: (
			jobApplicationId: string,
			params: RequestParams = {},
		) =>
			this.request<OfferingLetterPlaceholder, any>({
				path: `/job_application/offering-letter/get-placeholder/${jobApplicationId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Download offering letter as PDF file in base64 type.
		 *
		 * @tags job_application
		 * @name JobApplicationOfferingLetterPreviewCreate
		 * @request POST:/job_application/offering-letter/preview/
		 * @secure
		 */
		jobApplicationOfferingLetterPreviewCreate: (
			query: {
				/**
				 * Response type can be json/base64 or file/stream. Default is json/base64
				 * @default "json/base64"
				 */
				response_type: "file/stream" | "json/base64" | "text/html";
			},
			data: DownloadOfferLetterRequest,
			params: RequestParams = {},
		) =>
			this.request<DownloadOfferLetter, any>({
				path: `/job_application/offering-letter/preview/`,
				method: "POST",
				query: query,
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_application
		 * @name JobApplicationOfferingLetterSendCreate
		 * @request POST:/job_application/offering-letter/send/
		 * @secure
		 */
		jobApplicationOfferingLetterSendCreate: (
			data: OfferingLetterCreateRequest,
			params: RequestParams = {},
		) =>
			this.request<OfferingLetterCreate, any>({
				path: `/job_application/offering-letter/send/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>4th Step</b><br><br>Retrieve existing resume file.
		 *
		 * @tags job_application
		 * @name JobApplicationRetrieveConfirmResumeFileRetrieve
		 * @request GET:/job_application/retrieve-confirm-resume-file/{id}
		 * @secure
		 */
		jobApplicationRetrieveConfirmResumeFileRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ConfirmResume, any>({
				path: `/job_application/retrieve-confirm-resume-file/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>2nd Step</b><br><br>Retrieve existing contact details.
		 *
		 * @tags job_application
		 * @name JobApplicationRetrieveContactDetailRetrieve
		 * @request GET:/job_application/retrieve-contact-detail/{id}
		 * @secure
		 */
		jobApplicationRetrieveContactDetailRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ContactDetail, any>({
				path: `/job_application/retrieve-contact-detail/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>10th Step</b><br><br>Retrieve existing cover letter.
		 *
		 * @tags job_application
		 * @name JobApplicationRetrieveCoverLetterRetrieve
		 * @request GET:/job_application/retrieve-cover-letter/{id}
		 * @secure
		 */
		jobApplicationRetrieveCoverLetterRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<CoverLetter, any>({
				path: `/job_application/retrieve-cover-letter/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>6th Step</b><br><br>Retrieve existing promotion notes.
		 *
		 * @tags job_application
		 * @name JobApplicationRetrieveSelfPromotionRetrieve
		 * @request GET:/job_application/retrieve-self-promotion/{id}
		 * @secure
		 */
		jobApplicationRetrieveSelfPromotionRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<SelfPromotion, any>({
				path: `/job_application/retrieve-self-promotion/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>12th Step</b><br><br>Retrieve data for review summary application data.
		 *
		 * @tags job_application
		 * @name JobApplicationReviewApplicationRetrieve
		 * @request GET:/job_application/review-application/{id}
		 * @secure
		 */
		jobApplicationReviewApplicationRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<JobApplication, any>({
				path: `/job_application/review-application/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>9th Step</b><br><br>Add / update self promotion notes.<br><br>after this API was requested, application stage will saved as <b>'self_promote_note'</b> stage
		 *
		 * @tags job_application
		 * @name JobApplicationSelfPromotionPartialUpdate
		 * @request PATCH:/job_application/self-promotion/{id}
		 * @secure
		 */
		jobApplicationSelfPromotionPartialUpdate: (
			id: string,
			data: PatchedSelfPromotionRequest,
			params: RequestParams = {},
		) =>
			this.request<SelfPromotion, any>({
				path: `/job_application/self-promotion/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description <b>13th Step</b><br><br>Submit the application.<br><br>after this API was requested, application stage will saved as <b>'submitted'</b> stage
		 *
		 * @tags job_application
		 * @name JobApplicationSubmitApplicationPartialUpdate
		 * @request PATCH:/job_application/submit-application/{id}
		 * @secure
		 */
		jobApplicationSubmitApplicationPartialUpdate: (
			id: string,
			data: PatchedSubmitRequest,
			params: RequestParams = {},
		) =>
			this.request<Submit, any>({
				path: `/job_application/submit-application/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_application
		 * @name JobApplicationTestTabListRetrieve
		 * @request GET:/job_application/test-tab-list/
		 * @secure
		 */
		jobApplicationTestTabListRetrieve: (
			query?: {
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Ordering */
				sort_and_order_by?: (
					| "-company_name"
					| "-job_title"
					| "company_name"
					| "job_title"
				)[];
			},
			params: RequestParams = {},
		) =>
			this.request<JobTestList, any>({
				path: `/job_application/test-tab-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Withdrawn job application that have been submitted.
		 *
		 * @tags job_application
		 * @name JobApplicationWithdrawnApplicationPartialUpdate
		 * @request PATCH:/job_application/withdrawn-application/{id}
		 * @secure
		 */
		jobApplicationWithdrawnApplicationPartialUpdate: (
			id: string,
			data: PatchedWithdrawnRequest,
			params: RequestParams = {},
		) =>
			this.request<Withdrawn, any>({
				path: `/job_application/withdrawn-application/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Withdrawn tab API, to show application that has been withdrawn.
		 *
		 * @tags job_application
		 * @name JobApplicationWithdrawnTabListRetrieve
		 * @request GET:/job_application/withdrawn-tab-list/
		 * @secure
		 */
		jobApplicationWithdrawnTabListRetrieve: (
			query?: {
				/**
				 * Filter applied job by applied date after
				 * @format date
				 */
				date_range_after?: string;
				/**
				 * Filter applied job by applied date before
				 * @format date
				 */
				date_range_before?: string;
				/** Filter applied job application by keyword of job title or company name */
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Sorting and ordering based on choices. for sort by desc, you should add (-) prefix before the choices. */
				sort_and_order_by?:
					| "-company_name"
					| "-date_applies"
					| "-date_withdrawn"
					| "-job_title"
					| "company_name"
					| "date_applies"
					| "date_withdrawn"
					| "job_title";
			},
			params: RequestParams = {},
		) =>
			this.request<WithdrawnJobs, any>({
				path: `/job_application/withdrawn-tab-list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	jobTest = {
		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_test
		 * @name JobTestAnswerAssesmentCreateCreate
		 * @request POST:/job_test/answer/assesment/create/
		 * @secure
		 */
		jobTestAnswerAssesmentCreateCreate: (
			data: InputTestAnswerRequest,
			params: RequestParams = {},
		) =>
			this.request<InputTestAnswer, any>({
				path: `/job_test/answer/assesment/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestAnswerAssesmentRetrieveRetrieve
		 * @request GET:/job_test/answer/assesment/retrieve/{id}
		 * @secure
		 */
		jobTestAnswerAssesmentRetrieveRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<TestAnswer, any>({
				path: `/job_test/answer/assesment/retrieve/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_test
		 * @name JobTestAnswerAssesmentUpdatePartialUpdate
		 * @request PATCH:/job_test/answer/assesment/update/{id}
		 * @secure
		 */
		jobTestAnswerAssesmentUpdatePartialUpdate: (
			id: string,
			data: PatchedUpdateTestAnswerRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateTestAnswer, any>({
				path: `/job_test/answer/assesment/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job_test
		 * @name JobTestAnswerAssesmentUpdateScoreUpdate
		 * @request PUT:/job_test/answer/assesment/update/score/
		 * @secure
		 */
		jobTestAnswerAssesmentUpdateScoreUpdate: (
			data: UpdateAnswerScoreRequest[],
			params: RequestParams = {},
		) =>
			this.request<UpdateAnswerScore, any>({
				path: `/job_test/answer/assesment/update/score/`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestAnswerListSubmittedJobPosterRetrieve
		 * @request GET:/job_test/answer/list/submitted/job-poster/{id}
		 * @secure
		 */
		jobTestAnswerListSubmittedJobPosterRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<TestStatusByJobPoster, any>({
				path: `/job_test/answer/list/submitted/job-poster/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestAnswerRetrieveSubmittedJobPosterRetrieve
		 * @request GET:/job_test/answer/retrieve/submitted/job-poster/{id}
		 * @secure
		 */
		jobTestAnswerRetrieveSubmittedJobPosterRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<RetrieveAnswerPackageForJobPoster, any>({
				path: `/job_test/answer/retrieve/submitted/job-poster/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestAnswerRetrieveSubmittedJobSeekerRetrieve
		 * @request GET:/job_test/answer/retrieve/submitted/job-seeker/{id}
		 * @secure
		 */
		jobTestAnswerRetrieveSubmittedJobSeekerRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<AnswerPackage, any>({
				path: `/job_test/answer/retrieve/submitted/job-seeker/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_test
		 * @name JobTestAnswerStartCreate
		 * @request POST:/job_test/answer/start/
		 * @secure
		 */
		jobTestAnswerStartCreate: (data: CreateAnswerPackageRequest, params: RequestParams = {}) =>
			this.request<CreateAnswerPackage, any>({
				path: `/job_test/answer/start/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_test
		 * @name JobTestAnswerSubmitUpdate
		 * @request PUT:/job_test/answer/submit/{id}
		 * @secure
		 */
		jobTestAnswerSubmitUpdate: (
			id: string,
			data: SubmitAnswerRequest,
			params: RequestParams = {},
		) =>
			this.request<SubmitAnswer, any>({
				path: `/job_test/answer/submit/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_test
		 * @name JobTestPreloadedCopyTestCreate
		 * @request POST:/job_test/preloaded/copy-test/
		 * @secure
		 */
		jobTestPreloadedCopyTestCreate: (
			data: CopyPreloadedQuestionTestRequest[],
			params: RequestParams = {},
		) =>
			this.request<CopyPreloadedQuestionTest, any>({
				path: `/job_test/preloaded/copy-test/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_test
		 * @name JobTestPreloadedListRetrieve
		 * @request GET:/job_test/preloaded/list/
		 * @secure
		 */
		jobTestPreloadedListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				quiz_title?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<ListTest, any>({
				path: `/job_test/preloaded/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestPreloadedPreviewRetrieve
		 * @request GET:/job_test/preloaded/preview/{id}
		 * @secure
		 */
		jobTestPreloadedPreviewRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<DetailTestAndQuestions, any>({
				path: `/job_test/preloaded/preview/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_test
		 * @name JobTestQuestionCreateCreate
		 * @request POST:/job_test/question/create/
		 * @secure
		 */
		jobTestQuestionCreateCreate: (
			data: CustomQuestionInputRequest[],
			params: RequestParams = {},
		) =>
			this.request<CustomQuestionInput, any>({
				path: `/job_test/question/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_test
		 * @name JobTestQuestionDeleteDestroy
		 * @request DELETE:/job_test/question/delete/{id}
		 * @secure
		 */
		jobTestQuestionDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_test/question/delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestQuestionRetrieveJobPosterRetrieve
		 * @request GET:/job_test/question/retrieve/job-poster/{id}
		 * @secure
		 */
		jobTestQuestionRetrieveJobPosterRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ExposedKeyDetailQuestion, any>({
				path: `/job_test/question/retrieve/job-poster/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestQuestionRetrieveJobSeekerRetrieve
		 * @request GET:/job_test/question/retrieve/job-seeker/{id}
		 * @secure
		 */
		jobTestQuestionRetrieveJobSeekerRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<DetailQuestion, any>({
				path: `/job_test/question/retrieve/job-seeker/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_test
		 * @name JobTestQuestionUpdatePartialUpdate
		 * @request PATCH:/job_test/question/update/{id}
		 * @secure
		 */
		jobTestQuestionUpdatePartialUpdate: (
			id: string,
			data: PatchedUpdateQuestionRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateQuestion, any>({
				path: `/job_test/question/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_test
		 * @name JobTestTestAddCandidatesCreate
		 * @request POST:/job_test/test/add-candidates/
		 * @secure
		 */
		jobTestTestAddCandidatesCreate: (
			data: CandidatesTestRequest[],
			params: RequestParams = {},
		) =>
			this.request<CandidatesTest, any>({
				path: `/job_test/test/add-candidates/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags job_test
		 * @name JobTestTestCreateCreate
		 * @request POST:/job_test/test/create/
		 * @secure
		 */
		jobTestTestCreateCreate: (data: TestRequest, params: RequestParams = {}) =>
			this.request<Test, any>({
				path: `/job_test/test/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving or deleting a model instance.
		 *
		 * @tags job_test
		 * @name JobTestTestDeleteDestroy
		 * @request DELETE:/job_test/test/delete/{id}
		 * @secure
		 */
		jobTestTestDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/job_test/test/delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags job_test
		 * @name JobTestTestListRetrieve
		 * @request GET:/job_test/test/list/
		 * @secure
		 */
		jobTestTestListRetrieve: (
			query: {
				job_ad_id: string;
				job_application_id?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ListTest, any>({
				path: `/job_test/test/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestTestRetrieveTestJobPosterRetrieve
		 * @request GET:/job_test/test/retrieve-test/job-poster/{id}
		 * @secure
		 */
		jobTestTestRetrieveTestJobPosterRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<ExposedKeyDetailTestAndQuestions, any>({
				path: `/job_test/test/retrieve-test/job-poster/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags job_test
		 * @name JobTestTestRetrieveTestJobSeekerRetrieve
		 * @request GET:/job_test/test/retrieve-test/job-seeker/{id}
		 * @secure
		 */
		jobTestTestRetrieveTestJobSeekerRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<DetailTestAndQuestions, any>({
				path: `/job_test/test/retrieve-test/job-seeker/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags job_test
		 * @name JobTestTestUpdatePartialUpdate
		 * @request PATCH:/job_test/test/update/{id}
		 * @secure
		 */
		jobTestTestUpdatePartialUpdate: (
			id: string,
			data: PatchedUpdateTestRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateTest, any>({
				path: `/job_test/test/update/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	notifications = {
		/**
		 * No description
		 *
		 * @tags notifications
		 * @name NotificationsDeleteDestroy
		 * @request DELETE:/notifications/delete/
		 * @secure
		 */
		notificationsDeleteDestroy: (
			query: {
				/** notification id for deletion. */
				id: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/notifications/delete/`,
				method: "DELETE",
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags notifications
		 * @name NotificationsDeleteAllDestroy
		 * @request DELETE:/notifications/delete/all/
		 * @secure
		 */
		notificationsDeleteAllDestroy: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/notifications/delete/all/`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags notifications
		 * @name NotificationsListRetrieve
		 * @request GET:/notifications/list/
		 * @secure
		 */
		notificationsListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<NotificationList, any>({
				path: `/notifications/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags notifications
		 * @name NotificationsMarkReadPartialUpdate
		 * @request PATCH:/notifications/mark-read/
		 * @secure
		 */
		notificationsMarkReadPartialUpdate: (
			data: PatchedNotificationRecipientRequest,
			params: RequestParams = {},
		) =>
			this.request<NotificationRecipient, any>({
				path: `/notifications/mark-read/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags notifications
		 * @name NotificationsMarkReadAllPartialUpdate
		 * @request PATCH:/notifications/mark-read/all/
		 * @secure
		 */
		notificationsMarkReadAllPartialUpdate: (params: RequestParams = {}) =>
			this.request<MarkNotification, any>({
				path: `/notifications/mark-read/all/`,
				method: "PATCH",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags notifications
		 * @name NotificationsMarkSeenPartialUpdate
		 * @request PATCH:/notifications/mark-seen/
		 * @secure
		 */
		notificationsMarkSeenPartialUpdate: (
			data: PatchedNotificationRecipientRequest,
			params: RequestParams = {},
		) =>
			this.request<NotificationRecipient, any>({
				path: `/notifications/mark-seen/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	user = {
		/**
		 * @description Invite new company staff by <b>company_admin</b> or staff with <b>user_management</b> permissions
		 *
		 * @tags user
		 * @name UserAddStaffCreate
		 * @request POST:/user/add-staff/
		 * @secure
		 */
		userAddStaffCreate: (data: AddCompanyStaffRequest, params: RequestParams = {}) =>
			this.request<AddCompanyStaff, any>({
				path: `/user/add-staff/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description For checking existing token and fetch basic user detail
		 *
		 * @tags user
		 * @name UserBasicUserDetailRetrieve
		 * @request GET:/user/basicUserDetail/
		 * @secure
		 */
		userBasicUserDetailRetrieve: (params: RequestParams = {}) =>
			this.request<BasicUserProfile, any>({
				path: `/user/basicUserDetail/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserBulkZipResumesForBulkJobSeekersRetrieve
		 * @request GET:/user/bulk-zip-resumes-for-bulk-job-seekers/{job_ad_id}/
		 * @secure
		 */
		userBulkZipResumesForBulkJobSeekersRetrieve: (
			jobAdId: number,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/user/bulk-zip-resumes-for-bulk-job-seekers/${jobAdId}/`,
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * @description Change password for all users.
		 *
		 * @tags user
		 * @name UserChangePasswordPartialUpdate
		 * @request PATCH:/user/change-password/{id}
		 * @secure
		 */
		userChangePasswordPartialUpdate: (
			id: string,
			data: PatchedChangePasswordRequest,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/user/change-password/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserCompanyStaffRetrieve
		 * @request GET:/user/company-staff/
		 * @secure
		 */
		userCompanyStaffRetrieve: (
			query?: {
				email?: string;
				email__iexact?: string;
				email__istartswith?: string;
				full_name?: string;
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				position?: string;
				position__icontains?: string;
				position__istartswith?: string;
				staff_user_permissions?: (
					| "all_jobs"
					| "billing_management"
					| "company_profile_management"
					| "job_posting"
					| "specific_jobs"
					| "talent_search_utilization"
					| "user_management"
					| null
				)[];
				user_type?: string;
				username?: string;
				username__iexact?: string;
				username__istartswith?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<UserCompanyPosition, any>({
				path: `/user/company-staff/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Purpose of this API was to get the staff user data within requestor company's as payload for another API. <br><br>e.g. add collaborators API
		 *
		 * @tags user
		 * @name UserCompanyUserRetrieve
		 * @request GET:/user/company-user/
		 * @secure
		 */
		userCompanyUserRetrieve: (
			query?: {
				/** Filter users by exact email */
				email?: string;
				/** Filter users by email ignored uppercase / lowercase word */
				email__iexact?: string;
				/** Filter users using word that started in email */
				email__istartswith?: string;
				/** Filter users by string that contain in user full name */
				full_name?: string;
				/** Filter through fullname, position, and email match.  */
				keyword?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				/** Filter users by string that exact value of user position name */
				position?: string;
				/** Filter users by string that contains in user position name value */
				position__icontains?: string;
				/** Filter users by string that starts value of user position name */
				position__istartswith?: string;
				/** Filter users by staff user permissions */
				staff_user_permissions?: (
					| "all_jobs"
					| "billing_management"
					| "company_profile_management"
					| "job_posting"
					| "specific_jobs"
					| "talent_search_utilization"
					| "user_management"
				)[];
				/** Filter users by user type */
				user_type?: "company_admin" | "company_staff" | "job_seeker";
				/** Filter users by exact username */
				username?: string;
				/** Filter users by username ignored uppercase / lowercase word */
				username__iexact?: string;
				/** Filter users using word that started in username */
				username__istartswith?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<UserProfile, any>({
				path: `/user/company-user/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Add new educations history for job_seeker.
		 *
		 * @tags user
		 * @name UserEducationCreateCreate
		 * @request POST:/user/education/create/
		 * @secure
		 */
		userEducationCreateCreate: (data: EducationRequest, params: RequestParams = {}) =>
			this.request<Education, any>({
				path: `/user/education/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags user
		 * @name UserEducationDocumentProofCreateCreate
		 * @request POST:/user/education/document-proof/create/
		 * @secure
		 */
		userEducationDocumentProofCreateCreate: (
			data: EducationDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<EducationDocument, any>({
				path: `/user/education/document-proof/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserEducationDocumentProofGetEditDeleteRetrieve
		 * @request GET:/user/education/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userEducationDocumentProofGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<EducationDocument, any>({
				path: `/user/education/document-proof/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserEducationDocumentProofGetEditDeleteUpdate
		 * @request PUT:/user/education/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userEducationDocumentProofGetEditDeleteUpdate: (
			id: string,
			data: EducationDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<EducationDocument, any>({
				path: `/user/education/document-proof/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserEducationDocumentProofGetEditDeletePartialUpdate
		 * @request PATCH:/user/education/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userEducationDocumentProofGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedEducationDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<EducationDocument, any>({
				path: `/user/education/document-proof/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserEducationDocumentProofGetEditDeleteDestroy
		 * @request DELETE:/user/education/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userEducationDocumentProofGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/education/document-proof/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserEducationDocumentProofListRetrieve
		 * @request GET:/user/education/document-proof/list/
		 * @secure
		 */
		userEducationDocumentProofListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<EducationDocument, any>({
				path: `/user/education/document-proof/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserEducationGetEditDeleteRetrieve
		 * @request GET:/user/education/get-edit-delete/{id}
		 * @secure
		 */
		userEducationGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<Education, any>({
				path: `/user/education/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserEducationGetEditDeleteUpdate
		 * @request PUT:/user/education/get-edit-delete/{id}
		 * @secure
		 */
		userEducationGetEditDeleteUpdate: (
			id: string,
			data: EducationRequest,
			params: RequestParams = {},
		) =>
			this.request<Education, any>({
				path: `/user/education/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserEducationGetEditDeletePartialUpdate
		 * @request PATCH:/user/education/get-edit-delete/{id}
		 * @secure
		 */
		userEducationGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedEducationRequest,
			params: RequestParams = {},
		) =>
			this.request<Education, any>({
				path: `/user/education/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserEducationGetEditDeleteDestroy
		 * @request DELETE:/user/education/get-edit-delete/{id}
		 * @secure
		 */
		userEducationGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/education/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserEducationListRetrieve
		 * @request GET:/user/education/list/
		 * @secure
		 */
		userEducationListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<Education, any>({
				path: `/user/education/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description User register verification for all users.
		 *
		 * @tags user
		 * @name UserEmailActivationCreate
		 * @request POST:/user/email-activation/
		 * @secure
		 */
		userEmailActivationCreate: (data: UserActivationRequest, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/email-activation/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Add new job experiences for job_seeker.
		 *
		 * @tags user
		 * @name UserExperienceCreateCreate
		 * @request POST:/user/experience/create/
		 * @secure
		 */
		userExperienceCreateCreate: (data: ExperienceRequest, params: RequestParams = {}) =>
			this.request<Experience, any>({
				path: `/user/experience/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags user
		 * @name UserExperienceDocumentProofCreateCreate
		 * @request POST:/user/experience/document-proof/create/
		 * @secure
		 */
		userExperienceDocumentProofCreateCreate: (
			data: ExperienceDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<ExperienceDocument, any>({
				path: `/user/experience/document-proof/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserExperienceDocumentProofGetEditDeleteRetrieve
		 * @request GET:/user/experience/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceDocumentProofGetEditDeleteRetrieve: (
			id: string,
			params: RequestParams = {},
		) =>
			this.request<ExperienceDocument, any>({
				path: `/user/experience/document-proof/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserExperienceDocumentProofGetEditDeleteUpdate
		 * @request PUT:/user/experience/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceDocumentProofGetEditDeleteUpdate: (
			id: string,
			data: ExperienceDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<ExperienceDocument, any>({
				path: `/user/experience/document-proof/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserExperienceDocumentProofGetEditDeletePartialUpdate
		 * @request PATCH:/user/experience/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceDocumentProofGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedExperienceDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<ExperienceDocument, any>({
				path: `/user/experience/document-proof/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserExperienceDocumentProofGetEditDeleteDestroy
		 * @request DELETE:/user/experience/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceDocumentProofGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/experience/document-proof/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserExperienceDocumentProofListRetrieve
		 * @request GET:/user/experience/document-proof/list/
		 * @secure
		 */
		userExperienceDocumentProofListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ExperienceDocument, any>({
				path: `/user/experience/document-proof/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserExperienceGetEditDeleteRetrieve
		 * @request GET:/user/experience/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<Experience, any>({
				path: `/user/experience/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserExperienceGetEditDeleteUpdate
		 * @request PUT:/user/experience/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceGetEditDeleteUpdate: (
			id: string,
			data: ExperienceRequest,
			params: RequestParams = {},
		) =>
			this.request<Experience, any>({
				path: `/user/experience/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserExperienceGetEditDeletePartialUpdate
		 * @request PATCH:/user/experience/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedExperienceRequest,
			params: RequestParams = {},
		) =>
			this.request<Experience, any>({
				path: `/user/experience/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserExperienceGetEditDeleteDestroy
		 * @request DELETE:/user/experience/get-edit-delete/{id}
		 * @secure
		 */
		userExperienceGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/experience/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserExperienceListRetrieve
		 * @request GET:/user/experience/list/
		 * @secure
		 */
		userExperienceListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<Experience, any>({
				path: `/user/experience/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags user
		 * @name UserJobPosterResendRegistrationEmailCreate
		 * @request POST:/user/job-poster/resend-registration-email/
		 * @secure
		 */
		userJobPosterResendRegistrationEmailCreate: (
			data: JobPosterResendSignUpRequest,
			params: RequestParams = {},
		) =>
			this.request<JobPosterResendSignUp, any>({
				path: `/user/job-poster/resend-registration-email/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description User sign up for <b>company_admin</b>.
		 *
		 * @tags user
		 * @name UserJobPosterSignUpCreate
		 * @request POST:/user/job-poster/sign-up/
		 * @secure
		 */
		userJobPosterSignUpCreate: (data: JobPosterSignUpRequest, params: RequestParams = {}) =>
			this.request<JobPosterSignUp, any>({
				path: `/user/job-poster/sign-up/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Add new job preference for job_seeker.
		 *
		 * @tags user
		 * @name UserJobPreferenceCreateCreate
		 * @request POST:/user/job-preference/create/
		 * @secure
		 */
		userJobPreferenceCreateCreate: (data: JobPreferenceRequest, params: RequestParams = {}) =>
			this.request<JobPreference, any>({
				path: `/user/job-preference/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserJobPreferenceGetEditDeleteRetrieve
		 * @request GET:/user/job-preference/get-edit-delete/{id}
		 * @secure
		 */
		userJobPreferenceGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<JobPreference, any>({
				path: `/user/job-preference/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserJobPreferenceGetEditDeleteUpdate
		 * @request PUT:/user/job-preference/get-edit-delete/{id}
		 * @secure
		 */
		userJobPreferenceGetEditDeleteUpdate: (
			id: string,
			data: JobPreferenceRequest,
			params: RequestParams = {},
		) =>
			this.request<JobPreference, any>({
				path: `/user/job-preference/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserJobPreferenceGetEditDeletePartialUpdate
		 * @request PATCH:/user/job-preference/get-edit-delete/{id}
		 * @secure
		 */
		userJobPreferenceGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedJobPreferenceRequest,
			params: RequestParams = {},
		) =>
			this.request<JobPreference, any>({
				path: `/user/job-preference/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserJobPreferenceGetEditDeleteDestroy
		 * @request DELETE:/user/job-preference/get-edit-delete/{id}
		 * @secure
		 */
		userJobPreferenceGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/job-preference/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserJobPreferenceListRetrieve
		 * @request GET:/user/job-preference/list/
		 * @secure
		 */
		userJobPreferenceListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<JobPreference, any>({
				path: `/user/job-preference/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserJobSeekerPortalAccountFetchUserCreate
		 * @request POST:/user/job-seeker/portal-account/fetch-user/
		 * @secure
		 */
		userJobSeekerPortalAccountFetchUserCreate: (
			data: FetchPortalUserRequest,
			params: RequestParams = {},
		) =>
			this.request<FetchPortalUser, any>({
				path: `/user/job-seeker/portal-account/fetch-user/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserJobSeekerPortalAccountSignUpCreate
		 * @request POST:/user/job-seeker/portal-account/sign-up/
		 * @secure
		 */
		userJobSeekerPortalAccountSignUpCreate: (
			data: PortalSignUpRequest,
			params: RequestParams = {},
		) =>
			this.request<PortalSignUp, any>({
				path: `/user/job-seeker/portal-account/sign-up/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags user
		 * @name UserJobSeekerResendRegistrationEmailCreate
		 * @request POST:/user/job-seeker/resend-registration-email/
		 * @secure
		 */
		userJobSeekerResendRegistrationEmailCreate: (
			data: JobSeekerResendVerificationRequest,
			params: RequestParams = {},
		) =>
			this.request<JobSeekerResendVerification, any>({
				path: `/user/job-seeker/resend-registration-email/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description User sign up for <b>job_seeker</b>.
		 *
		 * @tags user
		 * @name UserJobSeekerSignUpCreate
		 * @request POST:/user/job-seeker/sign-up/
		 * @secure
		 */
		userJobSeekerSignUpCreate: (data: JobSeekerSignUpRequest, params: RequestParams = {}) =>
			this.request<JobSeekerSignUp, any>({
				path: `/user/job-seeker/sign-up/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Add new languages skill for job_seeker.
		 *
		 * @tags user
		 * @name UserLanguageCreateCreate
		 * @request POST:/user/language/create/
		 * @secure
		 */
		userLanguageCreateCreate: (data: LanguageRequest, params: RequestParams = {}) =>
			this.request<Language, any>({
				path: `/user/language/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags user
		 * @name UserLanguageDocumentProofCreateCreate
		 * @request POST:/user/language/document-proof/create/
		 * @secure
		 */
		userLanguageDocumentProofCreateCreate: (
			data: LanguageDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<LanguageDocument, any>({
				path: `/user/language/document-proof/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserLanguageDocumentProofGetEditDeleteRetrieve
		 * @request GET:/user/language/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageDocumentProofGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<LanguageDocument, any>({
				path: `/user/language/document-proof/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserLanguageDocumentProofGetEditDeleteUpdate
		 * @request PUT:/user/language/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageDocumentProofGetEditDeleteUpdate: (
			id: string,
			data: LanguageDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<LanguageDocument, any>({
				path: `/user/language/document-proof/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserLanguageDocumentProofGetEditDeletePartialUpdate
		 * @request PATCH:/user/language/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageDocumentProofGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedLanguageDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<LanguageDocument, any>({
				path: `/user/language/document-proof/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserLanguageDocumentProofGetEditDeleteDestroy
		 * @request DELETE:/user/language/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageDocumentProofGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/language/document-proof/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserLanguageDocumentProofListRetrieve
		 * @request GET:/user/language/document-proof/list/
		 * @secure
		 */
		userLanguageDocumentProofListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<LanguageDocument, any>({
				path: `/user/language/document-proof/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserLanguageGetEditDeleteRetrieve
		 * @request GET:/user/language/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<Language, any>({
				path: `/user/language/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserLanguageGetEditDeleteUpdate
		 * @request PUT:/user/language/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageGetEditDeleteUpdate: (
			id: string,
			data: LanguageRequest,
			params: RequestParams = {},
		) =>
			this.request<Language, any>({
				path: `/user/language/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserLanguageGetEditDeletePartialUpdate
		 * @request PATCH:/user/language/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedLanguageRequest,
			params: RequestParams = {},
		) =>
			this.request<Language, any>({
				path: `/user/language/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserLanguageGetEditDeleteDestroy
		 * @request DELETE:/user/language/get-edit-delete/{id}
		 * @secure
		 */
		userLanguageGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/language/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserLanguageListRetrieve
		 * @request GET:/user/language/list/
		 * @secure
		 */
		userLanguageListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<Language, any>({
				path: `/user/language/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description The purpose of this API is to list staff user permission for company <br>
		 *
		 * @tags user
		 * @name UserListAllStaffPermissionsRetrieve
		 * @request GET:/user/listAllStaffPermissions/
		 * @secure
		 */
		userListAllStaffPermissionsRetrieve: (params: RequestParams = {}) =>
			this.request<Record<string, any>, any>({
				path: `/user/listAllStaffPermissions/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserLocationListRetrieve
		 * @request GET:/user/location/list
		 * @secure
		 */
		userLocationListRetrieve: (
			query?: {
				city?: string;
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
				province?: string;
				/** A search term. */
				search?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<Location, any>({
				path: `/user/location/list`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Login API for all users.
		 *
		 * @tags user
		 * @name UserLoginCreate
		 * @request POST:/user/login/
		 * @secure
		 */
		userLoginCreate: (data: LoginRequest, params: RequestParams = {}) =>
			this.request<Login, any>({
				path: `/user/login/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Logout API for all users.
		 *
		 * @tags user
		 * @name UserLogoutCreate
		 * @request POST:/user/logout/
		 * @secure
		 */
		userLogoutCreate: (data: LogoutRequest, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/logout/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Concrete view for updating a model instance.
		 *
		 * @tags user
		 * @name UserNotificationEditPartialUpdate
		 * @request PATCH:/user/notification/edit/
		 * @secure
		 */
		userNotificationEditPartialUpdate: (
			data: PatchedUpdateNotificationRequest,
			params: RequestParams = {},
		) =>
			this.request<UpdateNotification, any>({
				path: `/user/notification/edit/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserNotificationGetRetrieve
		 * @request GET:/user/notification/get/
		 * @secure
		 */
		userNotificationGetRetrieve: (params: RequestParams = {}) =>
			this.request<GetNotification, any>({
				path: `/user/notification/get/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Add new portfolio file for job_seeker.
		 *
		 * @tags user
		 * @name UserPortfolioCreateCreate
		 * @request POST:/user/portfolio/create/
		 * @secure
		 */
		userPortfolioCreateCreate: (data: PortfolioRequest, params: RequestParams = {}) =>
			this.request<Portfolio, any>({
				path: `/user/portfolio/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserPortfolioGetEditDeleteRetrieve
		 * @request GET:/user/portfolio/get-edit-delete/{id}
		 * @secure
		 */
		userPortfolioGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<Portfolio, any>({
				path: `/user/portfolio/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserPortfolioGetEditDeleteUpdate
		 * @request PUT:/user/portfolio/get-edit-delete/{id}
		 * @secure
		 */
		userPortfolioGetEditDeleteUpdate: (
			id: string,
			data: PortfolioRequest,
			params: RequestParams = {},
		) =>
			this.request<Portfolio, any>({
				path: `/user/portfolio/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserPortfolioGetEditDeletePartialUpdate
		 * @request PATCH:/user/portfolio/get-edit-delete/{id}
		 * @secure
		 */
		userPortfolioGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedPortfolioRequest,
			params: RequestParams = {},
		) =>
			this.request<Portfolio, any>({
				path: `/user/portfolio/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserPortfolioGetEditDeleteDestroy
		 * @request DELETE:/user/portfolio/get-edit-delete/{id}
		 * @secure
		 */
		userPortfolioGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/portfolio/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserPortfolioListRetrieve
		 * @request GET:/user/portfolio/list/
		 * @secure
		 */
		userPortfolioListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<Portfolio, any>({
				path: `/user/portfolio/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Delete user account by owner, for job seeker only.
		 *
		 * @tags user
		 * @name UserProfileCloseAccountPartialUpdate
		 * @request PATCH:/user/profile/close-account/
		 * @secure
		 */
		userProfileCloseAccountPartialUpdate: (
			data: PatchedClosedAccountRequest,
			params: RequestParams = {},
		) =>
			this.request<ClosedAccount, any>({
				path: `/user/profile/close-account/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Deactivate user account by owner, for job seeker only.
		 *
		 * @tags user
		 * @name UserProfileDeactivateAccountPartialUpdate
		 * @request PATCH:/user/profile/deactivate-account/
		 * @secure
		 */
		userProfileDeactivateAccountPartialUpdate: (
			data: PatchedDeactivateAccountRequest,
			params: RequestParams = {},
		) =>
			this.request<DeactivateAccount, any>({
				path: `/user/profile/deactivate-account/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Update user account by owner.
		 *
		 * @tags user
		 * @name UserProfileEditUpdate
		 * @request PUT:/user/profile/edit/
		 * @secure
		 */
		userProfileEditUpdate: (data: UserProfileRequest, params: RequestParams = {}) =>
			this.request<UserProfile, any>({
				path: `/user/profile/edit/`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Update user account by owner.
		 *
		 * @tags user
		 * @name UserProfileEditPartialUpdate
		 * @request PATCH:/user/profile/edit/
		 * @secure
		 */
		userProfileEditPartialUpdate: (
			data: PatchedUserProfileRequest,
			params: RequestParams = {},
		) =>
			this.request<UserProfile, any>({
				path: `/user/profile/edit/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Reactive account api for <b>job_seeker</b> users
		 *
		 * @tags user
		 * @name UserProfileReactivatePartialUpdate
		 * @request PATCH:/user/profile/reactivate/
		 * @secure
		 */
		userProfileReactivatePartialUpdate: (
			data: PatchedReactivateAccountJobSeekerRequest,
			params: RequestParams = {},
		) =>
			this.request<ReactivateAccountJobSeeker, any>({
				path: `/user/profile/reactivate/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
		 *
		 * @tags user
		 * @name UserRefreshTokenCreate
		 * @request POST:/user/refresh-token/
		 */
		userRefreshTokenCreate: (data: TokenRefreshRequest, params: RequestParams = {}) =>
			this.request<TokenRefresh, any>({
				path: `/user/refresh-token/`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Callback forget password for all users.
		 *
		 * @tags user
		 * @name UserResetPasswordCreate
		 * @request POST:/user/reset-password/
		 */
		userResetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
			this.request<ResetPassword, any>({
				path: `/user/reset-password/`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Export resume api. If job poster is exporting resume then candidate_id is required
		 *
		 * @tags user
		 * @name UserResumeExportRetrieve
		 * @request GET:/user/resume-export/
		 * @secure
		 */
		userResumeExportRetrieve: (
			query?: {
				/** Resume owner id when job poster is exporting resume. Optional for job seeker */
				candidate_id?: string;
				/** Response type can be json/base64 or file/stream. Default is json/base64 */
				response_type?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<Record<string, any>, any>({
				path: `/user/resume-export/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserResumePreferenceRetrieve
		 * @request GET:/user/resume-preference/{id}
		 * @secure
		 */
		userResumePreferenceRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<Resume, any>({
				path: `/user/resume-preference/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserResumePreferenceDeleteFileDestroy
		 * @request DELETE:/user/resume-preference/delete-file/
		 * @secure
		 */
		userResumePreferenceDeleteFileDestroy: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/resume-preference/delete-file/`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Update job seeker resume preference.
		 *
		 * @tags user
		 * @name UserResumePreferenceUpdatePartialUpdate
		 * @request PATCH:/user/resume-preference/update/
		 * @secure
		 */
		userResumePreferenceUpdatePartialUpdate: (
			data: PatchedResumeRequest,
			params: RequestParams = {},
		) =>
			this.request<Resume, any>({
				path: `/user/resume-preference/update/`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserSelfSelfBadgeUserRetrieve
		 * @request GET:/user/self/self-badge-user/
		 * @secure
		 */
		userSelfSelfBadgeUserRetrieve: (params: RequestParams = {}) =>
			this.request<UserCompanyPosition, any>({
				path: `/user/self/self-badge-user/`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Ask forget password for all users.
		 *
		 * @tags user
		 * @name UserSendResetPasswordLinkCreate
		 * @request POST:/user/send-reset-password-link/
		 */
		userSendResetPasswordLinkCreate: (
			data: SendResetPasswordLinkRequest,
			params: RequestParams = {},
		) =>
			this.request<SendResetPasswordLink, any>({
				path: `/user/send-reset-password-link/`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Show complete profile, if user was job seeker it will showed : <br><br>        - <b>experience history</b><br>        - <b>education history</b><br>        - <b>skills</b><br>        - <b>job preference</b><br>        - <b>portfolio</b><br>        - <b>resume preference. </b>
		 *
		 * @tags user
		 * @name UserShowProfileRetrieve
		 * @request GET:/user/show-profile/{id}
		 * @secure
		 */
		userShowProfileRetrieve: (id: number, params: RequestParams = {}) =>
			this.request<CompleteUserProfile, any>({
				path: `/user/show-profile/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Add new skill for job_seeker.
		 *
		 * @tags user
		 * @name UserSkillCreateCreate
		 * @request POST:/user/skill/create/
		 * @secure
		 */
		userSkillCreateCreate: (data: SkillRequest[], params: RequestParams = {}) =>
			this.request<Skill, any>({
				path: `/user/skill/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for creating an instance, exactly like generics.CreateAPIView, but uses our own mixin. Subclass should override perform_create to do actual work. Note that perform_create should return created instance unlike super's mixins.CreateModelMixin.perform_create which returns nothing.
		 *
		 * @tags user
		 * @name UserSkillDocumentProofCreateCreate
		 * @request POST:/user/skill/document-proof/create/
		 * @secure
		 */
		userSkillDocumentProofCreateCreate: (
			data: SkillDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<SkillDocument, any>({
				path: `/user/skill/document-proof/create/`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserSkillDocumentProofGetEditDeleteRetrieve
		 * @request GET:/user/skill/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userSkillDocumentProofGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<SkillDocument, any>({
				path: `/user/skill/document-proof/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserSkillDocumentProofGetEditDeleteUpdate
		 * @request PUT:/user/skill/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userSkillDocumentProofGetEditDeleteUpdate: (
			id: string,
			data: SkillDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<SkillDocument, any>({
				path: `/user/skill/document-proof/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserSkillDocumentProofGetEditDeletePartialUpdate
		 * @request PATCH:/user/skill/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userSkillDocumentProofGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedSkillDocumentRequest,
			params: RequestParams = {},
		) =>
			this.request<SkillDocument, any>({
				path: `/user/skill/document-proof/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserSkillDocumentProofGetEditDeleteDestroy
		 * @request DELETE:/user/skill/document-proof/get-edit-delete/{id}
		 * @secure
		 */
		userSkillDocumentProofGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/skill/document-proof/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserSkillDocumentProofListRetrieve
		 * @request GET:/user/skill/document-proof/list/
		 * @secure
		 */
		userSkillDocumentProofListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<SkillDocument, any>({
				path: `/user/skill/document-proof/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Return the object identified by `pk`. --- parameters: - name: depth description: Nesting level for the object, default=0. type: integer paramType: query
		 *
		 * @tags user
		 * @name UserSkillGetEditDeleteRetrieve
		 * @request GET:/user/skill/get-edit-delete/{id}
		 * @secure
		 */
		userSkillGetEditDeleteRetrieve: (id: string, params: RequestParams = {}) =>
			this.request<Skill, any>({
				path: `/user/skill/get-edit-delete/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserSkillGetEditDeleteUpdate
		 * @request PUT:/user/skill/get-edit-delete/{id}
		 * @secure
		 */
		userSkillGetEditDeleteUpdate: (
			id: string,
			data: SkillRequest,
			params: RequestParams = {},
		) =>
			this.request<Skill, any>({
				path: `/user/skill/get-edit-delete/${id}`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserSkillGetEditDeletePartialUpdate
		 * @request PATCH:/user/skill/get-edit-delete/{id}
		 * @secure
		 */
		userSkillGetEditDeletePartialUpdate: (
			id: string,
			data: PatchedSkillRequest,
			params: RequestParams = {},
		) =>
			this.request<Skill, any>({
				path: `/user/skill/get-edit-delete/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Concrete view for retrieving, updating or deleting a model instance.
		 *
		 * @tags user
		 * @name UserSkillGetEditDeleteDestroy
		 * @request DELETE:/user/skill/get-edit-delete/{id}
		 * @secure
		 */
		userSkillGetEditDeleteDestroy: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/skill/get-edit-delete/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * @description Return paginated list of objects
		 *
		 * @tags user
		 * @name UserSkillListRetrieve
		 * @request GET:/user/skill/list/
		 * @secure
		 */
		userSkillListRetrieve: (
			query?: {
				/** A page number within the paginated result set. */
				page?: number;
				/** Number of results to return per page. */
				page_size?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<Skill, any>({
				path: `/user/skill/list/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * @description Delete company staff by company admin.
		 *
		 * @tags user
		 * @name UserStaffUserCloseAccountProfilePartialUpdate
		 * @request PATCH:/user/staff-user/close-account/profile/{id}
		 * @secure
		 */
		userStaffUserCloseAccountProfilePartialUpdate: (
			id: string,
			data: PatchedClosedAccountRequest,
			params: RequestParams = {},
		) =>
			this.request<ClosedAccount, any>({
				path: `/user/staff-user/close-account/profile/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Deactivate or activate company staff by company admin.
		 *
		 * @tags user
		 * @name UserStaffUserDeactivateReactivateAccountProfilePartialUpdate
		 * @request PATCH:/user/staff-user/deactivate-reactivate-account/profile/{id}
		 * @secure
		 */
		userStaffUserDeactivateReactivateAccountProfilePartialUpdate: (
			id: string,
			data: PatchedCompanyAccountActivationStatusRequest,
			params: RequestParams = {},
		) =>
			this.request<CompanyAccountActivationStatus, any>({
				path: `/user/staff-user/deactivate-reactivate-account/profile/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Update company staff profile by company admin, except company staff email and password.
		 *
		 * @tags user
		 * @name UserStaffUserUpdateProfilePartialUpdate
		 * @request PATCH:/user/staff-user/update/profile/{id}
		 * @secure
		 */
		userStaffUserUpdateProfilePartialUpdate: (
			id: string,
			data: PatchedUserProfileForCompanyAdminRequest,
			params: RequestParams = {},
		) =>
			this.request<UserProfileForCompanyAdmin, any>({
				path: `/user/staff-user/update/profile/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
		 *
		 * @tags user
		 * @name UserVerifyTokenCreate
		 * @request POST:/user/verify-token/
		 */
		userVerifyTokenCreate: (data: TokenVerifyRequest, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/verify-token/`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserZipAllDocsForBulkJobSeekersRetrieve
		 * @request GET:/user/zip-all-docs-for-bulk-job-seekers/{job_ad_id}/
		 * @secure
		 */
		userZipAllDocsForBulkJobSeekersRetrieve: (jobAdId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/zip-all-docs-for-bulk-job-seekers/${jobAdId}/`,
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * @description Export all documents in one zip for single job seeker api. If job poster is exporting resume then job_application_id is required
		 *
		 * @tags user
		 * @name UserZipDocsForSingleJobSeekerRetrieve
		 * @request GET:/user/zip-docs-for-single-job-seeker/{job_application_id}/
		 * @secure
		 */
		userZipDocsForSingleJobSeekerRetrieve: (
			jobApplicationId: number,
			query?: {
				/** Response type can be json/base64 or file/stream. Default is json/base64 */
				response_type?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<Record<string, any>, any>({
				path: `/user/zip-docs-for-single-job-seeker/${jobApplicationId}/`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
}
