import { homeUrl } from "./apiEndPoints";
// const request = require("request");
import axios from "axios";
const cookies = require("react-cookies");
const makeRequest = (options) => {
	return new Promise((resolve, reject) => {
		let url = new URL(options.url);

		const authHeader = cookies.load("ptkjauthtoken", { domain: homeUrl });
		const refreshToken = cookies.load("ptkjrefreshtoken", { domain: homeUrl });
		const lang = cookies.load("lang", { domain: homeUrl });

		options = {
			...options,
			...(options.body && { data: options.body }),
			url: url.href,
			headers: {
				...options.headers,
				"Accept-Language": `${lang === "eng" ? "en-us" : "id"}`,
			},
		};

		if (authHeader && refreshToken) {
			options.headers.Authorization = `Bearer ${authHeader}`;
		}

		const clearCookies = () => {
			cookies.remove("lang", { path: "/", domain: homeUrl });
			cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
			cookies.remove("ptkjrefreshtoken", {
				path: "/",
				domain: homeUrl,
			});
			cookies.remove("tokenexpiry", { path: "/", domain: homeUrl });
			setTimeout(() => {
				window.location.reload();
			}, 200);
		};

		axios(options)
			.then(async (response) => {
				let apiResponse,
					body = response.data;

				try {
					apiResponse = typeof body === "string" ? JSON.parse(body) : body;
				} catch (e) {
					apiResponse = body;
				}

				if (response.status === 401 && authHeader && refreshToken) {
					clearCookies();
				} else {
					resolve(apiResponse);
				}
			})
			.catch((err) => {
				let apiResponse;
				try {
					apiResponse = err.response.data;
					const TOKEN_EXPIRED =
						cookies.load("ptkjauthtoken") &&
						cookies.load("ptkjrefreshtoken") &&
						err.response.data.code === 400 &&
						err.response.data.message.includes("token");
					if (TOKEN_EXPIRED) {
						clearCookies();
						return;
					}
				} catch (e) {
					apiResponse =
						lang === "eng"
							? "An unknown error has occured"
							: "Telah terjadi kesalahan yang tidak diketahui";
				}
				resolve(apiResponse);
			});
	});
};

export default makeRequest;
