// Import from external link
import React from "react";
import { PencilIcon, TrashIcon } from "../../../shared/component/svg/Icons";
import { classHelper } from "../../../shared/utils/stringUtils";
import "../ProfileComponent.scss";

const ExpItemComponent = (props) => {
	return (
		<div className={classHelper("exp-item-comp mb-3", props.className)}>
			{props.icon}
			<div className="d-flex flex-column gap-xxs flex-grow-1 mw-0">
				<div className="d-flex justify-content-between">
					<div
						className={`d-flex flex-column gap-xxs flex-grow-1 ${
							props.children ? "" : ""
						}`}
					>
						{props.customTitle ?? (
							<h4 className="text-label-black text-lg mb-0">
								<strong>{props.title}</strong>
							</h4>
						)}
						{props.otherInfos}
					</div>
					{(props.onEdit || props.onDelete) && (
						<div className="d-flex gap-xs">
							{props.onDelete && (
								<button className="btn pfc-btn" onClick={props.onDelete}>
									<TrashIcon stroke="#667085" width="2.4rem" height="2.4rem" />
								</button>
							)}
							{props.onEdit && (
								<button className="btn pfc-btn" onClick={props.onEdit}>
									<PencilIcon stroke="#667085" width="2.4rem" height="2.4rem" />
								</button>
							)}
						</div>
					)}
				</div>
				{props.children}
			</div>
		</div>
	);
};

export default ExpItemComponent;
