import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import { COUNTRY_LIST } from "../../../shared/utils/dataOptions";
import Select from "../../../shared/component/SelectComponent/Select";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import RadioInput from "../../../shared/component/InputComponent/RadioInput";
import { orderBy } from "lodash";
import { isValueAllowed } from "shared/utils/numberUtils";

const PersonalInfoModal = ({ ENV_NAME, onHide, onSave, ...props }) => {
	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const [countryList, setCountryList] = useState(null);
	const [form, setForm] = useState({
		first_name: "",
		last_name: "",
		email: "",
		username: "",
		mobile_phone_number: "",
		mobile_phone_number_2: "",
		country: "",
		city: "",
		street_address: "",
		postal_code: "",
		nationality: "",
		date_of_birth: "",
		gender: "",
	});
	const [errors, setErrors] = useState({});

	const onChange = (e, type = null) => {
		const { name, value } = e.target;
		setErrors((prev) => ({ ...prev, [name]: false }));
		if (!!type) {
			setForm((p) => ({ ...p, [name]: isValueAllowed(value, type) ? value : p[name] }));
			return;
		}
		setForm((p) => ({
			...p,
			[name]: value,
		}));
	};

	const validateAndSave = () => {
		const errorsData = {};
		if (!form.first_name) errorsData["first_name"] = true;
		if (!form.mobile_phone_number) errorsData["mobile_phone_number"] = true;
		if (!form.country || form.country === Variable.SELECT_LABEL[ENV_NAME])
			errorsData["country"] = true;
		if (!form.city) errorsData["city"] = true;
		if (!form.street_address) errorsData["street_address"] = true;
		if (!form.date_of_birth) errorsData["date_of_birth"] = true;
		if (!form.gender) errorsData["gender"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		let body = {};
		Object.keys(props.data).forEach((key) => {
			if (props.data[key] !== form[key]) body[key] = form[key];
		});
		if (Object.keys(body).length) onSave && onSave(body);
		else onHide();
	};

	useEffect(() => {
		if (props.show && ENV_NAME) {
			setCountryList(
				orderBy(
					COUNTRY_LIST?.map((i) => ({ id: i, name: regionNames?.of(i) })),
					"name",
				),
			);
		}
	}, [props.show, ENV_NAME]); // eslint-disable-line

	useEffect(() => {
		if (props.show && props.data) {
			setForm(props.data);
		} else {
			setForm({});
		}
	}, [props.show, props.data]); // eslint-disable-line

	return (
		<ModalForm
			title={Variable.EDIT_PERSONAL_INFORMATION[ENV_NAME]}
			footer={
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={onHide}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.SAVE[ENV_NAME]}
					/>
				</div>
			}
			{...props}
		>
			<div className="pc-form">
				<div className="pc-form-row">
					<Input
						label={Variable.FIRST_NAME[ENV_NAME]}
						placeholder={Variable.FIRST_NAME[ENV_NAME]}
						value={form?.first_name || ""}
						name="first_name"
						onChange={onChange}
						inputType="label"
						className="w-100"
						isError={errors["first_name"]}
						required
					/>
					<Input
						label={Variable.LAST_NAME[ENV_NAME]}
						placeholder={Variable.LAST_NAME[ENV_NAME]}
						value={form?.last_name || ""}
						name="last_name"
						onChange={onChange}
						inputType="label"
						className="w-100"
					/>
				</div>
				<Input
					label={Variable.EMAIL[ENV_NAME]}
					placeholder={Variable.EMAIL[ENV_NAME]}
					value={form?.email || ""}
					name="email"
					onChange={onChange}
					inputType="email"
					className="w-100"
					disabled
				/>
				<Input
					label={Variable.USERNAME[ENV_NAME]}
					placeholder={Variable.USERNAME[ENV_NAME]}
					value={form?.username || ""}
					name="username"
					onChange={onChange}
					inputType="username"
					className="w-100"
				/>
				<div className="pc-form-row">
					<Input
						label={Variable.MOBILE_NUMBER_1[ENV_NAME]}
						placeholder={Variable.MOBILE_NUMBER_1[ENV_NAME]}
						value={form?.mobile_phone_number || ""}
						name="mobile_phone_number"
						onChange={(e) => onChange(e, "phone")}
						inputType="label"
						className="w-100"
						required
						isError={errors["mobile_phone_number"]}
					/>
					<Input
						label={Variable.ALTERNATE_MOBILE_NUMBER[ENV_NAME]}
						placeholder={Variable.ALTERNATE_MOBILE_NUMBER[ENV_NAME]}
						value={form?.mobile_phone_number_2 || ""}
						name="mobile_phone_number_2"
						onChange={(e) => onChange(e, "phone")}
						inputType="label"
						spesificType="text"
						className="w-100"
					/>
				</div>
				<div className="pc-form-row">
					<Select
						label={Variable.COUNTRY[ENV_NAME]}
						placeholder={Variable.COUNTRY[ENV_NAME]}
						name="country"
						value={form?.country || ""}
						items={countryList}
						onChange={onChange}
						searchable={false}
						isError={errors["country"]}
						required
						noDefault
					/>
					<Input
						label={Variable.CITY[ENV_NAME]}
						placeholder={Variable.CITY[ENV_NAME]}
						value={form?.city || ""}
						name="city"
						onChange={onChange}
						inputType="label"
						className="w-100"
						isError={errors["city"]}
						required
					/>
				</div>
				<Input
					label={Variable.STREET_ADDRESS[ENV_NAME]}
					placeholder={Variable.STREET_ADDRESS[ENV_NAME]}
					value={form?.street_address || ""}
					name="street_address"
					onChange={onChange}
					inputType="label"
					className="w-100"
					required
					isError={errors["street_address"]}
				/>
				<div className="pc-form-row">
					<Input
						label={Variable.POSTAL_CODE[ENV_NAME]}
						placeholder={Variable.POSTAL_CODE[ENV_NAME]}
						value={form?.postal_code || ""}
						name="postal_code"
						onChange={onChange}
						inputType="label"
						className="w-100"
					/>
					<Select
						label={Variable.NATIONALITY[ENV_NAME]}
						placeholder={Variable.NATIONALITY[ENV_NAME]}
						value={form?.nationality || ""}
						name="nationality"
						items={countryList}
						onChange={onChange}
						searchable={false}
						noDefault
					/>
				</div>

				<DatePickerDropdown
					label={Variable.DATE_OF_BIRTH[ENV_NAME]}
					dayLabel={<></>}
					monthLabel={<></>}
					yearLabel={<></>}
					name="date_of_birth"
					value={form?.date_of_birth}
					isError={errors["date_of_birth"]}
					onChange={onChange}
					isLabelRequired
					// required
				/>

				<div>
					<label className="as-label isRequired">{Variable.GENDER[ENV_NAME]}</label>
					<div className="pc-form-row">
						<RadioInput
							label={Variable.MALE[ENV_NAME]}
							id="male"
							name="gender"
							checked={form?.gender === "male"}
							onChange={onChange}
							isError={errors["gender"]}
						/>
						<RadioInput
							label={Variable.FEMALE[ENV_NAME]}
							id="female"
							name="gender"
							checked={form?.gender === "female"}
							onChange={onChange}
							isError={errors["gender"]}
						/>
					</div>
				</div>
			</div>
		</ModalForm>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(PersonalInfoModal));
