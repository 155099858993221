import { Modal } from "react-bootstrap";

import "./ModalComponent.scss";
import Icon from "../IconComponent/Icon";
import Button from "../ButtonComponent/Button";
import { classHelper } from "../../utils/stringUtils.js";
import useTranslate from "shared/hooks/useTranslate";

// DOCUMENTATION
// type : [verification, error, delete]

interface Props {
	type?: string;
	title?: string;
	subtitle?: string;
	userEmail?: string;
	isShow?: boolean;
	isHide?: () => void;
	onConfirm: () => void;
	iconType?: string;
	className?: string;
	customIcon?: React.ReactNode;
	cancelButtonType?: string;
	cancelLabel?: string;
	confirmButtonType?: string;
	confirmLabel?: string;
}

const ModalInfo = ({
	type,
	title,
	subtitle,
	userEmail,
	isShow,
	isHide,
	onConfirm,
	iconType,
	className,
	customIcon,
	cancelButtonType,
	cancelLabel,
	confirmButtonType,
	confirmLabel,
}: Props) => {
	const t = useTranslate();

	return (
		<>
			<Modal
				show={isShow}
				onHide={isHide}
				dialogClassName={classHelper(
					"modal-dialog-centered modal-report-job modal-info",
					className,
				)}
				size={`${
					type === "verification" || type === "error" || type === "delete" ? "sm" : "lg"
				}`}
			>
				<Modal.Body>
					<div
						className={`${
							type === "verification" || type === "error"
								? "ms m-email"
								: type === "delete"
								? "ms m-delete"
								: "ms"
						}`}
					>
						<div className="ms-head">
							{customIcon ? (
								customIcon
							) : (
								<Icon
									iconType={`${
										iconType
											? iconType
											: type === "verification"
											? "email"
											: type === "error"
											? "error"
											: type === "delete"
											? "email"
											: "success"
									}`}
									borderType="outline"
									className="mr-0"
								/>
							)}
							<h3 className="text-center">{`${
								title
									? title
									: type === "verification"
									? t("MODAL_VERIFICATION_TITLE")
									: type === "error"
									? t("UPLOAD_FAILED")
									: type === "delete"
									? t("DELETE_MEDIA_LABEL")
									: t("REPORT_SUCCESS_TITLE")
							}`}</h3>
							<h5
								dangerouslySetInnerHTML={{
									__html: `${
										subtitle
											? subtitle
											: type === "verification"
											? t("RESET_PASSWORD_VERIFICATION") +
											  "<b>" +
											  userEmail +
											  "</b>"
											: type === "error"
											? t("FORMAT_NOT_SUPPORTED")
											: type === "delete"
											? t("DELETE_TITLE")
											: t("REPORT_SUCCESS_TEXT")
									}`,
								}}
							></h5>
						</div>
					</div>
					<div className="mButton">
						{isHide && (
							<Button
								type={cancelButtonType || "outline"}
								size="sm"
								title={cancelLabel || t("CANCEL_LABEL")}
								onClick={isHide}
							/>
						)}
						{onConfirm && (
							<Button
								type={confirmButtonType || "error"}
								size="sm"
								title={confirmLabel || t("DELETE_LABEL")}
								onClick={onConfirm}
							/>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ModalInfo;
