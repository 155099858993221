import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import Select from "../../../shared/component/SelectComponent/Select";
import {
	COUNTRY_LIST,
	FIELD_OF_STUDY,
	LEVEL_OF_EDUCATION,
} from "../../../shared/utils/dataOptions";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import Image from "../../../shared/component/UI/Image";
import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import { getIconByName, isFileSizeLessOrEqual } from "../../../shared/utils/file";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import useToast from "shared/hooks/useToast";
import { isValueAllowed } from "../../../shared/utils/numberUtils";

const ALLOWED_FILES = [
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"text/plain",
	"application/msword",
	"image/png",
	"image/jpeg",
];

const EducationModal = ({
	ENV_NAME,
	onHide,
	onSave,
	onEdit,
	show,
	showLoader,
	educationId,
	...props
}) => {
	const GPA_PATTERN = /^(\d+(?:[.,]\d*)?|)$/;

	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const [form, setForm] = useState({
		is_still_enrolled: false,
	});
	const [monitor, setMonitor] = useState({});
	const [errors, setErrors] = useState({});
	const [countryList, setCountryList] = useState(null);
	const [showConfirm, setShowConfirm] = useState({ show: false, data: null });
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const uploadRef = useRef();
	const toast = useToast();

	useEffect(() => {
		if (show && ENV_NAME) {
			setCountryList(COUNTRY_LIST?.map((i) => ({ id: i, name: regionNames?.of(i) })));
		}
	}, [show, ENV_NAME]); // eslint-disable-line

	useEffect(() => {
		if (show && educationId) retrieveEducation(educationId);
		if (!show) resetState();
	}, [show, educationId]); // eslint-disable-line

	const retrieveEducation = async (id) => {
		const res = await makeRequest(generateRequestOptions("fetchEducation", { urlParams: id }));

		if (res.code === 200) {
			setForm({ ...res.data });
		} else toast.error(res.message);
	};

	const validateAndSave = async () => {
		const errorsData = {};
		if (!form?.institution_name) errorsData["institution_name"] = true;
		if (!form?.country || form.country === Variable.SELECT_LABEL[ENV_NAME])
			errorsData["country"] = true;

		if (!form.started_at) errorsData["started_at"] = true;
		if (!form.is_still_enrolled && !form.graduated_at) errorsData["graduated_at"] = true;
		if (!form?.institution_level) errorsData["institution_level"] = true;
		if (form.gpa && !GPA_PATTERN.test(form?.gpa)) errorsData["gpa"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		const docs = form?.education_document_proof
			?.filter((doc) => !doc?.education)
			?.map((doc) => doc.education_document_proof);

		setIsUploading(true);
		if (educationId) {
			// check monitor and perpare the body
			const body = {};
			Object.keys(monitor).forEach((key) => {
				if (key !== "education_document_proof") body[key] = form[key];
			});

			if (monitor["is_still_enrolled"] && body.is_still_enrolled) body.graduated_at = null;

			// TODO: The GPA can't be null but we don't want any value for GPA when the institution level is SD, SMP, SMA/SMU/SMK/STM
			// so BE suggested that we should pass 0 as the value because this field is not nullable
			if (
				monitor["institution_level"] &&
				LEVEL_OF_EDUCATION.slice(0, 3).includes(body?.institution_level)
			)
				body.gpa = 0;

			onEdit && (await onEdit(body, educationId, docs));
		} else {
			const formClone = { ...form };
			if (!formClone.started_at)
				formClone["started_at"] = `${new Date().getFullYear()}-01-01`;
			if (!formClone.is_still_enrolled && !formClone.graduated_at)
				formClone["graduated_at"] = `${new Date().getFullYear()}-01-01`;

			if (formClone?.is_still_enrolled) formClone.graduated_at = "";

			if (isUploadingFile) formClone.education_document_proof = docs;

			onSave && (await onSave(formClone));
		}
		setIsUploadingFile(false);
		setIsUploading(false);
	};

	const onFileChange = async (e) => {
		const file = e.target.files[0];
		if (file) {
			if (!ALLOWED_FILES.includes(file?.type))
				return toast.error(Variable.UNSUPPORTED_FILE[ENV_NAME]);

			const FILE_SIZE = 1024 * 1024 * 20;
			if (!isFileSizeLessOrEqual(file, FILE_SIZE))
				return toast.error(Variable.FILE_SIZE_ERROR[ENV_NAME]);

			setIsUploadingFile(true);
			const fileObj = {
				education_document_proof: file,
				file_name: file?.name,
				id: Date.now(),
				is_newfile: true,
			};
			const formClone = { ...form };
			if (formClone.education_document_proof)
				formClone.education_document_proof.push(fileObj);
			else formClone.education_document_proof = [{ ...fileObj }];

			setForm(formClone);
		}
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			education_document_proof: form.education_document_proof.filter(
				(file) => file.id !== docId,
			),
		});
	};

	const handleDeleteDocument = async () => {
		// There will be two kinds of delete
		// if there exists an education for the document proof, then call the API
		// otherwise, filter out the documents
		const docId = showConfirm?.data?.id;

		const documentProof = form.education_document_proof.find((doc) => doc.id === docId);
		if (documentProof?.education) {
			const res = await makeRequest(
				generateRequestOptions("deleteEducationDocument", {
					urlParams: docId,
				}),
			);

			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowConfirm({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowConfirm({ show: false, data: null });
		}
	};

	// FUNC: Utilities
	const resetState = () => {
		setForm({});
		setMonitor({});
		setErrors({});
	};

	const onChange = (e, type = null) => {
		const { name, value } = e.target;
		if (type === "numberWithDecimal") {
			setForm((prev) => ({
				...prev,
				[name]: isValueAllowed(value, type) ? value : prev[name],
			}));
		} else
			setForm((prev) => ({
				...prev,
				[name]: value,
			}));
		// setForm((p) => ({ ...p, [name]: value }));
		setErrors({ ...errors, [name]: false });
		setMonitor({ ...monitor, [name]: true });
	};

	return (
		<>
			<ModalForm
				title={
					educationId
						? Variable.EDIT_EDUCATION[ENV_NAME]
						: Variable.ADD_EDUCATION[ENV_NAME]
				}
				footer={
					<div className="flex-all-center gap-md w-100">
						<Button
							type="outline"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={() => {
								onHide();
								resetState();
							}}
							title={Variable.CLOSE[ENV_NAME]}
						/>
						<Button
							type="primary"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={validateAndSave}
							title={Variable.SAVE[ENV_NAME]}
							disabled={isUploading}
						/>
					</div>
				}
				show={show && !showConfirm?.show}
				{...props}
			>
				<div className="pc-form">
					<Input
						label={Variable.SCHOOL_COLLEGE_NAME[ENV_NAME]}
						placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Universitas Indonesia`}
						value={form?.institution_name || ""}
						name="institution_name"
						onChange={onChange}
						inputType="label"
						className="w-100"
						required
						isError={errors["institution_name"]}
					/>
					<Select
						label={Variable.COUNTRY[ENV_NAME]}
						placeholder={Variable.COUNTRY[ENV_NAME]}
						name="country"
						value={form?.country || ""}
						items={countryList}
						onChange={onChange}
						searchable={false}
						required
						noDefault
						isError={errors["country"]}
					/>
					<Toggle
						type="switch"
						leftText={Variable.CURRENTLY_ENROLLED[ENV_NAME]}
						name="is_still_enrolled"
						checked={form?.is_still_enrolled}
						onChange={(e) => {
							setForm((p) => ({ ...p, is_still_enrolled: e.target.checked }));
							setMonitor((prev) => ({ ...prev, is_still_enrolled: true }));
						}}
					/>
					<DatePickerDropdown
						className="pc-form-row"
						dayLabel={<>&nbsp;</>}
						monthLabel={Variable.START_DATE[ENV_NAME]}
						yearLabel={<>&nbsp;</>}
						name="started_at"
						value={form?.started_at}
						onChange={onChange}
						isError={errors["started_at"]}
						hideDate
						required
					/>
					{!form?.is_still_enrolled && (
						<DatePickerDropdown
							className="pc-form-row"
							dayLabel={<>&nbsp;</>}
							monthLabel={Variable.GRADUATION_DATE[ENV_NAME]}
							yearLabel={<>&nbsp;</>}
							name="graduated_at"
							value={form?.graduated_at}
							onChange={onChange}
							isError={errors["graduated_at"]}
							hideDate
							required
						/>
					)}
					<Select
						label={Variable.FIELD_OF_STUDY[ENV_NAME]}
						placeholder={Variable.FIELD_OF_STUDY[ENV_NAME]}
						name="field_of_study"
						value={form?.field_of_study}
						items={FIELD_OF_STUDY?.map((i) => ({ id: i, name: i }))}
						onChange={onChange}
						searchable={false}
						className="w-100"
						noDefault
					/>
					<Input
						label={Variable.MAJOR[ENV_NAME]}
						placeholder={Variable.MAJOR[ENV_NAME]}
						value={form?.major || ""}
						name="major"
						onChange={onChange}
						inputType="label"
						className="w-100"
					/>
					<div className="pc-form-row">
						<Select
							label={Variable.LEVEL_OF_EDUCATION[ENV_NAME]}
							placeholder={Variable.LEVEL_OF_EDUCATION[ENV_NAME]}
							name="institution_level"
							value={form?.institution_level || ""}
							items={LEVEL_OF_EDUCATION?.map((i) => ({ id: i, name: i }))}
							onChange={onChange}
							searchable={false}
							className="w-100"
							isError={errors["institution_level"]}
							required
							noDefault
						/>
						{LEVEL_OF_EDUCATION?.slice(3)?.includes(form?.institution_level) && (
							<Input
								label={Variable.GPA[ENV_NAME]}
								placeholder={Variable.GPA[ENV_NAME]}
								value={form?.gpa || ""}
								name="gpa"
								onChange={(e) => onChange(e, "numberWithDecimal")}
								inputType="label"
								className="w-100"
								isError={errors["gpa"]}
								required
							/>
						)}
					</div>
					<div>
						<h5 className="text-md font-weight-medium text-label-gray mb-sm">
							{Variable.DOCUMENT[ENV_NAME]}
						</h5>
						<span className="text-sm">
							{Variable.DOCUMENT_EDUCATION_SUBTITLE[ENV_NAME]}
						</span>
					</div>
					<div className="input-btn">
						<Button
							type="secondary"
							size="md"
							title={
								<div className="flex-all-center gap-xxs">
									<PlusIcon
										width="1.8rem"
										height="1.8rem"
										stroke="white"
										strokeWidth="3"
									/>
									{Variable.ADD_FILE[ENV_NAME]}
								</div>
							}
							onClick={() => uploadRef?.current?.click()}
						/>
						<input
							id="upload-files"
							type="file"
							name="subjectIcon"
							className="inputfile d-none"
							onChange={(e) => {
								onFileChange(e);
								uploadRef.current.value = null;
							}}
							accept={ALLOWED_FILES.join(",")}
							ref={uploadRef}
						/>
					</div>

					{form?.education_document_proof?.map((i) => (
						<FileCard
							key={i?.id}
							title={i?.file_name}
							icon={getIconByName(i?.file_name)}
							onDelete={() => setShowConfirm({ show: true, data: i })}
							className="fileCard"
							showGreenCheck={false}
							isUploading={i?.is_newfile && isUploading}
							isNewFile={i?.is_newfile}
						/>
					))}
				</div>
			</ModalForm>

			<ModalInfo
				isShow={showConfirm?.show}
				isHide={() => setShowConfirm({ show: false, data: null })}
				onConfirm={handleDeleteDocument}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(EducationModal));
