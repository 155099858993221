import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import { PlusIcon } from "shared/component/svg/Icons";
import FileCard from "shared/component/CardComponent/FileCard";
import { getIconByName, isFileSizeLessOrEqual } from "shared/utils/file";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import Image from "shared/component/UI/Image";
import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import useToast from "shared/hooks/useToast";

const ALLOWED_FILES = ["application/pdf", "image/jpeg", "image/png"];

const SkillModal = ({
	ENV_NAME,
	onHide,
	onSave,
	show,
	onEdit,
	skillId,
	skillAdded,
	updateSkillAdded,
	...props
}) => {
	const SKILLS = [
		{ id: "beginner", name: Variable.BEGINNER[ENV_NAME] },
		{ id: "intermediate", name: Variable.INTERMEDIATE[ENV_NAME] },
		{ id: "advance", name: Variable.ADVANCED[ENV_NAME] },
	];

	const [form, setForm] = useState({});
	const [monitor, setMonitor] = useState({});
	const [errors, setErrors] = useState({});
	const [showConfirm, setShowConfirm] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState({
		show: false,
		data: null,
	});
	const [isUploading, setIsUploading] = useState(false); // Indicate file is getting uploaded to the server
	const [isUploadingFile, setIsUploadingFile] = useState(false); // Indicate that we are adding a new file
	const uploadRef = useRef();
	const toast = useToast();

	useEffect(() => {
		if (show && skillId) retrieveSkill(skillId);
		if (!show) resetState();
	}, [show, skillId]); // eslint-disable-line

	const retrieveSkill = async (id) => {
		const res = await makeRequest(generateRequestOptions("fetchSkill", { urlParams: id }));

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((p) => ({ ...p, [name]: value }));
		setErrors({ ...errors, [name]: false });
		setMonitor({ ...monitor, [name]: true });
	};

	const onFileChange = async (e) => {
		console.log("Uploading File");
		const file = e.target.files[0];

		if (file) {
			if (!ALLOWED_FILES.includes(file?.type))
				return toast.error(Variable.UNSUPPORTED_FILE[ENV_NAME]);

			const FILE_SIZE = 1024 * 1024 * 20;
			if (!isFileSizeLessOrEqual(file, FILE_SIZE))
				return toast.error(Variable.FILE_SIZE_ERROR[ENV_NAME]);

			setIsUploadingFile(true);
			const fileObj = {
				skill_document_proof: file,
				file_name: file?.name,
				id: Date.now(),
				is_newfile: true,
			};

			const formClone = { ...form };
			if (formClone.skill_document_proof) formClone.skill_document_proof.push(fileObj);
			else formClone.skill_document_proof = [{ ...fileObj }];

			setForm(formClone);
		}
	};

	const validateAndSave = async () => {
		const errorsData = {};
		if (!form.name) errorsData["name"] = true;
		if (!form.level) errorsData["level"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		setIsUploading(true);
		const docs = form?.skill_document_proof
			?.filter((doc) => !doc?.skill)
			?.map((doc) => doc?.skill_document_proof);

		if (skillId) {
			const body = {};
			Object.keys(monitor).forEach((key) => {
				if (key !== "skill_document_proof") body[key] = form[key];
			});

			onEdit && (await onEdit(body, skillId, docs));
		} else {
			const formClone = { ...form };
			if (isUploadingFile) formClone.skill_document_proof = docs; // Here, we are checking if we are adding any files or not

			onSave && (await onSave(formClone));
		}
		setIsUploading(false);
		setIsUploadingFile(false);
		// resetState();
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			skill_document_proof: form?.skill_document_proof?.filter((file) => file.id !== docId),
		});
	};

	const handleFileDelete = async () => {
		// There will be two kinds of delete
		// if there exists an skill for the document proof, then call the API
		// otherwise, filter out the skill documents
		const docId = showDeleteModal?.data?.id;

		const documentProof = form.skill_document_proof.find((doc) => doc.id === docId);
		if (documentProof?.skill) {
			const res = await makeRequest(
				generateRequestOptions("deleteSkillDocument", {
					urlParams: docId,
				}),
			);
			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowDeleteModal({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowDeleteModal({ show: false, data: null });
		}
	};

	// FUNC: Utilities
	const resetState = () => {
		setForm({});
		setMonitor({});
		setErrors({});
	};

	return (
		<>
			<ModalForm
				title={skillId ? Variable.EDIT_SKILL[ENV_NAME] : Variable.ADD_SKILLS[ENV_NAME]}
				footer={
					<div className="flex-all-center gap-md w-100">
						<Button
							type="outline"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={() => {
								onHide();
								resetState();
							}}
							title={Variable.CLOSE[ENV_NAME]}
						/>
						<Button
							type="primary"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={validateAndSave}
							title={Variable.SAVE[ENV_NAME]}
							disabled={isUploading}
						/>
					</div>
				}
				show={show && !showConfirm?.show}
				{...props}
			>
				<div className="pc-form">
					<Input
						label={Variable.SKILL_NAME[ENV_NAME]}
						placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Komunikasi`}
						value={form?.name || ""}
						name="name"
						onChange={onChange}
						inputType="label"
						className="w-100"
						isError={errors["name"]}
						required
					/>
					<Select
						label={Variable.LEVEL[ENV_NAME]}
						placeholder={Variable.LEVEL[ENV_NAME]}
						name="level"
						value={form?.level || "IDR"}
						items={SKILLS}
						onChange={onChange}
						searchable={false}
						className="w-100"
						isError={errors["level"]}
						noDefault
						required
					/>
					<div>
						<h5 className="text-md font-weight-medium text-label-gray mb-sm">
							{Variable.DOCUMENT[ENV_NAME]}
						</h5>
						<span className="text-sm">
							{Variable.DOCUMENT_EXPERIENCE_SUBTITLE[ENV_NAME]}
						</span>
					</div>
					<div className="input-btn">
						<Button
							type="secondary"
							size="md"
							title={
								<div className="flex-all-center gap-xxs">
									<PlusIcon
										width="1.8rem"
										height="1.8rem"
										stroke="white"
										strokeWidth="3"
									/>
									{Variable.ADD_FILE[ENV_NAME]}
								</div>
							}
							onClick={() => uploadRef?.current?.click()}
						/>
						<input
							id="upload-files"
							type="file"
							name="subjectIcon"
							className="inputfile d-none"
							onChange={(e) => {
								onFileChange(e);
								uploadRef.current.value = null;
							}}
							accept={ALLOWED_FILES.join(",")}
							ref={uploadRef}
						/>
					</div>
					{form?.skill_document_proof?.map((i) => (
						<FileCard
							key={i?.id}
							title={i?.file_name}
							icon={getIconByName(i?.file_name)}
							onDelete={() => setShowDeleteModal({ show: true, data: i })}
							showGreenCheck={false}
							isUploading={i?.is_newfile && isUploading}
							isNewFile={i?.is_newfile}
						/>
					))}
				</div>
			</ModalForm>

			<ModalInfo
				isShow={showDeleteModal?.show}
				isHide={() => setShowDeleteModal({ show: false, data: null })}
				onConfirm={handleFileDelete}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(SkillModal));
