import { Modal } from "react-bootstrap";
import * as Icons from "react-icons/bs";
import clsx from "clsx";

import * as Variable from "shared/utils/variables";
import Button from "shared/component/ButtonComponent/Button";
import { Text, Title } from "shared/component/Typography/Typography";
import { useEnvironment } from "shared/hooks/useEnvironment";

import "./ModalInfo.scss";

type ModalDeactivateProps = React.PropsWithChildren & {
	title?: string | React.ReactNode;
	show?: boolean;
	description?: string;
	okLabel?: string;
	cancelLabel?: string;
	header?: JSX.Element | boolean;
	footer?: JSX.Element | boolean;
	dialogClassName?: string;
	contentClassName?: string;
	onOk?: () => any;
};

const ModalInfo = ({ show, onOk, ...props }: ModalDeactivateProps) => {
	const ENV_NAME = useEnvironment();

	return (
		<Modal
			show={show}
			contentClassName={clsx("border-0 p-0", props.contentClassName)}
			onHide={onOk}
			dialogClassName={clsx(
				"modal-dialog-centered modal-info",
				props.header === false && "no-header",
				props.footer === false && "no-footer",
				props.dialogClassName,
			)}
		>
			<Modal.Body className="modal-info__modal-body">
				{props.header !== false ? (
					<div className="body-item body-item-header">
						{props.header || (
							<>
								<Title
									size="xs"
									fontWeight="semibold"
									className="modal-title-label"
								>
									{props.title}
								</Title>
								<Icons.BsX size={32} onClick={onOk} />
							</>
						)}
					</div>
				) : null}
				<div className="body-item">
					{props.description ? (
						<Text className="tw text-gray-500" fontWeight="medium">
							{props.description}
						</Text>
					) : (
						props.children
					)}
				</div>
				{props.footer !== false ? (
					<div className="d-flex modal-info__modal-body__button-groups body-item body-item-footer">
						{props.footer || (
							<Button
								type="primary"
								size="md"
								className="modal-info__modal-body__button-groups-wrapper"
								btnClassName="modal-info__modal-body__button-groups-btn"
								onClick={onOk}
								title={props.okLabel || Variable.CLOSE_LABEL[ENV_NAME]}
							/>
						)}
					</div>
				) : null}
			</Modal.Body>
		</Modal>
	);
};

export default ModalInfo;
