import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import cookies from "react-cookies";
import { Overlay } from "react-bootstrap";

import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import { homeUrl } from "../../shared/utils/apiEndPoints";

import "./SignUpComponent.scss";
import Image from "../../shared/component/UI/Image";
import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";

const SignUpComponent = ({ setLoginModalShow, ENV_NAME, ...props }) => {
  const [nextToForm, setNextToForm] = useState(false);

  const goToHomepage = () => {
    props.history.push(RoutePath.ROOT);
  };

  const goToDetailsRegister = () => {
    props.history.push(RoutePath.DETAILS_REGISTER);
  };

  return (
    <div className={`suc ${nextToForm ? "form" : "  "}`}>
      <div className="suc-main">
        <div className="suc-left container">
          <div className="suc-left-content">
            {/* {Variable.SIGN_UP_TITLE[ENV_NAME]} */}
          </div>
        </div>
        <div className="suc-right container">
          {nextToForm ? (
            <div className="suc-right-form">
              <h1>{Variable.REGISTER[ENV_NAME]}</h1>
              <div className="formInput">
                <Input
                  inputType="label"
                  spesificType="text"
                  label={Variable.FIRST_NAME[ENV_NAME]}
                  placeholder={Variable.FIRST_NAME[ENV_NAME]}
                  required
                />
                <Input
                  inputType="label"
                  spesificType="text"
                  label={Variable.LAST_NAME_OPTIONAL[ENV_NAME]}
                  placeholder={Variable.LAST_NAME[ENV_NAME]}
                />
                <Input
                  inputType="label"
                  spesificType="email"
                  label={Variable.EMAIL[ENV_NAME]}
                  placeholder={Variable.EMAIL[ENV_NAME]}
                  required
                />
                <Input
                  inputType="label"
                  spesificType="text"
                  label={Variable.REGISTERED_BUSINESS_NAME[ENV_NAME]}
                  placeholder={Variable.EG_REGISTERED_BUSINESS_NAME[ENV_NAME]}
                  required
                />
                <Input
                  inputType="phone"
                  label={Variable.PHONE[ENV_NAME]}
                  placeholder={Variable.PHONE_PLACEHOLDER[ENV_NAME]}
                  required
                />
                <Input
                  inputType="password"
                  label={Variable.PASSWORD[ENV_NAME]}
                  placeholder={Variable.PASSWORD[ENV_NAME]}
                  required
                />
              </div>
              <div>
                <Button
                  type="primary"
                  size="sm"
                  title={Variable.REGISTER_ACCOUNT[ENV_NAME]}
                  onClick={() => {
                    goToDetailsRegister();
                  }}
                  className="padTop-16"
                />
              </div>
            </div>
          ) : (
            <div className="suc-right-content">
              <h1>{Variable.VERIFICATION_TITLE[ENV_NAME]}</h1>
              <div className="sucInput">
                <Input
                  inputType="label"
                  label={Variable.EMAIL_USERNAME[ENV_NAME]}
                  placeholder={Variable.EMAIL_USERNAME_PLACEHOLDER[ENV_NAME]}
                />
              </div>
              <h3>{Variable.HELP_TITLE[ENV_NAME]}</h3>
              <div className="d-flex w-100">
                <Button
                  type="outline"
                  size="sm"
                  title={Variable.BACK_TO_LOGIN[ENV_NAME]}
                  onClick={() => {
                    goToHomepage();
                    setLoginModalShow(true);
                  }}
                />
                <Button
                  type="primary"
                  size="sm"
                  title={Variable.NEXT_LABEL[ENV_NAME]}
                  className="marLeft-auto"
                  btnClassName="padX-44"
                  onClick={() => setNextToForm(true)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {
    setLoginModalShow: (payload = true) => {
      if (payload) {
        dispatch({
          type: ActionTypes.OPEN_LOGIN_MODAL,
        });
      } else {
        dispatch({
          type: ActionTypes.CLOSE_LOGIN_MODAL,
        });
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(SignUpComponent));
