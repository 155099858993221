import { useState } from "react";
import { Form } from "react-bootstrap";

import usePost from "shared/hooks/usePost";
import useTranslate from "shared/hooks/useTranslate";
import Input from "shared/component/InputComponent/Input";
import Button from "shared/component/ButtonComponent/Button";
import ReportImage from "assets/svg/report_warning_icon.svg";

import "./ReportJobStyle.scss";
import { ReportJobAd } from "types/API/TypesSwagger";

type PropTypes = {
	jobId: number;
	onCancel: () => any;
	onSuccessReport: () => any;
};

const ReportJob = (props: PropTypes) => {
	const t = useTranslate();
	const reportJobPost = usePost<ReportJobAd>("reportJob");

	const radioOptions = [
		{
			name: t("SPAM_OR_SCAM"),
			value: "spam_or_scam",
		},
		{
			name: t("MISLEADING"),
			value: "misleading",
		},
		{
			name: t("DISCRIMINATION_OR_OFFENSIVE"),
			value: "discrimination_or_offensive",
		},
		{
			name: t("OTHER"),
			value: "other",
		},
	];

	const [radio, setRadio] = useState("");
	const [comment, setComment] = useState("");
	const [isError, setIsError] = useState(false);
	const [commentChanged, setCommentChanged] = useState(false);

	const onSubmitReport = async () => {
		await reportJobPost.postFunction({
			body: {
				reason: radio,
				comment: comment,
				report_status: "",
				job_ad: props.jobId,
			},
		});

		props.onSuccessReport();
	};

	const validateForm = (): boolean => {
		if (radio === "") return false;
		if (comment === "") return false;

		return true;
	};

	return (
		<>
			<div className="body-item-top">
				<img src={ReportImage} alt="Report" />
				<p>{t("REPORT_THIS_JOB")}</p>
			</div>
			<div className="body-item-reason">
				{!isError && <span className="label">{t("REASON_LABEL")}</span>}
				{isError && radio && <span className="label">{t("REASON_LABEL")}</span>}
				{isError && !radio && (
					<span
						className="label"
						style={{ color: "#1D2939", fontWeight: "600", fontSize: "16px" }}
					>
						{t("REASON_LABEL")}
					</span>
				)}
				{isError && !radio && (
					<span style={{ display: "block" }}>{t("SELECT_REASON")}</span>
				)}
				<div className="reason_option">
					{radioOptions.map((el) => (
						<Form.Check
							label={el.name}
							name={el.value}
							key={el.value}
							type={"radio"}
							value={el.value}
							onChange={() => {
								setRadio(el.value);
								setIsError(false);
							}}
							id={`inline-${el.value}-1`}
							checked={el.value === radio}
						/>
					))}
				</div>
			</div>
			<div className="body-item-comment">
				<span>{t("ADDITIONAL_COMMENTS")}</span>
				<Input
					inputType="textarea"
					characterLimit={400}
					value={comment}
					isError={comment === "" && commentChanged}
					noHint={comment === "" && commentChanged}
					placeholder={t("WITHDRAW_FREE_TEXT_PLACEHOLDER")}
					errorHintText={t("ERROR_MESSAGE")}
					onChange={(t: string) => {
						setComment(t);
						setCommentChanged(true);
					}}
				/>
			</div>
			<div className="body-item-action">
				<Button type="outline" size="sm" onClick={props.onCancel} title={t("CLOSE")} />
				<Button
					type="primary"
					size="sm"
					disabled={!validateForm()}
					onClick={onSubmitReport}
					title={t("SUBMIT_LABEL")}
				/>
			</div>
		</>
	);
};

export default ReportJob;
