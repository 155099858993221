import { SalaryRate } from "types/types";

export const t = (key, env, text) => {
	if (key == null || typeof key !== "object") {
		if (key == null) return "";
		return text || "";
	}
	return key[env];
};

export const CURRENCY_LIST = [
	{ id: "idr", name: "IDR", locale: "id-ID" },
	{ id: "usd", name: "USD", locale: "en-US" },
	{ id: "sgd", name: "SGD", locale: "en-SG" },
	{ id: "myr", name: "MYR", locale: "en-MY" },
	{ id: "inr", name: "INR", locale: "en-IN" },
	{ id: "php", name: "PHP", locale: "en-PH" },
	{ id: "aud", name: "AUD", locale: "en-AU" },
	{ id: "thb", name: "THB", locale: "th-TH" },
	{ id: "hkd", name: "HKD", locale: "zh-HK" },
	{ id: "eur", name: "EUR", locale: "de-DE" },
	{ id: "cny", name: "CNY", locale: "zh-CN" },
	{ id: "jpy", name: "JPY", locale: "ja-JP" },
	{ id: "gbp", name: "GBP", locale: "en-GB" },
	{ id: "krw", name: "KRW", locale: "ko-KR" },
	{ id: "vnd", name: "VND", locale: "vi-VN" },
	{ id: "bdt", name: "BDT", locale: "bn-BD" },
	{ id: "nzd", name: "NZD", locale: "en-NZ" },
];

// Log Status Handler
export const TOAST_SESSION_EXPIRED = {
	eng: "Session Expired",
	bhs: "Sesi berakhir",
};
export const TOAST_USER_NOT_VALID = {
	eng: "User Not Valid",
	bhs: "Pengguna Tidak Valid",
};

// Loading Component
export const LOADING_LABEL = { eng: "Loading...", bhs: "Memuat..." };

// No content Component
export const NO_CONTENT_DEFAULT_LABEL = {
	eng: "No Content",
	bhs: "Tidak ada konten",
};
export const NO_CONTENT_DEFAULT_DESCRIPTION = {
	eng: "No Content Available",
	bhs: "Tidak ada konten tersedia",
};

// Header
export const SIGN_UP_FREE = {
	eng: "Sign up FREE Now",
	bhs: "Daftar GRATIS sekarang",
};
export const LOGIN_PORTAL_KERJA = {
	eng: "Log in to Portal Kerja",
	bhs: "Masuk ke Portal Kerja",
};
export const HEADER_SRC_PLACEHOLDER = {
	eng: "Search for Major, College",
	bhs: "Cari Jurusan, Kampus",
};
export const REGISTER = {
	eng: "Sign up",
	bhs: "Daftar",
};
export const SIGN_IN = {
	eng: "Log in",
	bhs: "Masuk",
};
export const FAVORITE = {
	eng: "Favorite",
	bhs: "Favorit",
};
export const DASHBOARD = {
	eng: "Dashboard",
	bhs: "Dashboard",
};
export const LOGOUT = {
	eng: "Log out",
	bhs: "Keluar",
};
export const LOGGED_OUT = {
	eng: "Logged out",
	bhs: "Keluar Akun",
};
export const VIEW_PROFILE = {
	eng: "My Profile",
	bhs: "Profil Saya",
};
export const ACCOUNT_SETTINGS = {
	eng: "Account Settings",
	bhs: "Pengaturan Akun",
};
export const SETTINGS = {
	eng: "Settings",
	bhs: "Pengaturan",
};
export const ACCOUNT = {
	eng: "Account",
	bhs: "Akun",
};
export const NOTIFICATION = {
	eng: "Notification",
	bhs: "Notifikasi",
};
export const MY_ACCOUNT = {
	eng: "My Account",
	bhs: "Akun Saya",
};
export const SUCCESS = {
	eng: "Success",
	bhs: "Berhasil",
};
export const WARNING = {
	eng: "Warning",
	bhs: "Peringatan",
};
export const ERROR = {
	eng: "Error",
	bhs: "Kesalahan",
};
export const SUCCESS_DEACTIVATED = {
	eng: "Your account has been deactivated, please contact support team for reactivate account.",
	bhs: "Akun Anda telah dinonaktifkan, silakan hubungi tim dukungan untuk mengaktifkan kembali akun Anda.",
};
export const SUCCESS_CLOSED = {
	eng: "Your account has been closed.",
	bhs: "Akun Anda telah ditutup.",
};
export const CLOSE_TOOLTIP = {
	eng: "Your account will be permanently deleted, can't be recovered anymore.",
	bhs: "Akun Anda akan dihapus permanen, tidak dapat dipulihkan kembali.",
};
export const DEACTIVATE_TOOLTIP = {
	eng: "Your profile will be hidden and your account can only be reactivated if you contact our support team.",
	bhs: "Profile Anda akan tersembunyi dan akun Anda hanya dapat diaktifkan kembali jika Anda menghubungi tim support kami.",
};
export const WRONG_PASSWORD = {
	eng: "The password is incorrect",
	bhs: "Password tidak sesuai",
};
export const DEACTIVATE_LABEL = {
	eng: "Deactivate",
	bhs: "Nonaktifkan",
};
export const CLOSE_LABEL = {
	eng: "Close",
	bhs: "Tutup",
};
export const DEACTIVATE_ACCOUNT = {
	eng: "Deactivate Account",
	bhs: "Nonaktifkan Akun",
};
export const DEACTIVATE_ACCOUNT_CONFIRM = {
	eng: "Are you sure want to deactivate your account?",
	bhs: "Apakah Anda yakin ingin menonaktifkan akun Anda?",
};
export const CLOSE_ACCOUNT_CONFIRM_MODAL = {
	eng: "Are you sure you want to close your account?",
	bhs: "Apakah Anda yakin ingin menutup akun Anda?",
};
export const DEACTIVATE_ACCOUNT_TEXT = {
	eng: "Everyone needs a break once in a while",
	bhs: "Setiap orang butuh rehat sejenak",
};
export const DEACTIVATE_ACCOUNT_TEXT_1 = {
	eng: "Last step before hibernating your account",
	bhs: "Langkah terakhir sebelum hibernasi akun Anda",
};
export const DEACTIVATE_ACCOUNT_DESC = {
	eng: "Deactivate your account is the best option if you’d like to temporarily hide your Portal Kerja profile and activity but not close your account.",
	bhs: "Nonaktifkan akun Anda adalah opsi terbaik jika Anda ingin menyembunyikan sementara profil dan aktivitas Portal Kerja Anda tetapi tidak menutup akun Anda.",
};
export const DEACTIVATE_ACCOUNT_DESC_1 = {
	eng: "Deactivate your account means you'll not have access to your profile and any mentions of you will not be present on our site.",
	bhs: "Menonaktifkan akun Anda berarti Anda tidak akan memiliki akses ke profil Anda dan sebutan apa pun tentang Anda tidak akan muncul di situs kami.",
};
export const DEACTIVATE_ACCOUNT_TELL = {
	eng: "Tell us why you want to hibernate your account (optional)",
	bhs: "Beri tahu kami mengapa Anda ingin menonaktifkan akun Anda (opsional)",
};
export const CLOSE_ACCOUNT_TELL = {
	eng: "Tell us the reason for closing your account:",
	bhs: "Beri tahu kami mengapa Anda ingin menutup akun Anda:",
};
export const CLOSE_CONFIRM = {
	eng: "{0}, we’re sorry to see you go",
	bhs: "{0}, kami mohon maaf melihat Anda pergi",
};
export const DEACTIVATE_ACCOUNT_TEXTAREA = {
	eng: "We value your feedback, but please note that we aren’t able to respond to comments about your account submitted in this form.",
	bhs: "Kami menghargai masukan Anda, namun perlu diingat bahwa kami tidak dapat menanggapi komentar Anda dikirimkan dalam formulir ini.",
};
export const DEACTIVATE_ACCOUNT_TEXTAREA_1 = {
	eng: "For your security, enter your password to make this change",
	bhs: "Demi keamanan Anda, masukkan kata sandi Anda untuk melakukan perubahan ini",
};
export const ENTER_DESCRIPTION = {
	eng: "Enter a description...",
	bhs: "Masukkan deskripsi...",
};
export const REQUIRED_FIELD = {
	eng: "This field is required",
	bhs: "Field ini harus di isi",
};
export const MUST_SAME = {
	eng: "New Password and Confirmation Password must be the same",
	bhs: "Kata Sandi Baru dan Kata Sandi Konfirmasi harus sama",
};
export const PASSWORD_VALID = {
	eng: "Password must be at least 8 characters long. Must contain letters, numbers and special characters.",
	bhs: "Password minimal 8 karakter. Harus berisi huruf, angka dan karakter khusus.",
};
export const CURRENT_PASSWORD_WRONG = {
	eng: "Incorrect password, please retype your password",
	bhs: "Kata Sandi salah, silakan ketikan kembali kata sandi Anda ",
};
export const CURRENT_PASSWORD_SAME = {
	eng: "Old password is same as new password",
	bhs: "Kata sandi lama sama dengan kata sandi baru",
};
export const I_NEED_BREAK = {
	eng: "I need a break",
	bhs: "Saya butuh istirahat",
};
export const GETTING_MANY_EMAILS = {
	eng: "I'm getting too many emails",
	bhs: "Saya mendapat terlalu banyak email",
};
export const PRIVACY_CONCERN = {
	eng: "I have a privacy concern",
	bhs: "Saya memiliki masalah privasi",
};
export const SAFETY_CONCERN = {
	eng: "I have a safety concern",
	bhs: "Saya memiliki masalah keamanan",
};
export const OTHER = {
	eng: "Other",
	bhs: "Lainnya",
};
export const DUPLICATE_ACCOUNT = {
	eng: "I have a duplicate account",
	bhs: "Saya memiliki akun duplikat",
};
export const NOT_GETTING_VALUE = {
	eng: "I’m not getting any value from my membership",
	bhs: "Saya tidak mendapatkan manfaat apa pun dari keanggotaan saya",
};
export const UNWANTED_CONTRACT = {
	eng: "I’m receiving unwanted contact",
	bhs: "Saya menerima kontak yang tidak diinginkan",
};
export const CLOSE_ACCOUNT = {
	eng: "Close Account",
	bhs: "Tutup Akun",
};
export const CLOSE_ACCOUNT_REASON = {
	eng: "Reason for closing account",
	bhs: "Alasan penutupan akun",
};
export const ENTER_CURRENT_PASSWORD = {
	eng: "Enter Current Password",
	bhs: "Masukkan Kata Sandi Saat Ini",
};
export const ENTER_NEW_PASSWORD = {
	eng: "Enter New Password",
	bhs: "Masukkan Kata Sandi Baru",
};
export const CONFIRM_NEW_PASSWORD = {
	eng: "Confirm New Password",
	bhs: "Konfirmasi Kata Sandi",
};
export const CLOSE_ACCOUNT_SUB_2 = {
	eng: "Enter your password to close this account",
	bhs: "Masukkan kata sandi Anda untuk menutup akun ini",
};
export const CLOSE_ACCOUNT_CONFIRM = {
	eng: "Are you sure you want to close your account? You’ll lose your connections, messages, endorsements, and recommendations.",
	bhs: "Apakah Anda yakin ingin menutup akun Anda? Anda akan kehilangan koneksi, pesan, dukungan, dan rekomendasi.",
};
export const DA_1 = {
	eng: "Temporarily hide the Job Seeker profile and activity but it will not delete the account. We will ask the user the category of reason and additional note to submit.",
	bhs: "Sembunyikan sementara profil dan aktivitas Pencari Kerja namun tidak akan menghapus akun. Kami akan menanyakan kepada pengguna kategori alasan dan catatan tambahan untuk dikirimkan.",
};
export const DA_2 = {
	eng: "Login won’t reactivate the Job Seeker account",
	bhs: "Login tidak akan mengaktifkan kembali akun Pencari Kerja",
};
export const CA_1 = {
	eng: "Permanently delete the account, all data will be gone. Please consider to keep show the company review, Job application and submissions in future from some users like this.",
	bhs: "Hapus akun secara permanen, semua data akan hilang. Harap pertimbangkan untuk terus menampilkan ulasan perusahaan, lamaran pekerjaan, dan kiriman di masa mendatang dari beberapa pengguna seperti ini.",
};
export const CA_2 = {
	eng: "In order to delete the account, the Job Seeker need to cancel all of the current active job applications first",
	bhs: "Untuk menghapus akun, Pencari Kerja harus membatalkan semua lamaran pekerjaan yang sedang aktif terlebih dahulu",
};
export const CA_3 = {
	eng: "We will still keep some users data in some data schema, but we will remove (or rename into deleted account/data) the personal data such as: Name, Email, Mobile Phone 1&2, All files attached (resume, portfolio, and all uploaded files)",
	bhs: "Kami akan tetap menyimpan beberapa data pengguna dalam beberapa skema data, namun kami akan menghapus (atau mengganti nama menjadi akun/data yang dihapus) data pribadi seperti: Nama, Email, Ponsel 1&2, Semua file terlampir (resume, portofolio, dan semua yang diunggah file)",
};
export const PASSWORD_SECURITY = {
	eng: "For your security, enter your password to make this change",
	bhs: "Demi keamanan Anda, masukkan kata sandi Anda untuk melakukan perubahan ini",
};
export const RESOURCE_NOT_FOUND = {
	eng: "Resource Not Found",
	bhs: "Sumber Tidak Ditemukan",
};
export const PLEASE_FILL_INPUT = {
	eng: "Please filled input correctly",
	bhs: "Harap isi input dengan benar",
};
export const MY_JOBS = {
	eng: "My Jobs",
	bhs: "My Jobs",
};
export const HELP = {
	eng: "Help",
	bhs: "Bantuan",
};
export const FOR_EMPLOYER = {
	eng: "For Employer",
	bhs: "Untuk Perusahaan",
};
export const COMING_SOON = {
	eng: "Coming soon",
	bhs: "Segera hadir",
};
export const COMING_SOON_AVAILABLE = {
	eng: "This feature will be available in the near future",
	bhs: "Fitur ini akan hadir dalam waktu dekat",
};

// Footer
export const CONTACT_US = {
	eng: "Contact Us",
	bhs: "Hubungi Kami",
};
export const PRIVACY_POLICY = {
	eng: "Privacy Policy",
	bhs: "Kebijakan Privasi",
};
export const TNC = {
	eng: "Terms & Conditions",
	bhs: "Syarat dan Ketentuan",
};
export const MAIL_TO_SUBJECT = {
	eng: "Inquiry / Complaint",
	bhs: "Pertanyaan / Keluhan",
};

// Home Component
export const COLLEGE = {
	eng: "College",
	bhs: "Kampus",
};
export const MAJOR = {
	eng: "Major",
	bhs: "Jurusan",
};
export const CAREER = {
	eng: "Career",
	bhs: "Karier",
};
export const SCHOLARSHIP = {
	eng: "Scholarship",
	bhs: "Beasiswa",
};
export const SEARCH_COLLEGE_CITY = {
	eng: "Search colleges located in {0}",
	bhs: "Cari kampus yang terletak di {0}",
};
export const NO_ITEM_FOUND_QUERY = {
	eng: "No item found for {0}",
	bhs: "Tidak ada {0} ditemukan",
};
export const CITY = {
	eng: "City",
	bhs: "Kota",
};
export const COUNTRY = {
	eng: "Country",
	bhs: "Negara",
};
export const RECENTLY_VIEW_COLLEGE = {
	eng: "Recently Viewed College",
	bhs: "Kampus yang Terakhir Dilihat",
};
export const RECENTLY_VIEW_MAJOR = {
	eng: "Recently Viewed Major",
	bhs: "Jurusan yang Terakhir Dilihat",
};
export const OUR_COLLEGE_RECOMMENDATION = {
	eng: "Our Recommended College",
	bhs: "Kampus Rekomendasi Kami",
};
export const OUR_MAJOR_RECOMMENDATION = {
	eng: "Our Recommended Major",
	bhs: "Jurusan Rekomendasi Kami",
};
export const VIEW_ALL = {
	eng: "View All",
	bhs: "Lihat Semua",
};
export const LOGOUT_SUCCEED = {
	eng: "User logged out",
	bhs: "Pengguna berhasil keluar",
};
export const ACCREDITATION = {
	eng: "Accreditation",
	bhs: "Akreditasi",
};
export const ASIAN_RANKING = {
	eng: "QS Asia Ranking",
	bhs: "QS Asia Ranking",
};
export const WORLD_RANKING = {
	eng: "QS World Ranking",
	bhs: "QS World Ranking",
};
export const START = {
	eng: "Start",
	bhs: "Mulai dari",
};
export const SMT = {
	eng: "smt",
	bhs: "smt",
};

// PS Login Modal
export const EMAIL_USERNAME = {
	eng: "Email/Username",
	bhs: "Email/Username",
};
export const PASSWORD = {
	eng: "Password",
	bhs: "Kata Sandi",
};
export const FORGOT_PASSWORD = {
	eng: "Forgot Password",
	bhs: "Lupa Kata Sandi",
};
export const FILL_CORRECT_PASSWORD = {
	eng: "Password Empty",
	bhs: "Kata Sandi kosong",
};
export const FILL_CORRECT_USER_ID = {
	eng: "Email/Username Empty",
	bhs: "Email/Nama Pengguna kosong",
};
export const FILL_CORRECT_USER_ID_PASSWORD = {
	eng: "User Name & Password Empty",
	bhs: "Nama Pengguna & Kata Sandi kosong",
};

// Login Modal
export const LOGIN = {
	eng: "Login",
	bhs: "Masuk",
};
export const OR_FILL_FORM_BELOW = {
	eng: "Or fill the form below",
	bhs: "Atau isi form di bawah",
};
export const NEW_USER = {
	eng: "New User",
	bhs: "Pengguna Baru",
};
export const CLICK_HERE_CREATE_ACCOUNT = {
	eng: "Click here to create an account!",
	bhs: "Klik di sini untuk buat akun!",
};
export const LOGIN_SUCCESS = {
	eng: "Login success!",
	bhs: "Berhasil masuk!",
};
export const ERROR_INVALID_LOGIN = {
	eng: "Login failed, please check your username and/or password!",
	bhs: "Gagal untuk masuk, silakan periksa username dan/atau password kamu!",
};

// Create Account
export const LETS_CREATE_ACCOUNT = {
	eng: "Let's create a Direktori Kampus account",
	bhs: "Ayo buat akun Direktori Kampus",
};
export const SIGNUP_WITH_GOOGLE = {
	eng: "Sign up with Google",
	bhs: "Daftar dengan Google",
};
export const ENTER = {
	eng: "Enter",
	bhs: "Masukkan",
};
export const FIRST_NAME = {
	eng: "First Name",
	bhs: "Nama Depan",
};
export const LAST_NAME = {
	eng: "Last Name",
	bhs: "Nama Belakang",
};
export const EMAIL = {
	eng: "Email",
	bhs: "Email",
};
export const USERNAME = {
	eng: "Username",
	bhs: "Nama Pengguna",
};
export const ALREADY_HAVE_ACCOUNT = {
	eng: "Already have an account?",
	bhs: "Sudah punya akun?",
};
export const CLICK_HERE_LOGIN = {
	eng: "Click here to log in",
	bhs: "Klik di sini untuk masuk",
};
export const CONFIRM_PASSWORD = {
	eng: "Confirm Password",
	bhs: "Konfirmasi Kata Sandi",
};
export const PASSWORD_NOT_MATCHED = {
	eng: "The passwords entered do not match",
	bhs: "Kata Sandi yang dimasukkan tidak cocok",
};
export const ACCEPT_PRIVACY_POLICY = {
	eng: "I accept with the privacy policy of",
	bhs: "Saya setuju dengan kebijakan privasi",
};
export const PARENT_UNDERAGE_CONSENT = {
	eng: "This agreement needs to be given to your parent if you're still under legal age.",
	bhs: "Persetujuan ini harus diberikan oleh orang tua Anda jika Anda masih di bawah umur.",
};
export const REGISTER_SUCCESS = {
	eng: "Successfully creating a new account",
	bhs: "Berhasil membuat akun baru",
};
export const EMAIL_INVALID = {
	eng: "Invalid email!",
	bhs: "Email tidak valid!",
};
export const USERNAME_ACCEPT = {
	eng: "Username can only accept letters, digits, @, ., +, -, and _",
	bhs: "Username/Nama Pengguna hanya menerima huruf, angka, @, ., +, -, dan _",
};

// Forgot Password
export const FORGOT_PASSWORD_MESSAGE = {
	eng: "Please enter your username",
	bhs: "Silakan masukkan nama pengguna anda",
};
export const FORGOT_PASSWORD_MAIL_SENT = {
	eng: "We have sent a confirmation email. please check your email to reset your password",
	bhs: "Kami telah mengirimkan email konfirmasi. silakan cek email Anda untuk mengatur ulang kata sandi Anda",
};
export const FORGOT_PASSWORD_MAIL_SENT_SHORT = {
	eng: "Confirmation Email Sent!",
	bhs: "Email konfirmasi terkirim!",
};
export const SEND = {
	eng: "Send",
	bhs: "Kirim",
};
export const RESEND = {
	eng: "Resend",
	bhs: "Kirim Ulang",
};
export const BACK_TO_HOME = {
	eng: "Back to the Homepage",
	bhs: "Kembali ke Halaman Utama",
};
export const HAVENT_GOT_EMAIL = {
	eng: "Haven't got e-mail?",
	bhs: "Belum mendapatkan e-mail?",
};
export const PASSWORD_RESET_TITLE = {
	eng: "Your password has been succesfully reset.",
	bhs: "Kata Sandi/Password sudah diatur ulang",
};
export const SET_NEW_PASSWORD_TITLE = {
	eng: "Set your new password",
	bhs: "Atur kata sandi baru anda",
};
export const PASSWORD_RESET = {
	eng: "Password Reset",
	bhs: "Kata Sandi/Password diatur ulang",
};
export const SET_NEW_PASSWORD = {
	eng: "Set New Password",
	bhs: "Atur Kata Sandi Baru",
};

// Profile Detail
export const EDIT_PROFILE = {
	eng: "Edit Profile",
	bhs: "Ubah Profil",
};
export const CHANGE_PASSWORD = {
	eng: "Change Password",
	bhs: "Ubah Kata Sandi",
};
export const CURRENT_PASSWORD = {
	eng: "Current Password",
	bhs: "Kata Sandi Saat Ini",
};
export const NEW_PASSWORD = {
	eng: "New Password",
	bhs: "Kata Sandi Baru",
};
export const SAVE_PROFILE = {
	eng: "Save Profile",
	bhs: "Simpan Profil",
};
export const SAVE_CHANGE = {
	eng: "Save Change",
	bhs: "Simpan Perubahan",
};
export const PLEASE_FILL_NECESSARY_FORM = {
	eng: "Please fill all necessary form",
	bhs: "Mohon mengisi bagian yang wajib diisi",
};
export const PROFILE_DETAIL_CHANGED = {
	eng: "Profile detail changed!",
	bhs: "Detil profil diubah!",
};
export const PASSWORD_CHANGED = {
	eng: "Password Changed Successfully",
	bhs: "Kata Sandi Berhasil Diubah",
};
export const PASSWORD_CHANGED_MSG = {
	eng: "Because of security reasons you need to relogin.",
	bhs: "Karena alasan keamanan, Anda perlu masuk kembali.",
};
export const PROFILE = {
	eng: "Profile",
	bhs: "Profil",
};

// Listing
export const SHOWING = {
	eng: "Showing",
	bhs: "Menampilkan",
};
export const COLLEGES = {
	eng: "College(s)",
	bhs: "Kampus",
};
export const MAJORS = {
	eng: "Major(s)",
	bhs: "Jurusan",
};
export const CAREERS = {
	eng: "Careers(s)",
	bhs: "Karier",
};
export const SCHOLARSHIPS = {
	eng: "Scholarships(s)",
	bhs: "Beasiswa",
};
export const VIEW_MORE = {
	eng: "View More",
	bhs: "Tampilkan Lebih Banyak",
};
export const FILTERS = {
	eng: "Filters",
	bhs: "Filter",
};

// Filter Box
export const SRC_PLACEHOLDER = {
	eng: "Search...",
	bhs: "Cari...",
};
export const SRC_BY_PLACEHOLDER = {
	eng: "Search {0} Name",
	bhs: "Cari Nama {0}",
};
export const RESET = {
	eng: "Reset",
	bhs: "Reset",
};
export const APPLY = {
	eng: "Apply",
	bhs: "Terapkan",
};

// College Listing
export const LOCATION = {
	eng: "Location",
	bhs: "Lokasi",
};
export const SRC_LOCATION = {
	eng: "Search Location",
	bhs: "Cari Lokasi",
};
export const SRC_ACCREDITATION = {
	eng: "Search Accreditation",
	bhs: "Cari Akreditasi",
};
export const DEGREE = {
	eng: "Degree",
	bhs: "Level Studi",
};
export const SRC_MAJOR = {
	eng: "Search Major",
	bhs: "Cari Jurusan",
};
export const ALL_MAJORS = {
	eng: "All majors",
	bhs: "Semua jurusan",
};
export const DIPLOMA_1 = {
	eng: "Diploma 1",
	bhs: "Diploma 1",
};
export const DIPLOMA_2 = {
	eng: "Diploma 2",
	bhs: "Diploma 2",
};
export const DIPLOMA_3 = {
	eng: "Diploma 3",
	bhs: "Diploma 3",
};
export const DIPLOMA_4 = {
	eng: "Diploma 4",
	bhs: "Diploma 4",
};
export const UNDERGRAD = {
	eng: "Undergraduate",
	bhs: "Sarjana",
};
export const POSTGRAD = {
	eng: "Postgraduate",
	bhs: "Pasca Sarjana",
};
export const DOCTORAL = {
	eng: "Doctoral",
	bhs: "Doktoral",
};
export const SOMETHING_WENT_WRONG_TRY_AGAIN = {
	eng: "Something went wrong please try again after some time!",
	bhs: "Ada yang tidak beres, coba lagi setelah beberapa saat!",
};
export const STATE_UNIVERSITY_ABBV = {
	eng: "State",
	bhs: "Negeri",
};
export const PRIVATE_UNIVERSITY_ABBV = {
	eng: "Private",
	bhs: "Swasta",
};
export const SHOW_ALL = {
	eng: "Show All",
	bhs: "Lihat Semua",
};
export const YES = {
	eng: "Yes",
	bhs: "Ya",
};
export const NO = {
	eng: "No",
	bhs: "Tidak",
};
export const NO_ACCREDITATION = {
	eng: "No Accreditation",
	bhs: "Tidak Terakreditasi",
};
export const OTHER_ACCREDITATION = {
	eng: "Other Accreditation",
	bhs: "Akreditasi Lainnya",
};
export const SELECTED = {
	eng: "{0} selected",
	bhs: "{0} terpilih",
};
export const SELECT_LABEL = {
	eng: "Select",
	bhs: "Pilih",
};
export const RESULT_LABEL = {
	eng: "Result",
	bhs: "Result",
};

// Major Listing
export const FACULTY_NAME = {
	eng: "Faculty Name",
	bhs: "Bidang Studi",
};
export const TUITION_FEE_SMT = {
	eng: "Tuition Fee per semester",
	bhs: "Biaya Per Semester",
};
export const ALL_FACULTY = {
	eng: "All Faculties",
	bhs: "Semua bidang studi",
};
export const ALL_TUITION = {
	eng: "All Tuition",
	bhs: "Semua biaya",
};
export const SRC_FACULTY = {
	eng: "Search Faculty",
	bhs: "Cari Fakultas",
};

// Career Listing
export const SECTOR = {
	eng: "Sector",
	bhs: "Karier",
};
export const ALL_SECTOR = {
	eng: "All Sectors",
	bhs: "Semua Karier",
};

// Scholarship Listing
export const SCHOLARSHIP_GIVER = {
	eng: "Scholarship giver",
	bhs: "Jenis",
};
export const ALL_SCHOLARSHIP_GIVER = {
	eng: "All Scholarship Giver",
	bhs: "Semua Jenis",
};
export const GOVERNMENT = {
	eng: "Government",
	bhs: "Pemerintah",
};
export const ORGANIZATION = {
	eng: "Organization",
	bhs: "Organisasi",
};
export const INTERNATIONAL = {
	eng: "International",
	bhs: "Internasional",
};
export const DESTINATION = {
	eng: "Destination",
	bhs: "Tujuan",
};
export const ALL_DESTINATION = {
	eng: "All Destinations",
	bhs: "Semua Tujuan",
};
export const DOMESTIC = {
	eng: "Domestic",
	bhs: "Dalam Negeri",
};
export const ABROAD = {
	eng: "Abroad",
	bhs: "Luar Negeri",
};
export const INTERNATIONAL_DESTINATION = {
	eng: "International",
	bhs: "Luar Negeri",
};
export const UNTIL = {
	eng: "Until",
	bhs: "Hingga",
};

// College Details
export const GENERAL_INFORMATION = {
	eng: "General Information",
	bhs: "Informasi Umum",
};
export const COLLEGE_NOT_FOUND = {
	eng: "Selected college not found!",
	bhs: "Tidak dapat menemukan kampus terpilih",
};
export const RANKING = {
	eng: "Ranking",
	bhs: "Ranking",
};
export const BASED_ON_WEBOMETRIC_INDO = {
	eng: "Based on Webometrics Country Rank in Indonesia",
	bhs: "Berdasarkan Webometrics Country Rank di Indonesia",
};
export const STATE_UNIVERSITY = {
	eng: "State University",
	bhs: "Universitas Negeri",
};
export const PRIVATE_UNIVERSITY = {
	eng: "Private University",
	bhs: "Universitas Swasta",
};
export const ADD_TO_FAVORITES = {
	eng: "Add to Favorites",
	bhs: "Tambahkan ke Favorit",
};
export const DOWNLOAD_BROCHURE = {
	eng: "Download Brochure",
	bhs: "Download Brosur",
};
export const REMOVE_FROM_FAVORITES = {
	eng: "Remove from Favorites",
	bhs: "Hapus dari Favorit",
};
export const STUDENT = {
	eng: "Student",
	bhs: "Mahasiswa",
};
export const LECTURER = {
	eng: "Lecturer",
	bhs: "Dosen",
};
export const WEBSITE = {
	eng: "Website",
	bhs: "Website",
};
export const PHONE = {
	eng: "Phone",
	bhs: "Nomor Telepon",
};
export const WHATSAPP = {
	eng: "WhatsApp",
	bhs: "WhatsApp",
};
export const DATA_NOT_AVAILABLE = {
	eng: "Data is not available",
	bhs: "Data tidak tersedia",
};
export const COLLEGE_DESCRIPTION = {
	eng: "College Description",
	bhs: "Deskripsi Kampus",
};
export const SRC_MAJOR_PLACEHOLDER = {
	eng: "Search Major",
	bhs: "Cari Jurusan",
};
export const FACILITIES = {
	eng: "Facilities",
	bhs: "Fasilitas",
};
export const FACULTIES_AND_MAJORS = {
	eng: "Faculties and Majors",
	bhs: "Fakultas dan Jurusan",
};
export const GENERAL_TUITION_FEES = {
	eng: "General Tuition Fees",
	bhs: "Biaya Kuliah",
};
export const SCHOLARSHIP_INFORMATION = {
	eng: "Scholarship Information",
	bhs: "Informasi Beasiswa",
};
export const MORE_INFO = {
	eng: "More Info",
	bhs: "Informasi Lebih Banyak",
};
export const TESTIMONY = {
	eng: "Testimony",
	bhs: "Testimoni",
};
export const LOCATIONS = {
	eng: "Location(s)",
	bhs: "Lokasi",
};
export const OPEN_IN_MAP = {
	eng: "Open in Map",
	bhs: "Lihat di Peta",
};
export const CAMPUS_NUM = {
	eng: "Campus {0}",
	bhs: "Kampus {0}",
};

// Major Details
export const MAJOR_NOT_FOUND = {
	eng: "Selected major not found!",
	bhs: "Tidak dapat menemukan jurusan terpilih",
};
export const FACULTY = {
	eng: "Faculty",
	bhs: "Fakultas",
};
export const MAJOR_DESCRIPTION = {
	eng: "Major Description",
	bhs: "Deskripsi Jurusan",
};
export const REGISTRATION_FEE = {
	eng: "Registration Fee",
	bhs: "Biaya Pendaftaran",
};
export const STUDY_TIME = {
	eng: "Study Time (Years)",
	bhs: "Lama Studi (Tahun)",
};
export const SKS = {
	eng: "SKS",
	bhs: "SKS",
};
export const NUMBER_STUDENT = {
	eng: "Number of Student",
	bhs: "Jumlah Siswa",
};
export const NUMBER_LECTURER = {
	eng: "Number of Lecturer",
	bhs: "Jumlah Dosen",
};
export const MAJOR_WEBSITE = {
	eng: "Major Website",
	bhs: "Website Jurusan",
};
export const MAJOR_EMAIL = {
	eng: "Major Email",
	bhs: "Email Jurusan",
};
export const MAJOR_PHONE = {
	eng: "Major Phone",
	bhs: "Telepon Jurusan",
};
export const REGISTRATION_INFO = {
	eng: "Registration Info",
	bhs: "Info Registrasi",
};
export const STUDY_FEES = {
	eng: "Study Fees",
	bhs: "Biaya Kuliah",
};
export const CURRICULUM = {
	eng: "Curriculum",
	bhs: "Kurikulum",
};
export const ACHIEVEMENTS = {
	eng: "Achievements",
	bhs: "Prestasi",
};
export const CONCENTRATION = {
	eng: "Concentration",
	bhs: "Konsentrasi",
};
export const CARRER_PATH = {
	eng: "Career Path",
	bhs: "Karier Terkait",
};
export const CARRER_PATH_DESC = {
	eng: "If you study in this major, you can work as:",
	bhs: "Jika kamu kuliah di jurusan ini, maka kamu dapat bekerja sebagai:",
};

// Career Details
export const CAREER_NOT_FOUND = {
	eng: "Selected career not found!",
	bhs: "Tidak dapat menemukan karier terpilih",
};
export const START_FROM = {
	eng: "Starts from",
	bhs: "Dimulai dari",
};
export const SALARY = {
	eng: "Salary",
	bhs: "Gaji",
};
export const MIN_DEGREE = {
	eng: "Minimum Degree",
	bhs: "Minimal Pendidikan",
};
export const CERTIFICATION = {
	eng: "Certification",
	bhs: "Sertifikasi",
};
export const CAREER_DESCRIPTION = {
	eng: "Career Description",
	bhs: "Deskripsi Karier",
};
export const FACT_STATS = {
	eng: "Fact Stats",
	bhs: "Fakta dan Statistik",
};
export const ROLE_RESPONSIBILITIES = {
	eng: "Role and Responsibilities",
	bhs: "Peran dan Tanggung Jawab",
};
export const REQUIRED_SKILLS = {
	eng: "Knowledge and Skills Required",
	bhs: "Pengetahuan dan Keahlian",
};
export const CAREER_PATH = {
	eng: "Career Path",
	bhs: "Jenjang Karier",
};
export const RELATED_MAJOR = {
	eng: "Faculty/Major Related",
	bhs: "Fakultas/Jurusan Terkait",
};

// Scholarship Details
export const SCHOLARSHIP_NOT_FOUND = {
	eng: "Selected scholarship not found!",
	bhs: "Tidak dapat menemukan beasiswa terpilih",
};
export const REGISTRATION_PERIOD = {
	eng: "Registration Period",
	bhs: "Pendaftaran",
};
export const SCHOLARSHIP_DESCRIPTION = {
	eng: "Scholarship Description",
	bhs: "Deskripsi Beasiswa",
};
export const REGISTRATION_TIMELINE = {
	eng: "Registration Timeline",
	bhs: "Info Proses Pendaftaran",
};
export const IMPORTANT_INFO = {
	eng: "Important Info",
	bhs: "Info Penting",
};
export const DOCUMENTS_REQUIRED = {
	eng: "Documents Required",
	bhs: "Berkas yang dibutuhkan",
};
export const SPECIAL_REQS = {
	eng: "Special Requirements",
	bhs: "Syarat khusus yang perlu diperhatikan",
};
export const LEVEL_OF_COMPS = {
	eng: "Level of Competition",
	bhs: "Tingkat persaingan untuk beasiswa",
};
export const SELECTION_PERIOD = {
	eng: "Selection Period",
	bhs: "Periode seleksi beasiswa",
};
export const CHECKING_ANNOUNCEMENT = {
	eng: "Checking Announcement",
	bhs: "Bagaimana cara mengetahui saya diterima atau tidak?",
};
export const OTHER_EXPLANATION = {
	eng: "Other Explanation",
	bhs: "Penjelasan lain-lain",
};
export const WHAT_BENEFIT = {
	eng: "What benefit you will get?",
	bhs: "Benefit apa saja yang didapat?",
};

// Favorite page
export const HOME = {
	eng: "Home",
	bhs: "Home",
};
export const NO_FAVORITE_TITLE = {
	eng: "You haven't save any favorite {0}",
	bhs: "Anda belum menyimpan {0} favorit Anda",
};
export const NO_FAVORITE_DESC = {
	eng: "Go to the {0} page here and mark as favorite",
	bhs: "Lihat halaman {0} di sini dan tandai sebagai favorit",
};
export const GENERAL_PAGE = {
	eng: "{0} Page",
	bhs: "Halaman {0}",
};
export const FAILED_FETCH_FAVORITES = {
	eng: "Failed to fetch favorites",
	bhs: "Gagal mendapatkan data favorit",
};
export const SUCCESS_ADD_FAVORITES = {
	eng: "Successfully adding this {0} to the favorite",
	bhs: "Berhasil menambahkan {0} ini ke favorit",
};
export const SUCCESS_REMOVE_FAVORITES = {
	eng: "Successfully removing this {0} from the favorite",
	bhs: "Berhasil menghapus {0} ini dari favorit",
};
export const FAILED_ADD_FAVORITES = {
	eng: "Failed to add this {0} to the favorite",
	bhs: "Gagal menambahkan {0} ini ke favorit",
};
export const FAILED_REMOVE_FAVORITES = {
	eng: "Failed to remove this {0} from the favorite",
	bhs: "Gagal menghapus {0} ini dari favorit",
};
export const CANNOT_GET_DATA = {
	eng: "Cannot get the sufficient data to do this operation",
	bhs: "Tidak dapat mendapatkan data untuk melakukan operasi ini",
};
export const SCROLL_TO_TOP = {
	eng: "Scroll to Top",
	bhs: "Scroll ke atas",
};

// Component
export const BUTTON_CTA = {
	eng: "Button CTA",
	bhs: "Button CTA",
};
export const LOCATION_LABEL = {
	eng: "Location",
	bhs: "Lokasi",
};
export const SEARCH_LABEL = {
	eng: "Search",
	bhs: "Pencarian",
};
export const COLLEGE_LABEL = {
	eng: "College",
	bhs: "Universitas",
};
export const LABEL_NAME = {
	eng: "Label",
	bhs: "Label",
};
export const DATE_LABEL = {
	eng: "Date Post",
	bhs: "Tanggal Unggah",
};
export const JOB_TYPE_LABEL = {
	eng: "Job Type",
	bhs: "Tipe Pekerjaan",
};
export const WORK_MODE_LABEL = {
	eng: "Work Mode",
	bhs: "Mode Kerja",
};
export const EXPERIENCE_LEVEL_LABEL = {
	eng: "Experience Level",
	bhs: "Level Pengalaman",
};
export const INDUSTRY_LABEL = {
	eng: "Industry",
	bhs: "Industri",
};
export const UNDER_TEN_APP_LABEL = {
	eng: "Under 10 Application",
	bhs: "Dibawah 10 pendaftar",
};
export const ANY_TIME_LABEL = {
	eng: "Any time",
	bhs: "Semua Waktu",
};
export const LAST_24_HOURS = {
	eng: "Last 24 hours",
	bhs: "24 Jam Lalu",
};
export const LAST_3_DAYS = {
	eng: "Last 3 days",
	bhs: "3 Hari Lalu",
};
export const LAST_7_DAYS = {
	eng: "Last 7 days",
	bhs: "7 Hari Lalu",
};
export const LAST_14_DAYS = {
	eng: "Last 14 days",
	bhs: "14 Hari Lalu",
};
export const LAST_30_DAYS = {
	eng: "Last 30 days",
	bhs: "30 Hari Lalu",
};
export const ONSITE_LABEL = {
	eng: "On site",
	bhs: "Di tempat",
};
export const HYBRID_LABEL = {
	eng: "Hybrid",
	bhs: "Hibrid",
};
export const REMOTE_LABEL = {
	eng: "Remote",
	bhs: "Remote",
};
export const FULL_TIME_LABEL = {
	eng: "Full Time",
	bhs: "Penuh Waktu",
};
export const PART_TIME_LABEL = {
	eng: "Part Time",
	bhs: "Paruh Waktu",
};
export const FRELANCE_LABEL = {
	eng: "Freelance",
	bhs: "Pekerja Lepas",
};
export const CONTRACT_LABEL = {
	eng: "Contract",
	bhs: "Kontrak",
};
export const INTERNSHIP_LABEL = {
	eng: "Internship",
	bhs: "Magang",
};
export const TEMPORARY_LABEL = {
	eng: "Temporary",
	bhs: "Sementara",
};
export const ENTRY_LEVELLABEL = {
	eng: "Entry Level",
	bhs: "Pemula",
};
export const ASSOCIATE_LABEL = {
	eng: "Associate",
	bhs: "Associate",
};
export const INTERMEDIATE_SENIOR_LABEL = {
	eng: "Intermediate - Senior",
	bhs: "Menengah - Senior",
};
export const DIRECTOR_LABEL = {
	eng: "Director",
	bhs: "Direktur",
};
export const EXECUTIVE_LABEL = {
	eng: "Executive",
	bhs: "Ekksekutif",
};
export const INPUT_PLACEHOLDER = {
	eng: "Input your text",
	bhs: "Masukkan tulisan anda",
};
export const EMAIL_PLACEHOLDER = {
	eng: "olivia@portalkerja.com",
	bhs: "olivia@portalkerja.com",
};
export const SALARY_PLACEHOLDER = {
	eng: "5.000",
	bhs: "5.000",
};
export const PHONE_PLACEHOLDER = {
	eng: "8777752364",
	bhs: "8777752364",
};
export const HINT_MESSAGE = {
	eng: "This is a hint text to help user",
	bhs: "Ini adalah bantuan untuk membimbing pengguna",
};
export const ERROR_HINT_MESSAGE = {
	eng: "This is an error message",
	bhs: "Ini adalah pesan error",
};
export const TEXTAREA = {
	eng: "Description",
	bhs: "Deskripsi",
};
export const JOBS_LABEL = {
	eng: "Jobs",
	bhs: "Pekerjaan",
};
export const HELP_LABEL = {
	eng: "Help",
	bhs: "Bantuan",
};
export const PRIVACY_LABEL = {
	eng: "Privacy",
	bhs: "Privasi",
};
export const COMPANY = {
	eng: "Company",
	bhs: "Perusahaan",
};
export const COMPANY_NAME = {
	eng: "Company Name",
	bhs: "Nama Perusahaan",
};
export const COMPANY_PLACEHOLDER = {
	eng: "Company Three",
	bhs: "Perusahaan",
};
export const REMOVE_LABEL = {
	eng: "Remove",
	bhs: "Hapus",
};
export const READ_MORE = {
	eng: "Read More",
	bhs: "Lebih Banyak",
};

// Pagination
export const OF_LABEL = {
	eng: "of",
	bhs: "dari",
};
export const PAGE_LABEL = {
	eng: "Page",
	bhs: "Halaman",
};

// Home Component
export const ENTER_YOUR_EMAIL = {
	eng: "Enter Your Email",
	bhs: "Masukkan Email anda",
};
export const SUBSCRIBE_LABEL = {
	eng: "Subscribe",
	bhs: "Subscribe",
};
export const UNSUBSCRIBE_LABEL = {
	eng: "Unsubscribed",
	bhs: "Unsubscribed",
};
export const CONTACT_INFO_LABEL = {
	eng: "Contact Info",
	bhs: "Informasi Kontak",
};

// Profile Component
export const PERSONAL_INFORMATION = {
	eng: "Personal Information",
	bhs: "Informasi Pribadi",
};
export const EXPERIENCE = {
	eng: "Experience",
	bhs: "Pengalaman",
};
export const EDUCATION = {
	eng: "Education",
	bhs: "Pendidikan",
};
export const SKILLS = {
	eng: "Skills",
	bhs: "Keahlian",
};
export const LANGUAGE = {
	eng: "Language",
	bhs: "Bahasa",
};
export const LANGUAGES = {
	eng: "Languages",
	bhs: "Bahasa",
};
export const JOB_PREFERENCES = {
	eng: "Job Preferences",
	bhs: "Preferensi Pekerjaan",
};
export const ADD_EXPERIENCE = {
	eng: "Add Experience",
	bhs: "Tambah Pengalaman",
};
export const ADD_EDUCATION = {
	eng: "Add Education",
	bhs: "Tambah Pendidikan",
};
export const SHOW_ALL_EXPERIENCES = {
	eng: "Show All Experiences",
	bhs: "Lihat Semua Pengalaman",
};
export const SHOW_ALL_EDUCATIONS = {
	eng: "Show All Education",
	bhs: "Lihat Semua Pendidikan",
};
export const SHOW_ALL_LANGUAGES = {
	eng: "Show All Languages",
	bhs: "Lihat Semua Bahasa",
};
export const SHOW_ALL_SKILLS = {
	eng: "Show All Skills",
	bhs: "Lihat Semua Keahlian",
};
export const ADD_SKILLS = {
	eng: "Add Skills",
	bhs: "Tambah Keahlian Lain",
};
export const ADD_SKILLS_DOCUMENTS = {
	eng: "Upload a document to support your skill",
	bhs: "Unggah dokumen untuk mendukung keahlian anda",
};
export const ADD_LANGUAGES = {
	eng: "Add Languages",
	bhs: "Tambah Bahasa",
};
export const ADD_JOB_PREFERENCES = {
	eng: "Add Job Preference",
	bhs: "Tambah Preferensi Pekerjaan",
};
export const UPLOAD_RESUME = {
	eng: "Upload Your Resume",
	bhs: "Unggah Resume Anda",
};
export const UPLOAD_PORTFOLIO = {
	eng: "Upload Portfolio",
	bhs: "Unggah Portofolio",
};
export const PORT_FILE_RESTRICTION_1 = {
	eng: "Portflio file must be in Word (.doc or .docx), Text (.txt), Rich Text (.rtf) or PDF (.pdf) format",
	bhs: "Berkas portofolio harus dalam format Word (.doc or .docx), Teks (.txt), Rich Text (.rtf) atau PDF (.pdf)",
};
export const PORT_FILE_RESTRICTION_2 = {
	eng: "File size must not exceed 20 MB",
	bhs: "Ukuran berkas tidak boleh melebihi 20 MB",
};
export const RESUME_PREFERENCES = {
	eng: "Resume Preferences",
	bhs: "Preferensi Resume",
};
export const OTHER_RESUME = {
	eng: "Other Resume",
	bhs: "Resume Tambahan",
};
export const UPLOAD_OTHER_RESUME = {
	eng: "Upload Your Other Resume",
	bhs: "Unggah Resume Tambahan",
};
export const PORTFOLIO = {
	eng: "Portfolio",
	bhs: "Portofolio",
};
export const MAIL_ADDRESS = {
	eng: "Mail Address",
	bhs: "Alamat Email",
};
export const NATIONALITY = {
	eng: "Nationality",
	bhs: "Kewarganegaraan",
};
export const BIRTH = {
	eng: "Birth",
	bhs: "Tanggal Lahir",
};
export const SPOKEN = {
	eng: "Spoken",
	bhs: "Lisan",
};
export const WRITTEN = {
	eng: "Written",
	bhs: "Tulisan",
};
export const SPOKEN_PROFICIENCY = {
	eng: "Spoken Proficiency",
	bhs: "Kecakapan Berbicara",
};
export const WRITTEN_PROFICIENCY = {
	eng: "Written Proficiency",
	bhs: "Kecakapan Menulis",
};
export const SPOKEN_PROFICIENCY_REPL = {
	eng: "{0} spoken proficiency",
	bhs: "Kecakapan berbicara {0}",
};
export const WRITTEN_PROFICIENCY_REPL = {
	eng: "{0} written proficiency",
	bhs: "Kecakapan Menulis {0}",
};
export const LANGUAGE_PROFICIENCY_NAME = {
	eng: "Language proficiency name",
	bhs: "Nama kecakapan berbahasa",
};
export const LANGUAGE_PROFICIENCY_SCORE = {
	eng: "Language proficiency score",
	bhs: "Nilai kecakapan berbahasa",
};
export const CERTIFICATION_SCORE = {
	eng: "Certification Score",
	bhs: "Skor Sertifikasi",
};
export const SALARY_EXPECTATION = {
	eng: "Salary Expectation",
	bhs: "Ekspektasi Gaji",
};
export const PREFERED_WORK_LOCATION = {
	eng: "Preferred Work Location",
	bhs: "Lokasi Prioritas",
};
export const CHANGE_FILE = {
	eng: "Change File",
	bhs: "Ubah Berkas",
};
export const ADDRESS = {
	eng: "Address",
	bhs: "Alamat",
};
export const GENDER = {
	eng: "Gender",
	bhs: "Jenis Kelamin",
};
export const MALE = {
	eng: "Male",
	bhs: "Laki-laki",
};
export const FEMALE = {
	eng: "Female",
	bhs: "Perempuan",
};
export const PRESENT = {
	eng: "Present",
	bhs: "Saat Ini",
};
export const YEAR = {
	eng: "Year",
	bhs: "Tahun",
};
export const MONTHS = {
	eng: "Months",
	bhs: "Bulan",
};
export const MONTH = {
	eng: "Month",
	bhs: "Bulan",
};
export const WEEK = {
	eng: "Week",
	bhs: "Pekan",
};
export const WEEKS = {
	eng: "Weeks",
	bhs: "Minggu",
};

export const MONTHLY_SALARY = {
	eng: "Monthly Salary",
	bhs: "Gaji Perbulan",
};
export const SALARY_AMOUNT = {
	eng: "Input",
	bhs: "Ketik angka nominal",
};
export const GRADE = {
	eng: "Grade",
	bhs: "Nilai",
};
export const GPA = {
	eng: "GPA",
	bhs: "IPK",
};
export const BEGINNER = {
	eng: "Beginner",
	bhs: "Pemula",
};
export const INTERMEDIATE = {
	eng: "Intermediate",
	bhs: "Menengah",
};
export const ADVANCED = {
	eng: "Advanced",
	bhs: "Mahir",
};
export const FLUENT = {
	eng: "Fluent",
	bhs: "Fasih",
};
export const EXPERT = {
	eng: "Expert",
	bhs: "Ahli",
};
export const NATIVE = {
	eng: "Native",
	bhs: "Bahasa Asli",
};
export const PRIMARY = {
	eng: "Primary",
	bhs: "Utama",
};
export const MY_RESUME = {
	eng: "My Resume",
	bhs: "Resume Saya",
};
export const MY_PORTFOLIO = {
	eng: "My Portofolio",
	bhs: "Portofolio Saya",
};
export const READY_TO_WORK = {
	eng: "Ready to Work",
	bhs: "Terbuka untuk bekerja",
};
export const NOT_READY_TO_WORK = {
	eng: "Not Available to Work",
	bhs: "Tidak tersedia untuk Kerja",
};
export const LAST_UPDATED = {
	eng: "Last Updated",
	bhs: "Terakhir Diubah",
};
export const PUBLIC = {
	eng: "Public",
	bhs: "Publik",
};
export const PRIVATE = {
	eng: "Private",
	bhs: "Pribadi",
};
export const PUBLIC_DESCRIPTION = {
	eng: "Your resume and profile information can be found through Portal Kerja by Job Posters looking for candidates",
	bhs: "Resume dan informasi profil Anda dapat ditemukan melalui Portal Kerja oleh Rekruter yang mencari kandidat",
};
export const PRIVATE_DESCRIPTION = {
	eng: "Employers cannot find your resume in a search on Portal Kerja. This does not affect previous applications or prevent employers you responded to from contacting you.",
	bhs: "Perusahaan tidak dapat menemukan resume Anda dalam pencarian. Ini tidak memengaruhi lamaran sebelumnya atau mencegah perusahaan yang Anda lamar untuk menghubungi Anda",
};
export const DOWNLOAD = {
	eng: "Download",
	bhs: "Unduh",
};
export const DELETE_FILE = {
	eng: "Delete File",
	bhs: "Hapus Berkas",
};

// Personal Information Modal
export const CLOSE = {
	eng: "Close",
	bhs: "Tutup",
};
export const SAVE = {
	eng: "Save",
	bhs: "Simpan",
};
export const EDIT_PERSONAL_INFORMATION = {
	eng: "Edit Personal Information",
	bhs: "Ubah Informasi Pribadi",
};
export const MOBILE_PHONE_NUMBER = {
	eng: "Mobile Phone Number",
	bhs: "Nomor HP",
};
export const MOBILE_NUMBER_1 = {
	eng: "Mobile phone number 1",
	bhs: "Nomor HP 1",
};
export const ALTERNATE_MOBILE_NUMBER = {
	eng: "Mobile phone number 2",
	bhs: "Nomor HP 2",
};
export const STREET_ADDRESS = {
	eng: "Street Address",
	bhs: "Alamat",
};
export const POSTAL_CODE = {
	eng: "Postal Code",
	bhs: "Kode Pos",
};
export const DATE_OF_BIRTH = {
	eng: "Date of Birth",
	bhs: "Tanggal Lahir",
};

// Experience Modal
export const EDIT_EXPERIENCE = {
	eng: "Edit Experience",
	bhs: "Ubah Pengalaman",
};
export const JOB_TITLE = {
	eng: "Job Title",
	bhs: "Jabatan",
};
export const WORK_HERE_CURRENTLY = {
	eng: "I am currently working here",
	bhs: "Saya masih bekerja di sini",
};
export const EXAMPLE_ABBV = {
	eng: "Ex:",
	bhs: "Contoh:",
};
export const WORK_FROM = {
	eng: "Work from",
	bhs: "Tanggal Mulai",
};
export const WORK_TO = {
	eng: "Work to",
	bhs: "Tanggal Berhenti",
};
export const DESCRIPTION = {
	eng: "Description",
	bhs: "Deskripsi",
};
export const DESCRIPTION_PLACEHOLDER = {
	eng: "Enter a description...",
	bhs: "Ketik deskripsi di sini",
};
export const DOCUMENT = {
	eng: "Document",
	bhs: "Dokumen",
};
export const DOCUMENT_EXPERIENCE_SUBTITLE = {
	eng: "Add documents or images that supports your work experience",
	bhs: "Tambah dokumen, gambar untuk mendukung profil Anda",
};
export const ADD_FILE = {
	eng: "Add File",
	bhs: "Unggah File",
};

// Education Modal
export const EDIT_EDUCATION = {
	eng: "Edit Education",
	bhs: "Ubah Pendidikan",
};
export const SCHOOL_COLLEGE_NAME = {
	eng: "School/College Name",
	bhs: "Nama Sekolah atau Universitas*",
};
export const START_DATE = {
	eng: "Start Date",
	bhs: "Tanggal Mulai",
};
export const GRADUATION_DATE = {
	eng: "Graduation Date (or expected)",
	bhs: "Tanggal Selesai",
};
export const EDUCATION_FROM = {
	eng: "Education From",
	bhs: "Pendidikan Dari",
};
export const EDUCATION_TO = {
	eng: "Education To",
	bhs: "Pendidikan Hingga",
};
export const FIELD_OF_STUDY = {
	eng: "Field of Study",
	bhs: "Bidang Pendidikan",
};
export const LEVEL_OF_EDUCATION = {
	eng: "Level of Education",
	bhs: "Jenjang Pendidikan",
};
export const DOCUMENT_EDUCATION_SUBTITLE = {
	eng: "Add documents or images that supports your education",
	bhs: "Tambah dokumen, gambar untuk mendukung profil Anda",
};
export const CURRENTLY_ENROLLED = {
	eng: "Currently enrolled",
	bhs: "Saya masih bersekolah di sini",
};
export const DELETE_EDUCATION = {
	eng: "Delete Education",
	bhs: "Hapus Pendidikan",
};
export const DELETE_EXPERIENCE = {
	eng: "Delete Experience",
	bhs: "Hapus Pengalaman",
};
export const DELETING_FILE = {
	eng: "Deleting File",
	bhs: "Menghapus file",
};
// Skill Modal
export const EDIT_SKILL = {
	eng: "Edit Skill",
	bhs: "Ubah Keahlian",
};
export const SKILL_NAME = {
	eng: "Skill Name",
	bhs: "Nama Keahlian",
};
export const LEVEL = {
	eng: "Level",
	bhs: "Tingkat",
};
export const DELETE_SKILL = {
	eng: "Delete Skill",
	bhs: "Hapus Keahlian",
};
export const SKILL_ADDED = {
	eng: "Your skill has been added",
	bhs: "Keahlian Anda berhasil ditambahkan",
};
export const YOU_CAN_ADD_OTHER_SKILLS = {
	eng: "You can add another skill and get better jobs.",
	bhs: "Anda tetap dapat menambahkan keahlian lain dan dapatkan pekerjaan yang lebih baik.",
};

// Language Modal
export const EDIT_LANGUAGE = {
	eng: "Edit Language",
	bhs: "Ubah Bahasa",
};
export const LANGUAGE_NAME = {
	eng: "Language Name",
	bhs: "Nama Bahasa",
};
export const DELETE_LANGUAGE = {
	eng: "Delete Language",
	bhs: "Hapus Bahasa",
};

// Job Preference Modal
export const EXPECTED_PAY_CURRENCY = {
	eng: "Expected Pay Currency",
	bhs: "Mata Uang yang Diharapkan",
};
export const EXPECTED_PAY_AMOUNT = {
	eng: "Expected Pay Amount",
	bhs: "Gaji yang Diharapkan",
};

// Other Resume Modal
export const OTHER_RESUME_DESC = {
	eng: "Please select a document to be set as your additional information",
	bhs: "Pilih dokumen yang akan ditetapkan sebagai informasi tambahan Anda",
};

export const FILE_FORMAT_SIZE_2 = {
	eng: "File allowed only with type of {0}, or {1} format. Maximum {2}MB",
	bhs: "Hanya 1 file dengan format {0}, atau {1} yang diizinkan. Maksimal {2}MB",
};
// Modal Info
export const REPORT_SUCCESS_TITLE = {
	eng: "Thank you for submitting a report",
	bhs: "Terima kasih sudah memberikan laporan",
};
export const REPORT_SUCCESS_TEXT = {
	eng: "We take report Seriously and after a throught review, our support team will get back to you.",
	bhs: "Kami menanggapi laporan dengan serius dan setelah tinjauan menyeluruh, tim pendukung kami akan menghubungi Anda kembali.",
};
export const DELETE_MEDIA_LABEL = {
	eng: "Delete Media",
	bhs: "Hapus Media",
};
export const DELETE_TITLE = {
	eng: "Do you want delete this item ?",
	bhs: "Apakah Anda ingin menghapus item ini?",
};
export const FORMAT_NOT_SUPPORTED = {
	eng: "The file format is not supported",
	bhs: "Format file tidak didukung",
};
export const RESET_PASSWORD_VERIFICATION = {
	eng: "We have sent a email for verification to ",
	bhs: "Kami telah mengirimkan email untuk verifikasi ke ",
};
export const UPLOAD_FAILED = {
	eng: "Upload Failed",
	bhs: "Gagal Mengunggah",
};
export const MODAL_VERIFICATION_TITLE = {
	eng: "Check your email",
	bhs: "Periksa email Anda",
};
export const CANCEL_LABEL = {
	eng: "Cancel",
	bhs: "Cancel",
};
export const NO_THANKS = {
	eng: "No Thanks",
	bhs: "Lain kali",
};
export const DELETE_LABEL = {
	eng: "Delete",
	bhs: "Hapus",
};

// Date Selector
export const DATE = {
	eng: "Date",
	bhs: "Tanggal",
};

// Resume Preference Modal
export const RESUME_VISIBILITY = {
	eng: "Resume Visibility",
	bhs: "Visibilitas Resume",
};
export const NOTICE_PERIOD = {
	eng: "Notice Period",
	bhs: "Tenggang Waktu",
};
export const LAST_MODIFIED = {
	eng: "Last Modified",
	bhs: "Terakhir Diubah",
};

// Avatar modal
export const PROFILE_PICTURE = {
	eng: "Profile Picture",
	bhs: "Foto Profil",
};
export const PROFILE_PHOTO = {
	eng: "Profile Photo",
	bhs: "Foto Profil",
};
export const CHANGE_PHOTO = {
	eng: "Change Profile Photo",
	bhs: "Ubah Foto Profil",
};
export const DELETE_PHOTO = {
	eng: "Delete Profile Photo",
	bhs: "Hapus Foto Profil",
};

// Sign Up
export const DRAG_FILE_LABEL = {
	eng: "Drag file here to upload or",
	bhs: "Seret file ke sini untuk mengunggah atau",
};
export const CHOOSE_FILE = {
	eng: "Choose file",
	bhs: "Pilih Berkas",
};
export const MAXIMUM_FILE = {
	eng: "PNG or PDF format. Maximum 20 MB",
	bhs: "Format PNG atau PDF. Maksimum 20 MB",
};
export const ABOUT_ME_LABEL = {
	eng: "About Me",
	bhs: "Tentang Saya",
};
export const PORTFOLIO_RESUME_LABEL = {
	eng: "Portfolio & Resume",
	bhs: "Portofolio & Ringkasan",
};
export const JOB_RESUME_LABEL = {
	eng: "Job & Resume Preference",
	bhs: "Pekerjan & Ringkasan",
};
export const EMAIL_USERNAME_PLACEHOLDER = {
	eng: "john@gmail.com",
	bhs: "john@gmail.com",
};
export const EG_REGISTERED_BUSINESS_NAME = {
	eng: "eg : PT Ceria",
	bhs: "eg : PT Ceria",
};
export const LAST_NAME_OPTIONAL = {
	eng: "Last Name (Optional)",
	bhs: "Nama Belakang (Opsional)",
};
export const REGISTERED_BUSINESS_NAME = {
	eng: "Registered business name",
	bhs: "Nama bisnis terdaftar",
};
export const SIGN_UP_TITLE = {
	eng: "Please Confirm the validity of your email address",
	bhs: "Harap Konfirmasi validitas alamat email Anda",
};
export const HELP_TITLE = {
	eng: "Once you sign up you're agreeing to out privacy policy",
	bhs: "Setelah Anda mendaftar, Anda menyetujui kebijakan privasi",
};
export const BACK_TO_LOGIN = {
	eng: "Back to Login",
	bhs: "Kembali ke Login",
};
export const BACK = {
	eng: "Back",
	bhs: "Kembali",
};
export const NEXT = {
	eng: "Next",
	bhs: "Selanjutnya",
};
export const CONTINUE_LABEL = {
	eng: "Continue",
	bhs: "Selanjutnya",
};
export const NEXT_LABEL = {
	eng: "Next",
	bhs: "Selanjutnya",
};
export const NEXT_STEP_LABEL = {
	eng: "Next Step",
	bhs: "Langkah Selanjutnya",
};
export const SKIP_LABEL = {
	eng: "Skip",
	bhs: "Lewati",
};
export const REGISTER_ACCOUNT = {
	eng: "Register Account",
	bhs: "Daftar Akun",
};
export const SEND_EMAIL = {
	eng: "Send email",
	bhs: "Kirim email",
};
export const HELP_TEXT_FORGOT = {
	eng: "No worries, we'll send you reset instruction.",
	bhs: "Jangan khawatir, kami akan mengirimkan instruksi reset.",
};
export const RESET_PASSWORD = {
	eng: "Reset Password",
	bhs: "Ubah Kata Sandi",
};
export const VERIFICATION_TITLE = {
	eng: "Verify your account",
	bhs: "Verifikasi akun anda",
};
export const DELETE_CONFIRM_DESC = {
	eng: "Are you sure you want to delete this",
	bhs: "Apa kamu yakin ingin menghapus ini",
};
export const DONT_DELETE = {
	eng: "Don't Delete",
	bhs: "Jangan hapus",
};
export const UPLOAD_FILE_FAILED_MSG = {
	eng: "Upload file again to save the {0}",
	bhs: "Unggah file lagi untuk menyimpan {0}",
};
export const UPLOAD = {
	eng: "Upload",
	bhs: "Mengunggah",
};

// Reset Password
export const HELP_TEXT_NP = {
	eng: "Set your new password",
	bhs: "Setel Kata Sandi Baru Anda",
};
export const PASSWORD_RESET_DESC = {
	eng: "Your password has been successfully reset",
	bhs: "Kata sandi Anda telah berhasil diatur ulang",
};
export const NOTIF_1_TITLE = {
	eng: "Jobs Alerts Email",
	bhs: "Email Pemberitahuan Pekerjaan",
};
export const NOTIF_1_DESC = {
	eng: "System will find the job ads that matched with the Job Preferences",
	bhs: "Sistem akan menemukan iklan lowongan kerja yang sesuai dengan Preferensi Pekerjaan",
};
export const NOTIF_1_ACT = {
	eng: "[Click here to set job preferences]",
	bhs: "[Klik di sini untuk mengatur preferensi pekerjaan]",
};
export const NOTIF_2_TITLE = {
	eng: "Opportunity Knocks Email",
	bhs: "Email Peluang Kerja",
};
export const NOTIF_2_DESC = {
	eng: "Opportunity Knocks is an email that lists employers that have searched and viewed your resume in Talent Search in the past week. Talent Search is a system that employers used to head hunt for candidates",
	bhs: "Email Peluang Kerja adalah email yang berisi daftar perusahaan yang telah mencari dan melihat resume Anda di Pencarian Talent dalam seminggu terakhir. Pencarian Talent adalah sistem yang digunakan perusahaan untuk mencari kandidat",
};
export const NOTIF_3_TITLE = {
	eng: "Profile Update Reminders Email",
	bhs: "Email Pengingat Pembaruan Profil",
};
export const NOTIF_3_DESC = {
	eng: "We will send you an email when you change your password and update your CV",
	bhs: "Kami akan mengirimkan email kepada Anda ketika Anda mengubah kata sandi dan memperbarui CV Anda",
};
export const NOTIF_4_TITLE = {
	eng: "Commercial Email",
	bhs: "Email Komersial",
};
export const NOTIF_4_DESC = {
	eng: "Receive career advice, tips, announcements, and other commercial emails from Portal Kerja",
	bhs: "Terima saran karier, tips, pengumuman, dan email komersial lainnya dari Portal Kerja",
};
export const NOTIF_UPDATE_SUCCESS = {
	eng: "Your settings have been successfully updated",
	bhs: "Pengaturan Anda telah berhasil diperbarui",
};
export const CONTINUE = {
	eng: "Continue",
	bhs: "Lanjutkan",
};
export const RESET_PASSWORD_FAILED_1 = {
	eng: "Password / Confirm Password are mandatory fields",
	bhs: "Kata Sandi / Konfirmasi Kata Sandi adalah bidang wajib",
};
export const RESET_PASSWORD_FAILED_2 = {
	eng: "Password / Confirm Password do not match",
	bhs: "Kata Sandi / Konfirmasi Kata sandi tidak cocok",
};

// Salary Rate translation
export const PER_HOUR = {
	eng: "per hour",
	bhs: "per jam",
};

export const PER_DAY = {
	eng: "per day",
	bhs: "per hari",
};

export const PER_WEEK = {
	eng: "per week",
	bhs: "per minggu",
};

export const PER_MONTH = {
	eng: "per month",
	bhs: "per bulan",
};

export const PER_YEAR = {
	eng: "per year",
	bhs: "per tahun",
};

/**
 * Get Salary rate translation based on rate name
 * @param {string | SalaryRate | undefined} rateName
 * @returns {Record<string, string>}
 */
export const SalaryRateUtils = (rateName) =>
	({
		per_hour: PER_HOUR,
		per_day: PER_DAY,
		per_week: PER_WEEK,
		per_month: PER_MONTH,
		per_year: PER_YEAR,
	}[rateName ?? "per_month"]);

export const CANCEL_BTN_LABEL = {
	eng: "Cancel",
	bhs: "Batalkan",
};
export const OK_BTN_LABEL = {
	eng: "Ok",
	bhs: "Ok",
};

export const ARE_YOU_SURE = {
	eng: "Are you sure?",
	bhs: "Apakah Anda Yakin?",
};

export const UNBOOKMARKING_DESC = {
	eng: "Unbookmarking this item will remove it from your saved bookmarks, and you won't be able to access it anymore in Bookmark Tab.",
	bhs: "Menghapus bookmark ini akan menghilangkannya dari daftar bookmark Anda, dan Anda tidak akan dapat mengaksesnya lagi di Tab Bookmark.",
};
export const UNSUPPORTED_FILE = {
	eng: "File Not Supported",
	bhs: "File Tidak Didukung",
};
export const UPLOADING_FILE = {
	eng: "Upload file",
	bhs: "Mengunggah berkas",
};

// MY JOB - Continue job applications
export const APP_INCOMPLETE = {
	eng: "There is job application that has not completed",
	bhs: "Ada lamaran yang belum selesai",
};
export const APP_INCOMPLETE_HEAD = {
	eng: "Application has not completed",
	bhs: "Lamaran yang belum selesai",
};
export const CHECK = {
	eng: "Check",
	bhs: "Periksa",
};
export const CONTACT_INFO = {
	eng: "Contact Info",
	bhs: "Info kontak",
};
export const EDIT_LABEL = {
	eng: "Edit",
	bhs: "Ubah",
};
export const APPLY_TO = {
	eng: "Apply to",
	bhs: "Lamar ke",
};
export const RESUME = {
	eng: "Resume",
	bhs: "Resume",
};
export const ADDITIONAL_QUESTIONS = {
	eng: "Additional Questions",
	bhs: "Pertanyaan Tambahan",
};
export const MAKE_PITCH = {
	eng: "Make your pitch (Recommended)",
	bhs: "Promosikan diri Anda (Direkomendasikan)",
};
export const COVER_LETTER = {
	eng: "Cover Letter",
	bhs: "Surat Lamaran",
};
export const REVIEW_PROFILE = {
	eng: "Review your profile",
	bhs: "Review profil Anda",
};
export const UPDATE_CONTACT_WARN = {
	eng: "Updating the above data will automatically update your profile detail.",
	bhs: "Mengubah data di atas secara otomatis akan mengubah detail profil Anda.",
};
export const UPLOAD_RESUME_FILE_FORMAT = {
	eng: "DOC, DOCX, TXT, RTF, or PDF format. Maximum 20 MB",
	bhs: "DOC, DOCX, TXT, RTF, or PDF format. Maksimal 20 MB",
};
export const COVER_LETTER_FORMAT = {
	eng: "PDF, WORD, or Image file only. Maximum 20 MB",
	bhs: "Hanya file bertipe PDF, Word, atau gambar saja. Maksimal 20 MB",
};
export const VIEW = {
	eng: "View",
	bhs: "Lihat",
};
export const CHANGE = {
	eng: "Change",
	bhs: "Mengubah",
};
export const FILE_SIZE_ERROR = {
	eng: "File is too large. Maximum file size is 20 MB",
	bhs: "File terlalu besar. Ukuran maksimal adalah 20MB",
};
export const PITCH_DESC_PLACEHOLDER = {
	eng: "Tell the employer why you are best suited for this role. Highlight specific skills and how you can contribute. Avoid generi pitches e.g I am responsible",
	bhs: "Beri tahu perusahaan mengapa Anda paling cocok untuk peran ini. Soroti keterampilan khusus dan bagaimana Anda dapat berkontribusi. Hindari penawaran umum, misalnya saya bertanggung jawab.",
};
export const UPLOAD_COVER_LETTER = {
	eng: "Upload Cover Letter",
	bhs: "Unggah Surat Lamaran",
};
export const NOT_UPLOADED = {
	eng: "Not Uploaded",
	bhs: "Belum diunggah",
};
export const NOT_FILLED_YET = {
	eng: "Not filled yet",
	bhs: "Belum diisi",
};
export const SUBMIT_APPLICATION = {
	eng: "Submit Application",
	bhs: "Kirim lamaran",
};
export const APPLICATION_SENT_HEAD = {
	eng: "Your application was sent to",
	bhs: "Lamaran Anda telah dikirm ke",
};
export const APPLICATION_SENT_DESC = {
	eng: 'You can keep track of your application in the "applied" tabs of my jobs',
	bhs: 'Anda dapat memantau proses lamaran pada tab "Dilamar" yang ada di my Jobs.',
};
export const DONE = {
	eng: "Done",
	bhs: "Selesai",
};
export const PER_MONTH_2 = {
	eng: "Per month",
	bhs: "Per bulan",
};
export const CANCELLING_JOB = {
	eng: "Cancelling Job",
	bhs: "Batalkan & Hapus Lamaran?",
};
export const CANCEL_JOB_DESC = {
	eng: "Are you sure? This application will be deleted from pending application page",
	bhs: "Apkah Anda yakin? Lamaran ini akan dihapus dari halaman lamaran yang belum selesai.",
};
export const YES_CANCEL_TEXT = {
	eng: "Yes, cancel",
	bhs: "Ya, batalkan & Hapus",
};
export const NO_TEXT = {
	eng: "No",
	bhs: "Jangan",
};
export const REPORT_THIS_JOB = {
	eng: "Report This Job",
	bhs: "Laporkan Pekerjaan Ini",
};

export const REASON_LABEL = {
	eng: "Reason",
	bhs: "Alasan",
};

export const SELECT_REASON = {
	eng: "Select the reason for report this job",
	bhs: "Pilih alasan untuk melaporkan pekerjaan ini",
};

export const ADDITIONAL_COMMENTS = {
	eng: "Additional Comments",
	bhs: "Komentar Tambahan",
};

export const ERROR_MESSAGE = {
	eng: "This field is required",
	bhs: "Field ini harus di isi",
};

export const SUBMIT_LABEL = {
	eng: "Submit",
	bhs: "Kirim",
};

export const SPAM_OR_SCAM = {
	eng: "Spam or Scam",
	bhs: "Spam atau Scam",
};

export const DISCRIMINATION_OR_OFFENSIVE = {
	eng: "Discrimination or Offensive",
	bhs: "Diskriminasi atau Penyerangan",
};

export const MISLEADING = {
	eng: "Misleading",
	bhs: "Menyesatkan",
};
export const PASSWORD_VALIDATION_ERR_MSG = {
	eng: "Password must be at least 8 characters, with 1 number, 1 upper case, and 1 lower case",
	bhs: "Kata sandi harus terdiri dari minimal 8 karakter, dengan minimal 1 angka, 1 huruf besar, dan 1 huruf kecil",
};

// Change Password
export const INCORRECT_PASSWORD = {
	eng: "The password you entered is incorrect",
	bhs: "Kata Sandi yang dimasukkan salah",
};
export const PASS_CHANGE_SUCCESS = {
	eng: "Your password has been changed successfully",
	bhs: "Kata Sandi Anda berhasil diubah",
};
export const PASS_CHANGE_SUCCESS_DESC = {
	eng: "Because of security reasons you need to relogin.",
	bhs: "Untuk alasan keamanan Anda perlu masuk kembali.",
};
export const SUCCESS_UPDATE_PROFILE = {
	eng: "Your profile has been successfully updated",
	bhs: "Profil Anda telah berhasil diperbarui",
};
export const UPDATE_PERSONAL_INFO_MSG = {
	eng: "Your personal information has been successfully updated",
	bhs: "Informasi Pribadi Anda berhasil diperbarui",
};
export const NEW_EXPERIENCE_MSG = {
	eng: "Your experience has been successfully {{method}}",
	bhs: "Pengalaman Anda telah berhasil {{method}}",
};
export const NEW_EDUCATION_MSG = {
	eng: "Your education has been successfully {{method}}",
	bhs: "Pendidikan Anda telah berhasil {{method}}",
};
export const NEW_SKILL_MSG = {
	eng: "Your skill has been successfully {{method}}",
	bhs: "Skil Anda telah berhasil {{method}}",
};
export const NEW_LANG_MSG = {
	eng: "Your language has been successfully {{method}}",
	bhs: "Bahasa Anda telah berhasil {{method}}",
};
export const NEW_PREFERENCE_JOB_MSG = {
	eng: " Your job preference has been successfully {{method}}",
	bhs: "preferensi pekerjaan Anda berhasil {{method}}",
};
export const UPDATE_RESUME_JOB_MSG = {
	eng: "Your resume has been successfully updated",
	bhs: "Resume Anda berhasil diperbarui",
};
export const OTHER_FILES_MSG = {
	eng: "Your other resume has been successfully {{method}}",
	bhs: "Resume tambahan Anda berhasil {{method}}",
};
export const NEW_PORTFOLIO_MSG = {
	eng: "Your portfolio has been successfully {{method}}",
	bhs: "Portofolio Anda berhasil {{method}}",
};
export const UPDATE_RESUME_PREF_MSG = {
	eng: "Your resume preference has been successfully updated",
	bhs: "Preferensi Resume Anda berhasil diperbarui",
};
export const ADDED_MSG = {
	eng: "added",
	bhs: "ditambahkan",
};
export const UPDATED_MSG = {
	eng: "updated",
	bhs: "diperbarui",
};

// My Jobs - This part is auto generated from csv file using csv-to-file by Sumi
export const BOOKMARK = {
	eng: "Bookmark",
	bhs: "Bookmark",
};
export const INTERVIEW = {
	eng: "Interview",
	bhs: "Wawancara",
};
export const APPLIED = {
	eng: "Applied",
	bhs: "Dilamar",
};
export const WITHDRAWN = {
	eng: "Withdrawn",
	bhs: "Mundur",
};
export const ALL = {
	eng: "Show all statuses",
	bhs: "Tampilkan semua",
};
export const PENDING_APPLIED = {
	eng: "Pending",
	bhs: "Menunggu",
};
export const SHORTLIST = {
	eng: "Shortlisted",
	bhs: "Terpilih",
};
export const OFFERED = {
	eng: "Offered",
	bhs: "Ditawarkan",
};
export const HIRED = {
	eng: "Hired",
	bhs: "Direkrut",
};
export const VIEWED = {
	eng: "Viewed",
	bhs: "Dilihat",
};
export const REJECTED = {
	eng: "Rejected",
	bhs: "Ditolak",
};
export const ACCEPTED = {
	eng: "Accepted",
	bhs: "Disetujui",
};
export const PROSPECT = {
	eng: "Prospect",
	bhs: "Prospek",
};
export const PENDING_INTERVIEW = {
	eng: "Waiting confirmation",
	bhs: "Menunggu konfirmasi",
};
export const DECLINED = {
	eng: "Declined",
	bhs: "Ditolak",
};
export const ASC_NEWESTOLDEST = {
	eng: "Newest to oldest",
	bhs: "Terbaru ke terlama",
};
export const DESC_AZ = {
	eng: "Z to A",
	bhs: "Z ke A",
};
export const ASC_AZ = {
	eng: "A to Z",
	bhs: "A ke Z",
};
export const COMPANY_NAME_ORDERBY = {
	eng: "Company name",
	bhs: "Nama perusahaan",
};
export const DATE_APPLIES_ORDERBY = {
	eng: "Date applied",
	bhs: "Tanggal dilamar",
};
export const DESC_NEWESTOLDEST = {
	eng: "Oldest to newest",
	bhs: "Terlama ke terbaru",
};
export const MAX_6_MONTH_ERROR = {
	eng: "Maximum 6 months, selecting {{0}} to {{1}} instead",
	bhs: "Maksimal 6 bulan, memilih {{0}} hingga {{1}}",
};
export const JOB_TITLE_ORDERBY = {
	eng: "Job title",
	bhs: "Jabatan ",
};
export const DATE_WITHDRAWN_ORDERBY = {
	eng: "Date withdrawn",
	bhs: "Tanggal mundur",
};
export const BOOKMARK_EMPTY_TITLE = {
	eng: "You don't have job saved yet",
	bhs: "Anda belum menyimpan iklan lowongan pekerjaan",
};
export const BOOKMARK_EMPTY_DESC = {
	eng: "Find jobs on the job board to get started",
	bhs: "Temukan iklan lowongan pekerjaan di menu tab Job",
};
export const UNBOOKMARK_OK_LABEL = {
	eng: "Unbookmark",
	bhs: "Hapus dari Bookmark",
};
export const MAX_6_MONTH_LABEL = {
	eng: "Maximal 6 month",
	bhs: "Maksimal 6 bulan",
};
export const APPLIED_CTX_REPORT = {
	eng: "Report this job ad",
	bhs: "Laporkan iklan ini",
};
export const ADD_TO_COMPARE = {
	eng: "Add to compare",
	bhs: "Bandingkan Pekerjaan",
};
export const APPLIED_CTX_CHECK_HISTORY = {
	eng: "Check history status",
	bhs: "Cek riwayat status",
};
export const APPLIED_CTX_WITHDRAW = {
	eng: "Withdraw",
	bhs: "Tarik lamaran",
};
export const APPLIED_MODAL_HISTORY_STATUS_TITLE = {
	eng: "History Status",
	bhs: "Riwayat Status",
};
export const APPLIED_MODAL_WITHDRAW_TITLE = {
	eng: "Withdraw your application",
	bhs: "Tarik lamaran Anda",
};
export const APPLIED_EMPTY_TITLE = {
	eng: "You don't have any active applications right now",
	bhs: "Anda belum melamar ke perusahaan mana pun",
};
export const WITHDRAW_OPT_2 = {
	eng: "Suspicious content from the employer",
	bhs: "Konten yang mencurigakan dari perusahaan",
};
export const WITHDRAW_OPT_1 = {
	eng: "This job isn't a good match",
	bhs: "Pekerjaan ini tidak cocok",
};
export const WITHDRAW_OPT_3 = {
	eng: "Portal kerja helped me get a job",
	bhs: "Sudah mendapatkan pekerjaan dari Portal Kerja",
};
export const WITHDRAW_OPT_5 = {
	eng: "Haven't heard back from the employer",
	bhs: "Belum mendapat kabar dari perusahaan",
};
export const WITHDRAW_OPT_4 = {
	eng: "Found a job another way",
	bhs: "Sudah menemukan pekerjaan dengan cara lain",
};
export const WITHDRAW_OPT_FREE_TEXT = {
	eng: "Something else",
	bhs: "Lainnya",
};
export const WITHDRAW_REVIEW_WARNING = {
	eng: "Review before proceeding",
	bhs: "Periksa kembali sebelum melanjutkan",
};
export const WITHDRAW_WARNING_1 = {
	eng: "You will not be able to reapply this job",
	bhs: "Anda tidak dapat melamar kembali pekerjaan ini",
};
export const WITHDRAW_WARNING_3 = {
	eng: "To update or correct your application, try messaging the employer instead",
	bhs: "Untuk memperbarui atau memperbaiki lamaran Anda, silakan kirim pesan ke perusahaan tersebut",
};
export const WITHDRAW_WARNING_2 = {
	eng: "The employer may still contact you",
	bhs: "Perusahaan masih dapat menghubungi Anda",
};
export const WITHDRAW_OPT_TITLE = {
	eng: "Why are you no longer interested in this job?",
	bhs: "Mengapa Anda tidak lagi tertarik dengan pekerjaan ini?",
};
export const WITHDRAW_OPT_IMPROVE = {
	eng: "Anything you share can help us improve Portal Kerja.",
	bhs: "Apapun yang Anda bagikan dapat membantu kami dalam meningkatkan Portal Kerja.",
};
export const WITHDRAW_FREE_TEXT_ERROR_HINT = {
	eng: "The fill required",
	bhs: "Wajib diisi",
};
export const STATUS_LABEL_ON_DATE = {
	eng: " on {{0}}",
	bhs: " pada {{0}}",
};
export const WITHDRAW_BTN_LABEL = {
	eng: "Withdraw application",
	bhs: "Tarik Lamaran",
};
export const INTERVIEW_EMPTY_TITLE = {
	eng: "You don't have interview",
	bhs: "Anda belum memiliki jadwal interview",
};
export const WITHDRAW_FREE_TEXT_PLACEHOLDER = {
	eng: "Enter a description...",
	bhs: "Ketik deskripsi di sini",
};
export const INTERVIEW_DATE_APPLIED = {
	eng: "Date applied: {{0}}",
	bhs: "Tanggal dilamar: {{0}}",
};
export const INTERVIEW_APPLICANTS_LABEL = {
	eng: "{{0}} Applicant{{1}}",
	bhs: "{{0}} Pelamar",
};
export const WITHDRAWN_EMPTY_TITLE = {
	eng: "You don�t have withdrawn application",
	bhs: "Anda belum melamar ke perusahaan mana pun dengan kata kunci tersebut",
};
export const WITHDRAWN_ON = {
	eng: "Withdrawn on {{0}}",
	bhs: "Mundur pada {{0}}",
};
export const MYJOB_SEARCH_PLACEHOLDER = {
	eng: "Search job and company",
	bhs: "Cari pekerjaan & perusahaan",
};
