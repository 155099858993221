import Image from "shared/component/UI/Image";
import useTranslate from "shared/hooks/useTranslate";
import { RenderIf } from "shared/utils/common";

import DefaultUser from "../../assets/svg/default_user.svg";
import PasswordInput from "shared/component/InputComponent/PasswordInput";
import Button from "shared/component/ButtonComponent/Button";
import { BiArrowBack } from "react-icons/bi";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import makeRequest from "shared/utils/request";
import { toast } from "react-toastify";
import useAuth from "shared/hooks/useAuth";
import useUserBadge, { UserBadge } from "shared/hooks/useUserBadge";
import { omit } from "lodash";

interface FormFields {
	password?: string;
	reason?: string;
	feedback?: string;
}

interface Props {
	form: FormFields;
	isDeactivating: boolean;
	onChange: (name: string, value: string) => void;
	onNextPrev: (id: number) => void;
	onShowModal: () => void;
}

const RenderUserProfile = ({ user }: { user: UserBadge }) => {
	return (
		<div className="profile">
			<Image src={user?.profile_picture || DefaultUser} alt="User" />
			<div>
				<h3 className="display-xs">{`${user?.first_name} ${user?.last_name}`}</h3>
				<p className="text-label-gray-400 text-400">@{user?.username}</p>
				<div className="row-layout mt-xxs">
					<p className="text-label-gray text-400">{user?.company_name || "-"}</p>
					<span className="text-label-gray text-400">|</span>
					<p className="text-label-gray text-400">{user?.position}</p>
				</div>
			</div>
		</div>
	);
};

const ConfirmDeactivateClose = ({
	form,
	isDeactivating,
	onChange,
	onNextPrev,
	onShowModal,
}: Props) => {
	const t = useTranslate();
	const userBadge = useUserBadge();
	const { handleLogout } = useAuth();

	const handleSave = async () => {
		let url = {
			...generateRequestOptions("deactivateAccount"),
			data: {
				password: form?.password,
				deactivate_answer: form?.reason,
				deactivate_feedback: form?.feedback,
			},
		};

		if (!isDeactivating) {
			url = {
				...generateRequestOptions("closeAccount"),
				data: {
					password: form?.password,
					closed_account_answer: form?.reason === "other" ? form?.feedback : form?.reason,
				},
			};
		}

		const res = await makeRequest(url);

		if (res?.code === 200) {
			handleLogout();
			onShowModal();
		} else toast.error(res.message);
	};

	return (
		<>
			<div className="header">
				<h3>{t(isDeactivating ? "DEACTIVATE_ACCOUNT" : "CLOSE_ACCOUNT")}</h3>
				<p>{t(isDeactivating ? "DEACTIVATE_ACCOUNT_TEXT_1" : "CLOSE_ACCOUNT_SUB_2")}</p>
			</div>
			<div className="col-layout">
				<RenderIf condition={isDeactivating}>
					<p className="mb-0 text-sm text-label-gray text-400">
						{t("DEACTIVATE_ACCOUNT_DESC_1")}
					</p>
					<RenderUserProfile user={omit(userBadge, "email")} />
				</RenderIf>

				<PasswordInput
					label={t(
						isDeactivating ? "DEACTIVATE_ACCOUNT_TEXTAREA_1" : "ENTER_CURRENT_PASSWORD",
					)}
					placeholder="********"
					value={form?.password || ""}
					name="password"
					onChange={(e) => onChange("password", e.target.value)}
				/>

				<div className="row-layout">
					<Button
						type="outline"
						size="sm"
						title={t("BACK")}
						customIcon={<BiArrowBack size={18} className="me-xxs" />}
						onClick={() => onNextPrev(2)}
					/>
					<Button
						title={t(isDeactivating ? "DEACTIVATE_ACCOUNT" : "CLOSE_ACCOUNT")}
						type="primary"
						size="sm"
						onClick={handleSave}
						disabled={!form?.password}
					/>
				</div>
			</div>
		</>
	);
};

export default ConfirmDeactivateClose;
