import { toast } from "react-toastify";

const useToast = () => {

    const toastSuccess = (message: string) => {
        toast.dismiss();
        toast.success(message);
    }

    const toastError = (message: string) => {
        toast.dismiss();
        toast.error(message);
    }

    return {
        success: toastSuccess,
        error: toastError
    };
}

export default useToast;