import { useSelector } from "react-redux";

import * as Variable from "shared/utils/variables";
import { classHelper } from "shared/utils/stringUtils";
import Image from "shared/component/UI/Image";

import "./ButtonComponent.scss";
import { RootState } from "store/types/RootStateTypes";
import { ENV } from "types/types";

// DOCUMENTATION
// type : [primary, secondary, error, outline, textGray, textLink]
// size : [xs, sm, md, lg, xl]

type Props = {
	type?: string;
	disabled?: boolean;
	title?: string;
	size?: string;
	color?: string;
	leftIcon?: string;
	rightIcon?: string;
	customIcon?: React.ReactNode | undefined;
	className?: string;
	btnClassName?: string;
	titleTooltip?: string;
	children?: React.ReactNode;
	onClick?: (item: React.MouseEvent) => void;
};

const Button = ({
	type,
	disabled,
	title,
	leftIcon,
	rightIcon,
	onClick,
	size,
	color,
	customIcon,
	className,
	btnClassName,
	titleTooltip,
	children,
}: Props) => {
	const { ENV_NAME } = useSelector((state: RootState) => ({
		ENV_NAME: state?.auth?.selectedEnvironment as ENV,
	}));

	return (
		<div className={classHelper("J-btnComp", className)}>
			<button
				className={classHelper(
					`${
						type === "primary"
							? "J-btnComp-primary"
							: type === "secondary"
							? "J-btnComp-secondary"
							: type === "error"
							? "J-btnComp-error"
							: type === "outline"
							? "J-btnComp-outline"
							: type === "textGray"
							? "J-btnComp-textGray"
							: type === "textLink"
							? "J-btnComp-textLink"
							: ""
					} ${
						disabled && type === "primary"
							? "J-btnComp-primary-disable"
							: disabled && type === "secondary"
							? "J-btnComp-secondary-disable"
							: disabled && type === "error"
							? "J-btnComp-error-disable"
							: disabled && type === "outline"
							? "J-btnComp-outline-disable"
							: disabled && type === "textGray"
							? "J-btnComp-textGray-disable"
							: disabled && type === "textLink"
							? "J-btnComp-textLink-disable"
							: ""
					} ${size || "xl"}`,
					btnClassName,
				)}
				style={{ color }}
				onClick={onClick}
				title={titleTooltip}
				disabled={disabled}
			>
				{customIcon}

				{leftIcon && (
					<span className="positionRelative left-icon">
						<Image
							src={leftIcon}
							className={`${
								type === "outline" || type === "textGray" || type === "textLink"
									? "padRight J-btnComp"
									: "padRight J-btnComp-whiteImg"
							}`}
						/>
					</span>
				)}

				{title && <>{children ? children : title ?? Variable.BUTTON_CTA[ENV_NAME]}</>}

				{rightIcon && (
					<span className="positionRelative">
						<Image
							src={rightIcon}
							className={`${
								type === "outline" || type === "textGray" || type === "textLink"
									? "padLeft J-btnComp"
									: "padLeft J-btnComp-whiteImg"
							}`}
						/>
					</span>
				)}
			</button>
		</div>
	);
};

export default Button;
