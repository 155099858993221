import { FunctionComponent, ReactElement } from 'react'
import './index.scss'
import PrivacyPolicyBanner from '../../assets/image/privacy_policy_banner.svg'
import { Link } from 'react-router-dom'
import { PRIVACY_POLICY } from '../../shared/utils/routeLink'

const TermsOfUse: FunctionComponent = (): ReactElement => {
  return (
    <div className='terms_ofuse'>
        <div className="terms_ofuse_header">
            <p>syarat & ketentuan penggunaan</p>
            <div>
                <img src={PrivacyPolicyBanner} alt="Banner" />
            </div>
        </div>
        <div className="terms_ofuse_content">
            <div className="item">
                <h3>Syarat & Ketentuan Penggunaan</h3>
                <h5>Syarat dan Ketentuan Penggunaan ini diubah terakhir kali pada 29 Agustus 2023.</h5>
                <p>
                    Dokumen Syarat dan Ketentuan Penggunaan ini adalah sebuah perjanjian antara Anda sebagai pengguna (“Pencari Kerja” serta “Pemberi Kerja”) dan PT. Visi Prima Nusantara atau “kami” (sebuah perseroan terbatas yang didirikan berdasarkan hukum di Indonesia). Syarat dan Ketentuan Penggunaan ini berlaku terhadap seluruh pengguna dan pengunjung situs “portalkerja.co.id” (“Website <strong>"Portal Kerja"</strong>”), aplikasi <strong>"Portal Kerja"</strong> (Android dan Apple), maupun segala fitur, konten, teknologi, dan layanan yang disediakan oleh kami.
                </p>
                <p>
                    Dengan menggunakan layanan kami, artinya Anda setuju dengan Syarat dan Ketentuan Penggunaan ini, serta menyadari bahwa Anda telah membaca, memahami, dan menyetujui dan tunduk kepada Syarat dan Ketentuan Penggunaan dan Kebijakan Privasi kami termasuk setiap perubahan atau penambahan dari waktu ke waktu.
                </p>
                <p>
                    Apabila Anda tidak setuju dengan sebagian atau seluruh Syarat dan Ketentuan Penggunaan yang ada dalam perjanjian ini, maka Anda tidak diperkenankan menggunakan platform kami. <br />
                    Syarat dan Ketentuan Penggunaan ini dapat diubah atau ditambah kapan pun, dan tetap berlaku meskipun kami tidak mengirimkan pemberitahuan kepada Anda. Anda dianjurkan untuk membuka kembali Syarat dan Ketentuan Penggunaan ini secara berkala untuk memeriksa perubahan atau penambahan yang ada.
                </p>
                <div className="list_of_contents">
                    <p>Daftar Isi</p>
                    <ol className="items_wrapper">
                        <li className='item'>
                            <a className='link' href="#one">Tujuan layanan</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#two">Akun</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#three">Informasi pribadi</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#four">Akses layanan</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#five">Pembayaran</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#six">Kewajiban Pengguna</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#seven">Batasan Tanggung Jawab Kami</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#eight">Perilaku penggunaan dan larangan</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#nine">Laporan kemungkinan pelanggaran</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#ten">Hak Kekayaan Intelektual</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#eleven">Keadaan kahar</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#twelve">Kebijakan Cookie</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#thirteen">Jaminan privasi</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#fourteen">Perubahan Syarat dan Ketentuan</a>
                        </li>
                        <li className='item'>
                            <a className='link' href="#fifteen">Cara menghubungi kami</a>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="item" id='one'>
                <div className="item_data">
                    <div className="title">
                        <h5>1.</h5>
                        <h5>Tujuan Layanan</h5>
                        <div/>
                        <p>
                            Platform <strong>"Portal Kerja"</strong> merupakan platform aplikasi yang menghubungkan “Pencari Kerja” dengan “Pemberi Kerja” secara digital supaya dapat saling kenal sehingga memungkinkan terjadinya proses perekrutan karyawan dari “Pemberi Kerja” kepada “Pencari Kerja” di mana prosesnya dibantu di dalam aplikasi <strong>"Portal Kerja"</strong>. <br /> <br />
                            Secara spesifik, “Pemberi Kerja” dapat menerbitkan lowongan pekerjaan yang dibutuhkan di platform aplikasi <strong>"Portal Kerja"</strong> yang kemudian dapat dicari oleh “Pencari Kerja” yang kemudian dapat diperiksa kesesuaiannya dengan profil “Pencari Kerja”. “Pencari Kerja” berhak untuk menilai kelayakan lowongan kerja yang ada di <strong>"Portal Kerja"</strong>, serta melaporkan lowongan kerja yang tidak layak agar dapat ditelusuri oleh tim internal <strong>"Portal Kerja"</strong>. Apabila sesuai, “Pencari Kerja” dapat melamar melalui aplikasi <strong>"Portal Kerja"</strong>. Dalam hal ini “Pemberi Kerja” akan menerima lamaran dan berkas dari para “Pencari Kerja terhadap lowongan yang diterbitkan di aplikasi <strong>"Portal Kerja"</strong>. Apabila pada akhirnya “Pemberi Kerja” dan “Pencari Kerja” sepakat untuk melakukan hubungan kerja atau terjadi kesepakatan yang dituangkan dalam perjanjian kerja antara “Pemberi Kerja” dan “Pencari Kerja” maka <strong>"Portal Kerja"</strong> tidak dapat menjadi bagian dari pihak yang disebutkan dalam perjanjian kerja tersebut. <br /> <br />
                            Untuk memperjelas, kami juga mengklarifikasi bahwa platform aplikasi <strong>"Portal Kerja"</strong> bukanlah pihak kontraktor, bukan juga pihak outsourcing untuk pekerjaan atau proyek apapun. <br /> <br />
                            Kami dapat mengubah,menangguhkan, atau menghentikan layanan <strong>"Portal Kerja"</strong> tanpa pemberitahuan terlebih dahulu kepada pengguna. <br /> Platform <strong>"Portal Kerja"</strong> memiliki dapat diunduh secara gratis dan dapat digunakan sebagai “Pemberi Kerja” atau “Pencari Kerja” sesuai dengan kebutuhan Anda. Penggunaan layanan tertentu dapat dikenakan biaya layanan, termasuk namun tidak terbatas pada biaya langganan, biaya layana, biaya administrasi, biaya tambahan untuk penggunaan sistem pembayaran <strong>"Portal Kerja"</strong>, yang dapat dipotong dari saldo kredit pengguna atau metode lain yang ditetapkan oleh <strong>"Portal Kerja"</strong>.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item" id='two'>
                <div className="item_data">
                    <div className="title">
                        <h5>2.</h5>
                        <h5>Akun</h5>
                        <div/>
                        <p>
                            Sebelum dapat menggunakan layanan <strong>"Portal Kerja"</strong>, Anda harus memiliki akun terlebih dahulu. Terdapat dua jenis akun yang dapat digunakan pada <strong>"Portal Kerja"</strong>, yakni akun “Pemberi Kerja” dan “Pencari Kerja”. Sebagai “Pemberi Kerja”, maka pengguna dapat memasang lowongan pekerjaan serta manajemen kandidat yang melamar ke lowongan tersebut di layanan <strong>"Portal Kerja"</strong>, sedangkan sebagai “Pencari Kerja”, pengguna dapat mencari lowongan pekerjaan dan melamar lowongan pekerjaan yang ada di <strong>"Portal Kerja"</strong>. <br /> <br />
                            Untuk melakukan pendaftaran, “Pemberi Kerja” harus dapat membuktikan bahwa pihak “Pemberi Kerja” tersebut layak untuk memasang lowongan pekerjaan dengan cara mengunggah dokumen yang dapat menyatakan kepemilikan usaha yang didaftarkan. Apabila “Pemberi Kerja” belum dapat menyediakan dokumen tersebut, maka “Pemberi Kerja belum dapat menerbitkan lowongan pekerjaan di layanan <strong>"Portal Kerja"</strong>. <br />
                            Pada sisi “Pencari Kerja” pengguna dapat mendaftarkan diri pada layanan <strong>"Portal Kerja"</strong> dengan menyediakan informasi pribadi tertentu yang kemudian dapat dilengkapi dengan informasi rinci lainnya untuk kebutuhan melamar pekerjaan.
                            Bilamana terdapat kondisi yang mengharuskan kami melakukan penangguhan, penghentian, penghapusan, maupun penutupan akun Anda, kami memiliki hak untuk melakukan hal tersebut dengan pemberitahuan sebelumnya kepada Anda.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item" id='three'>
                <div className="item_data">
                    <div className="title">
                        <h5>3.</h5>
                        <h5>Informasi Pribadi</h5>
                        <div/>
                        <p>
                            Kebijakan mengenai informasi pengguna sudah diatur dalam Kebijakan Privasi, yang merupakan bagian dari Syarat dan Ketentuan Penggunaan ini. <br /> <br />
                            Keamanan dan kerahasiaan akun Anda, termasuk namun tidak terbatas pada seluruh data pribadi yang Anda berikan kepada kami, sepenuhnya merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang timbul akibat atau sehubungan dengan kelalaian Anda dalam menjaga keamanan dan kerahasiaan akun Anda adalah tanggung jawab Anda.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item" id='four'>
                <div className="item_data">
                    <div className="title">
                        <h5>4.</h5>
                        <h5>Akses Layanan</h5>
                        <div/>
                        <p>Anda setuju dan mengakui bahwa ketentuan berikut sebagai bagian dari Ketentuan Penggunaan layanan <strong>"Portal Kerja"</strong>:</p>
                    </div>
                    <div className="hr_line" />
                    <ul className="data_content" style={{ listStyle: 'lower-alpha', gap: '4px' }}>
                       <li>Layanan kami saat ini tersedia untuk masyarakat Indonesia namun tidak terbatas pada lokasi di mana Anda mengakses layanan kami</li> 
                       <li>Layanan kami saat ini hanya dapat diakses dengan menggunakan internet.</li> 
                       <li>Gangguan dapat terjadi saat mengakses konten Kami yang dikarenakan gangguan pada server layanan kami, penyedia jaringan internet yang kami dan/atau Anda gunakan, maupun gangguan dari perangkat yang Anda gunakan.</li> 
                       <li>Segala interpretasi, pengambilan keputusan, dan tindakan yang Anda ambil, apabila berdasarkan, berhubungan, atau sebagai akibat dari konten, materi, informasi, jawaban, dan penjelasan yang ditampilkan pada layanan kami adalah di luar tanggung jawab Kami.</li> 
                       <li>Seluruh konten, materi, maupun informasi pada layanan kami sudah terdaftar sebagai Hak Kekayaan Intelektual (HAKI) <strong>"Portal Kerja"</strong>. Segala hal terkait perubahan konten, materi, maupun informasi pada layanan kami tanpa sepengetahuan dan persetujuan Kami adalah termasuk pelanggaran hukum dan akan ditindak berdasarkan aturan hukum yang berlaku.</li>
                    </ul>
                </div>
            </div>
            <div className="item" id='five'>
                <div className="item_data">
                    <div className="title">
                        <h5>5.</h5>
                        <h5>Pembayaran</h5>
                        <div/>
                        <p>Bagian-bagian yang terkait pembayaran dijabarkan sebagai berikut:</p>
                    </div>
                    <div className="hr_line" />
                    <ul className="data_content" style={{ listStyle: 'lower-alpha', gap: '4px' }}>
                       <li><strong>"Portal Kerja"</strong> menerapkan sistem saldo, di mana saldo <strong>"Portal Kerja"</strong> adalah saldo dana yang diberikan kepada “Pemberi Kerja” maupun “Pencari Kerja setelah pembelian dilakukan di sistem <strong>"Portal Kerja"</strong>. Saldo <strong>"Portal Kerja"</strong> memiliki batas waktu aktif, di mana saldo tersebut dapat digunakan hanya dalam kondisi aktif. Apabila saldo <strong>"Portal Kerja"</strong> berada dalam kondisi tidak aktif, maka pengguna perlu melakukan pembelian paket baru untuk mengaktifkan sisa saldo <strong>"Portal Kerja"</strong> yang sebelumnya tidak aktif.</li> 
                       <li>Dengan melakukan pembayaran, Pengguna setuju bahwa saldo <strong>"Portal Kerja"</strong> tersebut tidak dapat diuangkan kembali.</li> 
                       <li>Pengguna wajib membayar layanan <strong>"Portal Kerja"</strong> sesuai dengan paket yang telah dipilih terlepas bahwa Pengguna menggunakan sebagian atau seluruh layanan dalam paket tersebut.</li> 
                       <li>Anda setuju bahwa <strong>"Portal Kerja"</strong> dapat bekerja sama dengan Bank dan/atau pihak ketiga lainnya dalam pemrosesan transaksi pembayaran melalui platform layanan <strong>"Portal Kerja"</strong>. <strong>"Portal Kerja"</strong> tidak bertanggung jawab atas segala gangguan dan/atau permasalahan yang timbul dari waktu ke waktu akibat gangguan sistem dan/atau jaringan yang dialami oleh Bank dan/atau pemberi layanan pihak ketiga lainnya tersebut.</li> 
                       <li>Metode pembayaran dapat dilakukan dengan memilih salah satu metode yang disediakan oleh <strong>"Portal Kerja"</strong> termasuk namun tidak terbatas pada Virtual Account bank tertentu, transfer manual, pembayaran menggunakan E-Wallet. Anda setuju bahwa layanan <strong>"Portal Kerja"</strong> dapat dikenakan biaya tertentu tergantung dari kebijakan setiap Bank atau pihak ketiga penyedia layanan. Anda wajib melakukan pembayaran sesuai dengan instruksi pembayaran yang telah ditetapkan oleh <strong>"Portal Kerja"</strong> dan/atau penyedia layanan pembayaran.</li>
                       <li><strong>"Portal Kerja"</strong> dapat mengenakan biaya atas setiap transaksi yang dilakukan melalui platform <strong>"Portal Kerja"</strong>, termasuk namun tidak terbatas pada biaya administrasi dan/atau biaya layanan. <strong>"Portal Kerja"</strong> dapat mengubah atau memperbaharui jumlah biaya tersebut dari waktu ke waktu.</li>
                       <li><strong>"Portal Kerja"</strong> berhak untuk menolak, membatalkan, atau menangguhkan transaksi yang dilakukan baik oleh “Pemberi Kerja” atau “Pencari Kerja” yang dianggap mencurigakan, termasuk namun tidak terbatas pada transaksi atau kegiatan yang dicurigai melanggar Syarat dan Ketentuan Penggunaan ini dan/atau hukum dan peraturan perundang-undangan yang berlaku.</li>
                       <li><strong>"Portal Kerja"</strong> tidak bertanggung jawab atas kesalahan dan/atau kelalaian yang dilakukan oleh Anda, baik sebagai “Pemberi Kerja” atau “Pencari Kerja” atas segala aktivitas pembayaran pada platform <strong>"Portal Kerja"</strong>.</li>
                       <li>Anda setuju bahwa kami dapat membuka atau memberikan informasi ini kepada suatu lembaga pelaporan keuangan atau orang-orang yang berkepentingan sebagaimana diperlukan secara wajar.</li>
                    </ul>
                </div>
            </div>
            <div className="item" id='six'>
                <div className="item_data">
                    <div className="title">
                        <h5>6.</h5>
                        <h5>Kewajiban Pengguna</h5>
                        <div/>
                        <p>Sebagai Pengguna layanan <strong>"Portal Kerja"</strong>, Anda menyetujui kewajiban-kewajiban yang harus dipenuhi Anda mencakup namun tidak terbatas pada poin-poin berikut:</p>
                    </div>
                    <div className="hr_line" />
                    <ul className="data_content" style={{ listStyle: 'lower-alpha', gap: '4px' }}>
                       <li>Anda tidak dapat mengalihkan atau memindahkan hak dan kewajiban apa pun yang tertuang pada Syarat Ketentuan Penggunaan ini kepada pribadi atau entitas lain mana pun tanpa persetujuan tertulis dari pihak kami terlebih dahulu (di mana persetujuan tersebut tidak boleh ditahan secara tidak wajar). Apabila Anda adalah “Pemberi Kerja” (dalam hal ini adalah suatu perusahaan/instansi/badan usaha dalam bentuk apa pun), setiap perubahan dalam kendali efektif Anda akan dianggap sebagai suatu pengalihan untuk keperluan pasal ini.</li>
                       <li>Iklan lowongan pekerjaan dan informasi lain yang dipasang pada layanan <strong>"Portal Kerja"</strong> tidak melanggar hak kekayaan intelektual dari pihak ketiga mana pun.</li>
                       <li>Semua berkas yang diunggah ke layanan <strong>"Portal Kerja"</strong> atau dikirimkan kepada kami bebas dari infeksi virus, malware, spyware, atau perangkat lunak lain yang dapat mengubah proses kerja sistem kami atau pun merusak sebagian/seluruh fungsi layanan <strong>"Portal Kerja"</strong> serta sistem server lainnya dari kami.</li>
                       <li>Anda mengganti rugi dan akan selalu mengganti rugi kami, pejabat, karyawan dan agen kami terhadap segala tuntutan, perkara, gugatan, tanggung jawab, biaya aktual atau bersyarat, ganti rugi dan pengeluaran yang ditimbulkan oleh kami sehubungan dengan setiap pelanggaran terhadap Syarat Ketentuan Penggunaan ini atau Ketentuan Produk oleh Anda; pendaftaran atau pendaftaran yang diusulkan atas suatu iklan oleh Anda di <strong>"Portal Kerja"</strong> atau suatu situs/layanan/platform terkait; suatu pelanggaran aktual oleh Anda terhadap suatu hukum, undang-undang, peraturan, anggaran rumah tangga, peraturan pemerintah, surat pemerintah, atau pedoman perilaku yang terjadi sebagai akibat dari munculnya iklan Anda di <strong>"Portal Kerja"</strong> atau sebagai akibat dari penggunaan Anda atas data yang terdapat di dalamnya.</li>
                       <li>“Pemberi Kerja” maupun “Pencari Kerja” tidak boleh meminta atau mewajibkan “Pencari Kerja” atau pihak mana pun untuk membayar sejumlah biaya, pengeluaran, ongkos, atau uang apa pun juga sehubungan dengan proses perekrutan karyawan untuk suatu lowongan kerja yang diiklankan di platform layanan <strong>"Portal Kerja"</strong>, baik biaya, pengeluaran, ongkos atau uang tersebut diminta atau diwajibkan kepada “Pencari Kerja” dalam iklan lowongan kerja itu sendiri atau dalam suatu komunikasi dengan kandidat yang terjadi sebagai hasil dari iklan lowongan kerja yang dipasang di platform layanan <strong>"Portal Kerja"</strong>.</li>
                       <li>“Pemberi Kerja” tidak boleh menggunakan fitur apa pun dari platform layanan <strong>"Portal Kerja"</strong> untuk mengirimkan pesan elektronik komersial, atau pesan apa pun yang tidak berhubungan dengan proses perekrutan, yang tidak diminta kepada para “Pencari Kerja”, baik secara individu atau sebagai suatu kelompok. Fitur layanan <strong>"Portal Kerja"</strong> hanya dapat digunakan untuk berkomunikasi dengan para kandidat sesuai dengan Ketentuan Produk.</li>
                       <li>Segala bentuk kesepakatan yang terjadi antara “Pemberi Kerja” dan “Pencari Kerja” merupakan tanggung jawab dari “Pemberi Kerja” dan “Pencari Kerja” tersebut dan <strong>"Portal Kerja"</strong> tidak terlibat apa pun dalam proses pembuatan kesepakatan tersebut. Apa pun konsekuensi, masalah, sengketa hukum perdata maupun pidana, yang terjadi antara “Pemberi Kerja” dan “Pencari Kerja” tidak akan melibatkan <strong>"Portal Kerja"</strong> sama sekali.</li>
                    </ul>
                </div>
            </div>
            <div className="item" id='seven'>
                <div className="item_data">
                    <div className="title">
                        <h5>7.</h5>
                        <h5>Batasan Tanggung Jawab Kami</h5>
                        <div/>
                        <p>
                            Kami menyediakan layanan <strong>"Portal Kerja"</strong> sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari layanan <strong>"Portal Kerja"</strong> dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda setiap waktu, termasuk namun tidak terbatas pada layanan yang terdapat pada platform <strong>"Portal Kerja"</strong>, konten pihak ketiga dan penawaran atau promosi dari mitra bisnis yang sepenuhnya menjadi tanggung jawab penyedia layanan, penyedia konten pihak ketiga, penyedia penawaran, dan mitra bisnis. Kami tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap, sebagian, atau seluruh kegagalan, kesalahan, atau kerusakan yang dilakukan oleh penyedia layanan, penyedia konten pihak ketiga, penyedia penawaran, atau mitra bisnis ataupun kegagalan atau kesalahan Anda dalam mematuhi Syarat dan Ketentuan Penggunaan layanan <strong>"Portal Kerja"</strong> ini, atau Kebijakan Privasi. <br /> <br />
                            Layanan <strong>"Portal Kerja"</strong> dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang terdapat dalam penggunaan internet dan komunikasi elektronik, termasuk perangkat Anda, penyedia layanan, penyedia konten pihak ketiga, penyedia penawaran, atau mitra bisnis rusak, tidak terhubung dengan internet, berada di luar jangkauan, dinonaktifkan, dimatikan atau tidak berfungsi. Kami tidak bertanggung jawab atas keterlambatan, kegagalan permintaan atau pemrosesan perintah, kerusakan atau kerugian, termasuk potensi kehilangan keuntungan, yang diakibatkan oleh masalah-masalah tersebut. <br /> <br />
                            Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas layanan <strong>"Portal Kerja"</strong>. Namun, Kami tetap melakukan pengawasan untuk tujuan memastikan kelancaran penggunaan layanan <strong>"Portal Kerja"</strong> dan untuk memastikan kepatuhan terhadap Syarat dan Ketentuan Penggunaan layanan <strong>"Portal Kerja"</strong> ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif atau badan pemerintahan lainnya. <br /> <br />
                            Kami tidak bertanggung jawab atas pengunduhan dan penggunaan layanan <strong>"Portal Kerja"</strong> pada perangkat rooted ataujailbroken dan risiko penggunaan anda terhadap perangkat rooted atau jailbroken sepenuhnya adalah risiko Anda. Anda mengerti dan setuju bahwa Kami tidak bertanggung jawab atas segala kehilangan atau setiap konsekuensi lain yang diderita atau disebabkan oleh Anda sebagai akibat dari penggunaan layanan <strong>"Portal Kerja"</strong> pada perangkat rooted atau jailbroken, dan Kami mempunyai hak untuk menghentikan akses atau penggunaan Anda terhadap layanan <strong>"Portal Kerja"</strong> pada perangkat rooted ataujailbroken dan memblokir perangkat rooted atau jailbroken untuk mengakses atau menggunakan layanan <strong>"Portal Kerja"</strong>. <br />
                            Anda dengan ini menyatakan dan setuju untuk membebaskan Kami terhadap setiap dan segala jenis kerugian, tanggung jawab, kewajiban, kerusakan, biaya, pengeluaran, tuntutan, permintaan, putusan, persidangan, denda, penalti atau tindakan dalam bentuk apapun sehubungan dengan akses dan penggunaan Anda atas layanan <strong>"Portal Kerja"</strong>, termasuk konten dan layanan yang terdapat pada platform <strong>"Portal Kerja"</strong>.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item" id='eight'>
                <div className="item_data">
                    <div className="title">
                        <h5>8.</h5>
                        <h5>Perilaku Penggunaan dan Larangan</h5>
                        <div/>
                        <p>Anda setuju untuk menggunakan layanan <strong>"Portal Kerja"</strong> dalam koridor peraturan perundang-undangan yang berlaku. Apabila terdapat pelanggaran terhadap perundang-undangan yang berlaku, maka kami memiliki hak untuk menolak keanggotaan, menghentikan akses (baik sementara maupun selamanya), maupun menghapus akses akun Anda.</p>
                    </div>
                    <div className="hr_line" />
                    <div className="data_content" style={{ backgroundColor: '', paddingLeft: '40px' }}>
                        <p>Anda setuju untuk menaati peraturan perundang-undangan yang berlaku, dengan tidak melakukan hal-hal berikut:</p>
                        <ul className='another_ul'>
                            <li>Menggunakan layanan dengan cara apapun untuk melanggar peraturan perundang-undangan yang berlaku di wilayah lokal, nasional, maupun internasional.</li>
                            <li>Mengubah, memodifikasi, menerjemahkan, mengadaptasi atau menyalin sebagian/seluruh fitur atau layanan <strong>"Portal Kerja"</strong>.</li>
                            <li>Menghapus pemberitahuan hak cipta, merek dagang, atau hak kepemilikan lainnya yang terdapat dalam layanan <strong>"Portal Kerja"</strong>.</li>
                            <li>Menggunakan aplikasi lain, robot, virus, trojan, worm, spider, plugin, logic bomb, keystroke loggers, spyware, adware, malware, atau perangkat keras maupun perangkat lunak lain untuk mengambil data, mengindeks, merusak, atau mengubah sebagian/seluruh fitur atau layanan <strong>"Portal Kerja"</strong>.</li>
                            <li>Anda tidak boleh menggunakan layanan <strong>"Portal Kerja"</strong> pada perangkat atau sistem operasi yang telah dimodifikasi di luar perangkat atau konfigurasi sistem operasi dan konfigurasi yang Kami tentukan. Hal ini mencakup perangkat yang telah melalui proses “rooted” atau “jailbroken”. Perangkat rooted atau jailbroken adalah perangkat yang telah dibebaskan dari pembatasan yang dikenakan oleh penyedia perangkat dan yang diproduksi tanpa persetujuan penyedia perangkat. Penggunaan layanan <strong>"Portal Kerja"</strong> pada perangkat rooted atau jailbroken dapat meningkatkan celah keamanan dan berujung pada transaksi penipuan atau kerugian.</li>
                            <li>Mengumpulkan informasi tentang pengguna lain untuk tujuan yang tidak sah atau melanggar hukum.</li>
                            <li>Membuat akun pengguna dengan cara otomatis dengan data yang palsu maupun tidak sah.</li>
                            <li>Menyebarkan atau mendistribusikan layanan <strong>"Portal Kerja"</strong> tanpa izin yang sah dari kami, dengan tujuan untuk mengambil keuntungan pribadi atau pihak tertentu, maupun untuk tujuan apapun lainnya.</li>
                            <li>
                                Mengirimkan atau menyebarkan informasi, konten, atau materi yang mengandung unsur berikut:
                                <ul className="ul_inside_ul">
                                    <li>Melanggar, menyalahgunakan, atau melanggar hak kekayaan intelektual, hak cipta, publisitas, privasi, atau hak kepemilikan lainnya dari pihak manapun.</li>
                                    <li>Berupa iklan, promosi yang tidak diinginkan atau tidak sah atau spam kepada pengguna lain pada layanan <strong>"Portal Kerja"</strong>.</li>
                                    <li>Mencemarkan nama baik seseorang, pihak tertentu, perusahaan, atau merek tertentu.</li>
                                    <li>Mengandung materi yang tidak senonoh, bersifat pornografi, menghasut, bersifat kebencian, memprovokasi, menyulut perkelahian maupun kekerasan.</li>
                                    <li>Mengandung materi yang mengajak melakukan taruhan, perjudian, atau undian.</li>
                                    <li>Mengandung materi yang mengajak melakukan diskriminasi berdasarkan aspek apapun antara lain ras, suku, jenis kelamin, agama, warna kulit, bangsa, kondisi fisik, kecacatan, orientasi seksual, usia.</li>
                                    <li>Mengandung materi yang mengajak melakukan aksi kejahatan atau hal yang melanggar hukum dalam bentuk apapun.</li>
                                    <li>Mengandung materi yang mengajak melakukan penggunaan narkotika serta obat/zat terlarang.</li>
                                    <li>Mengandung materi yang mengajak melakukan penggunaan rokok dan materi berbahan tembakau.</li>
                                    <li>Mengganggu, meresahkan, membuat malu, menyinggung, mempermalukan, atau melecehkan siapa pun.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="item" id='nine'>
                <div className="item_data">
                    <div className="title">
                        <h5>9.</h5>
                        <h5>Laporan kemungkinan pelanggaran</h5>
                        <div/>
                        <p>Apabila Anda menemukan terdapat konten atau materi yang melanggar hal-hal yang telah disebutkan di atas, Anda dianjurkan untuk segera melaporkannya kepada kami agar kami dapat mengambil tindak lanjut secepatnya.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='ten'>
                <div className="item_data">
                    <div className="title">
                        <h5>10.</h5>
                        <h5>Hak Kekayaan Intelektual</h5>
                        <div/>
                        <p>Layanan kami memuat namun tidak terbatas pada nama, logo, merek dagang, kode program, desain, teknologi, model dan proses bisnis, materi atau konten yang disediakan oleh kami pada layanan (di luar konten atau materi yang diberikan oleh pengguna), telah dilindungi oleh hak cipta, merek, maupun hak kekayaan intelektual lainnya yang terdaftar dan dimiliki atas nama PT. Visi Prima Nusantara.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='eleven'>
                <div className="item_data">
                    <div className="title">
                        <h5>11.</h5>
                        <h5>Keadaan kahar</h5>
                        <div/>
                        <p>Apabila terdapat keadaan kahar termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain yang mengganggu atau menghentikan sepenuhnya layanan kami, Anda setuju untuk menerima keadaan tersebut dan membebaskan kami dari segala macam tuntutan dan tanggung jawab.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='twelve'>
                <div className="item_data">
                    <div className="title">
                        <h5>12.</h5>
                        <h5>Kebijakan Cookie</h5>
                        <div/>
                        <p>Cookie adalah berkas data kecil yang digunakan untuk menyimpan informasi di browser web. Cookie dapat digunakan untuk mengumpulkan, menyimpan, atau membagikan data pengenal dan informasi lain mengenai kegiatan pada website, termasuk layanan <strong>"Portal Kerja"</strong>. <br />
                        Kami menggunakan dua jenis cookie, yakni cookie sesi dan cookie bertahan. Cookie sesi akan berakhir setelah waktu yang singkat atau Anda menutup jendela browser, sedangkan cookie bertahan akan tetap tersimpan pada browser Anda untuk periode waktu tertentu. Layanan <strong>"Portal Kerja"</strong> menggunakan cookie dan teknologi sejenisnya untuk mengukur serta meningkatkan mutu layanan kami.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='thirteen'>
                <div className="item_data">
                    <div className="title">
                        <h5>13.</h5>
                        <h5>Jaminan privasi</h5>
                        <div/>
                        <p>Kami berjanji untuk berusaha menjaga keamanan dan kerahasiaan informasi pribadi yang diberikan pengguna pada saat mengakses layanan kami. Dalam hal ini, pengguna memahami dan setuju untuk ikut bertanggung jawab terhadap data pribadi yang diberikan pada layanan <strong>"Portal Kerja"</strong>. Informasi lengkap mengenai layanan privasi ini dapat dilihat pada halaman <Link to={PRIVACY_POLICY}>Kebijakan Privasi.</Link></p>
                    </div>
                </div>
            </div>
            <div className="item" id='fourteen'>
                <div className="item_data">
                    <div className="title">
                        <h5>14.</h5>
                        <h5>Perubahan Syarat dan Ketentuan</h5>
                        <div/>
                        <p>Anda setuju untuk menerima segala perubahan pada Syarat dan Ketentuan ini yang dapat terjadi sewaktu-waktu. Mohon dapat mengunjungi kembali halaman ini secara berkala, untuk melihat adanya pengubahan pada Syarat dan Ketentuan layanan kami.</p>
                    </div>
                </div>
            </div>
            <div className="item" id='fifteen'>
                <div className="item_data">
                    <div className="title">
                        <h5>15.</h5>
                        <h5>Cara menghubungi kami</h5>
                        <div/>
                        <p>Apabila Anda adalah pengguna akhir layanan kami dalam lingkup sekolah atau institusi, mohon menghubungi sekolah atau institusi Anda terlebih dahulu, karena kebijakan sekolah atau institusi dapat berpengaruh kepada penggunaan data pribadi Anda. Jika Anda mengalami kendala atau pertanyaan mengenai Syarat dan Ketentuan ini, Anda dapat menghubungi kami melalui alamat surat elektronik <a href="">info@portalkerja.co.id.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TermsOfUse