import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PiTrash } from "react-icons/pi";

import { classHelper } from "../../utils/stringUtils";

import "./CardComponent.scss";
import Image from "../UI/Image";
import { GreenCheck, RedCrossIcon } from "../svg/Icons";
import Paper from "../../../assets/svg/paper.svg";
import Loader from "../../../assets/svg/loader.svg";
import { Oval } from "react-loader-spinner";

// type = [job, public, private]

const FileCard = ({
	title,
	customIcon,
	icon,
	onClick,
	ENV_NAME,
	isLoading,
	onDelete,
	showGreenCheck = true,
	isUploading = false,
	isNewFile,
	...props
}) => {
	return (
		<div className={classHelper("J-FileCard", props.className)}>
			{customIcon ?? <Image src={icon || Paper} width="32rem" height="32rem" />}
			<div className="flex-grow-1 mw-0 text-sm">{title}</div>
			<div className="J-FileCard-UpStatus">
				<div className={`J-FileCard-Spinner ${isLoading ? "show" : ""}`}>
					<div className="spinner-border" role="status">
						<span className="sr-only"></span>
					</div>
				</div>
				{showGreenCheck && !isUploading && !isNewFile ? (
					<GreenCheck
						className={`J-FileCard-Success ${isLoading ? "" : "show"}`}
						width="3.2rem"
						height="3.2rem"
						fill="#32D583"
					/>
				) : (
					""
				)}
				{isUploading && (
					<Oval
						height={32}
						width={32}
						color="#475D80"
						visible={true}
						ariaLabel="oval-loading"
						secondaryColor="#EAECF0"
						strokeWidth={6}
						strokeWidthSecondary={6}
					/>
				)}
			</div>
			<button className="btn p-0" onClick={onDelete}>
				<PiTrash className="cursorPointer" size={"3.2rem"} color="#F04438" />
			</button>

			{/* uploading animation */}
			{/* {isUploading && (
				<div className="loader-wrapper">
					<div className="loader-container">
						<Image src={Loader} alt="File uploading" />
					</div>
				</div>
			)} */}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(FileCard));
