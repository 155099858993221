import { memo, useState, useRef, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import clsx from "clsx";

import useOutside from "shared/hooks/outsideClick";

import { Typography } from "../Typography/Typography";
import "./ThreeDotsMenuStyle.scss";

export type PropTypes = React.PropsWithChildren & {
	options: { label: string; key: string; onClick?: () => any }[] | React.ReactElement[];
	visible?: boolean;
};

const ThreeDotsMenu = (props: PropTypes) => {
	const dotsRef = useRef<HTMLDivElement>(null);

	const [visible, setVisible] = useState(false);

	const onHide = () => {
		if (visible) setVisible(false);
	};

	useOutside(dotsRef, onHide);

	useEffect(() => {
		if (props.visible) {
			setVisible(props.visible);
		}
	}, [props.visible]);

	return (
		<div className="three-dots-cont" ref={dotsRef}>
			<div className="three-dots-menu" onClick={() => setVisible((curr) => !curr)}>
				<BsThreeDotsVertical size={32} color="#667085" />
			</div>
			<div className={clsx("menu-cont", visible && "visible")}>
				<div className="menu">
					{props.options.map((v: PropTypes["options"][number]) =>
						"label" in v ? (
							<div
								className="menu-item"
								key={v.key}
								onClick={() => {
									onHide();
									v.onClick?.();
								}}
							>
								<Typography.Text>{v.label}</Typography.Text>
							</div>
						) : (
							v
						),
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(ThreeDotsMenu);
