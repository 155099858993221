import { PiMoney } from "react-icons/pi";
import Button from "shared/component/ButtonComponent/Button";
import Image from "shared/component/UI/Image";
import useTranslate from "shared/hooks/useTranslate";
import { Application } from "./IncompleteApps";
import { SalaryRangeFormatUtil } from "../SalaryRangeFormatUtil";
import { SalaryTypeEnum } from "types/API/TypesSwagger";

interface Props {
	item: Application;
	onUpdateCancel: () => void;
	onContinueApplication: () => void;
}

const ApplicationCard = ({ item, onUpdateCancel, onContinueApplication }: Props) => {
	const t = useTranslate();

	return (
		<div className="ac">
			<Image
				className="company_logo"
				src={item?.company?.company_logo}
				alt={item?.company?.name}
			/>
			<div className="content">
				<h3>{item?.job_ad?.job_title}</h3>
				<p>{item?.company?.name}</p>
				<p>{`${item?.job_ad?.posting_location_city}, ${item?.job_ad?.posting_location_district}`}</p>
				{item?.job_ad?.display_salary && (
					<p>
						<PiMoney size={14} />
						<strong>
							{SalaryRangeFormatUtil({
								currency: item?.job_ad?.salary_currency || "idr",
								salaryStart: item?.job_ad?.salary_starting_amount,
								salaryEnd: item?.job_ad?.salary_maximum_amount,
								salaryRate: item?.job_ad?.salary_rate || "per_month",
								salaryAmount: item?.job_ad?.salary_amount,
								salaryType: item?.job_ad?.salary_type as SalaryTypeEnum,
							})}
						</strong>
					</p>
				)}
			</div>
			<div className="btns">
				<Button
					type="outline"
					title={t("CANCEL_BTN_LABEL")}
					size="sm"
					onClick={onUpdateCancel}
				/>
				<Button
					type="primary"
					title={t("CONTINUE")}
					size="sm"
					onClick={onContinueApplication}
				/>
			</div>
		</div>
	);
};

export default ApplicationCard;
