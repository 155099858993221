export const COUNTRY_LIST = [
	"AF",
	"AX",
	"AL",
	"DZ",
	"AS",
	"AD",
	"AO",
	"AI",
	"AQ",
	"AG",
	"AR",
	"AM",
	"AW",
	"AU",
	"AT",
	"AZ",
	"BS",
	"BH",
	"BD",
	"BB",
	"BY",
	"BE",
	"BZ",
	"BJ",
	"BM",
	"BT",
	"BO",
	"BQ",
	"BA",
	"BW",
	"BV",
	"BR",
	"IO",
	"BN",
	"BG",
	"BF",
	"BI",
	"KH",
	"CM",
	"CA",
	"CV",
	"KY",
	"CF",
	"TD",
	"CL",
	"CN",
	"CX",
	"CC",
	"CO",
	"KM",
	"CG",
	"CD",
	"CK",
	"CR",
	"CI",
	"HR",
	"CU",
	"CW",
	"CY",
	"CZ",
	"DK",
	"DJ",
	"DM",
	"DO",
	"EC",
	"EG",
	"SV",
	"GQ",
	"ER",
	"EE",
	"ET",
	"FK",
	"FO",
	"FJ",
	"FI",
	"FR",
	"GF",
	"PF",
	"TF",
	"GA",
	"GM",
	"GE",
	"DE",
	"GH",
	"GI",
	"GR",
	"GL",
	"GD",
	"GP",
	"GU",
	"GT",
	"GG",
	"GN",
	"GW",
	"GY",
	"HT",
	"HM",
	"VA",
	"HN",
	"HK",
	"HU",
	"IS",
	"IN",
	"ID",
	"IR",
	"IQ",
	"IE",
	"IM",
	"IL",
	"IT",
	"JM",
	"JP",
	"JE",
	"JO",
	"KZ",
	"KE",
	"KI",
	"KP",
	"KR",
	"XK",
	"KW",
	"KG",
	"LA",
	"LV",
	"LB",
	"LS",
	"LR",
	"LY",
	"LI",
	"LT",
	"LU",
	"MO",
	"MK",
	"MG",
	"MW",
	"MY",
	"MV",
	"ML",
	"MT",
	"MH",
	"MQ",
	"MR",
	"MU",
	"YT",
	"MX",
	"FM",
	"MD",
	"MC",
	"MN",
	"ME",
	"MS",
	"MA",
	"MZ",
	"MM",
	"NA",
	"NR",
	"NP",
	"NL",
	"AN",
	"NC",
	"NZ",
	"NI",
	"NE",
	"NG",
	"NU",
	"NF",
	"MP",
	"NO",
	"OM",
	"PK",
	"PW",
	"PS",
	"PA",
	"PG",
	"PY",
	"PE",
	"PH",
	"PN",
	"PL",
	"PT",
	"PR",
	"QA",
	"RE",
	"RO",
	"RU",
	"RW",
	"BL",
	"SH",
	"KN",
	"LC",
	"MF",
	"PM",
	"VC",
	"WS",
	"SM",
	"ST",
	"SA",
	"SN",
	"RS",
	"CS",
	"SC",
	"SL",
	"SG",
	"SX",
	"SK",
	"SI",
	"SB",
	"SO",
	"ZA",
	"GS",
	"SS",
	"ES",
	"LK",
	"SD",
	"SR",
	"SJ",
	"SZ",
	"SE",
	"CH",
	"SY",
	"TW",
	"TJ",
	"TZ",
	"TH",
	"TL",
	"TG",
	"TK",
	"TO",
	"TT",
	"TN",
	"TR",
	"TM",
	"TC",
	"TV",
	"UG",
	"UA",
	"AE",
	"GB",
	"US",
	"UM",
	"UY",
	"UZ",
	"VU",
	"VE",
	"VN",
	"VG",
	"VI",
	"WF",
	"EH",
	"YE",
	"ZM",
	"ZW",
];

export const FIELD_OF_STUDY = [
	"Ilmu Pengetahuan Alam (IPA)",
	"Matematikan dan Ilmu Alam (MIA)",
	"Ilmu Pengetahuan Sosial (IPS)",
	"Ilmu-Ilmu Sosial (IIS)",
	"Bahasa dan Sastra",
	"Agrikultur/Aquakultur/Perhutanan",
	"Apoteker",
	"Arsitektur",
	"Biologi",
	"BioTeknologi",
	"Bisnis/Administrasi/Manajemen",
	"Ekonomi",
	"Farmasi",
	"Filosofi",
	"Fisika",
	"Geologi/Geofisika",
	"Hukum",
	"Ilmu & Manajemen Olahraga",
	"Ilmu Geografi",
	"Ilmu Kelautan",
	"Ilmu Komputer/Teknologi Informasi",
	"Ilmu Pengetahuan & Teknologi",
	"Ilmu Politik",
	"Ilmu Sosial/Sosiologi",
	"Jurnalisme",
	"Kedokteran",
	"Kedokteran Gigi",
	"Kedokteran Hewan",
	"Kemanusiaan/Pengetahuan Budaya",
	"Keperawatan",
	"Keuangan/Akuntansi/Perbankan",
	"Kimia",
	"Komersial",
	"Komunikasi Massa",
	"Linguistik/Bahasa",
	"Logistik/Transportasi",
	"Manajemen HR",
	"Manajemen Pelayanan Makanan & Minuman",
	"Manajemen Perpustakaan",
	"Matematika",
	"Musik/Seni Panggung",
	"Operasi Pesawat Terbang/Manajemen Bandara",
	"Optometri",
	"Pelayanan & Manajemen Perlindungan",
	"Pemasaran",
	"Pendidikan/Pengajaran/Pelatihan",
	"Pengembangan Properti/Manajemen Real Estate",
	"Perhotelan/Pariwisata/Manajemen Hotel",
	"Periklanan/Media",
	"Personal Service",
	"Psikologi",
	"Sejarah",
	"Sekretari",
	"Seni/Desain/Multimedia Kreatif",
	"Studi Perkotaan/Perencanaan Kota",
	"Survei Kuantitas",
	"Teknik (Aviasi/Penerbangan/Astronotika)",
	"Teknik (Bioteknologi/Biomedikal)",
	"Teknik (Elektro)",
	"Teknik (Fabrikasi/Peralatan Metal & Pencelupan/Pengelasan)",
	"Teknik (Ilmu Materi)",
	"Teknik (Industri)",
	"Teknik (Kelautan)",
	"Teknik (Komputer/Telekomunikasi)",
	"Teknik (Lainnya)",
	"Teknik (Lingkungan/Kesehatan/Keamanan)",
	"Teknik (Mechatronik/Elektromekanikal)",
	"Teknik (Mekanikal)",
	"Teknik (Pertambangan/Mineral)",
	"Teknik (Petroleum/Minyak/Gas)",
	"Teknik Kimia",
	"Teknik Sipil",
	"Teknologi Pangan/Nutrisi/Gizi",
	"Tekstil/Fashion Design",
	"Terapi Fisik/Fisioterapi",
	"Lainnya/Others",
];

export const LEVEL_OF_EDUCATION = [
	"SD",
	"SMP",
	"SMA/SMU/SMK/STM",
	"Diploma - D3",
	"Diploma - D4",
	"Sarjana - S1",
	"Magister - S2",
	"Doktor - S3",
];
