const qs = require("qs");
const getURL = (protocol = "https") => {
	let url = window.location.href.split("/")[2];
	let splitUrl = url.split(".");
	if (splitUrl[1] === "portalkerja") {
		return `${protocol}://backend.portalkerja.co.id`;
	} else if (splitUrl[1] === "feedlot") {
		return `${protocol}://backend.feedlot.id`;
	} else {
		return `${protocol}://backend.accel.id`;
	}
};

export const baseURL = (() => {
	return getURL();
})();

export const socketBaseURL = (() => {
	return getURL("wss");
})();

export const homeUrl = (() => {
	let url = window.location.href.split("/")[2];
	let splitUrl = url.split(".");
	if (splitUrl[1] === "portalkerja") {
		return "portalkerja.co.id";
	} else if (splitUrl[1] === "feedlot") {
		return `feedlot.id`;
	} else if (splitUrl[1] === "accel") {
		return `accel.id`;
	} else {
		return `localhost`;
	}
})();

export const EPStore = {
	getNewAccessToken: {
		url: "user/refresh-token/",
		method: "POST",
	},
	getUserViaToken: {
		url: "user/basicUserDetail/",
		method: "GET",
	},
	signUp: {
		url: "api/auth/userRegistration/",
		method: "POST",
	},
	logIn: { url: "user/login/", method: "POST" },
	logout: { url: "user/logout/", method: "POST" },
	sendResetPasswordEmail: {
		url: "user/send-reset-password-link/",
		method: "POST",
	},
	resetPassword: {
		url: "user/reset-password/",
		method: "POST",
	},
	//My Jobs
	getBookmarkedJobs: {
		url: "job_ad/bookmark-list/",
		method: "GET",
	},
	getAppliedJobs: {
		url: "job_application/applied-tab-list/",
		method: "GET",
	},
	getInterviewJobs: {
		url: "job_application/interview-tab-list/",
		method: "GET",
	},
	getWithdrawnJobs: {
		url: "job_application/withdrawn-tab-list/",
		method: "GET",
	},
	getJobAds: {
		url: "job_ad/list",
		method: "GET",
	},
	getJobBadge: {
		url: "job_ad/jobs/badge-counter",
		method: "GET",
	},
	addBookmark: {
		url: "job_ad/add-bookmark/",
		method: "POST",
	},
	removeBookmark: {
		url: "job_ad/remove-bookmark/",
		method: "DELETE",
	},
	candidateHistoryStatus: {
		url: "job_application/candidate-history-status/",
		method: "GET",
	},
	postWithdrawnApplication: {
		url: "job_application/withdrawn-application/",
		method: "PATCH",
	},
	reportJob: {
		url: "job_ad/report/",
		method: "POST",
	},

	// User Profile
	getProfile: {
		url: "user/show-profile/",
		method: "GET",
	},
	editUserProfile: {
		url: "user/profile/edit/",
		method: "PATCH",
	},
	userBadge: {
		url: "user/self/self-badge-user/",
		method: "GET",
	},
	// User Profile | Experience
	addExperience: {
		url: "user/experience/create/",
		method: "POST",
	},
	editExperience: {
		url: "user/experience/get-edit-delete/",
		method: "PATCH",
	},
	fetchExperience: {
		url: "user/experience/get-edit-delete/",
		method: "GET",
	},
	deleteExperience: {
		url: "user/experience/get-edit-delete/",
		method: "DELETE",
	},
	addExperienceDocument: {
		url: "user/experience/document-proof/create/",
		method: "POST",
	},
	deleteExperienceDocument: {
		url: "user/experience/document-proof/get-edit-delete/",
		method: "DELETE",
	},

	// User Profile | Education
	addEducation: {
		url: "user/education/create/",
		method: "POST",
	},
	editEducation: {
		url: "user/education/get-edit-delete/",
		method: "PATCH",
	},
	fetchEducation: {
		url: "user/education/get-edit-delete/",
		method: "GET",
	},
	deleteEducation: {
		url: "user/education/get-edit-delete/",
		method: "DELETE",
	},
	addEducationDocument: {
		url: "user/education/document-proof/create/",
		method: "POST",
	},
	deleteEducationDocument: {
		url: "user/education/document-proof/get-edit-delete/",
		method: "DELETE",
	},

	// User Profile | Skills
	addSkill: {
		url: "user/skill/create/",
		method: "POST",
	},
	fetchSkill: {
		url: "user/skill/get-edit-delete/",
		method: "GET",
	},
	editSkill: {
		url: "user/skill/get-edit-delete/",
		method: "PATCH",
	},
	deleteSkill: {
		url: "user/skill/get-edit-delete/",
		method: "DELETE",
	},
	addSkillDocument: {
		url: "user/skill/document-proof/create/",
		method: "POST",
	},
	deleteSkillDocument: {
		url: "user/skill/document-proof/get-edit-delete/",
		method: "DELETE",
	},

	// User Profile | Languages
	addLanguage: {
		url: "user/language/create/",
		method: "POST",
	},
	fetchLanguage: {
		url: "user/language/get-edit-delete/",
		method: "GET",
	},
	editLanguage: {
		url: "user/language/get-edit-delete/",
		method: "PATCH",
	},
	deleteLanguage: {
		url: "user/language/get-edit-delete/",
		method: "DELETE",
	},
	addLanguageDocument: {
		url: "user/language/document-proof/create/",
		method: "POST",
	},
	deleteLanguageDocument: {
		url: "user/language/document-proof/get-edit-delete/",
		method: "DELETE",
	},

	// User Profile | Job Preference
	addJobPreference: {
		url: "user/job-preference/create/",
		method: "POST",
	},
	fetchJobPreference: {
		url: "user/job-preference/get-edit-delete/",
		method: "GET",
	},
	editJobPreference: {
		url: "user/job-preference/get-edit-delete/",
		method: "PATCH",
	},

	// User Profile | Other Resume
	exportResume: {
		url: "user/resume-export",
		method: "GET",
	},
	updateResume: {
		url: "user/resume-preference/update/",
		method: "PATCH",
	},
	deleteResume: {
		url: "user/resume-preference/delete-file/",
		method: "DELETE",
	},

	// User Profile | Portfolio
	addPortfolio: {
		url: "user/portfolio/create/",
		method: "POST",
	},
	updatePortfolio: {
		url: "user/portfolio/get-edit-delete/",
		method: "PATCH",
	},
	deletePortfolio: {
		url: "user/portfolio/get-edit-delete/",
		method: "DELETE",
	},
	showProfile: {
		// url: "user/show-profile/",
		url: "user/self/self-badge-user/",
		method: "GET",
	},
	deactivateAccount: {
		url: "user/profile/deactivate-account/",
		method: "PATCH",
	},
	closeAccount: {
		url: "user/profile/close-account/",
		method: "PATCH",
	},
	getNotificationSetting: {
		url: "user/notification/get/",
		method: "GET",
	},
	updateNotificationSetting: {
		url: "user/notification/edit/",
		method: "PATCH",
	},
	changePassword: {
		url: "user/change-password/",
		method: "PATCH",
	},

	// User Profile | Resume Preference
	updateResumePreferences: {
		url: "user/resume-preference/update/",
		method: "PATCH",
	},

	getJobApplication: {
		url: "job_application/application-list/",
		method: "GET",
	},
	cancelApplication: {
		url: "job_application/cancel-job-application/",
		method: "DELETE",
	},
	createJobApplication: {
		url: "job_application/create-job-application/",
		method: "POST",
	},

	// contact details
	fetchContactDetails: {
		url: "job_application/retrieve-contact-detail/",
		method: "GET",
	},
	updateContactDetail: {
		url: "job_application/contact-detail/",
		method: "PATCH",
	},

	// resume
	fetchResumeDetails: {
		url: "job_application/retrieve-confirm-resume-file/",
		method: "GET",
	},
	updateResumeDetails: {
		url: "job_application/confirm-resume-file/",
		method: "PATCH",
	},

	// additional questions
	fetchAdditionalQuestions: {
		url: "job_ad/list-prescreening-question/",
		method: "GET",
	},
	saveAdditionalQuestions: {
		url: "job_ad/create-prescreening-answer/",
		method: "POST",
	},
	listPreScreeningAnswers: {
		url: "job_ad/list-prescreening-answer/",
		method: "GET",
	},
	bulkUpdatePreScreeningAnswer: {
		url: "job_ad/update-bulk-prescreening-answer/",
		method: "PUT",
	},

	// pitch
	getPitch: {
		url: "job_application/retrieve-self-promotion/",
		method: "GET",
	},
	updatePitch: {
		url: "job_application/self-promotion/",
		method: "PATCH",
	},

	// Cover Letter
	getCoverLetter: {
		url: "job_application/retrieve-cover-letter/",
		method: "GET",
	},
	updateCoverLetter: {
		url: "job_application/add-cover-letter/",
		method: "PATCH",
	},

	// Review Application
	fetchReviewApplicationData: {
		url: "job_application/review-application/",
		method: "GET",
	},
	submitApplication: {
		url: "job_application/submit-application/",
		method: "PATCH",
	},
	companyDetail: {
		url: "company/mini-detail/",
		method: "GET",
	},
};

export const generateRequestOptions = (key, options = {}) => {
	if (!key) {
		return null;
	}

	if (options && options.hasOwnProperty("queryParams")) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${
						options.urlParams || ""
				  }?${qs.stringify(options.queryParams)}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams || ""}?${qs.stringify(
						options.queryParams,
						options.queryOptions,
				  )}`,
		};
	}

	if (options && options.hasOwnProperty("queryParamsNoArrIdx")) {
		let qsString = qs.stringify(
			options.queryParamsNoArrIdx,
			!EPStore[key].hasOwnProperty("baseURL") ? options.queryOptions : undefined,
		);
		qsString = qsString.replace(/%5B[0-9]%5D=/g, "=");

		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${
						options.urlParams || ""
				  }?${qsString}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams || ""}?${qsString}`,
		};
	}

	if (options && options.hasOwnProperty("urlParams")) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${options.urlParams}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams}`,
		};
	}

	if (options && options.hasOwnProperty("innerUrlParams")) {
		let url = Object.keys(options.innerUrlParams).map((k) => {
			return EPStore[key].url.replace(`{${k}}`, options.innerUrlParams[k]);
		})[Object.keys(options.innerUrlParams).length - 1];
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${url}`
				: `${baseURL}/${url}`,
		};
	}
	return {
		...EPStore[key],
		url: EPStore[key].hasOwnProperty("baseURL")
			? `${EPStore[key].baseURL}/${EPStore[key].url}`
			: `${baseURL}/${EPStore[key].url}`,
	};
};
export const generateURL = (key) => {
	return `${homeUrl}/${EPStore[key].url}`;
};
