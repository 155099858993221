import React from "react";

const Anchor = ({ children, className, ...props }) => {
	return (
		<a className={className + " cursorPointer"} {...props}>
			{children}
		</a>
	);
};
export default Anchor;
