import { useState } from "react";
import { EPStore, generateRequestOptions } from "shared/utils/apiEndPoints";
import makeRequest from "shared/utils/request";

interface IResponse<T> {
	code?: number;
	message?: string;
	data?: T;
}

/**
 * @params type T for data type
 * @params type V for body type
 */
interface IUsePost<T, V = null> {
	data?: IResponse<T>["data"];
	error: string;
	loading: boolean;
	postFunction: (v: IPostFunction<V>) => Promise<IResponse<T>>;
}

interface IPostFunction<V> {
	body?: V | {};
	urlParams?: string;
	isFormData?: boolean;
}

const usePost = <T>(url: keyof typeof EPStore): IUsePost<T> => {
	const [data, setData] = useState<IUsePost<T>["data"]>();
	const [error, setError] = useState<IUsePost<T>["error"]>("");
	const [loading, setLoading] = useState<IUsePost<T>["loading"]>(false);

	const postFunction = async <V = {}>({
		body = {},
		urlParams,
		isFormData,
	}: IPostFunction<V>): Promise<IResponse<T>> => {
		setLoading(true);
		setError("");

		const res: IResponse<T> = await makeRequest({
			...generateRequestOptions(url, { urlParams: urlParams || "" }),
			headers: {
				"content-type": !isFormData ? "application/json" : "multipart/form-data",
			},
			body: body,
			json: true,
		});

		if (res.code === 200) {
			setData(res.data);
		} else {
			setError(res.message || "An error occurred.");
		}
		setLoading(false);

		return res;
	};

	return {
		data,
		error,
		loading,
		postFunction,
	};
};

export default usePost;
