import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as Variable from "../../../shared/utils/variables.js";
import * as ActionTypes from "../../../store/actions/actionTypes";
import { homeUrl } from "../../../shared/utils/apiEndPoints";

import "../SignUpComponent";
import arrowLeft from "../../../assets/svg/arrow_left.svg";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import Image from "../../../shared/component/UI/Image.js";
import ProgressBarComponent from "../../../shared/component/ProgressBarComponent/ProgressBarComponent.js";
import Toggle from "../../../shared/component/ToggleComponent/Toggle.js";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown.js";
import Select from "../../../shared/component/SelectComponent/Select.js";
import { PlusIcon, TrashIcon } from "../../../shared/component/svg/Icons.js";

const Skills = ({ move, ENV_NAME, ...props }) => {
	const SKILLS_STEP = [
		{ id: 1, name: Variable.SKILLS[ENV_NAME] },
		{ id: 2, name: Variable.DOCUMENT[ENV_NAME] },
	];

	const [step, setStep] = useState(1);
	const [toNext, setToNext] = useState(SKILLS_STEP[0]?.id);

	const nextStep = () => {
		setStep((p) => Math.min(p + 1, SKILLS_STEP?.length));
	};

	const previousStep = () => {
		setStep((p) => Math.max(p - 1, 0));
	};

	const validateAndSave = () => {
		console.log("validateAndSave");
	};

	const addNewSkill = () => {
		console.log("addNewSkill");
	};

	return (
		<div className="multiComponent">
			<div>
				<Image
					src={arrowLeft}
					onClick={previousStep}
					className="mb-std cursorPointer"
				/>
				<ProgressBarComponent percent={(step / SKILLS_STEP?.length) * 100} />
			</div>
			{toNext === SKILLS_STEP[0]?.id ? (
				<div className="">
					<h1>{Variable.SKILLS[ENV_NAME]}</h1>
					<div className="multiComponent-content">
						<div className="d-flex gap-sm">
							<Input
								label={Variable.SKILL_NAME[ENV_NAME]}
								placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Prototype`}
								name="skill_name"
								// value={}
								// onChange={}
								inputType="label"
								className="w-100"
							/>
							<Select
								label={Variable.LEVEL[ENV_NAME]}
								placeholder={Variable.SELECT_LABEL[ENV_NAME]}
								name="skill_level"
								// value={}
								// onChange={}
								// items={}
								searchable={false}
								className="w-100"
							/>
							<button className="btn pfc-btn pt-lg" onClick={() => {}}>
								<TrashIcon stroke="#667085" width="2.4rem" height="2.4rem" />
							</button>
						</div>
						<Button
							title={
								<div className="flex-all-center gap-xxs">
									<PlusIcon
										width="1.8rem"
										height="1.8rem"
										stroke="#193560"
										strokeWidth="3"
									/>
									{Variable.ADD_SKILLS[ENV_NAME]}
								</div>
							}
							btnClassName="p-0"
							type="textLink"
							onClick={() => addNewSkill()}
						/>
						<div className="multiComponent-btnContainer pt-md">
							<Button
								title={Variable.SKIP_LABEL[ENV_NAME]}
								btnClassName="px-xl"
								type="outline"
								size="sm"
								onClick={() => {
									nextStep();
									setToNext(SKILLS_STEP[1]?.id);
								}}
							/>
							<Button
								title={Variable.SAVE[ENV_NAME]}
								className="marLeft-auto"
								btnClassName="px-xl"
								type="primary"
								size="sm"
								onClick={() => {
									validateAndSave();
								}}
							/>
						</div>
					</div>
				</div>
			) : toNext === SKILLS_STEP[1]?.id ? (
				<div className="">
					<h1>{Variable.DOCUMENT[ENV_NAME]}</h1>
					<div className="multiComponent-content"></div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Skills));
