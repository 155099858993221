import { useEffect, useState } from "react";
import { last } from "lodash";

import ReviewSection from "./ReviewSection";
import Image from "../../shared/component/UI/Image";
import Button from "../../shared/component/ButtonComponent/Button";

import useToast from "shared/hooks/useToast";
import makeRequest from "../../shared/utils/request";
import useTranslate from "shared/hooks/useTranslate";
import { getIconByName } from "../../shared/utils/file";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";

import "./ApplyJobModal.scss";

function ReviewForm({ ENV_NAME, userDetails, jobAppId, jumpToForm, onApply, onHide, ...props }) {
	const t = useTranslate();
	const [form, setForm] = useState({});
	const toast = useToast();

	useEffect(() => {
		if (jobAppId) retrieveFormData();
	}, [jobAppId]);

	const retrieveFormData = async () => {
		const res = await makeRequest(
			generateRequestOptions("fetchReviewApplicationData", {
				urlParams: jobAppId,
			}),
		);

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const validateAndSave = async () => {
		const res = await makeRequest({
			...generateRequestOptions("submitApplication", { urlParams: jobAppId }),
			body: { stage: "submitted" },
		});

		if (res.code === 200) {
			onApply();
			onHide();
		} else toast.error(res.message);
	};

	return (
		<div className="form-container">
			<div className="contact-form review">
				<div className="body">
					{/* Contact Info */}
					<ReviewSection title={t("CONTACT_INFO")} onEdit={() => jumpToForm(1)}>
						<div className="section-info contact">
							<div className="user">
								<Image src={userDetails?.profile_picture} />
								<div className="user-details">
									<p className="name">{`${form?.first_name} ${form?.last_name}`}</p>
									<p className="email">{form?.email}</p>
									<p className="company-role">
										{form?.current_company}{" "}
										{form?.current_job_title ? <span>|</span> : ""}{" "}
										{form?.current_job_title}
									</p>
								</div>
							</div>

							<div className="user-contact mb-4">
								<p className="label">{t("MOBILE_NUMBER_1")}</p>
								<p className="contact">{form?.mobile_phone_number}</p>
							</div>
							<div className="user-contact">
								<p className="label">{t("ALTERNATE_MOBILE_NUMBER")}</p>
								<p className="contact">{form?.mobile_phone_number_2}</p>
							</div>
						</div>
					</ReviewSection>

					{/* Resume */}
					<ReviewSection title={t("RESUME")} onEdit={() => jumpToForm(2)}>
						<div className="section-info resume">
							{form?.resume_file?.resume_file ? (
								<div className="file-card">
									<div className="file-name">
										<Image src={getIconByName(form?.resume_file?.file_name)} />
										<p>{form?.resume_file?.file_name}</p>
									</div>
									<div className="file-card-btns">
										<Button
											type="textGray"
											title={t("VIEW")}
											className="btn-view"
											onClick={() =>
												window.open(
													form?.resume_file?.resule_file,
													"_blank",
												)
											}
										/>
									</div>
								</div>
							) : (
								<p>{t("NOT_UPLOADED")}</p>
							)}
						</div>
					</ReviewSection>

					{/* Additional Questions */}
					{form?.pre_screening_answer?.length && (
						<ReviewSection
							title={t("ADDITIONAL_QUESTIONS")}
							onEdit={() => jumpToForm(3)}
						>
							<div className="section-info">
								{form?.pre_screening_answer ? (
									<>
										{form?.pre_screening_answer.map((answer) => (
											<div className="question mb-4">
												<p className="label mb-0">
													{answer?.question_data?.question_text}
												</p>
												<p className="answer">
													{answer?.text_answer ||
														answer?.numeric_answer ||
														answer?.mcq_answer?.join(", ") ||
														(answer?.tf_answer ? t("YES") : t("NO"))}
												</p>
											</div>
										))}
									</>
								) : (
									<p>{`${t("NO")} ${t("ADDITIONAL_QUESTIONS")}`}</p>
								)}
							</div>
						</ReviewSection>
					)}

					{/* Pitch */}
					<ReviewSection title={t("MAKE_PITCH")} onEdit={() => jumpToForm(4)}>
						<div className="section-info">
							{form?.self_promote_note ? (
								<p>{form?.self_promote_note}</p>
							) : (
								<p className="light-text">{t("NOT_FILLED_YET")}</p>
							)}
						</div>
					</ReviewSection>

					{/* Cover Letter */}
					<ReviewSection title={t("COVER_LETTER")} onEdit={() => jumpToForm(5)}>
						<div className="section-info resume">
							{form?.cover_letter ? (
								<div className="file-card">
									<div className="file-name">
										<Image
											src={getIconByName(last(form?.cover_letter.split("/")))}
										/>
										<p>{last(form?.cover_letter.split("/"))}</p>
									</div>
									<div className="file-card-btns">
										<Button
											type="textGray"
											title={t("VIEW")}
											className="btn-view"
										/>
									</div>
								</div>
							) : (
								<p>{t("NOT_UPLOADED")}</p>
							)}
						</div>
					</ReviewSection>
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => props.onUpdateActiveForm(5)}
						title={t("BACK")}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={t("SUBMIT_APPLICATION")}
					/>
				</div>
			</div>
		</div>
	);
}

export default ReviewForm;
