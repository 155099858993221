import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import * as Variable from "../../utils/variables";
// import * as ActionTypes from "../../../store/actions/actionTypes";
import { classHelper } from "../../utils/stringUtils";

import "./ProgressBarComponent.scss";

const ProgressBarComponent = ({
  percent,
  positionPercent,
  customPercent,
  ENV_NAME,
  ...props
}) => {
  return (
    <div className={classHelper("progressBar", props.className)}>
      <div className="d-flex w-100 align-items-center">
        <div className="progressBar-bg">
          <div
            className="progressBar-content"
            style={{ width: `${Math.max(Number(percent || 0), 10)}%` }}
          ></div>
        </div>
        {positionPercent === "right" && (
          <label className="progressBar-label">
            {customPercent ||
              `${Math.max(Number(percent || 0), 1).toFixed(0) + "%"}`}
          </label>
        )}
      </div>
      {positionPercent === "bottom" && (
        <div className="progressBar-right mt-xs">
          <label>
            {customPercent ||
              `${Math.max(Number(percent || 0), 1).toFixed(0) + "%"}`}
          </label>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(ProgressBarComponent));
