import { useEffect, useState } from "react";
import { ProgressBar } from "react-step-progress-bar";
import { pick } from "lodash";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";

import useUserDetails from "../../shared/hooks/userDetails";

import ContactInfoForm from "./ContactInfoForm";
import ResumeForm from "./ResumeForm";
import AdditionalQuestions from "./AdditionalQuestions";
import Pitch from "./Pitch";
import CoverLetter from "./CoverLetter";
import ReviewForm from "./ReviewForm";

import "./ApplyJobModal.scss";
import "react-step-progress-bar/styles.css";
import useToast from "shared/hooks/useToast";
import useTranslate from "shared/hooks/useTranslate";
import ModalForm from "shared/component/ModalComponent/ModalForm";

function ApplyJobModal({
	userId,
	show,
	jobAppId,
	jobData,
	onHide,
	onApply,
	onFetchJobs,
	...props
}) {
	const t = useTranslate();
	const FORM_TYPE = {
		"contact-info": t("CONTACT_INFO"),
		resume: t("RESUME"),
		"additional-questions": t("ADDITIONAL_QUESTIONS"),
		pitch: t("MAKE_PITCH"),
		"cover-letter": t("COVER_LETTER"),
		review: t("REVIEW_PROFILE"),
	};
	const CURR_FORM = Object.keys(FORM_TYPE);
	const userDetails = useUserDetails();
	const [jobApp, setJobApp] = useState();

	const [activeForm, setActiveForm] = useState(1);
	const [editingForm, setEditingForm] = useState(null);
	const [hasQuestions, setHasQuestions] = useState(false);
	const toast = useToast();

	const updateActiveForm = (form) => setActiveForm(form);

	useEffect(() => {
		if (jobData?.id && jobData?.isContinued) retrieveJobApplication();
	}, [jobData]);

	const retrieveJobApplication = async () => {
		const res = await makeRequest(
			generateRequestOptions("getJobApplication", {
				queryParams: { job_ad_id: jobData?.id },
			}),
		);

		if (res?.code === 200) setJobApp(res.data?.[0]?.id);
		else toast.error(res.message);
	};

	return (
		<>
			<ModalForm
				className="apply-job"
				title={`${t("APPLY_TO")} ${jobData?.title}`}
				closeButton
				show={show}
				onHide={() => {
					onHide();
					setActiveForm(1);
					onFetchJobs();
				}}
				{...props}
			>
				<div className="aj-form">
					<div className="progress-bar">
						<ProgressBar
							percent={(activeForm * 100) / CURR_FORM.length}
							filledBackground="#193560"
						/>
						<h4>{FORM_TYPE[CURR_FORM[activeForm - 1]]}</h4>
					</div>

					<div className="aj-form-container">
						{activeForm === 1 ? (
							<ContactInfoForm
								onHide={onHide}
								jobAppId={jobApp}
								onUpdateActiveForm={updateActiveForm}
								userDetails={pick(userDetails?.personal_info, ["profile_picture"])}
								onFetchJobs={onFetchJobs}
							/>
						) : activeForm === 2 ? (
							<ResumeForm
								jobAppId={jobApp}
								resume={pick(userDetails?.resume, ["resume_file", "file_name"])}
								onUpdateActiveForm={updateActiveForm}
							/>
						) : activeForm === 3 ? (
							<AdditionalQuestions
								jobAppId={jobApp}
								jobId={jobData?.id}
								clearEditingForm={() => setEditingForm(null)}
								onUpdateActiveForm={updateActiveForm}
								onUpdateQuestions={setHasQuestions}
							/>
						) : activeForm === 4 ? (
							<Pitch
								jobAppId={jobApp}
								isContinued={jobData?.isContinued}
								hasQuestions={hasQuestions}
								onUpdateActiveForm={updateActiveForm}
							/>
						) : activeForm === 5 ? (
							<CoverLetter jobAppId={jobApp} onUpdateActiveForm={updateActiveForm} />
						) : activeForm === 6 ? (
							<ReviewForm
								jobAppId={jobApp}
								userDetails={pick(userDetails?.personal_info, ["profile_picture"])}
								onApply={onApply}
								onHide={() => {
									onHide();
									setActiveForm(1);
								}}
								jumpToForm={(formId) => {
									setActiveForm(formId);
									setEditingForm(formId);
								}}
								onUpdateActiveForm={updateActiveForm}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</ModalForm>
		</>
	);
}

export default ApplyJobModal;
