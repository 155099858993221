import * as Icons from "react-icons/bs";

import { classHelper } from "shared/utils/stringUtils";

import "./IconStatusStyle.scss";

type PropsType = {
	type: "error" | "success" | "warning";
};

const IconStatus = (props: PropsType) => (
	<div className={classHelper("icon-status-container", props.type)}>
		{props.type === "error" && <Icons.BsExclamationTriangle />}
		{props.type === "success" && <Icons.BsCheckLg />}
	</div>
);

export default IconStatus;
