import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import Select from "../../../shared/component/SelectComponent/Select";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import Image from "../../../shared/component/UI/Image";
import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import { getIconByName, isFileSizeLessOrEqual } from "../../../shared/utils/file";
import { COUNTRY_LIST } from "../../../shared/utils/dataOptions";
import makeRequest from "./../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import formatNumber from "../../../shared/utils/formatNumber";
import useToast from "shared/hooks/useToast";

import "../ProfileComponent.scss";

const ALLOWED_FILES = [
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"text/plain",
	"application/msword",
	"image/png",
	"image/jpeg",
];

const ExperienceModal = ({ ENV_NAME, onHide, onSave, onEdit, show, experienceId, ...props }) => {
	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const [form, setForm] = useState({ is_currently_working: false });
	const [monitor, setMonitor] = useState({});
	const [errors, setErrors] = useState({});
	const [countryList, setCountryList] = useState(null);
	const [showConfirm, setShowConfirm] = useState({ show: false, data: null });
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const uploadRef = useRef();
	const toast = useToast();

	useEffect(() => {
		if (show && ENV_NAME) {
			setCountryList(COUNTRY_LIST?.map((i) => ({ id: i, name: regionNames?.of(i) })));
		}
	}, [show, ENV_NAME]); // eslint-disable-line

	useEffect(() => {
		if (show && !experienceId)
			setForm((prev) => ({
				...prev,
				currency: "idr",
			}));
	}, [show, experienceId]); // eslint-disable-line

	useEffect(() => {
		if (show && experienceId) retrieveExperience(experienceId);
		if (!show) resetState();
	}, [show, experienceId]); // eslint-disable-line

	const retrieveExperience = async (id) => {
		const res = await makeRequest(generateRequestOptions("fetchExperience", { urlParams: id }));

		if (res.code === 200)
			setForm({
				...res.data,
				salary: formatNumber(res.data?.salary, res.data?.currency),
			});
		else toast.error(res.message);
	};

	const validateAndSave = async () => {
		const errorsData = {};
		if (!form.job_title) errorsData["job_title"] = true;
		if (!form.company_name) errorsData["company_name"] = true;
		if (!form.country || form.country === Variable.SELECT_LABEL[ENV_NAME])
			errorsData["country"] = true;
		if (!form.city) errorsData["city"] = true;
		if (!form.started_at) errorsData["started_at"] = true;
		if (!form.is_currently_working && !form.ended_at) errorsData["ended_at"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		setIsUploading(true);
		const docs = form?.experience_document_proof
			?.filter((doc) => !doc?.experience)
			?.map((doc) => doc?.experience_document_proof);

		if (experienceId) {
			const body = {};
			Object.keys(monitor).forEach((key) => {
				if (key !== "experience_document_proof") body[key] = form[key];
			});

			if (monitor["salary"]) {
				if (body.salary) body.salary = body?.salary?.replace(/[^0-9]/g, "");
				else body.salary = null;
			}

			if (body.is_currently_working) body.ended_at = null;

			body.currency = "idr";
			onEdit && (await onEdit(body, experienceId, docs));
		} else {
			const formClone = { ...form };
			if (!formClone.started_at)
				formClone["started_at"] = `${new Date().getFullYear()}-01-01`;
			if (!formClone.is_currently_working && !formClone.ended_at)
				formClone["ended_at"] = `${new Date().getFullYear()}-01-01`;

			if (formClone.is_currently_working) delete formClone.ended_at;

			if (!formClone.currency) formClone.currency = "idr";
			if (monitor["salary"] && formClone.salary)
				formClone.salary = formClone?.salary?.replace(/[^0-9]/g, "");

			if (isUploadingFile) formClone.experience_document_proof = docs;

			onSave && (await onSave(formClone));
		}
		setIsUploadingFile(false);
		setIsUploading(false);
	};

	const onFileChange = async (e) => {
		const file = e.target.files[0];
		setIsUploadingFile(true);
		if (file) {
			if (!ALLOWED_FILES.includes(file?.type))
				return toast.error(Variable.UNSUPPORTED_FILE[ENV_NAME]);

			const FILE_SIZE = 1024 * 1024 * 20;
			if (!isFileSizeLessOrEqual(file, FILE_SIZE))
				return toast.error(Variable.FILE_SIZE_ERROR[ENV_NAME]);

			setIsUploadingFile(true);
			const fileObj = {
				experience_document_proof: file,
				file_name: file?.name,
				id: Date.now(),
				is_newfile: true,
			};
			const formClone = { ...form };
			if (formClone.experience_document_proof)
				formClone.experience_document_proof.push(fileObj);
			else {
				formClone.experience_document_proof = [{ ...fileObj }];
			}
			setForm(formClone);
		}
		setIsUploadingFile(false);
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			experience_document_proof: form?.experience_document_proof?.filter(
				(file) => file.id !== docId,
			),
		});
	};

	const handleFileDelete = async () => {
		// There will be two kinds of delete
		// if there exists an experience for the document proof, then call the API
		// otherwise, filter out the experience documents
		const docId = showConfirm?.data?.id;

		const documentProof = form.experience_document_proof.find((doc) => doc.id === docId);
		if (documentProof?.experience) {
			const res = await makeRequest(
				generateRequestOptions("deleteExperienceDocument", {
					urlParams: docId,
				}),
			);
			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowConfirm({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowConfirm({ show: false, data: null });
		}
	};

	// FUNC: Utilities
	const resetState = () => {
		setForm({ currency: "idr" });
		setMonitor({});
		setErrors({});
	};

	const onChange = (e) => {
		const { name, value } = e.target;

		setForm((p) => ({
			...p,
			[name]: name === "salary" ? formatNumber(value, form?.currency) : value,
		}));
		setErrors({ ...errors, [name]: false });
		setMonitor({ ...monitor, [name]: true });
	};

	return (
		<>
			<ModalForm
				title={
					experienceId
						? Variable.EDIT_EXPERIENCE[ENV_NAME]
						: Variable.ADD_EXPERIENCE[ENV_NAME]
				}
				footer={
					<div className="flex-all-center gap-md w-100">
						<Button
							type="outline"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={() => {
								onHide();
								resetState();
							}}
							title={Variable.CLOSE[ENV_NAME]}
						/>
						<Button
							type="primary"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={validateAndSave}
							title={Variable.SAVE[ENV_NAME]}
							disabled={isUploading}
						/>
					</div>
				}
				show={show && !showConfirm?.show}
				{...props}
			>
				<div className="pc-form">
					<Input
						label={Variable.JOB_TITLE[ENV_NAME]}
						placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Sales`}
						value={form?.job_title || ""}
						name="job_title"
						onChange={onChange}
						inputType="label"
						className="w-100"
						required
						isError={errors["job_title"]}
					/>
					<Input
						label={Variable.COMPANY_NAME[ENV_NAME]}
						placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Facebook`}
						value={form?.company_name || ""}
						name="company_name"
						onChange={onChange}
						inputType="label"
						className="w-100"
						required
						isError={errors["company_name"]}
					/>
					<div className="pc-form-row">
						<Select
							label={Variable.COUNTRY[ENV_NAME]}
							placeholder={Variable.COUNTRY[ENV_NAME]}
							name="country"
							value={form?.country || ""}
							items={countryList}
							onChange={onChange}
							searchable={false}
							required
							noDefault
							isError={errors["country"]}
						/>
						<Input
							label={Variable.CITY[ENV_NAME]}
							placeholder={Variable.CITY[ENV_NAME]}
							value={form?.city || ""}
							name="city"
							onChange={onChange}
							inputType="label"
							className="w-100"
							required
							isError={errors["city"]}
						/>
					</div>
					<Toggle
						type="switch"
						leftText={Variable.WORK_HERE_CURRENTLY[ENV_NAME]}
						name="is_currently_working"
						checked={form?.is_currently_working}
						onChange={(e) => {
							setForm((p) => ({ ...p, is_currently_working: e.target.checked }));
							setMonitor((p) => ({ ...p, is_currently_working: true }));
						}}
					/>
					<DatePickerDropdown
						className="pc-form-row"
						dayLabel={<>&nbsp;</>}
						monthLabel={Variable.WORK_FROM[ENV_NAME]}
						yearLabel={<>&nbsp;</>}
						name="started_at"
						value={form?.started_at}
						onChange={onChange}
						isError={errors["started_at"]}
						hideDate
						required
					/>
					{!form?.is_currently_working && (
						<DatePickerDropdown
							className="pc-form-row"
							dayLabel={<>&nbsp;</>}
							monthLabel={Variable.WORK_TO[ENV_NAME]}
							yearLabel={<>&nbsp;</>}
							name="ended_at"
							value={form?.ended_at}
							onChange={onChange}
							hideDate
							isError={errors["ended_at"]}
						/>
					)}
					<div className="pc-form-row">
						<Select
							label={Variable.MONTHLY_SALARY[ENV_NAME]}
							placeholder={Variable.MONTHLY_SALARY[ENV_NAME]}
							name="currency"
							value={"IDR"}
							items={Variable.CURRENCY_LIST}
							onChange={onChange}
							searchable={false}
							className="w-100"
							disabled
						/>
						<Input
							label={<>&nbsp;</>}
							placeholder={Variable.SALARY_AMOUNT[ENV_NAME]}
							value={form?.salary || ""}
							name="salary"
							onChange={onChange}
							inputType="text"
							className="w-100"
						/>
					</div>
					<Input
						label={Variable.DESCRIPTION[ENV_NAME]}
						placeholder={Variable.DESCRIPTION_PLACEHOLDER[ENV_NAME]}
						value={form?.description || ""}
						name="description"
						onChange={(e) => {
							setForm({ ...form, description: e });
							setMonitor((prev) => ({ ...prev, description: true }));
						}}
						inputType="textarea"
						className="w-100"
					/>
					<div>
						<h5 className="text-md font-weight-medium text-label-gray mb-sm">
							{Variable.DOCUMENT[ENV_NAME]}
						</h5>
						<span className="text-sm">
							{Variable.DOCUMENT_EXPERIENCE_SUBTITLE[ENV_NAME]}
						</span>
					</div>
					<div className="input-btn">
						<Button
							type="secondary"
							size="md"
							title={
								<div className="flex-all-center gap-xxs">
									<PlusIcon
										width="1.8rem"
										height="1.8rem"
										stroke="white"
										strokeWidth="3"
									/>
									{Variable.ADD_FILE[ENV_NAME]}
								</div>
							}
							onClick={() => uploadRef?.current?.click()}
						/>
						<input
							id="upload-files"
							type="file"
							name="subjectIcon"
							className="inputfile d-none"
							onChange={(e) => {
								onFileChange(e);
								uploadRef.current.value = null;
							}}
							accept={ALLOWED_FILES.join(",")}
							ref={uploadRef}
						/>
					</div>
					{form?.experience_document_proof?.map((i) => (
						<FileCard
							key={i?.id}
							title={i?.file_name}
							icon={getIconByName(i?.file_name)}
							onDelete={() => setShowConfirm({ show: true, data: i })}
							className="fileCard"
							showGreenCheck={false}
							isUploading={i?.is_newfile && isUploading}
							isNewFile={i?.is_newfile}
						/>
					))}
				</div>
			</ModalForm>

			<ModalInfo
				isShow={showConfirm?.show}
				isHide={() => setShowConfirm({ show: false, data: null })}
				onConfirm={handleFileDelete}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ExperienceModal));
