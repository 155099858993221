import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../ProfileComponent.scss";
import ModalForm from "../../../shared/component/ModalComponent/ModalForm";
import * as Variable from "../../../shared/utils/variables";
import Button from "../../../shared/component/ButtonComponent/Button";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import formatNumber from "../../../shared/utils/formatNumber";
import useToast from "shared/hooks/useToast";

const JobPrefModal = ({ ENV_NAME, onHide, onEdit, onSave, jobPrefId, ...props }) => {
	const WORK_MODEL = [
		{ id: "remote", name: Variable.REMOTE_LABEL[ENV_NAME] },
		{ id: "hybrid", name: Variable.HYBRID_LABEL[ENV_NAME] },
		{ id: "onsite", name: Variable.ONSITE_LABEL[ENV_NAME] },
	];
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const toast = useToast();

	useEffect(() => {
		if (props.show && !jobPrefId)
			setForm({
				currency: "idr",
			});
	}, [props.show, jobPrefId]); // eslint-disable-line

	useEffect(() => {
		if (form?.preferred_work_model && form?.preferred_work_model === "remote") {
			setForm((prev) => ({
				...prev,
				preferred_work_location: "",
				preferred_work_location_2: "",
				preferred_work_location_3: "",
			}));
		}
	}, [form?.preferred_work_model]);

	useEffect(() => {
		if (props.show && jobPrefId) retrieveJobPref(jobPrefId);
	}, [props.show, jobPrefId]); // eslint-disable-line

	const retrieveJobPref = async () => {
		const res = await makeRequest(
			generateRequestOptions("fetchJobPreference", { urlParams: jobPrefId }),
		);

		if (res.code === 200)
			setForm({
				...res.data,
				expected_salary: formatNumber(res.data?.expected_salary, res.data?.currency),
			});
		else toast.error(res.message);
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((p) => ({
			...p,
			[name]: name === "expected_salary" ? formatNumber(value, form?.currency) : value,
		}));
		setErrors((p) => ({ ...p, [name]: false }));
	};

	const validateAndSave = () => {
		const {
			preferred_work_model,
			preferred_work_location,
			preferred_work_location_2,
			preferred_work_location_3,
		} = form;

		const errorsData = {};
		if (
			["hybrid", "onsite"].includes(preferred_work_model) &&
			!preferred_work_location &&
			!preferred_work_location_2 &&
			!preferred_work_location_3
		) {
			errorsData["preferred_work_location"] = true;
			errorsData["preferred_work_location_2"] = true;
			errorsData["preferred_work_location_3"] = true;
		}

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		if (!form?.preferred_work_model) delete form?.preferred_work_model;

		if (form.expected_salary)
			form.expected_salary = form?.expected_salary?.replace(/[^0-9]/g, "");
		form.currency = "idr";

		if (jobPrefId) onEdit && onEdit(form, jobPrefId);
		else onSave && onSave(form);
	};

	return (
		<ModalForm
			title={Variable.JOB_PREFERENCES[ENV_NAME]}
			footer={
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={onHide}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.SAVE[ENV_NAME]}
					/>
				</div>
			}
			{...props}
		>
			<div className="pc-form">
				<div className="pc-form-row">
					<Select
						label={Variable.EXPECTED_PAY_CURRENCY[ENV_NAME]}
						placeholder={Variable.EXPECTED_PAY_CURRENCY[ENV_NAME]}
						name="currency"
						value={"idr"}
						items={Variable.CURRENCY_LIST}
						onChange={onChange}
						searchable={false}
						className="w-100"
						disabled
					/>
					<Input
						label={Variable.EXPECTED_PAY_AMOUNT[ENV_NAME]}
						placeholder={Variable.EXPECTED_PAY_AMOUNT[ENV_NAME]}
						name="expected_salary"
						value={form?.expected_salary || ""}
						onChange={onChange}
						inputType="number"
						className="w-100"
					/>
				</div>
				<Select
					label={Variable.WORK_MODE_LABEL[ENV_NAME]}
					placeholder={Variable.WORK_MODE_LABEL[ENV_NAME]}
					name="preferred_work_model"
					value={form?.preferred_work_model || ""}
					items={WORK_MODEL}
					onChange={onChange}
					searchable={false}
					className="w-100"
					noDefault
				/>
				<Input
					label={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 1"}
					placeholder={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 1"}
					value={form?.preferred_work_location || ""}
					name="preferred_work_location"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["preferred_work_location"]}
					disabled={form?.preferred_work_model === "remote"}
				/>
				<Input
					label={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 2"}
					placeholder={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 2"}
					value={form?.preferred_work_location_2 || ""}
					name="preferred_work_location_2"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["preferred_work_location"]}
					disabled={form?.preferred_work_model === "remote"}
				/>
				<Input
					label={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 3"}
					placeholder={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 3"}
					value={form?.preferred_work_location_3 || ""}
					name="preferred_work_location_3"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["preferred_work_location"]}
					disabled={form?.preferred_work_model === "remote"}
				/>
			</div>
		</ModalForm>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(JobPrefModal));
