import { homeUrl } from "shared/utils/apiEndPoints";
import Button from "shared/component/ButtonComponent/Button";
import { Typography } from "shared/component/Typography/Typography";

import "./EmptyTabStateStyle.scss";

type PropTypes = {
	title: string;
	desc: string;
	buttonBrowse?: boolean;
	image: JSX.Element;
};

const EmptyTabState = (props: PropTypes) => {
	return (
		<div className="empty-state-cont">
			{props.image}
			<div className="coming-soon-label">
				<Typography.Text fontWeight="semibold" size="md">
					{props.title}
				</Typography.Text>
				<Typography.Text fontWeight="regular" size="sm">
					{props.desc}
				</Typography.Text>
			</div>
			{!!props.buttonBrowse && (
				<div>
					<Button
						type="primary"
						title="Browse Job"
						size="sm"
						onClick={() =>
							window.open(`https://jobmarket.${homeUrl}/cari-kerja`, "_self")
						}
					/>
				</div>
			)}
		</div>
	);
};

export default EmptyTabState;
