import { CSSProperties, FC, ReactElement, useRef, useState } from "react";
import cx from "classnames";

import "./Tooltip.scss";
import useOutsideClick from "shared/hooks/outsideClick";

interface TooltipProps {
	children: ReactElement;
	description: string;
	placement?: string;
	className?: string;
	showWhenHover?: boolean;
	showTriangle?: boolean;
	style?: CSSProperties;
	onClick?: (e: any) => void;
}

const Tooltip: FC<TooltipProps> = ({
	children,
	description,
	placement = "bottom",
	className,
	showWhenHover = true,
	showTriangle = true,
	style = {},
	onClick = () => {},
}) => {
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [showContactDetail, setShowContactDetail] = useState(false);

	useOutsideClick(tooltipRef, () => setShowContactDetail(false));

	return (
		<div
			className={cx("tooltip-container", className, {
				"tooltip-hover": showWhenHover,
			})}
			ref={tooltipRef}
			style={{ ...style }}
			onClick={onClick}
		>
			<span onClick={() => setShowContactDetail((prev) => !prev)}>{children}</span>
			<div
				className={cx(`tooltip tooltip-potition__${placement}`, {
					"hide-tooltip": !showContactDetail,
					"tooltip-triangle": showTriangle,
				})}
			>
				<div
					className="tooltip-wrapper"
					dangerouslySetInnerHTML={{ __html: description }}
				></div>
			</div>
		</div>
	);
};

export default Tooltip;
