import { ChangeEvent } from "react";
import { BiArrowBack } from "react-icons/bi";

import * as Variables from "../../shared/utils/variables";
import RadioInput from "shared/component/InputComponent/RadioInput";
import Input from "shared/component/InputComponent/Input";
import Button from "shared/component/ButtonComponent/Button";

import useTranslate from "shared/hooks/useTranslate";
import { formatString } from "shared/utils/string";
import { RootState } from "store/types/RootStateTypes";
import { useSelector } from "react-redux";

interface FormFields {
	password?: string;
	reason?: string;
	feedback?: string;
}

interface Props {
	form: FormFields;
	isDeactivating: boolean;
	onChange: (name: string, value: string) => void;
	onNextPrev: (id: number) => void;
}

interface Questions {
	label: keyof typeof Variables;
	value:
		| "i_need_a_break"
		| "im_getting_too_many_emails"
		| "i_have_a_privacy_concerns"
		| "i_have_a_safety_concerns"
		| "other"
		| string;
}

const QUESTIONS: Questions[] = [
	{ label: "I_NEED_BREAK", value: "i_need_a_break" },
	{ label: "GETTING_MANY_EMAILS", value: "im_getting_too_many_emails" },
	{ label: "PRIVACY_CONCERN", value: "i_have_a_privacy_concerns" },
	{ label: "SAFETY_CONCERN", value: "i_have_a_safety_concerns" },
	{ label: "OTHER", value: "other" },
];

const CLOSE_QUESTIONS: Questions[] = [
	{ label: "DUPLICATE_ACCOUNT", value: "i_have_a_duplicate_account" },
	{ label: "GETTING_MANY_EMAILS", value: "im_getting_too_many_emails" },
	{ label: "NOT_GETTING_VALUE", value: "im_not_getting_any_value_from_my_membership" },
	{ label: "PRIVACY_CONCERN", value: "i_have_a_privacy_concerns" },
	{ label: "UNWANTED_CONTRACT", value: "im_receiving_unwanted_contact" },
	{ label: "OTHER", value: "other" },
];

const Questionnaire = ({ form, onChange, isDeactivating, onNextPrev }: Props) => {
	const t = useTranslate();
	const { first_name } = useSelector((state: RootState) => ({
		first_name: state?.auth?.userDetails?.first_name,
	}));

	return (
		<>
			<div className="header">
				<h3>{t(isDeactivating ? "DEACTIVATE_ACCOUNT" : "CLOSE_ACCOUNT")}</h3>
				{isDeactivating ? (
					<p>{t("DEACTIVATE_ACCOUNT_TEXT")}</p>
				) : (
					<p>{formatString(t("CLOSE_CONFIRM"), first_name)}</p>
				)}
			</div>
			<div className="col-layout">
				{isDeactivating ? (
					<p className="mb-0 text-sm text-label-gray text-500">
						{t("DEACTIVATE_ACCOUNT_DESC")}
					</p>
				) : (
					""
				)}
				<div>
					{isDeactivating ? (
						<p className="text-sm text-500 mb-4">{t("DEACTIVATE_ACCOUNT_TELL")}</p>
					) : (
						<p className="text-sm text-500 mb-4">{t("CLOSE_ACCOUNT_TELL")}</p>
					)}
					<div className="col-layout">
						{(isDeactivating ? QUESTIONS : CLOSE_QUESTIONS).map((question) => (
							<RadioInput
								key={question?.value}
								label={t(question?.label)}
								id={question?.value}
								name="reason"
								value={form?.reason}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									onChange(e.target.name, e.target.value)
								}
								checked={form?.reason === question?.value}
							/>
						))}
					</div>
				</div>
				{(isDeactivating || (!isDeactivating && form?.reason === "other")) && (
					<div>
						<p className="mb-0 text-sm text-label-gray text-500">
							{t(
								isDeactivating
									? "DEACTIVATE_ACCOUNT_TEXTAREA"
									: "CLOSE_ACCOUNT_REASON",
							)}
						</p>

						<Input
							value={form?.feedback || ""}
							name="feedback"
							onChange={(value: string) => onChange("feedback", value)}
							inputType="textarea"
							placeholder={t("ENTER_DESCRIPTION")}
							className="mt-3"
						/>
					</div>
				)}

				<div className="row-layout">
					<Button
						type="outline"
						size="sm"
						title={t("BACK")}
						customIcon={<BiArrowBack size={18} className="me-xxs" />}
						onClick={() => onNextPrev(isDeactivating ? 0 : 1)}
					/>
					<Button
						title={t("NEXT")}
						type="primary"
						size="sm"
						onClick={() => onNextPrev(3)}
					/>
				</div>
			</div>
		</>
	);
};

export default Questionnaire;
