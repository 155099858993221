import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";
import arrowDown from "../../../assets/svg/arrow_down.svg";
import arrowTop from "../../../assets/svg/arrow_top.svg";

import "./DropdownComponent.scss";
import Image from "../UI/Image";
import useOutside from "../../hooks/outsideClick";

// DOCUMENTATION
// type : [date, salary, jobType, workMode, experienceLevel, industry, underTenApp]

const Dropdown = ({ type, ENV_NAME, onApplyButton, ...props }) => {
    
  const DATE_POST = [
    { id: "anyTime", name: Variable.ANY_TIME_LABEL[ENV_NAME] },
    { id: "24hours", name: Variable.LAST_24_HOURS[ENV_NAME] },
    { id: "3days", name: Variable.LAST_3_DAYS[ENV_NAME] },
    { id: "7days", name: Variable.LAST_7_DAYS[ENV_NAME] },
    { id: "14days", name: Variable.LAST_14_DAYS[ENV_NAME] },
    { id: "30days", name: Variable.LAST_30_DAYS[ENV_NAME] },
  ];

  const WORK_MODE = [
    { id: "onSite", name: Variable.ONSITE_LABEL[ENV_NAME] },
    { id: "hybrid", name: Variable.HYBRID_LABEL[ENV_NAME] },
    { id: "remote", name: Variable.REMOTE_LABEL[ENV_NAME] },
  ];

  const JOB_TYPE = [
    { id: "fullTime", name: Variable.FULL_TIME_LABEL[ENV_NAME] },
    { id: "partTime", name: Variable.PART_TIME_LABEL[ENV_NAME] },
    { id: "freelance", name: Variable.FRELANCE_LABEL[ENV_NAME] },
    { id: "contract", name: Variable.CONTRACT_LABEL[ENV_NAME] },
    { id: "internship", name: Variable.INTERNSHIP_LABEL[ENV_NAME] },
    { id: "temporary", name: Variable.TEMPORARY_LABEL[ENV_NAME] },
  ];

  const EXPERIENCE_LEVEL = [
    { id: "entryLevel", name: Variable.ENTRY_LEVELLABEL[ENV_NAME] },
    { id: "associate", name: Variable.ASSOCIATE_LABEL[ENV_NAME] },
    {
      id: "intermediateSenior",
      name: Variable.INTERMEDIATE_SENIOR_LABEL[ENV_NAME],
    },
    { id: "director", name: Variable.DIRECTOR_LABEL[ENV_NAME] },
    { id: "executive", name: Variable.EXECUTIVE_LABEL[ENV_NAME] },
  ];

	const onHide = () => {
		if (isOpen) setIsOpen(false);
	};

  const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef();

	useOutside(dropdownRef, onHide);

  return (
    <div className={classHelper("J-ddComp", props.className)} ref={dropdownRef}>
      <div className="J-ddComp-container" onClick={() => setIsOpen(!isOpen)}>
        {type === "date"
          ? Variable.DATE_LABEL[ENV_NAME]
          : type === "salary"
          ? Variable.SALARY[ENV_NAME]
          : type === "jobType"
          ? Variable.JOB_TYPE_LABEL[ENV_NAME]
          : type === "workMode"
          ? Variable.WORK_MODE_LABEL[ENV_NAME]
          : type === "experienceLevel"
          ? Variable.EXPERIENCE_LEVEL_LABEL[ENV_NAME]
          : type === "industry"
          ? Variable.INDUSTRY_LABEL[ENV_NAME]
          : type === "underTenApp"
          ? Variable.UNDER_TEN_APP_LABEL[ENV_NAME]
          : Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME]}
        <span>
          {type !== "underTenApp" && (
            <Image
              className="J-ddComp-down"
              src={`${isOpen ? arrowTop : arrowDown}`}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
        </span>
      </div>
      <div className="J-ddComp-menu-cont">
        {isOpen && type === "date" ? (
          <>
            <div className="J-ddComp-menu">
              {DATE_POST?.map((dp, dpIdx) => (
                <div className="menuDD">
                  <label htmlFor={`${dp?.id}-inMenu`}>{dp?.name}</label>
                  <input
                    type="checkbox"
                    id={`${dp?.id}-inMenu`}
                    name={`${dp?.id}-inMenu`}
                  />
                </div>
              ))}
            </div>
            <div className="J-ddComp-apply">
              <button onClick={onApplyButton}>{Variable.APPLY[ENV_NAME]}</button>
            </div>
          </>
        ) : isOpen && type === "jobType" ? (
          <>
            <div className="J-ddComp-menu">
              {JOB_TYPE?.map((jt, jtIdx) => (
                <div className="menuDD">
                  <label htmlFor={`${jt?.id}-inMenu`}>{jt?.name}</label>
                  <input
                    type="checkbox"
                    id={`${jt?.id}-inMenu`}
                    name={`${jt?.id}-inMenu`}
                  />
                </div>
              ))}
            </div>
            <div className="J-ddComp-apply">
              <button onClick={onApplyButton}>{Variable.APPLY[ENV_NAME]}</button>
            </div>
          </>
        ) : isOpen && type === "workMode" ? (
          <>
            <div className="J-ddComp-menu">
              {WORK_MODE?.map((wm, wmIdx) => (
                <div className="menuDD">
                  <label htmlFor={`${wm?.id}-inMenu`}>{wm?.name}</label>
                  <input
                    type="checkbox"
                    id={`${wm?.id}-inMenu`}
                    name={`${wm?.id}-inMenu`}
                  />
                </div>
              ))}
            </div>
            <div className="J-ddComp-apply">
              <button onClick={onApplyButton}>{Variable.APPLY[ENV_NAME]}</button>
            </div>
          </>
        ) : isOpen && type === "experienceLevel" ? (
          <>
            <div className="J-ddComp-menu">
              {EXPERIENCE_LEVEL?.map((el, elIdx) => (
                <div className="menuDD">
                  <label htmlFor={`${el?.id}-inMenu`}>{el?.name}</label>
                  <input
                    type="checkbox"
                    id={`${el?.id}-inMenu`}
                    name={`${el?.id}-inMenu`}
                  />
                </div>
              ))}
            </div>
            <div className="J-ddComp-apply">
              <button onClick={onApplyButton}>{Variable.APPLY[ENV_NAME]}</button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(Dropdown));
