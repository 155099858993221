import ReactDOM from "react-dom";
import Router from "./Route";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "./shared/component/IconComponent/Icon";
import { RedCrossIcon } from "./shared/component/svg/Icons";
import { Text } from "shared/component/Typography/Typography";
import * as Variable from "shared/utils/variables";
import { useEnvironment } from "shared/hooks/useEnvironment";

// On this we only load Router and Toast Container
// More detailed code is in the Router
function App() {
	const ENV_NAME = useEnvironment();

	return (
		<>
			<Router />
			{/* Put default toast on the top center */}
			{ReactDOM.createPortal(
				<ToastContainer
					hideProgressBar
					draggable={false}
					position={toast.POSITION.TOP_CENTER}
					icon={({ theme, type }) => {
						switch (type) {
							case "success":
								return (
									<>
										<Icon
											iconType="success"
											style={{
												marginTop: 0,
											}}
										/>
										<Text
											size="md"
											fontWeight="semibold"
											style={{
												marginLeft: "1.75rem",
											}}
										>
											{Variable.SUCCESS[ENV_NAME]}
										</Text>
									</>
								);
							case "warning":
								return (
									<>
										<Icon
											iconType="warning"
											style={{
												marginTop: 0,
											}}
										/>
										<Text
											size="md"
											fontWeight="semibold"
											style={{
												marginLeft: "1.75rem",
											}}
										>
											{Variable.WARNING[ENV_NAME]}
										</Text>
									</>
								);
							case "error":
								return (
									<>
										<Icon
											iconType="error"
											style={{
												marginTop: 0,
											}}
										/>
										<Text
											size="md"
											fontWeight="semibold"
											style={{
												marginLeft: "1.75rem",
											}}
										>
											{Variable.ERROR[ENV_NAME]}
										</Text>
									</>
								);
							default:
								return <Icon iconType="success" />;
						}
					}}
					closeButton={({ type, closeToast }) => {
						let strokeColor = "";
						switch (type) {
							case "success":
								strokeColor = "#039855";
								break;
							case "warning":
								strokeColor = "#DC6803";
								break;
							case "error":
								strokeColor = "#D92D20";
								break;
							default:
								strokeColor = "#039855";
								break;
						}
						return (
							<div className="cursorPointer flex-all-center" onClick={closeToast}>
								<RedCrossIcon
									width="3.2rem"
									height="3.2rem"
									stroke={strokeColor || "#039855"}
									className="my-auto"
								/>
							</div>
						);
					}}
				/>,
				document.getElementById("toast-portal"),
			)}
		</>
	);
}

export default App;
