import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useTranslate from "shared/hooks/useTranslate";

import { RenderIf } from "shared/utils/common";
import Questionnaire from "./Questionnaire";
import ConfirmDeactivateClose from "./ConfirmDeactivateClose";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import { ROOT } from "shared/utils/routeLink";
import CloseConfirm from "./CloseConfirm";

interface Props {
	isDeactivating: boolean;
	onDeactivateClose: (status: string) => void;
}

interface FormFields {
	password?: string;
	reason?: string;
	feedback?: string;
}

const DeactivateCloseAccount = ({ isDeactivating, onDeactivateClose }: Props) => {
	const t = useTranslate();
	const history = useHistory();
	const [activeForm, setActiveForm] = useState(0);
	const [form, setForm] = useState<FormFields>({
		password: "",
		reason: "",
		feedback: "",
	});
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (isDeactivating) setActiveForm(2);
		else setActiveForm(1);
	}, [isDeactivating]);

	const handleChange = (name: string, value: string) => setForm((p) => ({ ...p, [name]: value }));

	const handleNextPrev = (id: number) => {
		if (id === 0) return onDeactivateClose("");
		setActiveForm(id);
	};

	return (
		<div className="dc-comp">
			<div className="data-div">
				<RenderIf condition={activeForm === 1 && !isDeactivating}>
					{/* Only for Close account */}
					<CloseConfirm onNextPrev={handleNextPrev} />
				</RenderIf>
				{/* Form 2 -> Questionnaire */}
				<RenderIf condition={activeForm === 2}>
					<Questionnaire
						form={form}
						isDeactivating={isDeactivating}
						onChange={handleChange}
						onNextPrev={handleNextPrev}
					/>
				</RenderIf>

				{/* Form 3 -> Enter password */}
				<RenderIf condition={activeForm === 3}>
					<ConfirmDeactivateClose
						form={form}
						isDeactivating={isDeactivating}
						onChange={handleChange}
						onNextPrev={handleNextPrev}
						onShowModal={() => setShowModal(true)}
					/>
				</RenderIf>
			</div>

			<ModalInfo
				isShow={showModal}
				title={t("SUCCESS")}
				subtitle={t(isDeactivating ? "SUCCESS_DEACTIVATED" : "SUCCESS_CLOSED")}
				onConfirm={() => {
					setShowModal(false);
					history.push(ROOT);
				}}
				confirmLabel={t("DONE")}
				confirmButtonType="primary"
			></ModalInfo>
		</div>
	);
};

export default DeactivateCloseAccount;
