import { nFormatter } from "shared/utils/string";
import { SalaryRateUtils } from "shared/utils/variables";
import { useEnvironment } from "shared/hooks/useEnvironment";
import { SalaryTypeEnum } from "types/API/TypesSwagger";

type SalaryRangeType = {
	salaryStart: number;
	salaryEnd: number;
	currency: string;
	salaryRate: string;
	salaryType?: SalaryTypeEnum;
	salaryAmount?: number;
};

export const SalaryRangeFormatUtil = ({
	salaryStart,
	salaryEnd,
	currency,
	salaryRate,
	salaryType = SalaryTypeEnum.RangeAmount,
	salaryAmount,
}: SalaryRangeType) => {
	const ENV = useEnvironment();

	let salaryFormatList: Record<SalaryTypeEnum, () => string[]> = {
		range_amount: () => [
			nFormatter(salaryStart ?? 0, 1, ENV),
			"-",
			nFormatter(salaryEnd ?? 0, 1, ENV),
		],
		exact_amount: () => [nFormatter(salaryAmount ?? 0, 1, ENV)],
		starting_amount: () => [nFormatter(salaryStart ?? 0, 1, ENV)],
		maximum_amount: () => [nFormatter(salaryEnd ?? 0, 1, ENV)],
	};

	return [
		currency.toUpperCase(),
		...(salaryType
			? salaryFormatList?.[salaryType]() ?? salaryFormatList.starting_amount()
			: ""),
		SalaryRateUtils(salaryRate)[ENV],
	].join(" ");
};
