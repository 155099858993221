import { homeUrl } from 'shared/utils/apiEndPoints';

import production from 'featureFlags/production.json';
import staging from 'featureFlags/staging.json';
import uat from 'featureFlags/uat.json';

const uatRelease = ['portalkerja.co.id'];
const stagingRelease = ['accel.id', 'employer.accel.id', 'localhost'];
const productionRelease = ['portalkerja.co.id'];

export const useFlags = () => {
    let flags = {};

    switch (true) {
        case productionRelease.includes(homeUrl):
            flags = production;
            break;
        case uatRelease.includes(homeUrl):
            flags = uat;
            break;
        case stagingRelease.includes(homeUrl):
            flags = staging;
            break;
        default:
            break;
    }

    const flag = (k: string) => {
        let value = flags;
        const keys = k.split('.');
    
        for (const k of keys) {
            if (value && typeof value === 'object' && value.hasOwnProperty(k)) {
                value = value[k];
            } else {
                return false;
            }
        }
        return value || false;
    }

    return { flag };
};