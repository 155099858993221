import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { classHelper } from "../../utils/stringUtils";
import * as Variable from "../../utils/variables";

import "./CardComponent.scss";
import Image from "../UI/Image";
import Button from "../ButtonComponent/Button";
import Icon from "../IconComponent/Icon";
import tokpedIcon from "../../../assets/svg/tokped.svg";
import publicImg from "../../../assets/svg/public_icon.svg";
import privateImg from "../../../assets/svg/private_icon.svg";

// type = [job, public, private]

const Card = ({
  imgCompany,
  title,
  type,
  isPrivate,
  company,
  region,
  salary,
  postedAt,
  onClick,
  ENV_NAME,
  ...props
}) => {
  return (
    <>
      {type === "job" ? (
        <div className={classHelper("J-cardComp", props.className)}>
          <div className="J-cardComp-icon">
            <Image src={imgCompany || tokpedIcon} />
          </div>
          <div className="J-cardComp-title">
            {company || "(Live Host) Social Media Specialist" || title}
            <p>{"Tokopedia"}</p>
          </div>
          <div className="J-cardComp-region">
            {region || "Malang, Jawa Timur"}
            <br />
            {salary || "IDR 3,5 jt - 6 jt per bulan"}
          </div>
          <div className="J-cardComp-postedAt">
            {postedAt || "Posted 1 day ago"}
          </div>
          <div className="J-cardComp-btn">
            <Button
              type="primary"
              size="md"
              title="Apply"
              className="w-100"
              btnClassName="widthCard"
            />
            <Icon
              iconType="bookmark"
              borderType="outline"
              className="px-8rem cursorPointer"
            />
          </div>
        </div>
      ) : type === "private" || type === "public" ? (
        <div
          className={classHelper(
            `J-pubPriv cursorPointer ${props.active ? "active" : ""}`,
            props.className
          )}
          onClick={onClick}
        >
          <div className="ppLeft">
            <Image
              src={type === "public" ? publicImg : privateImg}
              width="32rem"
              height="32rem"
            />
          </div>
          <div className="ppRight">
            <h1>
              {type === "public"
                ? Variable.PUBLIC[ENV_NAME]
                : Variable.PRIVATE[ENV_NAME]}
            </h1>
            <h4>
              {type === "public"
                ? Variable.PUBLIC_DESCRIPTION[ENV_NAME]
                : Variable.PRIVATE_DESCRIPTION[ENV_NAME]}
            </h4>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Card));
