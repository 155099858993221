import { FunctionComponent, ReactElement, useState } from "react";

import useTranslate from "shared/hooks/useTranslate";
import * as Variables from "shared/utils/variables";
import { RenderIf } from "shared/utils/common";
import { Typography } from "shared/component/Typography/Typography";

import DeactivateCloseAccount from "./DeactivateCloseAccount";
import AccountTab from "./AccountTab";
import NotificationTab from "./NotificationTab";

import { ReactComponent as ComingSoon } from "../../assets/svg/coming_soon.svg";

import "./settings.scss";

type TabsType = Record<
	string,
	{
		label: keyof typeof Variables;
		Component: React.JSXElementConstructor<any>;
		onDeactivateClose?: (item: string) => void;
	}
>;

const ComingSoonTab = () => {
	const t = useTranslate();

	return (
		<div className="d-flex flex-column justify-content-center align-items-center text-center my-5">
			<ComingSoon />
			<div className="my-2">
				<Typography.Title className="my-2">{t("COMING_SOON")}</Typography.Title>
				<Typography.Text fontWeight="regular" size="sm">
					{t("COMING_SOON_AVAILABLE")}
				</Typography.Text>
			</div>
		</div>
	);
};

const Settings: FunctionComponent = (): ReactElement => {
	const t = useTranslate();

	const [tabName, setTabName] = useState("account");
	const [startDeactivateClose, setStartDeactivateClose] = useState("");

	const Tabs: TabsType = {
		account: {
			label: "ACCOUNT",
			Component: AccountTab,
			onDeactivateClose: (status: string) => setStartDeactivateClose(status),
		},
		notification: {
			label: "NOTIFICATION",
			// Component: NotificationTab,
			Component: ComingSoonTab,
		},
	};

	const SelectedTab = Tabs[tabName];

	const handleChangeTabHeader = (name: string) => () => setTabName(name);

	return (
		<div className="settings">
			<div className="settings-container">
				<RenderIf condition={startDeactivateClose}>
					<DeactivateCloseAccount
						isDeactivating={startDeactivateClose === "deactivate"}
						onDeactivateClose={setStartDeactivateClose}
					/>
				</RenderIf>

				<RenderIf condition={!startDeactivateClose}>
					<div className="data-div">
						<h3>{t("SETTINGS")}</h3>
						<div className="header-div">
							{Object.keys(Tabs).map((tab: string) => (
								<div
									key={tab}
									onClick={handleChangeTabHeader(tab)}
									className={`header-item ${tabName === tab ? "selected" : ""}`}
								>
									<p>{t(Tabs[tab]?.label)}</p>
								</div>
							))}
							<hr />
						</div>
						<div className="content-div">
							<SelectedTab.Component
								onDeactivateClose={SelectedTab.onDeactivateClose}
							/>
						</div>
					</div>
				</RenderIf>
			</div>
		</div>
	);
};

export default Settings;
