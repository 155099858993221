import { Modal } from "react-bootstrap";

import * as Variable from "shared/utils/variables";
import Button from "shared/component/ButtonComponent/Button";
import { Text, Title } from "shared/component/Typography/Typography";

import "./ModalConfirm.scss";
import IconStatus from "./IconStatus";

type ModalDeactivateProps = React.PropsWithChildren & {
	title: string | React.ReactNode;
	show?: boolean;
	ENV_NAME: string;
	description?: string;
	type: "error" | "success";
	okLabel?: string;
	cancelLabel?: string;
	onCancel?: () => any;
	onOk?: () => any;
};

const ModalConfirmation = ({ show, onCancel, onOk, ENV_NAME, ...props }: ModalDeactivateProps) => {
	return (
		<Modal
			show={show}
			contentClassName="border-0 p-0"
			onHide={onCancel}
			dialogClassName="modal-dialog-centered modal-confirmation"
		>
			<Modal.Body className="modal-confirmation__modal-body">
				<div className="modal-confirmation__alert-icon-circle">
					<IconStatus type={props.type} />
				</div>
				<div>
					<Title size="xs" fontWeight="semibold" className="modal-title-label">
						{props.title}
					</Title>
					{props.description ? (
						<Text className="tw text-gray-500" fontWeight="medium">
							{props.description}
						</Text>
					) : (
						props.children
					)}
				</div>
				<div className="d-flex modal-confirmation__modal-body__button-groups">
					<Button
						type="outline"
						size="md"
						className="modal-confirmation__modal-body__button-groups-wrapper"
						btnClassName="modal-confirmation__modal-body__button-groups-btn"
						onClick={onCancel}
						title={props.cancelLabel || Variable.CANCEL_BTN_LABEL[ENV_NAME]}
					/>
					<Button
						type={props.type === "error" ? "error" : "primary"}
						size="md"
						className="modal-confirmation__modal-body__button-groups-wrapper"
						btnClassName="modal-confirmation__modal-body__button-groups-btn"
						onClick={onOk}
						title={props.okLabel || Variable.OK_BTN_LABEL[ENV_NAME]}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalConfirmation;
