import { useSelector } from "react-redux";

import { RootState } from "store/types/RootStateTypes";
import { ENV } from "types/types";

import { t } from "../utils/variables";
import * as Variable from "../utils/variables";

const useTranslate = () => {
	const { ENV_NAME } = useSelector((state: RootState) => ({
		ENV_NAME: state?.auth?.selectedEnvironment as ENV,
	}));

	/**
	 * @description Function to get translation by variable name
	 * @param key The variable name from Variable.js file
	 * @param params Additional parameters to replace {{n}} in the translation string
	 * @returns string
	 */
	const translate = (key: keyof typeof Variable | string, ...params: string[] | undefined[]) => {
		if (key === "" || key === undefined) return "";

		let res = t(Variable[key], ENV_NAME);
		if (params.length > 0) {
			// eslint-disable-next-line array-callback-return
			params.map((v: string | undefined, k: number) => {
				if (v !== undefined) res = res.replace(`{{${k}}}`, v);
			});
		}

		return res;
	};

	return translate;
};

export default useTranslate;
