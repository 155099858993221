import * as actionTypes from "../actions/actionTypes";

const initialState = {
	isAuthenticated: false,
	userDetails: null,
	selectedEnvironment: "bhs",
	userType: "",
	loginModalShow: false,
	jobTabActive: true,
	currency: { id: "IDR", name: "IDR" },
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOGOUT_SUCCEED: {
			return initialState;
		}
		case actionTypes.UPDATE_LANGUAGE: {
			if (action.payload)
				return {
					...state,
					selectedEnvironment: action.payload,
				};
			return state;
		}
		case actionTypes.UPDATE_CURRENCY: {
			if (action.payload)
				return {
					...state,
					currency: action.payload,
				};
			return state;
		}
		case actionTypes.LOGIN_SUCCEED_REDUX: {
			return {
				...state,
				isAuthenticated: true,
				userDetails: action.payload,
			};
		}
		case actionTypes.OPEN_LOGIN_MODAL: {
			return {
				...state,
				loginModalShow: true,
			};
		}
		case actionTypes.CLOSE_LOGIN_MODAL: {
			return {
				...state,
				loginModalShow: false,
			};
		}
		case actionTypes.ACTIVE_MENU_TAB: {
			return {
				...state,
				jobTabActive: true,
			}
		}
		default:
			return state;
	}
};
export default reducer;
